import React, { KeyboardEventHandler, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { TabButton } from '../../atoms/Buttons/TabButton/TabButton';
import { HeaderSideMenuTabs } from '../../constants/HeaderSideMenuTabs';
import { SubscriptionSource } from '../../models/Subscription/SubscriptionData';
import { setSideMenuActivePage } from '../../store/ducks/layout';
import { activePageSelector } from '../../store/ducks/layoutSelectors';
import { setSubscriptionSource } from '../../store/ducks/subscription/common';
import { AdvantageTabPanel } from '../AdvantageTabPanel/AdvantageTabPanel';
import { GemsShopTabPanel } from '../GemsShopTabPanel/GemsShopTabPanel';
import styles from './GemsShopContainer.css';

const GEMS_TAB = 'Discovered';
const GEMS_TAB_ID = `${GEMS_TAB}-tabpanel`;
const GEMS_TAB_ARIA = `${GEMS_TAB}-tab`;
const ADVANTAGE_TAB = 'Undiscovered';
const ADVANTAGE_TAB_ID = `${ADVANTAGE_TAB}-tabpanel`;
const ADVANTAGE_TAB_ARIA = `${ADVANTAGE_TAB}-tab`;

export const GemsShopContainer = () => {
    const sideMenuActivePage = useSelector(activePageSelector);
    const gemsTabRef = useRef<HTMLButtonElement | null>(null);
    const advantageTabRef = useRef<HTMLButtonElement | null>(null);
    const dispatch = useDispatch();

    const setTab = (tab: HeaderSideMenuTabs) => dispatch(setSideMenuActivePage(tab));

    const handleArrowClick: KeyboardEventHandler<HTMLDivElement> = (event) => {
        /** We use preventDefault method for preventing scrolling the page with arrow buttons usage.
         * If we call it outside of conditions Tab click behaviour will be broken.
         * */
        const { key } = event;
        if (key === 'ArrowLeft') {
            event.preventDefault();
            dispatch(setSideMenuActivePage(HeaderSideMenuTabs.SHOP_TAB));
            gemsTabRef.current?.focus();
        } else if (key === 'ArrowRight') {
            event.preventDefault();
            dispatch(setSideMenuActivePage(HeaderSideMenuTabs.SUBSCRIPTION_TAB));
            advantageTabRef.current?.focus();
        }
    };

    return (
        <>
            <div className={styles.tabList} role="tablist" onKeyDown={handleArrowClick}>
                <TabButton
                    ref={gemsTabRef}
                    tabKey={GEMS_TAB}
                    active={sideMenuActivePage === HeaderSideMenuTabs.SHOP_TAB}
                    onClick={() => setTab(HeaderSideMenuTabs.SHOP_TAB)}
                    className="gemsShopTab"
                >
                    Gems
                </TabButton>
                <TabButton
                    ref={advantageTabRef}
                    tabKey={ADVANTAGE_TAB}
                    active={sideMenuActivePage === HeaderSideMenuTabs.SUBSCRIPTION_TAB}
                    onClick={() => {
                        dispatch(setSubscriptionSource(SubscriptionSource.SHOP_AA_TAB));
                        setTab(HeaderSideMenuTabs.SUBSCRIPTION_TAB);
                    }}
                    className="gemsShopTab"
                >
                    Arkadium Advantage
                </TabButton>
            </div>
            <>
                {sideMenuActivePage === HeaderSideMenuTabs.SHOP_TAB && (
                    <div
                        className={styles.tabPanel}
                        role="tabpanel"
                        id={GEMS_TAB_ID}
                        aria-labelledby={GEMS_TAB_ARIA}
                        tabIndex={0}
                    >
                        <GemsShopTabPanel />
                    </div>
                )}
                {sideMenuActivePage === HeaderSideMenuTabs.SUBSCRIPTION_TAB && (
                    <div
                        className={styles.tabPanel}
                        role="tabpanel"
                        id={ADVANTAGE_TAB_ID}
                        aria-labelledby={ADVANTAGE_TAB_ARIA}
                        tabIndex={0}
                    >
                        <AdvantageTabPanel />
                    </div>
                )}
            </>
        </>
    );
};
