import { SubscriptionSourceType } from '../../models/Subscription/SubscriptionData';
import { GiftCardAnalyticsAi } from './AI/GiftCardAnalyticsAi';
import { AnalyticsEventsPair, makeEventsPair } from './AnalyticsEventBuilder';
import { GiftCardAnalyticsGaGa } from './GA/GiftCardAnalyticsGa';

export class GiftCardAnalytics {
    aiAnalytics = new GiftCardAnalyticsAi();
    gaAnalytics = new GiftCardAnalyticsGaGa();

    async gifterStart(location: SubscriptionSourceType): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(this.gaAnalytics.gifterStart(location), this.aiAnalytics.gifterStart(location));
    }

    async giftRecipientName(location: SubscriptionSourceType): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.giftRecipientName(location),
            this.aiAnalytics.giftRecipientName(location)
        );
    }

    async giftRecipientEmail(location: SubscriptionSourceType): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.giftRecipientEmail(location),
            this.aiAnalytics.giftRecipientEmail(location)
        );
    }

    async giftConfirmEmail(location: SubscriptionSourceType): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.giftConfirmEmail(location),
            this.aiAnalytics.giftConfirmEmail(location)
        );
    }

    async giftDeliveryDate(location: SubscriptionSourceType): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.giftDeliveryDate(location),
            this.aiAnalytics.giftDeliveryDate(location)
        );
    }

    async giftCustomMessage(location: SubscriptionSourceType): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.giftCustomMessage(location),
            this.aiAnalytics.giftCustomMessage(location)
        );
    }

    async giftSenderName(location: SubscriptionSourceType): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.giftSenderName(location),
            this.aiAnalytics.giftSenderName(location)
        );
    }

    async giftSenderEmail(location: SubscriptionSourceType): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.giftSenderEmail(location),
            this.aiAnalytics.giftSenderEmail(location)
        );
    }

    async gifterNextStep(location: SubscriptionSourceType): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.gifterNextStep(location),
            this.aiAnalytics.gifterNextStep(location)
        );
    }

    async gifterSendAgain(location: SubscriptionSourceType): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.gifterSendAgain(location),
            this.aiAnalytics.gifterSendAgain(location)
        );
    }

    async gifterFormViewable(location: SubscriptionSourceType): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.gifterFormViewable(location),
            this.aiAnalytics.gifterFormViewable(location)
        );
    }
}
