import React, { FC, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';

import { routes } from '../../../routes';
import { Avatar, AvatarTypes } from '../../atoms/Avatar/Avatar';
import { I18nText } from '../../atoms/i18nText/i18nText';
import { ArkadiumFullLogo } from '../../atoms/Icons/Styleguide/ArkadiumLogo/ArkadiumLogo';
import { NavLink } from '../../atoms/Link/Link';
import { PadLockIcon } from '../../FigmaStyleguide/Icons/PadLockIcon';
import UserService from '../../services/UserService';
import styles from './AppBodySubscription.css';

const HeaderLogo = (props: any) => {
    return (
        <ArkadiumFullLogo
            className={props.className}
            arrowInheritColor={props.isSideMenuOpened}
            textInheritColor={props.isSideMenuOpened}
        />
    );
};

export const AppBodySubscription: FC = () => {
    AppBodySubscription.displayName = 'AppBodySubscription';

    const user = useSelector((state) => state.user);
    const userAvatar = useSelector((state) => state.user?.avatar || state.user?.subscriberAvatar?.avatar || '');
    const redirectUrl = '/?subsLogout=1';

    useEffect(() => {
        document.body.style.overflow = 'visible';
    }, []);

    const handleLogOut = () => {
        UserService.userLogout();
        window.location.href = redirectUrl;
    };
    return (
        <div className={styles.sub_body}>
            <div className={styles.sub_header}>
                <HeaderLogo className={styles.logo_block} />
                <div className={styles.secure_elem}>
                    <PadLockIcon />
                    <h1 className={styles.secure_label}>Secure checkout</h1>
                </div>
                {user && (
                    <div className={styles.avatar_block}>
                        <Avatar
                            image={userAvatar}
                            selectable={false}
                            selected={false}
                            size={AvatarTypes.SMALL}
                            onSelect={() => {}}
                            disabled
                        />
                    </div>
                )}
            </div>
            <div className={styles.sub_wrapper}>{renderRoutes(routes)}</div>
            <div className={styles.sub_footer}>
                {user && (
                    <div className={styles.sub_footer__text}>
                        Not {user.name}?{' '}
                        <NavLink onClick={handleLogOut} className={styles.logoutLink}>
                            <I18nText keyName="LOG_OUT" />
                        </NavLink>
                    </div>
                )}
            </div>
        </div>
    );
};
