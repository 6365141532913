import React, { useEffect, useState } from 'react';

import classnames from 'classnames';
import { push } from 'connected-react-router';
import { batch, useDispatch } from 'react-redux';

import { Responsive } from '../../atoms/Layout/Responsive';
import { SubscriptionSource } from '../../models/Subscription/SubscriptionData';
import { Analytics } from '../../services/Analytics/Analytics';
import { TranslationService } from '../../services/TranslationService';
import UserService from '../../services/UserService';
import { setSubscriptionSource } from '../../store/ducks/subscription/common';
import { Button } from '../Button/Button';
import styles from './GemsTabAdvantagePromo.css';

type Text = {
    title: string;
    description: string;
};

type GemsAdvantagePromoRenderListProps = {
    textsList: Text[];
};

type GemsTabAdvantagePromoProps = {
    storyIsForSubscriber?: boolean;
    storyTextsList?: Text[];
    storySubscribeButtonText?: string;
    storySubscribeButtonTextNow?: string;
    storySubscriberText?: string;
    storySubscriberTextDesktop?: string;
};

export const TEXTS_LIST: Text[] = [
    { title: 'Save 15%', description: ' on gems' },
    { title: 'Play 100+', description: ' games ad-free' },
    { title: 'Early access', description: ' to NEW games' },
];
const SUBSCRIBER_TEXT = TranslationService.translateIt('GEMS_TAB_AA_PROMO_SUBSCRIBER_TEXT');
const SUBSCRIBER_TEXT_DESKTOP = TranslationService.translateIt('GEMS_TAB_AA_PROMO_SUBSCRIBER_TEXT_DESKTOP');

const DESKTOP_TEXT_BREAKPOINT = 1025;

const GemsAdvantagePromoRenderLogo = () => (
    <img className={styles.logo} alt="Arkadium Advantage logo" src="/images/gems/ArkadiumAdvantagePromoLogo.svg" />
);
const GemsAdvantagePromoSubscriberText = ({ subscriberText, subscriberTextDesktop }) => (
    <>
        <Responsive minWidth={DESKTOP_TEXT_BREAKPOINT}>
            <div className={styles.subscriberText}>{subscriberTextDesktop}</div>
        </Responsive>
        <Responsive maxWidth={DESKTOP_TEXT_BREAKPOINT - 1}>
            <div className={styles.subscriberText}>{subscriberText}</div>
        </Responsive>
    </>
);
const GemsAdvantagePromoRenderList = ({ textsList }: GemsAdvantagePromoRenderListProps) => (
    <ul className={styles.list}>
        {textsList &&
            textsList.length &&
            textsList.map(
                (texts, index) =>
                    texts &&
                    texts.title &&
                    texts.description && (
                        <li className={styles.list_item} key={index}>
                            <span className={styles.list_item_title}>{texts.title}</span>
                            {texts.description}
                        </li>
                    )
            )}
    </ul>
);
const GemsAdvantagePromoRenderButton = ({ handleSubscribe, subscribeButtonText, subscribeButtonTextDesktop }) => (
    <>
        <Responsive maxWidth={DESKTOP_TEXT_BREAKPOINT - 1}>
            <Button className={styles.button} advantageBlue={true} onClick={handleSubscribe}>
                {subscribeButtonText}
            </Button>
        </Responsive>
        <Responsive minWidth={DESKTOP_TEXT_BREAKPOINT}>
            <Button className={styles.button} advantageBlue={true} onClick={handleSubscribe}>
                {subscribeButtonTextDesktop}
            </Button>
        </Responsive>
    </>
);

export const GemsTabAdvantagePromo = ({
    storyIsForSubscriber,
    storyTextsList,
    storySubscribeButtonText,
    storySubscribeButtonTextNow,
    storySubscriberText,
    storySubscriberTextDesktop,
}: GemsTabAdvantagePromoProps) => {
    const textsList = storyTextsList || TEXTS_LIST;
    const [isForSubscriber, setIsSubscriber] = useState(storyIsForSubscriber || false);
    const subscribeButtonText = storySubscribeButtonTextNow || TranslationService.translateIt('SUBSCRIBE_NOW');
    const subscribeButtonTextDesktop = storySubscribeButtonText || TranslationService.translateIt('SUBSCRIBE');
    const getSubscriberText = (text) => text.split('\n').map((text, index) => <p key={index}>{text}</p>);
    const subscriberText = getSubscriberText(storySubscriberText || SUBSCRIBER_TEXT);
    const subscriberTextDesktop = getSubscriberText(storySubscriberTextDesktop || SUBSCRIBER_TEXT_DESKTOP);
    const user = UserService.getUserFromStore();
    const isUserSubscriber = UserService.isUserSubscriber();
    const dispatch = useDispatch();
    const handleSubscribe = async () => {
        batch(() => {
            dispatch(setSubscriptionSource(SubscriptionSource.SHOP_AA_BANNER));
            dispatch(push('/subscription'));
        });
        await Analytics.trackEvent(Analytics.gems.gemShopSubscribeBanner());
    };

    useEffect(() => {
        setIsSubscriber(storyIsForSubscriber || isUserSubscriber);
    }, [user, storyIsForSubscriber, isUserSubscriber]);

    return (
        <div className={classnames(styles.container, { [styles.__forSubscribers]: isForSubscriber })}>
            <GemsAdvantagePromoRenderLogo />
            {isForSubscriber && <GemsAdvantagePromoSubscriberText {...{ subscriberText, subscriberTextDesktop }} />}
            {!isForSubscriber && <GemsAdvantagePromoRenderList {...{ isForSubscriber, textsList }} />}
            {!isForSubscriber && (
                <GemsAdvantagePromoRenderButton
                    {...{ handleSubscribe, subscribeButtonText, subscribeButtonTextDesktop }}
                />
            )}
        </div>
    );
};
