export enum SignInUpComponents {
    SIGN_UP = 'SIGN_UP',
    SIGN_IN = 'SIGN_IN',
    AFTER_SIGN = 'AFTER_SIGN',
    AFTER_SIGN_COMPLETE = 'AFTER_SIGN_COMPLETE',
    AFTER_SIGN_SOCIAL = 'AFTER_SIGN_SOCIAL',
    THANK_YOU = 'THANK_YOU',
    RESET_PASSWORD = 'RESET_PASSWORD',
    RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
    CONFIRM_RESET_PASSWORD = 'CONFIRM_RESET_PASSWORD',
    REQUEST_EMAIL = 'REQUEST_EMAIL',
    REQUEST_ACTIVATION = 'REQUEST_ACTIVATION',
    PERSONALIZE_PROFILE = 'PERSONALIZE_PROFILE',
    COMPLETE_SOCIAL_PROFILE = 'COMPLETE_SOCIAL_PROFILE',
}
