import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { useSelector, useDispatch, batch } from 'react-redux';

import { MiscUtils } from '../../../utils/MiscUtils';
import { AppLoader } from '../../atoms/AppLoader/AppLoader';
import { Button } from '../../FigmaStyleguide/Button/Button';
import { DIALOG_CONTENT_TYPES, LS_COOKIE_CONSTS } from '../../models/Enums';
import { DialogWrapper } from '../../molecules/Dialog/Dialog';
import { Analytics } from '../../services/Analytics/Analytics';
import { LEANPLUM_EVENTS, LeanplumAnalytics } from '../../services/Analytics/LeanplumAnalytics';
import { CookieService } from '../../services/CookieService';
import { LocalStorageService } from '../../services/LocalStorage';
import UserService from '../../services/UserService';
import { setCategoryPromoState } from '../../store/ducks/categoryPromo/categoryPromo';
import { setDialogData } from '../../store/ducks/dialog/dialog';
import styles from './CategoryPromo.css';


enum themes {
    DEFAULT = 'defaultTheme',
    WHITE = 'whiteTheme',
}

export const CategoryPromo = React.memo(() => {
    const userProcessed = useSelector((state) => state.userProcessed);
    const user = useSelector((state) => state.user);
    const categoryPromoState = useSelector((state) => state.categoryPromoState);
    const categoryPromoData = useSelector((state) => state.categoryPromoData);
    const { isCollectBtnDisabled } = categoryPromoState;
    const textColorTheme: string = categoryPromoData?.whiteThemeOn ? themes.WHITE : themes.DEFAULT;
    const [bgImage, setBgImage] = useState(getBgImage());

    const dispatch = useDispatch();
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [hovered, setHovered] = useState(false);
    const [loading, setLoading] = useState(true);

    const authProvider = LocalStorageService.getItem('authProvider');

    function getBgImage() {
        if (MiscUtils.isServer) {
            return '';
        }
        const matchesMedia = (window as any).matchMedia('(max-width: 600px)').matches;
        const url = matchesMedia ? categoryPromoData?.promoBGImageMobile?.url : categoryPromoData?.promoBGImage?.url;
        return `url(${url})`;
    }

    const handleBtnClick = () => {
        if (isUserLoggedIn) {
            sweeptakesPromoCookieSet();
            batch(() => {
                dispatch(setDialogData({ open: true, type: DIALOG_CONTENT_TYPES.CATEGORY_PROMO_DIALOG }));
                dispatch(setCategoryPromoState({ isCollectBtnDisabled: true }));
            });
            Analytics.trackEvent(Analytics.categories.claimDailyEntry(MiscUtils.getAuthSource(authProvider)));
            LeanplumAnalytics.trackEvent(LEANPLUM_EVENTS.CLAIM_ENTRY, { promo: categoryPromoData?.categoryName }); // Problem with WINDOW
        } else {
            UserService.openSignUpPanel();
        }
    };

    useEffect(() => {
        const updateWindowDimensions = () => {
            setBgImage(getBgImage());
        };
        const debounceEvent = debounce(updateWindowDimensions, 300);

        window.addEventListener('resize', debounceEvent);

        return () => window.removeEventListener('resize', debounceEvent);
    }, []);

    useEffect(() => {
        if (userProcessed) {
            // if CATEGORY_PROMO_STATUS cookie exists then disable btn
            CookieService.getArkCookie(LS_COOKIE_CONSTS.CATEGORY_PROMO_STATUS) &&
                dispatch(setCategoryPromoState({ isCollectBtnDisabled: true }));
            setLoading(false);
            user ? setIsUserLoggedIn(true) : setIsUserLoggedIn(false);
        }
    }, [userProcessed, user]);

    const convertText = (text: string) => {
        // additional security check
        const scriptRegex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
        text = text.replace(scriptRegex, '');

        // a tag coversion
        const pattern = /\[(.*)\]\((.*)\)/;
        return text.replace(
            pattern,
            '<a href="$2" target="_blank" rel="noopener noreferrer" aria-label="pdf file">$1</a>'
        );
    };

    const onMouseOver = () => {
        if (!hovered) {
            // preload popup image on button hover
            const img = new Image();
            img.src = categoryPromoData?.promoPopupBG?.url;
            setHovered(true);
        }
    };

    return (
        <div
            className={styles.wrapper}
            style={{
                backgroundImage: bgImage,
            }}
        >
            <DialogWrapper />
            <img src={categoryPromoData?.promoLeftImage?.url} alt="promo-logo" />
            <div className={classNames(styles.content, styles[textColorTheme])}>
                <p className={styles.title}>{categoryPromoData?.promoTitle}</p>
                {loading ? (
                    <AppLoader className={styles.loader} />
                ) : (
                    <>
                        <p
                            className={styles.subtitle}
                            dangerouslySetInnerHTML={{
                                __html: isCollectBtnDisabled
                                    ? convertText(categoryPromoData?.promoTextClicked)
                                    : convertText(categoryPromoData?.promoText),
                            }}
                        ></p>
                        {!isCollectBtnDisabled && (
                            <Button onClick={handleBtnClick} className={styles.btn} onMouseOver={onMouseOver}>
                                {isUserLoggedIn
                                    ? categoryPromoData?.promoButtonTextRegistered
                                    : categoryPromoData?.promoButtonTextUnRegistered}
                            </Button>
                        )}
                    </>
                )}
            </div>
        </div>
    );
});

export function sweeptakesPromoCookieSet(isTest = false) {
    const timezoneOffsetLocal =
        new Date().getTimezoneOffset() * // returns -240 minutes
        60 * // seconds
        1000; // milliseconds
    const timezoneOffsetEST =
        -4 * // hours (-5 for real EST, but ET as EST used in fact at the moment)
        60 * // minutes
        60 * // seconds
        1000; // milliseconds;
    const resetTime = new Date();
    resetTime.setHours(24, 1, 0, 0); // next midnight
    const updateDate = new Date(
        resetTime.getTime() - // next EST midnight
            timezoneOffsetLocal - // for local to UTC
            timezoneOffsetEST // for EST to UTC
    );
    const expires = updateDate.toUTCString();
    if (!isTest) {
        document.cookie = `${LS_COOKIE_CONSTS.CATEGORY_PROMO_STATUS}=disabled; expires=${expires};path=/;`;
    } else {
        document.cookie = `${LS_COOKIE_CONSTS.CATEGORY_PROMO_STATUS}__TEST=disabled; expires=${expires};path=/;`;
    }
}
