import { GameEvents } from '../../models/Game/GameObservable';
import { UrlService } from '../UrlService';
import { getLeanplum } from './LeanplumAnalytics';

const GameMarketingActionQsParamName = 'MarketingAction';
const targetTabQsParamName = 'targetTab';

// Supported marketing actions (via url's search param GameMarketingActionQsParamName - ?MarketingAction=)
export enum GameMarketingActions {
    USE_HINT = 'UseHint',
}

// Marketing actions' additional callbacks
const reactionsToLeanplumActions = {
    [GameMarketingActions.USE_HINT]: function () {},
};

// Marketing actions mounting script
export function handleLeanplumMarketingActions(
    functionToToggleOff?: (e: any) => void, // add or remove handler
    isGameActionWithBoundThis: boolean = false // for games actions (need this.gameSubject)
) {
    const Leanplum = getLeanplum();
    if (!Leanplum) {
        return;
    }
    const gameSubject = isGameActionWithBoundThis ? this?.gameSubject : null;
    const leanplumEventsHandler = (e) => handleLeanplumEvents(e, gameSubject); // to keep context

    Leanplum[functionToToggleOff ? 'off' : 'on']('openUrl', leanplumEventsHandler);

    return leanplumEventsHandler; // return isolated handler function to use in turn off

    function handleLeanplumEvents(e, gameSubject?) {
        // prevent default open
        e?.preventDefault?.();
        const { url } = e;
        // url can be both "?MarketingAction=UseHint" or just "UseHint"
        const actionParam = UrlService.getQSParam(url, GameMarketingActionQsParamName) || url || '';
        const actionKey = Object.keys(GameMarketingActions).find(
            (definedAction) =>
                GameMarketingActions[definedAction].toString().toLowerCase() === actionParam.toLowerCase()
        );
        const actionName = GameMarketingActions?.[actionKey] || actionParam || '';
        if (actionKey && !functionToToggleOff) {
            if (actionKey && GameMarketingActions?.[actionKey]) {
                // do some arena side logic if exists
                reactionsToLeanplumActions?.[actionKey]?.();
            }
            if (Boolean(gameSubject)) {
                // fire event for game
                const event = {
                    type: GameEvents.MARKETING_ACTION, // main event type
                    payload: {
                        action: actionName, /// CRV ToDo: Check if more payload should be provided
                    },
                };
                gameSubject?.dispatch?.(event);
            }
        } else if (url) {
            const targetParam = UrlService.getQSParam(url, targetTabQsParamName);
            const target =
                targetParam && ['_self', '_blank', '_parent', '_top'].includes(targetParam) ? targetParam : '_self';
            window?.open?.(url, target);
        }
    }
}
