import React from 'react';

import { I18nText } from '../../atoms/i18nText/i18nText';
import { Button } from '../../FigmaStyleguide/Button/Button';
import { UrlService } from '../../services/UrlService';
import styles from './GemShopErrorView.css';
type GemShopErrorViewProps = {
    tryAgainHandler: () => void;
    goBackHandler: () => void;
};
export const GemShopErrorView = React.memo(({ tryAgainHandler, goBackHandler }: GemShopErrorViewProps) => {
    return (
        <div className={styles.gemErrorContainer}>
            <img
                className={styles.image}
                src={UrlService.createURL('/images/gems/graphics/safe.png')}
                alt={'Safe with gems graphic'}
            />
            <I18nText as="h2" className={styles.message} keyName="GEMS_SHOP_ERROR" />
            <Button onClick={tryAgainHandler}>Try again</Button>
            <Button onClick={goBackHandler}>Go back</Button>
        </div>
    );
});
GemShopErrorView.displayName = 'GemShopErrorView';
