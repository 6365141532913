import React from 'react';

import { useDispatch } from 'react-redux';

import { I18nText } from '../../../atoms/i18nText/i18nText';
import { ArrowSmallIcon } from '../../../atoms/Icons/Styleguide/ArrowSmallIcon';
import { setMobileNavigationUxRedesignedActiveButton } from '../MobileNavigationUxRedesign.ducks';
import styles from './styles.css';

export const IconBack = () => {
    const dispatch = useDispatch();
    const actionBackButtonClick = () => {
        dispatch(setMobileNavigationUxRedesignedActiveButton(null));
    };
    return (
        <button key="back" className={styles.BackIcon} onClick={actionBackButtonClick}>
            <ArrowSmallIcon className={styles.BackIcon_icon} />
            <I18nText keyName="SIDEBAR_HIDE" className={styles.BackIcon_caption} />
        </button>
    );
};
