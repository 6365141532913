import { createAction, createReducer } from 'redux-act';

export type arkConfigType = {
  textCaptchaOn1StepPurchase: string;
  lpDelayWakeUp: number;
  feedId: string,
  disablePreroll: {
    starDate: string;
    endDate: string;
    env: string[];
    days: number;
  };
};

export const arkConfigReducer = createReducer<arkConfigType>({}, null);
export const setArkConfigData = createAction<arkConfigType>('set ark config');
arkConfigReducer.on(setArkConfigData, (_state, payload) => payload);
