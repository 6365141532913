import { GameCardTypes } from "./GameCard";
import { StringUtils } from "../../../utils/StringUtils";
import { defaultNonEnSupportedLocales } from "../../constants/Locales";
import { UrlService } from "../../services/UrlService";
import { GameCardCobrandedTypes } from "../GameCardCobranded/GameCardCobranded";

export const getDescriptionDisabled = (gameCardType: GameCardTypes|GameCardCobrandedTypes, pseudoTile: boolean, disableDescription: boolean): boolean => {
  let isDescriptionDisabled = disableDescription;

  if (defaultNonEnSupportedLocales.find((l) => StringUtils.equalIgnoreCase(l, UrlService.currentLang))) {
    isDescriptionDisabled = true;
  }

  if (gameCardType === GameCardTypes.SQUARE || pseudoTile) {
    isDescriptionDisabled = true;
  }

  return isDescriptionDisabled;
};
