import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import styles from './Ad.css';
import { I18nText } from '../../atoms/i18nText/i18nText';
import { LS_COOKIE_CONSTS } from '../../models/Enums';
import { CookieService } from '../../services/CookieService';
import { DisplayAd } from '../DisplayAd/DisplayAd';

export enum AdTypes {
    AD_300x250 = 'AD_300x250',
    AD_250x250 = 'AD_250x250',
    AD_336x280 = 'AD_336x280',
    AD_300x600 = 'AD_300x600',
    AD_728x90 = 'AD_728x90',
    AD_970x90 = 'AD_970x90',
    AD_970x250 = 'AD_970x250',
    AD_320x50 = 'AD_320x50',
    AD_320x100 = 'AD_320x100',
    AD_468x60 = 'AD_468x60',
    AD_160x600 = 'AD_160x600',
}

export enum CaptionPositionTypes {
    LEFT = 'left',
    CENTER = 'center',
    RIGHT = 'right',
}

export enum BackgroundToneTypes {
    LIGHT = 'light',
    DARK = 'dark',
}

const AdTypesSizes = {
    [AdTypes.AD_300x250]: [300, 250],
    [AdTypes.AD_250x250]: [250, 250],
    [AdTypes.AD_336x280]: [336, 280],
    [AdTypes.AD_300x600]: [300, 600],
    [AdTypes.AD_728x90]: [728, 90],
    [AdTypes.AD_970x90]: [970, 90],
    [AdTypes.AD_970x250]: [970, 250],
    [AdTypes.AD_320x50]: [320, 50],
    [AdTypes.AD_320x100]: [320, 100],
    [AdTypes.AD_468x60]: [468, 60],
    [AdTypes.AD_160x600]: [160, 600],
};

type AdProps = {
    id: string;
    adType: AdTypes[];
    captionPosition?: CaptionPositionTypes;
    hasBackground?: boolean;
    backgroundTone?: BackgroundToneTypes;
    keepSize?: boolean;
    className?: string;
    hideCaption?: boolean;
    useDefaultBidTimeout?: boolean;
    refreshRate?: number;
    viewableOnly?: boolean;
    keyValues?: [string[]];
    isContainerDisabled?: boolean;
};

export const Ad = React.memo((props: AdProps) => {
    const {
        id,
        adType,
        captionPosition,
        hasBackground,
        backgroundTone,
        keepSize,
        className,
        useDefaultBidTimeout,
        refreshRate,
        viewableOnly,
        keyValues,
        isContainerDisabled,
    } = props;
    const [hideCaption, setHideCaption] = useState(true);

    useEffect(() => {
        const isAdfree = CookieService.getArkCookie(LS_COOKIE_CONSTS.AD_FREE_VER) === 'true';
        const isSubscriber = CookieService.getArkCookie(LS_COOKIE_CONSTS.SUBSCRIPTION) === 'true';

        if (isAdfree || isSubscriber) {
            setHideCaption(true);
        } else {
            setHideCaption(false);
        }
    }, []);

    const isHorizontalAd = [AdTypes.AD_320x50, AdTypes.AD_320x100, AdTypes.AD_468x60, AdTypes.AD_728x90].some((r) =>
        adType.includes(r)
    );
    // this help ad container to keep size even if there is no ad arrived (adblocker etc.)
    let adContainerFixedSizeClassName = '';

    if (keepSize) {
        // since we do not know the exact size in advance, we will use first size provided, so always enumerate sizes from larger to smaller order
        switch (adType[0]) {
            case AdTypes.AD_160x600:
                adContainerFixedSizeClassName = styles.adContainer_160x600;
                break;
            case AdTypes.AD_300x250:
                adContainerFixedSizeClassName = styles.adContainer_300x250;
                break;
            case AdTypes.AD_250x250:
                adContainerFixedSizeClassName = styles.adContainer_250x250;
                break;
            case AdTypes.AD_336x280:
                adContainerFixedSizeClassName = styles.adContainer_336x280;
                break;
            case AdTypes.AD_300x600:
                adContainerFixedSizeClassName = styles.adContainer_300x600;
                break;
            case AdTypes.AD_320x50:
                adContainerFixedSizeClassName = styles.adContainer_320x50;
                break;
            case AdTypes.AD_320x100:
                adContainerFixedSizeClassName = styles.adContainer_320x100;
                break;
            case AdTypes.AD_468x60:
                adContainerFixedSizeClassName = styles.adContainer_468x60;
                break;
            case AdTypes.AD_728x90:
                adContainerFixedSizeClassName = styles.adContainer_728x90;
                break;
            case AdTypes.AD_970x90:
                adContainerFixedSizeClassName = styles.adContainer_970x90;
                break;
            case AdTypes.AD_970x250:
                adContainerFixedSizeClassName = styles.adContainer_970x250;
                break;
            default:
                break;
        }
    }

    const matchAdSize = (types: AdTypes[]): any[] => {
        const result = [];

        types.forEach((t) => {
            result.push(AdTypesSizes[t]);
        });
        return result;
    };
    const options: any = {};

    if (!useDefaultBidTimeout) {
        options.bidtimeout = 1200;
    }

    options.viewableOnly = viewableOnly;
    options.refreshRate = refreshRate;

    return (
        <div
            className={classNames(
                styles.adContainer,
                {
                    [styles.disabledContainer]: isContainerDisabled,
                    [styles.horizontalAd]: isHorizontalAd,
                    [styles.hasBackground]: hasBackground,
                    [styles.adContainerBackgroundLight]: hasBackground && backgroundTone === BackgroundToneTypes.LIGHT,
                    [styles.adContainerBackgroundDark]: hasBackground && backgroundTone === BackgroundToneTypes.DARK,
                },
                className
            )}
            suppressHydrationWarning
        >
            {!hideCaption && (
                <I18nText
                    keyName="ADVERTISMENT_CARD"
                    className={classNames(styles.caption, {
                        [styles.captionLight]: backgroundTone === BackgroundToneTypes.LIGHT,
                        [styles.captionDark]: backgroundTone === BackgroundToneTypes.DARK,
                        [styles.captionAlignLeft]: captionPosition === CaptionPositionTypes.LEFT,
                        [styles.captionAlignCenter]: captionPosition === CaptionPositionTypes.CENTER,
                        [styles.captionAlignRight]: captionPosition === CaptionPositionTypes.RIGHT,
                        [styles.hideCaption]: Boolean(hideCaption),
                    })}
                />
            )}
            <DisplayAd
                dimensions={matchAdSize(adType)}
                id={id}
                className={adContainerFixedSizeClassName}
                keyValues={keyValues}
                {...options}
            />
        </div>
    );
});
