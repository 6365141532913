import { PageTypes } from '../../../constants/Pages';
import { RecurlyGemsRibbonLabels } from '../../../constants/RecurlyGemPackItems';
import {
    GemsAnalyticsRegistrationLocations,
    GemsAnalyticsShopLocations,
    LS_SHOP_LOCATION_PROP,
} from '../../../store/ducks/leanplum/lpAnalytics';
import {
    gemsGetCurrentAmount,
    gemsIsFirstPurchase,
    getGemsVirtualItemsSpentFromLocalStorage,
} from '../../GemsService';
import { LocalStorageService } from '../../LocalStorage';
import UserService from '../../UserService';
import { AnalyticsEvent, AnalyticsEventAction, AnalyticsEventBuilder } from '../AnalyticsEventBuilder';

export enum GemsDimensionsMocks {
    SPECIAL_MARK = 'not provided by eagle yet',
    BOOST_INTERNAL_PRICE = 'not provided by games yet',
}

export enum GemsAnalyticsEvents {
    // not changed keys because these events are dedicated to gem shop anyway
    gemShopImpression = 'virtualItemShopImpression',
    gemPurchaseClick = 'virtualItemPurchaseClick',
    gemPurchaseNextStep = 'virtualItemPurchaseNextStep',
    gemPurchaseChangeGems = 'virtualItemPurchaseChange',
    gemPurchaseSuccess = 'virtualItemPurchaseSuccess',
    gemSkipAdButton = 'virtualItemSkipAdButton',
    gemSpendingSkipAdSuccess = 'virtualItemSpendingSkipAdSuccess',
    gemSpendingPowerUp = 'virtualItemSpendingPowerUp',
    gemShopSignInUp = 'virtualItemShopSignInUp',
    gemShopSubscribeBanner = 'virtualItemShopSubscribeBanner',
    manageGems = 'manageVirtualItems',
}

export type GemsAnalyticsCustomDimensions = {
    gemsPackId: string;
    priceInGem?: number;
    gemsInPack?: number;
    isFirstPurchase?: boolean;
    shopLocation?: GemsAnalyticsShopLocations;
};
export type GemsAnalyticsCustomDimensionsNew = {
    // Gem packs
    purchasableItemId?: string; // ID of gems pack in shop
    purchasableItemAmount?: number; // Number of gems in corresponding pack
    internalPrice?: number | GemsDimensionsMocks.BOOST_INTERNAL_PRICE; // Price of gem pack in USD
    // Spending gems
    virtualItemId?: string; // Name of boost or power-up
    // Placements
    registrationLocation?: GemsAnalyticsRegistrationLocations;
    loginLocation?: GemsAnalyticsRegistrationLocations;
    shopLocation?: GemsAnalyticsShopLocations | PageTypes | string;
    // Were in "global CD" in task, seems to be accidentally
    isFirstPurchase?: boolean; // true/false if it is user's first gems purchase
    isFirstSpend?: boolean; // true/false if it is user's first gems spend
    specialMark?: RecurlyGemsRibbonLabels | GemsDimensionsMocks.SPECIAL_MARK; // gem pack's ribbon mark if it was used
    gameId?: string; // game id for shop page
};

export type GemsAnalyticsCustomDimensionsGlobal = {
    userInventory?: number; // user's current balance of gems
    virtualItemSpent?: true; // user spent gems at least once (call once for TRUE val => only type true)
};

export class GemsAnalyticsAi {
    // SHOP AND PURCHASING

    async gemShopImpression(shopLocation: GemsAnalyticsShopLocations | PageTypes): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName(GemsAnalyticsEvents.gemShopImpression);
        event.setEventAction(AnalyticsEventAction.IMPRESSION);
        event.setNonInteraction(false);
        event.setCustom('shopLocation', LocalStorageService.getItem(LS_SHOP_LOCATION_PROP) || shopLocation);
        event.setCustom('isSubscriber', UserService.isUserSubscriber());
        event.setCustom('userInventory', await gemsGetCurrentAmount());
        return event.build();
    }

    async gemPurchaseClick({
        shopLocation,
        internalPrice,
        purchasableItemId,
        purchasableItemAmount,
        specialMark,
    }: GemsAnalyticsCustomDimensionsNew): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName(GemsAnalyticsEvents.gemPurchaseClick);
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        event.setCustom('shopLocation', LocalStorageService.getItem(LS_SHOP_LOCATION_PROP) || shopLocation);
        event.setCustom('internalPrice', internalPrice);
        event.setCustom('purchasableItemId', purchasableItemId);
        event.setCustom('purchasableItemAmount', purchasableItemAmount);
        event.setCustom('isFirstPurchase', await gemsIsFirstPurchase());
        event.setCustom('specialMark', specialMark);
        event.setCustom('isSubscriber', UserService.isUserSubscriber());
        event.setCustom('userInventory', await gemsGetCurrentAmount());
        return event.build();
    }

    async gemPurchaseClickImpression({
        internalPrice,
        purchasableItemId,
        purchasableItemAmount,
        specialMark,
        shopLocation,
    }: GemsAnalyticsCustomDimensionsNew): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName(GemsAnalyticsEvents.gemPurchaseClick);
        event.setEventAction(AnalyticsEventAction.IMPRESSION);
        event.setNonInteraction(false);
        event.setCustom('shopLocation', LocalStorageService.getItem(LS_SHOP_LOCATION_PROP) || shopLocation);
        event.setCustom('internalPrice', internalPrice);
        event.setCustom('purchasableItemId', purchasableItemId);
        event.setCustom('purchasableItemAmount', purchasableItemAmount);
        event.setCustom('isFirstPurchase', await gemsIsFirstPurchase());
        event.setCustom('specialMark', specialMark);
        event.setCustom('isSubscriber', UserService.isUserSubscriber());
        event.setCustom('userInventory', await gemsGetCurrentAmount());
        return event.build();
    }

    async gemsPurchaseNextStepClick({
        internalPrice,
        purchasableItemId,
        purchasableItemAmount,
        specialMark,
        shopLocation,
    }: GemsAnalyticsCustomDimensionsNew): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName(GemsAnalyticsEvents.gemPurchaseNextStep);
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        event.setCustom('shopLocation', LocalStorageService.getItem(LS_SHOP_LOCATION_PROP) || shopLocation);
        event.setCustom('internalPrice', internalPrice);
        event.setCustom('purchasableItemId', purchasableItemId);
        event.setCustom('purchasableItemAmount', purchasableItemAmount);
        event.setCustom('isFirstPurchase', await gemsIsFirstPurchase());
        event.setCustom('specialMark', specialMark);
        event.setCustom('isSubscriber', UserService.isUserSubscriber());
        event.setCustom('userInventory', await gemsGetCurrentAmount());
        return event.build();
    }

    async gemsPurchaseNextStepImpression({
        internalPrice,
        purchasableItemId,
        purchasableItemAmount,
        specialMark,
        shopLocation,
    }: GemsAnalyticsCustomDimensionsNew): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName(GemsAnalyticsEvents.gemPurchaseNextStep);
        event.setEventAction(AnalyticsEventAction.IMPRESSION);
        event.setNonInteraction(false);
        event.setCustom('shopLocation', LocalStorageService.getItem(LS_SHOP_LOCATION_PROP) || shopLocation);
        event.setCustom('internalPrice', internalPrice);
        event.setCustom('purchasableItemId', purchasableItemId);
        event.setCustom('purchasableItemAmount', purchasableItemAmount);
        event.setCustom('isFirstPurchase', await gemsIsFirstPurchase());
        event.setCustom('specialMark', specialMark);
        event.setCustom('isSubscriber', UserService.isUserSubscriber());
        event.setCustom('userInventory', await gemsGetCurrentAmount());
        return event.build();
    }

    async gemsPurchaseChangeGems({
        shopLocation,
        internalPrice,
        purchasableItemId,
        purchasableItemAmount,
        specialMark,
    }: GemsAnalyticsCustomDimensionsNew): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName(GemsAnalyticsEvents.gemPurchaseChangeGems);
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        event.setCustom('shopLocation', LocalStorageService.getItem(LS_SHOP_LOCATION_PROP) || shopLocation);
        event.setCustom('internalPrice', internalPrice);
        event.setCustom('purchasableItemId', purchasableItemId);
        event.setCustom('purchasableItemAmount', purchasableItemAmount);
        event.setCustom('isFirstPurchase', await gemsIsFirstPurchase());
        event.setCustom('specialMark', specialMark);
        event.setCustom('isSubscriber', UserService.isUserSubscriber());
        event.setCustom('userInventory', await gemsGetCurrentAmount());
        return event.build();
    }

    async gemsPurchaseSuccess({
        internalPrice,
        purchasableItemId,
        purchasableItemAmount,
        shopLocation,
        gameId,
    }: GemsAnalyticsCustomDimensionsNew): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName(GemsAnalyticsEvents.gemPurchaseSuccess);
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        event.setCustom('shopLocation', LocalStorageService.getItem(LS_SHOP_LOCATION_PROP) || shopLocation);
        event.setCustom('internalPrice', internalPrice);
        event.setCustom('purchasableItemId', purchasableItemId);
        event.setCustom('purchasableItemAmount', purchasableItemAmount);
        event.setCustom('isFirstPurchase', await gemsIsFirstPurchase());
        event.setCustom('isSubscriber', UserService.isUserSubscriber());
        event.setCustom('userInventory', await gemsGetCurrentAmount());
        event.setCustom('game', gameId);
        return event.build();
    }

    async gemsPurchaseSuccessImpression({
        internalPrice,
        purchasableItemId,
        purchasableItemAmount,
        shopLocation,
        gameId,
    }: GemsAnalyticsCustomDimensionsNew): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName(GemsAnalyticsEvents.gemPurchaseSuccess);
        event.setEventAction(AnalyticsEventAction.IMPRESSION);
        event.setNonInteraction(false);
        event.setCustom('internalPrice', internalPrice);
        event.setCustom('purchasableItemId', purchasableItemId);
        event.setCustom('purchasableItemAmount', purchasableItemAmount);
        event.setCustom('shopLocation', LocalStorageService.getItem(LS_SHOP_LOCATION_PROP) || shopLocation);
        event.setCustom('isFirstPurchase', await gemsIsFirstPurchase());
        event.setCustom('isSubscriber', UserService.isUserSubscriber());
        event.setCustom('userInventory', await gemsGetCurrentAmount());
        event.setCustom('game', gameId);
        return event.build();
    }

    async gemSkipAdButton({ internalPrice }: GemsAnalyticsCustomDimensionsNew): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName(GemsAnalyticsEvents.gemSkipAdButton);
        event.setEventAction(AnalyticsEventAction.SKIP_AD);
        event.setNonInteraction(false);
        event.setCustom('internalPrice', internalPrice);
        event.setCustom('virtualItemId', 'pass ads with gem');
        event.setCustom('isSubscriber', UserService.isUserSubscriber());
        event.setCustom('userInventory', await gemsGetCurrentAmount());
        return event.build();
    }

    async gemSpendingSkipAdSuccess({
        internalPrice,
        isFirstSpend,
    }: GemsAnalyticsCustomDimensionsNew): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName(GemsAnalyticsEvents.gemSpendingSkipAdSuccess);
        event.setEventAction(AnalyticsEventAction.SKIP_AD);
        event.setNonInteraction(false);
        event.setCustom('internalPrice', internalPrice);
        event.setCustom('virtualItemId', 'pass ads with gem');
        event.setCustom('isFirstSpend', !getGemsVirtualItemsSpentFromLocalStorage());
        event.setCustom('isSubscriber', UserService.isUserSubscriber());
        event.setCustom('userInventory', await gemsGetCurrentAmount());
        return event.build();
    }

    async gemSpendingPowerUp({
        virtualItemId,
        internalPrice,
    }: GemsAnalyticsCustomDimensionsNew): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName(GemsAnalyticsEvents.gemSpendingPowerUp);
        event.setEventAction(AnalyticsEventAction.POWER_UP);
        event.setNonInteraction(false);
        event.setCustom('internalPrice', internalPrice);
        event.setCustom('virtualItemId', virtualItemId);
        event.setCustom('isFirstSpend', !getGemsVirtualItemsSpentFromLocalStorage());
        event.setCustom('isSubscriber', UserService.isUserSubscriber());
        event.setCustom('userInventory', await gemsGetCurrentAmount());
        return event.build();
    }

    async gemShopSignInUp(
        registrationLocation: GemsAnalyticsRegistrationLocations,
        isRegistration
    ): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName(GemsAnalyticsEvents.gemShopSignInUp);
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        if (isRegistration) {
            event.setCustom('registrationLocation', registrationLocation);
        } else {
            event.setCustom('loginLocation', registrationLocation);
        }
        event.setCustom('isSubscriber', UserService.isUserSubscriber());
        event.setCustom('userInventory', await gemsGetCurrentAmount());
        return event.build();
    }

    async gemShopSubscribeBanner(): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName(GemsAnalyticsEvents.gemShopSubscribeBanner);
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        event.setCustom('isSubscriber', UserService.isUserSubscriber());
        event.setCustom('userInventory', await gemsGetCurrentAmount());
        return event.build();
    }

    async manageGems(shopLocation: GemsAnalyticsShopLocations | PageTypes): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName(GemsAnalyticsEvents.manageGems);
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        event.setCustom('shopLocation', LocalStorageService.getItem(LS_SHOP_LOCATION_PROP) || shopLocation);
        event.setCustom('isSubscriber', UserService.isUserSubscriber());
        event.setCustom('userInventory', await gemsGetCurrentAmount());
        return event.build();
    }

    private async newEventBuilder(): Promise<AnalyticsEventBuilder> {
        return await new AnalyticsEventBuilder().baseEvent();
    }
}
