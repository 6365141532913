import { createAction, createReducer } from 'redux-act';

export const setGiftCardStep = createAction<number>('gift card step');
export const giftCardStepReducer = createReducer<number>({}, 0);
giftCardStepReducer.on(setGiftCardStep, (_state, payload) => payload);

type GiftCardPurchase = {
    customMessage: string;
    deliveryDate: any;
    recipientEmail: string;
    recipientName: string;
    senderEmail: string;
    senderName: string;
};

export const setGiftCardPurchase = createAction<GiftCardPurchase>('set giftCardPurchase data');
export const giftCardPurchaseReducer = createReducer<GiftCardPurchase>(
    {},
    {
        customMessage: '',
        deliveryDate: '',
        recipientEmail: '',
        recipientName: '',
        senderEmail: '',
        senderName: '',
    }
);
giftCardPurchaseReducer.on(setGiftCardPurchase, (_state, payload) => payload);
