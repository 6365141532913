import { createAction, createReducer } from 'redux-act';

type authErrorsProps = {
  email: string;
  password: string;
  checkbox: string;
};

export const setAuthErrors = createAction<authErrorsProps>('set auth errors');
export const authErrorsReducer = createReducer<authErrorsProps>({}, { email: '', password: '', checkbox: '' });
authErrorsReducer.on(setAuthErrors, (_state, payload) => payload);

export type TAuthValues = {
  email: string;
  password: string;
  checkbox: boolean;
  authType: string | null
};

export const setAuthValues = createAction<TAuthValues>('set auth values');
export const authValuesReducer = createReducer<TAuthValues>(
  {},
  { email: '', password: '', checkbox: true, authType: null }
);
authValuesReducer.on(setAuthValues, (_state, payload) => payload);

export const setIsUserExists = createAction<boolean>('set auth values');
export const isUserExistsReducer = createReducer<boolean>({}, true);
isUserExistsReducer.on(setIsUserExists, (_state, payload) => payload);
