import React from "react";

import classNames from "classnames";

import { StringUtils } from "../../../../utils/StringUtils";
import { UrlService } from "../../../services/UrlService";
import { GameCardProps, GameCardTypes } from "../GameCard";
import styles from "../GameCard.css";

const GameCardInfo = React.memo(({ game, gameCardType, disableTitle, pseudoTile }: GameCardProps) => {
  if (pseudoTile || gameCardType === GameCardTypes.LONG) return null;

  const limitCharacters = !StringUtils.equalIgnoreCase('en', UrlService.currentLang);

  return (
    <div className={styles.info}>
      {gameCardType !== GameCardTypes.SQUARE && !disableTitle && (
        <h3
          className={classNames(styles.caption, {
            [styles.limitCharacters]: limitCharacters,
          })}
          suppressHydrationWarning
        >
          {game?.shortTitle}
        </h3>
      )}
      {gameCardType === GameCardTypes.DETAILED && (
        <>
          <p className={styles.description}>{game?.details}</p>
          <p className={styles.tags}>
            <span>{game?.subTitle}</span>
          </p>
        </>
      )}
    </div>
  );
});

export default GameCardInfo;
