import { createAction, createReducer } from 'redux-act';

import { HeaderSideMenuTabs } from '../../constants/HeaderSideMenuTabs';
import { PageName, ProfileRightSideRoutes } from '../../constants/Pages';
import { SignInUpComponents } from '../../constants/SignInUpComponents';
import { HelpFormStatus } from '../../models/HelpFormStatus';
import { LeaderboardNotificationType } from '../../organisms/Leaderboard/Leaderboard';

type SideMenuModel = {
  isOpened: boolean;
  activePage: HeaderSideMenuTabs;
  currentRequest: string;
  helpFormStatus: HelpFormStatus;
};

export const setSideMenuOpened = createAction<boolean>('set side menu opened');
export const setSideMenuActivePage = createAction<HeaderSideMenuTabs>('set side activePage');
export const setSideMenuRequest = createAction<string>('set side request');
export const setHelpTabFormActive = createAction<HelpFormStatus>('set help tab form active');
export const sideMenuReducer = createReducer<SideMenuModel>(
  {},
  {
    isOpened: false,
    currentRequest: '',
    activePage: HeaderSideMenuTabs.SEARCH_TAB,
    helpFormStatus: new HelpFormStatus(false)
  }
);
sideMenuReducer.on(setSideMenuOpened, (_state, payload: boolean) => ({
  ..._state,
  isOpened: payload
}));
sideMenuReducer.on(setSideMenuActivePage, (_state, payload: HeaderSideMenuTabs) => ({
  ..._state,
  isOpened: true,
  activePage: payload
}));
sideMenuReducer.on(setSideMenuRequest, (_state, payload: string) => ({
  ..._state,
  currentRequest: payload
}));
sideMenuReducer.on(setHelpTabFormActive, (_state, payload: HelpFormStatus) => ({
  ..._state,
  helpFormStatus: payload
}));

type SignInUpState = {
  activeComponent: SignInUpComponents;
  copyConfirmation: boolean;
};

export const setSignInUpState = createAction<SignInUpComponents>('set active sign in/up state');
export const setSignInUpCopyState = createAction<boolean>('set active sign in copy confirmation');
export const signInUpStateReducer = createReducer<SignInUpState>(
  {},
  { activeComponent: SignInUpComponents.SIGN_IN, copyConfirmation: false }
);
signInUpStateReducer.on(setSignInUpState, (_state, payload: SignInUpComponents) => ({
  ..._state,
  activeComponent: payload
}));
signInUpStateReducer.on(setSignInUpCopyState, (_state, payload: boolean) => ({ ..._state, copyConfirmation: payload }));

type ProfilePanelState = {
  isOpened: boolean;
  caption: string;
  target: ProfileRightSideRoutes;
};

export const setProfilePanelState = createAction<ProfilePanelState>('set profile page state');
export const profilePanelReducer = createReducer<ProfilePanelState>(
  {},
  {
    isOpened: false,
    caption: '',
    target: ProfileRightSideRoutes.NOOP
  }
);
profilePanelReducer.on(setProfilePanelState, (_state, payload) => payload);

type mobileProgressBarState = {
  isOpened: boolean;
};

export const setMobileProgressBarState = createAction<mobileProgressBarState>('set progress bar state');
export const mobileProgressBarReducer = createReducer<mobileProgressBarState>(
  {},
  {
    isOpened: false
  }
);
mobileProgressBarReducer.on(setMobileProgressBarState, (_state, payload) => payload);

export const setLoaderStart = createAction<boolean>('set loader to show');
export const userLoaderReducer = createReducer<boolean>({}, false);
userLoaderReducer.on(setLoaderStart, (_state, payload: boolean) => payload);

type Snackbar = {
  message?: string;
  type?: string;
  isOpened: boolean;
  static?: boolean;
  parentNode?: string;
};

export const setSnackbarData = createAction<Snackbar>('show snackbar');
export const snackbarReducer = createReducer<Snackbar>(
  {},
  {
    type: 'success',
    message: '',
    isOpened: false,
    static: false
  }
);
snackbarReducer.on(setSnackbarData, (_state, payload: Snackbar) => ({
  ..._state,
  isOpened: payload.isOpened,
  message: payload.message,
  type: payload.type,
  static: payload.static
}));

export const setPrevRegistrationScreen = createAction<string>('set previous reg screen value');
export const prevRegistrationScreenReducer = createReducer<string>({}, '');
prevRegistrationScreenReducer.on(setPrevRegistrationScreen, (_state, payload: string) => payload);

export const setLeaderBoardNotificationType = createAction<LeaderboardNotificationType>('set leaderboard notification');
export const leaderboardNotificationTypeReducer = createReducer<LeaderboardNotificationType>(
  {},
  { ICON: false, ROW: false }
);
leaderboardNotificationTypeReducer.on(setLeaderBoardNotificationType, (_state, payload) => payload);

export const setCategoryPageName = createAction<PageName>('set page name');
export const pageNameReducer = createReducer<PageName>({}, '');
pageNameReducer.on(setCategoryPageName, (_state, payload: PageName) => payload);

export const setFullScreenLoading = createAction<boolean>('set fullscreen loader status');
export const fullScreenLoading = createReducer<boolean>({}, true);
fullScreenLoading.on(setFullScreenLoading, (_state, payload) => payload);

type FramesPanelState = {
  background?: string;
  avatar?: string;
  frame?: string;
};

export const setFramesPanelState = createAction<FramesPanelState>('set frame tab state');
export const framesPanelReducer = createReducer<FramesPanelState>(
  {},
  {
    background: '',
    frame: ''
  }
);
framesPanelReducer.on(setFramesPanelState, (_state, payload) => payload);

export const setOpensModalElementRef = createAction<HTMLElement>(
  'set reference to the element that opens the modal window'
);

export const opensModalElementRefReducer = createReducer<HTMLElement | null>({}, null);
opensModalElementRefReducer.on(setOpensModalElementRef, (_state, payload: HTMLElement) =>
  payload === undefined ? null : payload
);
