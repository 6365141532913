import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import { I18nText } from '../../i18nText/i18nText';
import { ArrowSmallIcon } from '../../Icons/Styleguide/ArrowSmallIcon';
import styles from './ButtonWithIcon.css';

export enum ButtonWithIconTypes {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TRANSPARENT = 'transparent',
}

type ButtonWithIconProps = {
    caption: string;
    disabled?: boolean;
    buttonType?: ButtonWithIconTypes;
    wrapLines?: boolean;
    onClick?: (e?: any) => void;
    className?: string;
    captionClassName?: string;
    dataElementDescription?: string;
};

export const ButtonWithIcon = React.memo((props: ButtonWithIconProps) => {
    const buttonType = props.buttonType || ButtonWithIconTypes.PRIMARY;
    const [userPressedItem, setUserPressedItem] = useState(false);

    const onMouseUp = () => setUserPressedItem(false);

    useEffect(() => {
        window.document.addEventListener('mouseup', onMouseUp);
        window.document.addEventListener('touchend', onMouseUp);
        window.document.addEventListener('touchcancel', onMouseUp);
        return () => {
            window.document.removeEventListener('mouseup', onMouseUp);
            window.document.removeEventListener('touchend', onMouseUp);
            window.document.removeEventListener('touchcancel', onMouseUp);
        };
    });

    return (
        <button
            tabIndex={0}
            className={classNames(
                styles.buttonWithIcon,
                [styles[buttonType]],
                {
                    [styles.wrapLines]: Boolean(props.wrapLines),
                    [styles.pressedState]: userPressedItem,
                },
                props.className
            )}
            onClick={props.onClick}
            disabled={props.disabled}
            onMouseDown={() => {
                setUserPressedItem(true);
            }}
            onTouchStart={() => {
                setUserPressedItem(true);
            }}
            data-element-description={props.dataElementDescription}
        >
            <I18nText keyName={props.caption} className={classNames(styles.caption, props.captionClassName)} />

            <ArrowSmallIcon className={styles.icon} />
        </button>
    );
});
