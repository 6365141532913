import React, { useEffect } from 'react';

import { batch, useDispatch, useSelector } from 'react-redux';

import { GemCard, GemCardProps } from '../../molecules/Cards/GemCard/GemCard';
import { Analytics } from '../../services/Analytics/Analytics';
import GemsService from '../../services/GemsService';
import { LocalStorageService } from '../../services/LocalStorage';
import { LocalStorageListenedProps, lsRemoveGemsPurchaseFinished } from '../../services/LocalStorageListenerLogic';
import { setGemsAmount } from '../../store/ducks/gems/gems';
import { gemsShopLocationSelector } from '../../store/ducks/gems/gemsSelectors';
import { setSideMenuOpened } from '../../store/ducks/layout';
import styles from './GemsCardsList.css';

type GemsCardsListProps = {
    data: GemCardProps[];
};

export const GemsCardsList = ({ data }: GemsCardsListProps) => {
    const dispatch = useDispatch();
    const shopLocation = useSelector(gemsShopLocationSelector);

    const successCallback = async (ev: StorageEvent) => {
        batch(async () => {
            dispatch(setGemsAmount(await GemsService.getUserGemsAmount()));
            dispatch(setSideMenuOpened(false));
        });
        lsRemoveGemsPurchaseFinished();
    };

    const checkGemsUpdate = async () => dispatch(setGemsAmount(await GemsService.getUserGemsAmount()));
    const removeCallback = () => {
        checkGemsUpdate();
        LocalStorageService.removeStorageListening(LocalStorageListenedProps.GEMS_PURCHASE_NEW_TAB);
    };

    const analyticsEffect = async () => {
        await Analytics.trackEvent(Analytics.gems.gemShopImpression(shopLocation), false);

        if (LocalStorageService.checkStorageListening()) {
            LocalStorageService.removeStorageListening(LocalStorageListenedProps.GEMS_PURCHASE_NEW_TAB);
        } else {
            LocalStorageService.addStorageListening({
                [LocalStorageListenedProps.GEMS_PURCHASE_NEW_TAB]: successCallback,
            });
        }
    };

    useEffect(() => {
        analyticsEffect();

        return removeCallback;
    }, []);

    return (
        <div className={styles.gemsCardsList}>
            {data.map(({ gemPackId, gemsAmount, imageSrc, price, oldPrice }) => (
                <GemCard
                    key={gemPackId}
                    gemPackId={gemPackId}
                    gemsAmount={gemsAmount}
                    imageSrc={imageSrc}
                    price={price}
                    oldPrice={oldPrice}
                />
            ))}
        </div>
    );
};
