import React from 'react';

import { iconProps } from '../../../../models/Icon/IconPropsModel';

export const FlagEn = React.memo((props: iconProps) => {
    return (
        <svg
            role="img"
            aria-label="English"
            className={props.className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_503_9186)">
                <path
                    d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
                    fill="#F0F0F0"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.3853 6.26118H12.0216V3.3916H18.8471C19.851 4.19116 20.712 5.16239 21.3853 6.26118ZM22.9998 12.0003H12.0216V9.13076H22.6209C22.8675 10.0457 22.9998 11.0075 22.9998 12.0003ZM11.9999 23.0004C14.5887 23.0004 16.9682 22.1056 18.8472 20.6091H5.15257C7.0316 22.1056 9.41108 23.0004 11.9999 23.0004ZM21.3855 17.7394H2.61447C2.07388 16.8573 1.65464 15.8931 1.37891 14.8698H22.621C22.3453 15.8931 21.926 16.8573 21.3855 17.7394Z"
                    fill="#D80027"
                />
                <path
                    d="M6.09541 2.7178H7.09782L6.1654 3.39521L6.52157 4.4913L5.58919 3.81389L4.65681 4.4913L4.96447 3.54439C4.14351 4.22824 3.42395 5.02944 2.83098 5.92216H3.15218L2.55865 6.35335C2.46618 6.50761 2.37749 6.66431 2.2925 6.82334L2.57592 7.69565L2.04715 7.31146C1.91571 7.58994 1.79548 7.8747 1.68741 8.16538L1.99967 9.12651H3.15218L2.21975 9.80391L2.57592 10.9L1.64354 10.2226L1.08504 10.6284C1.02913 11.0778 1 11.5355 1 12H12C12 5.92491 12 5.2087 12 1C9.82698 1 7.80131 1.63035 6.09541 2.7178ZM6.52157 10.9L5.58919 10.2226L4.65681 10.9L5.01298 9.80391L4.08056 9.12651H5.23307L5.58919 8.03042L5.94532 9.12651H7.09782L6.1654 9.80391L6.52157 10.9ZM6.1654 6.59956L6.52157 7.69565L5.58919 7.01825L4.65681 7.69565L5.01298 6.59956L4.08056 5.92216H5.23307L5.58919 4.82607L5.94532 5.92216H7.09782L6.1654 6.59956ZM10.4672 10.9L9.53484 10.2226L8.60246 10.9L8.95863 9.80391L8.02621 9.12651H9.17871L9.53484 8.03042L9.89097 9.12651H11.0435L10.1111 9.80391L10.4672 10.9ZM10.1111 6.59956L10.4672 7.69565L9.53484 7.01825L8.60246 7.69565L8.95863 6.59956L8.02621 5.92216H9.17871L9.53484 4.82607L9.89097 5.92216H11.0435L10.1111 6.59956ZM10.1111 3.39521L10.4672 4.4913L9.53484 3.81389L8.60246 4.4913L8.95863 3.39521L8.02621 2.7178H9.17871L9.53484 1.62171L9.89097 2.7178H11.0435L10.1111 3.39521Z"
                    fill="#0052B4"
                />
            </g>
            <path
                d="M12 22C6.47715 22 2 17.5228 2 12H0C0 18.6274 5.37258 24 12 24V22ZM22 12C22 17.5228 17.5228 22 12 22V24C18.6274 24 24 18.6274 24 12H22ZM12 2C17.5228 2 22 6.47715 22 12H24C24 5.37258 18.6274 0 12 0V2ZM12 0C5.37258 0 0 5.37258 0 12H2C2 6.47715 6.47715 2 12 2V0Z"
                fill="#F9F9F9"
            />
            <defs>
                <clipPath id="clip0_503_9186">
                    <path
                        d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
});
