import { AnalyticsEventBuilder } from './Analytics/AnalyticsEventBuilder';
import { UrlService } from './UrlService';
import UserService from './UserService';
import { MiscUtils } from '../../utils/MiscUtils';

export class AppInsightService {
  private static customDimensionsAppinsights: {};
  private static pingInterval = 30000;
  private static lastEventSent = false;
  private static timer: any = null;


  static init() {
    this.trackPageView();
    this.setupPingTracking();
  }

  private static setupPingTracking() {
    this.timer = setInterval(() => {
      if (!this.lastEventSent) {
        this.trackPingEvent();
      }

      this.lastEventSent = false;
    }, this.pingInterval);
  }

  private static trackPingEvent() {
    this.trackEvent('alive', {
      message: 'User is alive',
      timestamp: new Date().toISOString(),
      ...this.customDimensionsAppinsights
    });
  }

  static trackEvent(name: string, data: any) {
    if ((window as any).appInsightsArkadium) {
      (window as any).appInsightsArkadium.trackEvent({
        name,
        properties: { ...data, pagePath: window.location.pathname }
      });
      this.lastEventSent = true;
    }
  }

  static trackAppError(error: any, info = {}, isErrorBoundary = false) {
    const problemPagePath = window ? window.location.pathname : undefined;

    this.trackEvent('js-error', {
      error,
      env: BUILD_ENV,
      info: JSON.stringify(info),
      page: problemPagePath,
      adBlockEnabled: MiscUtils.isAdBlockEnabled(),
      isUserLoggedIn: UserService.isUserLoggedIn(),
      isUserSubscribed: UserService.isUserSubscriber(),
      type: isErrorBoundary ? 'Something went wrong' : 'common',
      ...this.customDimensionsAppinsights
    });
  }

  static async trackPageView() {
    if ((window as any).appInsightsArkadium) {
      (window as any).appInsightsArkadium.trackPageView({
        name: document.title,
        uri: UrlService.convertEntities(window.location.href),
        properties: {
          ...(await new AnalyticsEventBuilder().pageViewEvent()).options,
          pagePath: window.location.pathname
        },
        screenResolution: window.innerWidth + 'x' + window.innerHeight,
        ...this.customDimensionsAppinsights
      });
      this.lastEventSent = true;
    }
  }

  public static setCustomDimensions(customDimensions: any) {
    this.customDimensionsAppinsights = {
      ...this.customDimensionsAppinsights,
      ...customDimensions
    };
  }
}
