import React, { Dispatch, SetStateAction, useEffect } from 'react';

import styles from './ChooseAvatar.css';
import { Avatar, AvatarTypes } from '../../../../atoms/Avatar/Avatar';
import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { AvatarPickerDefaultImagesData } from '../../../../constants/AvatarPickerDefaultImagesData';
import { ButtonAvatarMore } from '../../../../FigmaStyleguide/ButtonAvatarMore/ButtonAvatarMore';

type ChooseAvatarProps = {
  selectedAvatar: string;
  setSelectedAvatar: Dispatch<SetStateAction<string>>;
  moreBtnClickHandler: () => void;
};

const ChooseAvatar = React.memo(({ selectedAvatar, setSelectedAvatar, moreBtnClickHandler }: ChooseAvatarProps) => {
  const handleSelect = (filename: string) => {
    setSelectedAvatar(filename);
  };

  useEffect(() => {
    // by default, set the first avatar selected
    handleSelect(AvatarPickerDefaultImagesData[0].filename);
  }, []);

  return (
    <div className={styles.container}>
      <I18nText as="div" keyName={'CHOOSE_YOUR_AVATAR'} className={styles.chooseTitle}/>
      <I18nText as="div" keyName={'CLICK_MORE_AVATAR_PICKER'} className={styles.subTitle}/>
      <div className={styles.avatarsRow}>
        {AvatarPickerDefaultImagesData.map((item, _) => (
          <Avatar
            key={item?.filename}
            image={item?.filename}
            selectable={true}
            selected={item?.filename === selectedAvatar}
            avatarList
            size={AvatarTypes.MEDIUM_LIST_ITEM}
            noFrame
            onSelect={() => {
              handleSelect(item.filename);
            }}
          />
        ))}
        <ButtonAvatarMore onClick={moreBtnClickHandler}/>
      </div>
    </div>
  );
});

export default ChooseAvatar;
