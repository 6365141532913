import React from 'react';

import { iconProps } from '../../../models/Icon/IconPropsModel';

export const FacebookFooterIcon = React.memo((props: iconProps) => {
    return (
        <svg
            className={props.className}
            width="11"
            height="22"
            viewBox="0 0 11 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.99175 3.65292H11V0.154917C10.6535 0.10725 9.46196 0 8.07424 0C1.72044 0 3.44913 7.19583 3.19615 8.25H0V12.1605H3.19523V22H7.11274V12.1614H10.1787L10.6654 8.25092H7.11182C7.28414 5.66225 6.4143 3.65292 8.99175 3.65292Z"
                fill="#231F20"
            />
        </svg>
    );
});
