import React from 'react';

import { useSelector } from 'react-redux';

import { SignInUpComponents } from '../../constants/SignInUpComponents';
import { TabRouter } from '../../molecules/TabRouter/TabRouter';
import { AfterSignUp } from '../HeaderSideMenu/SignInUp/AfterSignUp/AfterSignUp';
import { AfterSignUpSocial } from '../HeaderSideMenu/SignInUp/AfterSignUpSocial/AfterSignUpSocial';
import { CompleteSocialProfile } from '../HeaderSideMenu/SignInUp/CompleteSocialProfile/CompleteSocialProfile';
import { ConfirmResetPassword } from '../HeaderSideMenu/SignInUp/ConfirmResetPassword/ConfirmResetPassword';
import { PersonalizeProfile } from '../HeaderSideMenu/SignInUp/PersonalizeProfile/PersonalizeProfile';
import { RequestActivation } from '../HeaderSideMenu/SignInUp/RequestActivation/RequestActivation';
import { RequestEmail } from '../HeaderSideMenu/SignInUp/RequestEmail/RequestEmail';
import { ResetPassword } from '../HeaderSideMenu/SignInUp/ResetPassword/ResetPassword';
import {
  ResetPasswordSuccess
} from '../HeaderSideMenu/SignInUp/ResetPassword/ResetPasswordSuccess/ResetPasswordSuccess';
import { ThankYou } from '../HeaderSideMenu/SignInUp/ResetPassword/ThankYou/ThankYou';
import SignIn from '../HeaderSideMenu/SignInUp/SignIn/SignIn';
import SignUp from '../HeaderSideMenu/SignInUp/SignUp/SignUp';

const ComponentsMap = new Map<string, React.ElementType>();

ComponentsMap.set(SignInUpComponents.SIGN_IN, SignIn);
ComponentsMap.set(SignInUpComponents.SIGN_UP, SignUp);
ComponentsMap.set(SignInUpComponents.AFTER_SIGN, AfterSignUp);
ComponentsMap.set(SignInUpComponents.AFTER_SIGN_COMPLETE, AfterSignUp);
ComponentsMap.set(SignInUpComponents.AFTER_SIGN_SOCIAL, AfterSignUpSocial);
ComponentsMap.set(SignInUpComponents.THANK_YOU, ThankYou);
ComponentsMap.set(SignInUpComponents.RESET_PASSWORD, ResetPassword);
ComponentsMap.set(SignInUpComponents.RESET_PASSWORD_SUCCESS, ResetPasswordSuccess);
ComponentsMap.set(SignInUpComponents.REQUEST_EMAIL, RequestEmail);
ComponentsMap.set(SignInUpComponents.REQUEST_ACTIVATION, RequestActivation);
ComponentsMap.set(SignInUpComponents.CONFIRM_RESET_PASSWORD, ConfirmResetPassword);
ComponentsMap.set(SignInUpComponents.PERSONALIZE_PROFILE, PersonalizeProfile);
ComponentsMap.set(SignInUpComponents.COMPLETE_SOCIAL_PROFILE, CompleteSocialProfile);

type SignInUpContainerProps = {
  onSignInUpFinished?: () => unknown;
};

const noop = () => null;

export const SignInUpContainer = React.memo(({ onSignInUpFinished }: SignInUpContainerProps) => {
  const activeComponent = useSelector(({ signInUpState }) => signInUpState.activeComponent);
  const onSignInUpFinishedHandler = onSignInUpFinished || noop;

  return (
    <TabRouter
      activeState={activeComponent}
      componentsMap={ComponentsMap}
      onSignInUpFinished={onSignInUpFinishedHandler}
    />
  );
});
SignInUpContainer.displayName = 'SignInUpContainer';
