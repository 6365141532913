import React, { FC } from 'react';

import classNames from 'classnames';

import styles from './HoverElement.css';

interface HoverElementProps {
    visible: boolean;
}

export const HoverElement: FC<HoverElementProps> = React.memo(({ visible }: HoverElementProps) => {
    return (
        <div className={classNames(styles.container, { [styles.visible]: visible })}>
            Access premium avatars and more when you subscribe to Arkadium Advantage
        </div>
    );
});
