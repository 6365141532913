import { createAction, createReducer } from 'redux-act';

import { GameState } from '../../models/Enums';
import { GameModel } from '../../models/Game/GameModel';
import { GameObservable } from '../../models/Game/GameObservable';
import { IframeGamesList } from '../../models/Game/IframeGamesList';
import { GameLists, GameListsRedesign } from '../../models/GamesList';

const defaultState: GameModel[] = [];

export const setGames = createAction<GameModel[]>('add games');
export const gameReducer = createReducer<typeof defaultState>({}, defaultState);
gameReducer.on(setGames, (_state, payload: GameModel[] = []) => payload.slice(0));

export const setIframeGamesList = createAction<IframeGamesList>('add iframe games list');
export const iframeGamesListReducer = createReducer<IframeGamesList>({}, []);
iframeGamesListReducer.on(setIframeGamesList, (_state, payload: IframeGamesList) => payload);

export const setGrmGamesList = createAction<string[]>('add grm games list');
export const grmGamesListReducer = createReducer<string[]>({}, []);
grmGamesListReducer.on(setGrmGamesList, (_state, payload: IframeGamesList) => payload);

export const setHomepageGames = createAction<GameLists>('add homepage games');
export const homepageGameReducer = createReducer<GameLists | null>({}, null);
homepageGameReducer.on(setHomepageGames, (_state, payload: GameLists) => payload);

export const setHomepageGamesRedesign = createAction<GameListsRedesign>('add homepage games redesign');
export const homepageGameRedesignReducer = createReducer<GameListsRedesign | null>({}, null);
homepageGameRedesignReducer.on(setHomepageGamesRedesign, (_state, payload: GameListsRedesign) => payload);

export const setGameState = createAction<GameState>('set game status');
export const gameStateReducer = createReducer<GameState>({}, GameState.PENDING);
gameStateReducer.on(setGameState, (_state, payload: GameState) => payload);

export const setArkadiumGameSlug = createAction<string>('set current game arkadium slug');
export const gameArkadiumSlugReducer = createReducer<string>({}, '');
gameArkadiumSlugReducer.on(setArkadiumGameSlug, (_state, payload: string) => payload);

export const setArenaGameSlug = createAction<string>('set current game arena slug');
export const gameArenaSlugReducer = createReducer<string>({}, '');
gameArenaSlugReducer.on(setArenaGameSlug, (_state, payload: string) => payload);

export const setGameName = createAction<string>('set current game name');
export const gameNameReducer = createReducer<string>({}, '');
gameNameReducer.on(setGameName, (_state, payload: string) => payload);

type gameObservableType = GameObservable | null;

export const setGameObservable = createAction<gameObservableType>('set game observable');
export const gameObservableReducer = createReducer<gameObservableType>({}, null);
gameObservableReducer.on(setGameObservable, (_state, payload: gameObservableType) => payload);

type gamePageViewState = {
    activeTab: string;
    activeTab2?: string;
};

export const setActiveGameView = createAction<gamePageViewState>('set game page view bar state');
export const gamePageViewStateReducer = createReducer<gamePageViewState>(
    {},
    {
        activeTab: 'game',
        activeTab2: '',
    }
);
gamePageViewStateReducer.on(setActiveGameView, (_state, payload) => payload);
