import { CategoriesAnalyticsAi } from './AI/CategoriesAnalyticsAi';
import { AnalyticsEventsPair, makeEventsPair } from './AnalyticsEventBuilder';
import { RegistrationSource } from '../../models/User/RegistrationSource';

export class CategoriesAnalytics {
    aiAnalytics = new CategoriesAnalyticsAi();

    async categoryLeftNavigationClick(category: string): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.categoryLeftNavigationClick(category));
    }

    async claimDailyEntry(source: RegistrationSource): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.claimDailyEntry(source));
    }

    async seeAllBannerClick(): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.seeAllBannerClick());
    }

    async seeAllCategoryClick(category: string): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.seeAllCategoryClick(category));
    }
}
