import React, { FC, ReactNode, useEffect, useRef } from 'react';

import classNames from 'classnames';

interface NavBtnSideMenuProps {
    dataElementDescription: string;
    children: ReactNode;
    onClick: () => void;
    className?: string;
    isActive?: boolean;
    id?: string;
}

export const NavBtnSideMenu: FC<NavBtnSideMenuProps> = React.memo(
    ({ className, id, dataElementDescription, children, onClick, isActive }) => {
        NavBtnSideMenu.displayName = 'NavBtnSideMenu';
        const btnRef = useRef<HTMLButtonElement>(null);

        useEffect(() => {
            if (isActive) {
                btnRef.current?.focus();
            }
        }, [isActive]);

        return (
            <button
                id={id}
                ref={btnRef}
                className={classNames(className, {})}
                onClick={onClick}
                data-element-description={dataElementDescription}
                role="tab"
                aria-controls={id}
                aria-selected={isActive}
                tabIndex={isActive ? 0 : -1}
            >
                {children}
            </button>
        );
    }
);
NavBtnSideMenu.displayName = 'NavBtnSideMenu';
