import React, { FC, useEffect, useRef, useState, HTMLInputTypeAttribute, useCallback } from 'react';

import classNames from 'classnames';
import EmailValidator from 'email-validator';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';

import { MiscUtils } from '../../../utils/MiscUtils';
import { ApproveIcon } from '../../atoms/Icons/Styleguide/ApproveIcon';
import { CloseIcon } from '../../atoms/Icons/Styleguide/CloseIcon';
import { ExclamationIcon } from '../../atoms/Icons/Styleguide/ExclamationIcon';
import { MailIcon } from '../../atoms/Icons/Styleguide/MailIcon';
import { LeanplumAnalytics, LEANPLUM_EVENTS } from '../../services/Analytics/LeanplumAnalytics';
import { UserEffects } from '../../store/effects/user.effects';
import styles from './ToastBanner.css';

export type ToastBannerProps = {
    messageId?: string;
    title: string;
    subtitle?: string;
    disclaimer?: string;
    desktopBackground: string;
    tabletBackground?: string;
    mobileBackground?: string;
    buttonLabel?: string;
    buttonLink?: string;
    userEmail?: string;
    emailOptIn?: boolean;
    checkboxLabel?: string;
    theme?: string;
    onClose: (...args: never) => unknown;
};

export const ToastBanner: FC<ToastBannerProps> = ({
    messageId,
    title,
    subtitle,
    disclaimer,
    desktopBackground,
    tabletBackground,
    mobileBackground,
    emailOptIn,
    checkboxLabel,
    buttonLabel,
    buttonLink,
    userEmail,
    theme,
    onClose,
}) => {
    const formRef = useRef();
    const [isValid, setIsValid] = useState<boolean>(!emailOptIn || userEmail !== undefined);
    const [error, setError] = useState<string>(undefined);
    const [device, setDevice] = useState<'desktop' | 'tablet' | 'mobile'>(getDevice());
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();

    function getDevice() {
        if (MiscUtils.isServer) {
            return 'desktop';
        }
        const isMobile = (window as any).matchMedia('(max-width: 576px)').matches;
        if (isMobile) {
            return 'mobile';
        }
        const isTablet = (window as any).matchMedia('(max-width: 1024px)').matches;
        if (isTablet) {
            return 'tablet';
        }
        return 'desktop';
    }

    const getBackgroundImage = useCallback(() => {
        let bg = desktopBackground;
        switch (device) {
            case 'mobile':
                bg = mobileBackground || tabletBackground || desktopBackground;
                break;
            case 'tablet':
                bg = tabletBackground || desktopBackground;
                break;
            case 'desktop':
            default:
                break;
        }
        return bg ? `url(${bg})` : undefined;
    }, [device]);

    useEffect(() => {
        const updateDevice = () => {
            setDevice(getDevice());
        };
        const debounceEvent = debounce(updateDevice, 200);

        window.addEventListener('resize', debounceEvent);

        return () => {
            window.removeEventListener('resize', debounceEvent);
            document.body.style.overflow = 'initial';
        };
    }, []);

    useEffect(() => {
        if (!formRef?.current || !emailOptIn) {
            return;
        }
        const form: HTMLFormElement = formRef.current;
        const emailInput: HTMLInputElement = form.elements[0] as HTMLInputElement;
        emailInput.value = user ? userEmail || '' : '';
        if (!user) {
            setIsValid(false);
        }
    }, [user]);

    useEffect(() => {
        document.body.style.overflow = device === 'mobile' ? 'hidden' : 'initial';
    }, [device]);

    const debouncedFormValidation = useRef(
        debounce(async (e: React.ChangeEvent<HTMLFormElement>) => {
            const { target } = e;
            if ((target.type as HTMLInputTypeAttribute) === 'email') {
                validateEmail(target);
            }
            setIsValid(target.form.checkValidity());
        }, 200)
    ).current;

    const validateEmail = (target: HTMLFormElement) => {
        let message = '';
        const isValid = EmailValidator.validate(target.value);
        if (!isValid) {
            message = 'Please enter a valid email address';
        }
        target.setCustomValidity(message);
        setError(message);
    };

    const getInputIcon = useCallback(() => {
        if (error === undefined) {
            return <CloseIcon />;
        }
        if (error) {
            return <ExclamationIcon className={styles.errorIcon} />;
        }
        return <ApproveIcon />;
    }, [error]);

    const ctaClick = async (e) => {
        e.preventDefault();
        if (!isValid) {
            return;
        }
        if (emailOptIn) {
            if (user && !user.emailRetrievalConsent) {
                dispatch(UserEffects.updateUser({ emailRetrievalConsent: true }));
            }
            LeanplumAnalytics.setUserAttributesCustom(
                user ? { emailConsent: true } : { user_email: e.target.form.email.value }
            );
        }
        LeanplumAnalytics.trackEvent(LEANPLUM_EVENTS.TOAST_BANNER_BUTTON, {
            type: emailOptIn ? 'emailOpt-In' : 'redirect',
            page: window.location.pathname,
            messageId,
        });
        if (buttonLink && !emailOptIn) {
            setTimeout(() => (window.location.href = buttonLink), 300);
        }
        onClose();
    };

    useEffect(() => {
        return () => {
            debouncedFormValidation.cancel();
        };
    }, [debouncedFormValidation]);

    return (
        <>
            <div
                className={classNames(styles.container, styles[theme], { [styles.noEmail]: !emailOptIn })}
                style={{ backgroundImage: getBackgroundImage() }}
            >
                <span className={styles.content}>
                    <h2>{title}</h2>
                    <p>{subtitle}</p>
                    {disclaimer && <p dangerouslySetInnerHTML={{ __html: disclaimer }}></p>}
                </span>
                <form ref={formRef} className={styles.bannerForm} onChange={debouncedFormValidation}>
                    {emailOptIn && (
                        <>
                            <div className={styles.emailContainer}>
                                <MailIcon className={styles.mailIcon} />
                                <input
                                    disabled={user !== null}
                                    required
                                    id="email"
                                    type="email"
                                    maxLength={50}
                                    placeholder="Enter your e-mail address"
                                />
                                <span className={styles.inputActiveLabel}>E-mail</span>
                                {getInputIcon()}
                            </div>
                            {error && (
                                <p className={styles.error}>
                                    <ExclamationIcon className={styles.errorIcon} />
                                    <span>{error}</span>
                                </p>
                            )}
                            <label className={styles.checkboxContainer}>
                                <input
                                    required
                                    id="consent"
                                    className={styles.checkbox}
                                    defaultChecked={true}
                                    type="checkbox"
                                />
                                <span>{checkboxLabel}</span>
                            </label>
                        </>
                    )}
                    <button
                        onClick={ctaClick}
                        disabled={!isValid}
                        className={styles.cta}
                        role={emailOptIn ? undefined : 'link'}
                    >
                        {buttonLabel}
                    </button>
                </form>
                <button className={styles.close} type="button" aria-label="Close banner" onClick={onClose}>
                    <CloseIcon />
                </button>
            </div>
            <div className={styles.mobileOverlay} />
        </>
    );
};

ToastBanner.displayName = 'ToastBanner';
