import { AnalyticsEventsPair, makeEventsPair } from '../../services/Analytics/AnalyticsEventBuilder';
import { MobileNavButtonsNames } from './MobileNavigationUxRedesigned.types';
import { MobileNavMenuUxRedesignedAnalyticsAi } from './MobileNavUxRedesignedAnalyticsAI';

export class MobileNavMenuUxRedesignedAnalytics {
    aiAnalytics = new MobileNavMenuUxRedesignedAnalyticsAi();

    async bottomNavSlot(slotButtonName: MobileNavButtonsNames): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.bottomNavSlot(slotButtonName));
    }

    async bottomNavMenu(menuButtonName: string): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.bottomNavMenu(menuButtonName));
    }
}
