import { AnalyticsEvent, AnalyticsEventAction, AnalyticsEventBuilder } from '../AnalyticsEventBuilder';
import { Analytics } from '../Analytics';
import { environment } from '../../../config/environment';

export class ProfileAnalyticsGa {
    private newEventBuilder(): AnalyticsEventBuilder {
        return new AnalyticsEventBuilder();
    }

    registration(xsollaId: string): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('registration');
        event.setNameGA('registration');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel(xsollaId);
        event.setGaNonInteraction(false);
        return event.build();
    }

    createAccountClick(): AnalyticsEvent {
        const event = this.newEventBuilder();

        event.setNameGA('create_Account_Click');
        event.setCustom('event_label', Analytics.generateLabelForGA4(environment.ARENA_DOMAIN, window.location.pathname));
        event.setGaNonInteraction(false);
        return event.build();
    }

    createAccountSuccess(): AnalyticsEvent {
        const event = this.newEventBuilder();

        event.setNameGA('create_Account_Success');
        event.setCustom('event_label', Analytics.generateLabelForGA4(environment.ARENA_DOMAIN, window.location.pathname));
        event.setGaNonInteraction(false);
        return event.build();
    }
}
