import { CONSTS } from '@arkadium/modules';

import { common } from './common';
import { Environment, EnvironmentName } from '../constants/Environment';

export const environment: Environment = {
  ...common(),
  Name: EnvironmentName.PRODUCTION,
  // Blobs
  GAME_API_URL: 'https://arenacloud.cdn.arkadiumhosted.com/game-api-origin-live',
  GAMES_CONTAINER_URL: 'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-games',
  SORTING_URL:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arkcom-games-sorting-analytics/arkadium.com_auto.json',
  GAME_ARTS:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-index/_arena-shared-content_/arkcom-game-arts',
  GAME_METAS: 'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-game-metas',
  WHYNOTTRYTHESE_URL:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arkcom-games-whynottrythese-analytics/arkadium.json',
  CONNECTED_APPS_URL:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-index/_arena-shared-content_/ark-connected-apps/arkadium-connected-apps.json',
  HOMEPAGE_GAMES_URL:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-index/_arena-shared-content_/ark-homepage-games/game-lists.prod.json',
  HOMEPAGE_GAMES_URL_REDESIGN:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-index/_arena-shared-content_/ark-homepage-games/game-lists-redesign.prod.json',
  GAMES_SORTED_BY_GAME_STARTS:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-index/_arena-shared-content_/ark-games-sorted-by-game-starts/ark-games-sorted-by-game-starts.json',
  IFRAME_GAMES_LIST_PATH:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-index/_arena-shared-content_/arkcom-iframe-games/iframe-games-list.json',
  GRM_GAMES_LIST_PATH: CONSTS.GRM_GAMES_LIST_URL.arkcom.live,
  AB_TESTS:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-index/_arena-shared-content_/arkcom-abtests/arena-data-abtest.json',
  ARK_CONFIG:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-index/www.arkadium.com/ark_config.json',
  //
  FEED_ID: '1',
  FEED_NAME: 'ArkCom_Prod',
  ARENA_DOMAIN: 'arkadium.com',
  // SITE_BASE_URL:   'https://arkcom-arena-origin-live.arkadium.com', // direct site url
  SITE_BASE_URL: 'https://www.arkadium.com', // site cdn url
  // AD
  AD_REFRESH_TIME_MS: 30000, // 30 sec
  // CDN
  USE_CDN: true,
  CDN_BASE_URL_OVERWRITES:
    'https://arenacloud.cdn.arkadiumhosted.com/overwrites-arkadiumcom-live/iframe-rendering.html',
  // APP INSIGHT
  APP_INSIGHT_INSTRUMENTATION_KEY: '652a75a4-b6da-4557-8bad-23e52c5802a0', // azure name: arkadiumcom-appins-live
  APP_INSIGHT_INSTRUMENTATION_KEY_FRONT: '30844a10-84d8-4424-80be-38aa72ef97ab', // azure name: arkadium.com
  VALIDATE_ANALYTICS_TRACKS: true,
  /* eslint-disable-next-line */
  ADO_BUILD_ID: ADO_BUILD_ID,
  // local and session storage prefix
  STORAGE_PREFIX: 'arkcom:',
  // determine whether to show server errors in browser with details or simple message
  ERROR_DETAILS: false,
  // cache time for memored service
  CACHE_TIME_MS: 5 * 60 * 1000, // 5 minutes;
  PAGE_SEO_URL:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-index/_arena-shared-content_/ark-pages-seo/categories-seo.production.json',
  NON_CAT_PAGE_SEO:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-index/_arena-shared-content_/ark-pages-seo/non-categories-seo.production.json',
  CO_BRANDED_PAGE_SEO:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-index/_arena-shared-content_/ark-pages-seo/co-branded-pages-seo.production.json',
  AFFILIATE_DATA:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-index/_arena-shared-content_/ark-affiliate-data/',
  STRAPI_URL: 'https://arenacloud.cdn.arkadiumhosted.com/strapi-prod',
  STRAPI_API_KEY:
    '5f897c4d7d8951dc0c995ad2750d7553706c8f8ea961f630c9eb3dd154343b677ed2c77252734af74ae9e41b77659ef6837043bb446adc3a8fb95882c903d2df71d85c31a25f7c1d5d0431353a22c6f038cc068108f87ab8970841d73c064a3f64980eebac89e2e052802c4a37068385af412c7dd7b8e2911c23567fb2c52951',
  // used for upload path
  PATH: '/dist/production/public/uploads',
  REDIRECTS_SETTINGS_PATH:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-global-settings/redirects/redirects.json',
  PUSH_NOTIFICATIONS_URL: `https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-index/arkadium.com/push-notification/`,

  // api urls
  // USER_API_BASE_URL: `https://uup-api-user-prod.arkadiumhosted.com/api/v1`,
  // SCORE_API_BASE_URL: `https://uup-api-score-prod.arkadiumhosted.com/api/v1`,
  USER_API_BASE_URL: `https://arenacloud.cdn.arkadiumhosted.com/uup-api-user-prod/api/v1`,
  SCORE_API_BASE_URL: `https://arenacloud.cdn.arkadiumhosted.com/uup-api-score-prod/api/v1`,
  BLOB_IMAGES_BASE_URL: `https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-index/`,
  EAGLE_USER_GAME_DATA_API: CONSTS.EAGLE_USER_GAME_DATA_API_ENDPOINT.prod,

  EAGLE_API_BASE_URL: `https://eagle-user-api-prod.arkadiumhosted.com/`,
  EAGLE_API_PAYMENT: `https://arenacloud.cdn.arkadiumhosted.com/eagle-payment-api-prod/`,
  RECURLY_BILLING_PAGE: `https://arkadium.recurly.com/account/`,

  EAGLE_API_COLLECTIONS_URL: 'https://arenacloud.cdn.arkadiumhosted.com/eagle-virtual-item-api-prod/',

  DISPLAY_ADS: 'https://ams.cdn.arkadiumhosted.com/advertisement/display/stable/quantcast/display-ads.js',
  VIDEO_ADS: 'https://ams.cdn.arkadiumhosted.com/advertisement/video/stable/quantcast/video-ads.js',
  ADS_QUERY_PARAM: '__arkads',
  ADS_TXT:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-index/_arena-shared-content_/arkcom-adstxt/ads.txt',

  RECURLY_PUBLIC_KEY: 'ewr1-6voS8MpDMGlmNWVWMrqyp7',
  RECURLY_PLAN_PREFIX: '',
  RECURLY_GEMS_PREFIX: '',
  RECURLY_GIFT_CARD_PRODUCT_CODE: 'gift_card',

  // LEANPLUM
  LEANPLUM_APP_ID: 'app_1uCrr3zP6h9eCca397ydY9W6Oz6fPgpXdn7CdqwFwc0',
  LEANPLUM_API_KEY: 'prod_uojvo6pm2nfi6AV9b1pKNnESoGos35LTFBmIzsWUjCY',

  // prod key
  OPEN_WEB_KEY: 'sp_DoaWA1rg',

  // Skip ad product SKU name
  SKU_GEMS_SKIP_AD: 'skip_ad',
  SKU_GEMS_SKIP_AD_BUNDLE: 'skip_ad_bundle',
  SKU_GEMS_NAME: 'gems_prod',

  //HotJar ID
  HOTJAR_SITE_ID: 0, //3257420,

  //Tag Manager
  TAGMANAGER_SITE_ID: 'GTM-5MVVNRR',

  //Recaptcha Key ID
  RECAPTCHAAPPKEY: '6LeKF_4jAAAAAAYevkgmxE64WG6ZagnLOH8FwA-g',
  CHALLENGE_CAPTCHA_KEY: '6LeIG7EoAAAAAHtfqlgeaP5u-QOLF9YzpTyj0zy7',
  CDN_CAPTCHA_IFRAME:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-3rd-party-scripts/captcha/iframe-captcha-production.html',

  //hardcoded nest env for games team
  NEST_ENVIRONMENT: 'prod',
};
