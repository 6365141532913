import React from 'react';

import { iconProps } from '../../models/Icon/IconPropsModel';

export const ArkadiumAdvantageLogo = React.memo((props: iconProps) => {
    return (
        <svg
            width="164"
            height="79"
            className={props.className}
            viewBox="0 0 164 79"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.9235 46.365H160.442C163.021 46.365 164.516 48.4219 163.794 50.993L157.502 73.9275C156.779 76.4987 154.149 78.5556 151.57 78.5556H4.05182C1.47283 78.5556 -0.022978 76.4987 0.699138 73.9275L6.99187 50.993C7.6624 48.4219 10.3445 46.365 12.9235 46.365Z"
                fill="url(#paint0_linear)"
            />
            <path
                d="M23.7955 70.8482L22.3982 67.6199H15.4782L14.081 70.8482H9.97778L17.2748 55.2594H20.5129L27.8321 70.8482H23.7955ZM16.7868 64.5684H21.0896L18.9382 59.5712L16.7868 64.5684Z"
                fill="#0B103D"
            />
            <path
                d="M28.7579 55.2594H35.1234C36.8534 55.2594 38.3394 55.5689 39.5814 56.1881C40.8382 56.8072 41.7993 57.7064 42.4647 58.8857C43.1301 60.0503 43.4628 61.4359 43.4628 63.0427C43.4628 64.6643 43.1301 66.0647 42.4647 67.244C41.8141 68.4085 40.8604 69.3004 39.6036 69.9195C38.3616 70.5386 36.8681 70.8482 35.1234 70.8482H28.7579V55.2594ZM34.835 67.5756C36.3728 67.5756 37.5187 67.1997 38.2728 66.4479C39.0269 65.6961 39.404 64.5611 39.404 63.0427C39.404 61.5244 39.0269 60.3967 38.2728 59.6596C37.5187 58.9078 36.3728 58.5319 34.835 58.5319H32.8389V67.5756H34.835Z"
                fill="#0B103D"
            />
            <path
                d="M56.858 55.2594H60.9834L53.8638 70.8482H50.6257L43.5061 55.2594H47.7424L52.2891 65.7846L56.858 55.2594Z"
                fill="#0B103D"
            />
            <path
                d="M72.226 70.8482L70.8287 67.6199H63.9088L62.5115 70.8482H58.4083L65.7053 55.2594H68.9435L76.2627 70.8482H72.226ZM65.2174 64.5684H69.5202L67.3688 59.5712L65.2174 64.5684Z"
                fill="#0B103D"
            />
            <path
                d="M87.3244 55.2594H91.1392V70.8482H88.1229L80.9811 61.9371V70.8482H77.1885V55.2594H80.1605L87.3244 64.1704V55.2594Z"
                fill="#0B103D"
            />
            <path d="M97.5011 70.8482V58.554H92.8212V55.2594H106.306V58.554H101.626V70.8482H97.5011Z" fill="#0B103D" />
            <path
                d="M117.863 70.8482L116.465 67.6199H109.545L108.148 70.8482H104.045L111.342 55.2594H114.58L121.899 70.8482H117.863ZM110.854 64.5684H115.157L113.005 59.5712L110.854 64.5684Z"
                fill="#0B103D"
            />
            <path
                d="M136.253 62.0477V69.8532C135.44 70.2364 134.516 70.5312 133.481 70.7376C132.446 70.944 131.389 71.0472 130.309 71.0472C128.609 71.0472 127.13 70.7303 125.873 70.0964C124.631 69.4478 123.678 68.5264 123.012 67.3324C122.347 66.1236 122.014 64.6937 122.014 63.0427C122.014 61.4359 122.347 60.0281 123.012 58.8194C123.678 57.6106 124.617 56.6819 125.829 56.0333C127.056 55.3847 128.491 55.0604 130.132 55.0604C131.27 55.0604 132.335 55.2078 133.326 55.5026C134.331 55.7974 135.144 56.2028 135.765 56.7187L134.501 59.5933C133.777 59.1216 133.082 58.7825 132.416 58.5761C131.751 58.3698 131.041 58.2666 130.287 58.2666C128.882 58.2666 127.833 58.6572 127.138 59.4385C126.457 60.2198 126.117 61.4212 126.117 63.0427C126.117 64.7085 126.472 65.9467 127.182 66.7575C127.906 67.5535 129.008 67.9515 130.487 67.9515C131.196 67.9515 131.899 67.8778 132.594 67.7304V64.8338H129.843V62.0477H136.253Z"
                fill="#0B103D"
            />
            <path
                d="M138.918 70.8482V55.2594H150.03V58.3992H142.888V61.3622H149.586V64.48H142.888V67.7083H150.03V70.8482H138.918Z"
                fill="#0B103D"
            />
            <path
                d="M147.701 1.98714C148.063 1.98714 148.424 2.08999 148.733 2.24425C150.229 2.96417 150.28 4.09547 150.28 6.15238V21.7849C150.28 21.8877 150.023 24.2532 148.32 24.2532C148.011 24.2532 147.701 24.2018 147.392 24.0475C146.36 23.6361 145.948 22.0934 145.535 21.2707C144.968 20.1908 143.936 18.1853 141.615 17.054C140.583 16.5912 139.5 16.3341 138.366 16.3341C137.54 16.3341 136.663 16.4369 135.838 16.6426C135.168 16.7969 134.239 17.1568 133.362 17.1568C133.053 17.1568 132.692 17.1054 132.434 16.9512C130.061 15.6142 132.331 13.4544 132.382 13.403L144.555 3.73551C145.741 2.75848 146.67 1.98714 147.701 1.98714ZM147.65 0.444458C146.102 0.444458 144.968 1.37007 143.627 2.44994V2.50137L131.454 12.1688L131.351 12.2717L131.299 12.3745C130.216 13.4544 129.752 14.6886 129.906 15.8199C130.268 17.5168 131.763 18.6995 133.465 18.6995C134.188 18.6481 134.91 18.5453 135.58 18.3396C135.838 18.2881 136.044 18.2367 136.199 18.1853H136.251C136.973 18.031 137.643 17.9282 138.366 17.9282C139.242 17.9282 140.119 18.1339 140.945 18.4938C142.75 19.368 143.575 20.9107 144.142 21.9392L144.194 21.9906C144.246 22.1448 144.349 22.3505 144.452 22.5562C144.916 23.5847 145.484 24.9731 146.876 25.4873C147.34 25.693 147.856 25.7959 148.372 25.7959C150.177 25.7959 151.518 24.356 151.828 22.042V21.9392V6.10096C151.828 4.19832 151.879 1.98714 149.455 0.804417C148.888 0.598726 148.269 0.444458 147.65 0.444458Z"
                fill="#F9F9F9"
            />
            <path
                d="M34.5357 41.3255H33.1431V26.67H34.5357V29.3954C35.4642 27.5956 37.321 26.4643 39.3842 26.4643C40.2095 26.4643 40.9832 26.6186 41.7053 26.9271L41.8085 26.9786V28.5212L41.499 28.367C40.7769 28.007 40.0032 27.8013 39.2295 27.8527C36.5473 27.8527 34.4841 30.3725 34.4841 33.7149L34.5357 41.3255Z"
                fill="#F9F9F9"
            />
            <path
                d="M65.535 41.5313C60.7381 41.5313 57.6433 38.5487 57.6433 33.9721C57.6433 29.3955 60.7381 26.4644 65.5866 26.4644C67.5982 26.413 69.5066 26.9786 71.1572 28.1613V26.6701H72.5498V41.377H71.1572V39.8857C69.5066 41.017 67.495 41.5827 65.535 41.5313ZM65.5866 27.8014C61.5634 27.8014 59.036 30.1668 59.036 33.9721C59.036 37.7774 61.5634 40.1943 65.5866 40.1943C67.9077 40.1943 69.8677 39.5258 71.1572 38.2402V29.8069C69.6098 28.4699 67.6498 27.75 65.5866 27.8014Z"
                fill="#F9F9F9"
            />
            <path
                d="M83.5362 41.5313C78.7393 41.5313 75.6445 38.5487 75.6445 33.9721C75.6445 29.3955 78.7393 26.4644 83.5878 26.4644C85.5994 26.413 87.5079 27.03 89.1584 28.1613V20.9622H90.5511V41.377H89.1584V39.8857C87.5079 41.017 85.5478 41.5827 83.5362 41.5313ZM83.5878 27.8014C79.5646 27.8014 77.0372 30.1668 77.0372 33.9721C77.0372 37.7774 79.5646 40.1943 83.5878 40.1943C85.8057 40.1943 87.8174 39.5258 89.1069 38.2402V29.8069C87.611 28.4699 85.651 27.75 83.5878 27.8014Z"
                fill="#F9F9F9"
            />
            <path
                d="M96.122 41.3254H94.7294V26.6699H96.122V41.3254ZM95.4515 24.3045C94.7809 24.3045 94.2651 23.7902 94.2651 23.1217C94.2651 22.4533 94.7809 21.939 95.4515 21.939C96.122 21.939 96.6378 22.4533 96.6378 23.1217C96.6378 23.7388 96.122 24.3045 95.4515 24.3045Z"
                fill="#F9F9F9"
            />
            <path
                d="M106.283 41.5313C101.899 41.5313 99.9387 39.5772 99.9387 35.1549V26.6701H101.331V35.1549C101.331 38.703 102.827 40.1943 106.335 40.1943C108.449 40.1943 110.667 39.4229 112.008 38.2402V26.6701H113.401V41.377H112.008V39.7315C110.358 40.9142 108.346 41.5313 106.283 41.5313Z"
                fill="#F9F9F9"
            />
            <path
                d="M140.841 41.3255H139.449V32.7893C139.449 29.2926 138.108 27.8527 135.013 27.8527C133.053 27.8527 131.144 28.4698 129.545 29.6525C129.855 30.681 130.01 31.7609 130.01 32.8407V41.4283H128.617V32.7893C128.617 29.2926 127.327 27.8527 124.284 27.8527C122.273 27.8527 120.364 28.5212 118.765 29.704V41.3769H117.373V26.67H118.765V28.2641C120.416 27.1328 122.376 26.5157 124.387 26.4643C126.605 26.4643 128.153 27.1328 129.03 28.5212C130.783 27.1842 132.898 26.4643 135.064 26.4643C138.984 26.4643 140.738 28.4184 140.738 32.7379L140.841 41.3255Z"
                fill="#F9F9F9"
            />
            <path
                d="M21.0216 22.2478L12.1499 41.3257H13.6973L16.1215 36.0291H26.9533L29.3775 41.3257H30.9249L22.1564 22.4021L22.1048 22.2993L21.0216 22.2478ZM21.5374 24.2019L26.3343 34.6407H16.7405L21.5374 24.2019Z"
                fill="#F9F9F9"
            />
            <path d="M56.6633 41.3255H56.7665L56.6633 41.2741V41.3255Z" fill="#F9F9F9" />
            <path d="M44.1809 20.9622V41.3256H45.5736V24.2532V24.0989V20.9622H44.1809Z" fill="#F9F9F9" />
            <path
                d="M53.9811 26.8244L46.3989 33.3551L54.9096 41.1199H57.1791L48.5137 33.3036L56.1475 26.8244H53.9811Z"
                fill="#F9F9F9"
            />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="0.492819"
                    y1="62.4624"
                    x2="163.959"
                    y2="62.4624"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C28C2B" />
                    <stop offset="0.0328009" stopColor="#C6912D" />
                    <stop offset="0.2114" stopColor="#D9AA38" />
                    <stop offset="0.3742" stopColor="#E5BA3F" />
                    <stop offset="0.5056" stopColor="#E9BF41" />
                    <stop offset="0.6755" stopColor="#E0B33C" />
                    <stop offset="0.9825" stopColor="#C7932E" />
                    <stop offset="1" stopColor="#C5912D" />
                </linearGradient>
            </defs>
        </svg>
    );
});
