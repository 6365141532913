import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const SupportedLangs = ['en', 'fr', 'es', 'de', '__empty__'];
export const SupportedLangsSitemap = ['en', 'fr'];

export const HrefLangsMappingNonUs = {
    fr: 'fr-fr',
    es: 'es-es',
    de: 'de-de',
};

export const i18nOptions = {
    debug: false,
    whitelist: SupportedLangs,
    fallbackLng: '',
    lowerCaseLng: true,
    interpolation: {
        escapeValue: false,
    },
};

// client init
export const i18nClientInit = () => {
    return i18n.use(initReactI18next).init(i18nOptions);
};
