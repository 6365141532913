import React, { RefObject, useRef } from 'react';

import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import styles from './Button.css';
import { AppLoader } from '../../atoms/AppLoader/AppLoader';
import { ArrowRightIcon } from '../../atoms/Icons/Styleguide/ArrowRightIcon';
import { ExternalLinkIcon } from '../../atoms/Icons/Styleguide/ExternalLinkIcon';
import { setOpensModalElementRef } from '../../store/ducks/layout';

type ButtonProps = {
  id?: string;
  focusable?: boolean;
  className?: string;
  onClick?: (...args: Array<unknown>) => void;
  onMouseOut?: (...args: Array<unknown>) => void;
  onMouseOver?: (...args: Array<unknown>) => void;
  onMouseLeave?: (...args: Array<unknown>) => void;
  onMouseMove?: (...args: Array<unknown>) => void;
  onDragStart?: (...args: Array<unknown>) => void;
  onMouseEnter?: (...args: Array<unknown>) => void;
  onFocus?: (...args: Array<unknown>) => void;
  onBlur?: (...args: Array<unknown>) => void;
  type?: 'submit' | 'reset' | 'button';
  useDefaultStyles?: boolean;
  draggable?: boolean;
  children?: React.ReactNode;
  noPadding?: boolean;
  fullWidth?: boolean;
  style?: React.CSSProperties;
  ariaLabel?: string;
  ariaSelected?: boolean;
  ariaControls?: string;
  disabled?: boolean;
  outlined?: boolean;
  secondaryStyle?: boolean;
  isLink?: boolean;
  linkOnMobile?: boolean;
  withArrow?: boolean;
  white?: boolean;
  transparent?: boolean;
  black?: boolean;
  advantageBlue?: boolean;
  pseudoDisabled?: boolean;
  dataElementDescription?: string;
  modalOpens?: boolean;
  role?: string;
  next?: boolean;
  loading?: boolean;
  iconLink?: boolean;
  iconColor?: string;
};

const ButtonComponent = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      id,
      useDefaultStyles = true,
      focusable = true,
      className,
      onClick,
      type,
      children,
      noPadding,
      fullWidth,
      disabled,
      style,
      ariaLabel,
      outlined,
      secondaryStyle,
      linkOnMobile,
      withArrow,
      white,
      black,
      transparent,
      advantageBlue,
      pseudoDisabled,
      dataElementDescription,
      modalOpens = false,
      role,
      loading,
      isLink,
      ariaSelected,
      ariaControls,
      next,
      iconLink,
      iconColor,
      ...restProps
    },
    refFromContainer: RefObject<HTMLButtonElement>
  ) => {
    Button.displayName = 'ButtonFigma';
    const btnRef = useRef<HTMLButtonElement | null>(null);
    const dispatch = useDispatch();
    const onClickHandler = () => {
      if (modalOpens && refFromContainer?.current) {
        dispatch(setOpensModalElementRef(refFromContainer.current));
      } else if (modalOpens && btnRef.current) {
        dispatch(setOpensModalElementRef(btnRef.current));
      }

      onClick?.();
    };

    return (
      <button
        id={id}
        ref={refFromContainer || btnRef}
        tabIndex={focusable ? 0 : -1}
        role={role}
        className={classNames(
          className,
          useDefaultStyles && styles.container,
          outlined && styles.outlined,
          transparent && styles.transparent,
          white && styles.white,
          black && styles.black,
          advantageBlue && styles.advantageBlue,
          noPadding && styles.noPadding,
          fullWidth && styles.fullWidth,
          secondaryStyle && styles.secondaryStyle,
          linkOnMobile && styles.linkOnMobile,
          withArrow && styles.withArrow,
          pseudoDisabled && styles.pseudoDisabled,
          isLink && styles.isLink
        )}
        type={type}
        style={style}
        disabled={!pseudoDisabled && disabled}
        onClick={onClickHandler}
        aria-label={ariaLabel}
        aria-selected={ariaSelected}
        aria-controls={ariaControls}
        data-element-description={dataElementDescription}
        {...restProps}
      >
        {loading ? <AppLoader className={styles.btnLoader}/> : children}
        {next && <ArrowRightIcon/>}
        {iconLink && <ExternalLinkIcon color={iconColor}/>}
      </button>
    );
  }
);

ButtonComponent.displayName = 'Button';

export const Button = React.memo(ButtonComponent);
