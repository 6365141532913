import 'cross-fetch/polyfill';
import { globalErrorHandler } from './LogUtils';

const inMemoryCache = new Map();

export class HttpUtils {
  /**
   * Dynamically loads a script into the document head.
   * @param url - The URL of the script.
   * @param token - Optional data-ad-client token.
   */
  public static loadScript(url: string, token?: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const script: any = document.createElement('script');

      script.type = 'text/javascript';
      script.async = true;

      if (token) {
        script.dataset.adClient = token; // Safe and modern attribute handling
      }

      // Attach success and error handlers
      script.onload = () => resolve();
      script.onerror = () => reject(new Error(`Failed to load script: ${url}`));

      // Handle IE support (for old browsers)
      if (script.readyState) {
        script.onreadystatechange = () => {
          if (script.readyState === 'loaded' || script.readyState === 'complete') {
            script.onreadystatechange = null;
            resolve();
          }
        };
      }

      script.src = url;
      document.head.appendChild(script);
    });
  }

  /**
   * Fetches data from the specified URL with optional configuration.
   * @param url - The URL to fetch.
   * @param options - Optional fetch options.
   * @param jsonResponse - Whether to parse response as JSON.
   * @param returnNullContent - Return null for 204 responses or bad responses.
   */
  public static async fetch(url: string, options?, jsonResponse = true, returnNullContent = false): Promise<any> {
    options = options || {};
    options.headers = options.headers || new Headers();

    const response = await fetch(url, options);

    HttpUtils.assertFetchStatus(response);

    // Handle 204 No Content or invalid responses
    if (returnNullContent && (response.status === 204 || !(response.ok || response.status === 304))) {
      return null;
    }

    return jsonResponse ? response.json() : response.text();
  }

  /**
   * Asserts that the fetch response has a successful status code.
   * @param response - The fetch response.
   */
  private static assertFetchStatus(response: Response): void {
    if (!(response.status >= 200 && response.status < 300) && response.status !== 304) {
      throw new Error(`Request failed: ${response.status} - ${response.statusText}`);
    }
  }

  /**
   * Fetches data with ETag caching support.
   * @param url - The URL to fetch.
   * @param options - Optional fetch options.
   * @param jsonResponse - Whether to parse response as JSON.
   * @param returnNullContent - Return null for 204 responses or bad responses.
   */
  public static async fetchWithEtag(
    url: string,
    options: RequestInit = {},
    jsonResponse = true,
    returnNullContent = false
  ): Promise<any> {
    // Ensure options is always initialized and options.headers is a valid Headers instance
    options = options || {};
    options.headers = options.headers instanceof Headers ? options.headers : new Headers(options.headers || {});

    const cachedData = inMemoryCache.get(url);

    if (cachedData?.etag) {
      options.headers.set('If-None-Match', cachedData.etag);
    }

    try {
      const response = await fetch(url, options);

      HttpUtils.assertFetchStatus(response);

      // Return cached data if response is 304 Not Modified
      if (response.status === 304) {
        return cachedData ? cachedData.data : null;
      }

      // Handle 204 No Content or bad responses
      if (response.status === 204 || (returnNullContent && !response.ok)) {
        return null;
      }

      const data = jsonResponse ? await response.json() : await response.text();

      // Cache the response if it's successful and contains an ETag
      if (response.status === 200) {
        const etag = response.headers.get('Etag');

        if (etag) {
          inMemoryCache.set(url, { etag, data });
        }
      }

      return data;
    } catch (error) {
      globalErrorHandler({ error, filename: 'HttpUtils.ts', info: `Fetch with ETag failed for url: ${url}` });
    }
  }

  /**
   * Fetches a blog post with fallback support and ETag caching.
   * @param url - The primary URL to fetch.
   * @param fallbackUrl - The fallback URL in case the primary one fails.
   * @param options - Optional fetch options.
   * @param jsonResponse - Whether to parse response as JSON.
   * @param returnNullContent - Return null for 204 responses or bad responses.
   */
  public static async fetchBlogPostWithEtag(
    url: string,
    fallbackUrl: string,
    options: RequestInit = {},
    jsonResponse = true,
    returnNullContent = false
  ): Promise<any> {
    options = options || {};
    options.headers = options.headers instanceof Headers ? options.headers : new Headers(options.headers || {});

    const cachedData = inMemoryCache.get(url);

    if (cachedData?.etag) {
      options.headers.set('If-None-Match', cachedData.etag);
    }

    try {
      const response = await fetch(url, options);

      if (response.status === 404) {
        // Attempt to fetch from the fallback URL if 404 is encountered
        return await HttpUtils.fetchWithEtag(fallbackUrl, options, jsonResponse, returnNullContent);
      }

      HttpUtils.assertFetchStatus(response);

      if (response.status === 304) {
        return cachedData ? cachedData.data : null;
      }

      if (response.status === 204 || (returnNullContent && !response.ok)) {
        return null;
      }

      const data = jsonResponse ? await response.json() : await response.text();

      if (response.status === 200) {
        const etag = response.headers.get('Etag');

        if (etag) {
          inMemoryCache.set(url, { etag, data });
        }
      }

      return data;
    } catch (error) {
      globalErrorHandler({
        error,
        filename: 'HttpUtils.ts',
        info: `Fetch blog post with ETag failed for url: ${url}, fallbackUrl: ${fallbackUrl}`
      });
    }
  }
}
