export type BillingInfoValues = {
    address1: string;
    city: string;
    country: string;
    postal_code: string;
    creditBrand: string;
    creditLastFour: string;
    state: string;
};

export enum PaymentMethod {
    CARD = 'card',
    PAYPAL = 'paypal',
    APPLE_PAY = 'applePay',
    GIFT_CARD = 'giftCard',
}
