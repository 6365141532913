import { IGame } from '../../../models/Game/Game';
import { RegistrationSource } from '../../../models/User/RegistrationSource';
import { GemsAnalyticsRegistrationLocations } from '../../../store/ducks/leanplum/lpAnalytics';
import { AnalyticsEvent, AnalyticsEventAction, AnalyticsEventBuilder } from '../AnalyticsEventBuilder';

export class ProfileAnalyticsAi {
    async loginImpression(category: string, game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        // this is not correct
        event.setName('loginButton');
        event.setEventAction(AnalyticsEventAction.IMPRESSION);
        event.setNonInteraction(true);
        if (category) {
            event.setCustom('category', category);
        }
        if (game) {
            await event.setGame(game);
        }
        return event.build();
    }

    async loginClick(
        category: string,
        game: IGame,
        registrationLocation?: GemsAnalyticsRegistrationLocations,
        loginRegisterSource?: RegistrationSource
    ): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('loginButton');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        if (category) {
            event.setCustom('category', category);
        }
        if (loginRegisterSource) {
            event.setCustom('loginRegisterType', loginRegisterSource);
        }
        if (game) {
            await event.setGame(game);
        }
        if (registrationLocation) {
            event.setCustom('registrationLocation', registrationLocation);
        }
        return event.build();
    }

    async loginSuccess(
        category: string,
        game: IGame,
        loginLocation: GemsAnalyticsRegistrationLocations,
        loginRegisterSource?: RegistrationSource
    ): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('loginButton');
        event.setEventAction(AnalyticsEventAction.LOGIN);
        event.setNonInteraction(false);
        if (category) {
            event.setCustom('category', category);
        }
        if (loginRegisterSource) {
            event.setCustom('loginRegisterType', loginRegisterSource);
        }
        if (game) {
            await event.setGame(game);
        }
        if (loginLocation) {
            event.setCustom('loginLocation', loginLocation);
        }
        return event.build();
    }

    async emailOptIn(): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('loginButton');
        event.setEventAction(AnalyticsEventAction.EMAILOPTIN);
        event.setNonInteraction(false);
        return event.build();
    }

    async registration(
        category: string,
        game: IGame,
        source: RegistrationSource,
        avatarType: string,
        avatarBackground: string,
        registrationLocation: GemsAnalyticsRegistrationLocations,
        xsollaId: string
    ): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('registration');
        event.setEventAction('');
        event.setNonInteraction(false);
        event.setCustom('loginRegisterType', source);
        if (category) {
            event.setCustom('category', category);
        }
        if (game) {
            await event.setGame(game);
        }
        event.setCustom('avatarType', avatarType);
        event.setCustom('avatarBackground', avatarBackground);
        event.setCustom('registrationLocation', registrationLocation);
        if (xsollaId) {
            event.setCustom('xsollaId', xsollaId);
        }
        return event.build();
    }

    async registrationEmailConfirm(
        category: string,
        game: IGame,
        source: RegistrationSource,
        avatarType: string,
        avatarBackground: string,
        registrationLocation: GemsAnalyticsRegistrationLocations
    ): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('registrationEmailConfirm');
        event.setEventAction('');
        event.setNonInteraction(false);
        event.setCustom('loginRegisterType', source);
        if (category) {
            event.setCustom('category', category);
        }
        if (game) {
            await event.setGame(game);
        }
        event.setCustom('avatarType', avatarType);
        event.setCustom('avatarBackground', avatarBackground);
        event.setCustom('registrationLocation', registrationLocation);
        return event.build();
    }

    async publicProfileName(source?: RegistrationSource): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('publicProfileName');
        if (source) event.setCustom('loginRegisterType', source);
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        return event.build();
    }

    async profileEmail(source?: RegistrationSource): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('profileEmail');
        event.setEventAction(AnalyticsEventAction.CLICK);
        if (source) event.setCustom('loginRegisterType', source);
        event.setNonInteraction(false);
        return event.build();
    }

    async personalInformation(fieldName: string, fieldValue?: string): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('personalInformation');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        event.setCustom('personalInfoType', fieldName);
        if (fieldName === 'gender') event.setCustom('profileGender', fieldValue);
        return event.build();
    }

    async profileAvatar(avatarType: string, source?: RegistrationSource): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('profileAvatar');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        event.setCustom('avatarType', avatarType);
        if (source) event.setCustom('loginRegisterType', source);
        return event.build();
    }

    async profileAvatarCustomize(
        avatarBackground: string,
        avatarFrame: string,
        source?: RegistrationSource
    ): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('profileAvatarCustomize');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        event.setCustom('avatarBackground', avatarBackground);
        event.setCustom('avatarFrame', avatarFrame);
        if (source) event.setCustom('loginRegisterType', source);
        return event.build();
    }

    async onboardingPersonal(source?: RegistrationSource): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('onboardingPersonal');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        if (source) event.setCustom('loginRegisterType', source);
        return event.build();
    }

    async onboardingEmail(source?: RegistrationSource): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('onboardingEmail');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        if (source) event.setCustom('loginRegisterType', source);
        return event.build();
    }

    async resendConfirmation(): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('resendConfirmation');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        return event.build();
    }

    async resetPassword(type: string): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('resetPassword');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        event.setCustom('type', type);
        return event.build();
    }

    async socialEmailRequest(): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('socialEmailRequest');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        return event.build();
    }

    async registrationMoreAvatars(avatarType: string, source?: RegistrationSource): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('registrationMoreAvatars');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        event.setCustom('avatarType', avatarType);
        if (source) event.setCustom('loginRegisterType', source);
        return event.build();
    }

    async registrationAvatarSkip(avatarType: string, source?: RegistrationSource): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('registrationAvatarSkip');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        event.setCustom('avatarType', avatarType);
        if (source) event.setCustom('loginRegisterType', source);
        return event.build();
    }

    async createAccountButton(): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('createAccountButton');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        return event.build();
    }

    async createAccountSuccess(): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('createAccountSuccess');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        return event.build();
    }

    private async newEventBuilder(): Promise<AnalyticsEventBuilder> {
        return await new AnalyticsEventBuilder().baseEvent();
    }
}
