import { createAction, createReducer } from 'redux-act';
import { IBlog } from '../../../models/Strapi';

const initialBlogState: IBlog = {
    id: null,
    metaKeywords: '',
    facebookLink: '',
    instagramLink: '',
    createdAt: '',
    updatedAt: '',
    publishedAt: '',
    posts: [],
    postsModular: [],
    promoPost: null,
    promoPostModular: null,
    recommendedPosts: [],
    filteredPosts: [],
    filteredPostsModular: [],
    allCategories: [],
    blogCta: null,
};

export const setBlogArchiveData = createAction<IBlog>('set blog archive data');
export const blogArchiveDataReducer = createReducer<IBlog>({}, initialBlogState);
blogArchiveDataReducer.on(setBlogArchiveData, (_state, payload) => {
    return {
        ..._state,
        ...payload,
        filteredPosts: payload.posts || [],
        filteredPostsModular: payload.postsModular || []
    }
});

export const filterBlogArchiveData = createAction<IBlog>('filter blog archive data');
blogArchiveDataReducer.on(filterBlogArchiveData, (_state, payload) => payload);

export const setCurrentBlogCategory = createAction<any>('set current blog category');
export const currentBlogCategoryReducer = createReducer<any>({}, 'All');
currentBlogCategoryReducer.on(setCurrentBlogCategory, (_state, payload) => payload);
