import { IGame } from '../../../models/Game/Game';
import { AnalyticsEvent, AnalyticsEventAction, AnalyticsEventBuilder } from '../AnalyticsEventBuilder';

export class NewHomeAnalyticsAi {
    async searchNavClick(navType: string): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('SearchNav');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        event.setCustom('topnavClick', navType.toLowerCase());
        return event.build();
    }

    async seoImpression() {
        const event = await this.newEventBuilder();
        event.setName('SeoNav');
        event.setEventAction(AnalyticsEventAction.IMPRESSION);
        event.setNonInteraction(true);
        return event.build();
    }

    async seoClick(seo: string) {
        const event = await this.newEventBuilder();
        event.setName('SeoNav');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        event.setCustom('topnavClick', seo.toLowerCase());
        return event.build();
    }

    async footerSocialNav(socialNetwork: string): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('FooterSocialNav');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        event.setCustom('topnavClick', socialNetwork.toLowerCase());
        return event.build();
    }

    async searchNavSupportImpression(): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('SearchNav Support');
        event.setEventAction(AnalyticsEventAction.IMPRESSION);
        event.setNonInteraction(true);
        return event.build();
    }

    async searchNavSupportClick(clickTarget: string): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('SearchNav Support');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        event.setCustom('topnavClick', clickTarget);
        return event.build();
    }

    async searchNavFeedbackClick(clickTarget: string): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('SearchNav Feedback');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        event.setCustom('topnavClick', clickTarget);
        return event.build();
    }

    async chatBotButtonClick(clickTarget: string): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName(clickTarget);
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        event.setCustom('topnavClick', clickTarget);
        return event.build();
    }

    async promoboxImpression(promoTarget: string, promoType: string): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('promobox');
        event.setEventAction(AnalyticsEventAction.IMPRESSION);
        event.setNonInteraction(true);
        event.setCustom('promoboxImpression', promoTarget.toLowerCase());
        event.setCustom('promobox Type', promoType.toLowerCase());
        return event.build();
    }

    async promoboxSlotClick(
        promoTarget: string,
        promoType: string,
        position: string,
        gameOrCategory: string
    ): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('promoboxSlot');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        event.setCustom('game', gameOrCategory.toLowerCase());
        event.setCustom('promoboxSlotClick', promoTarget.toLowerCase());
        event.setCustom('promobox Type', `${promoType.toLowerCase()}|${position}`);
        return event.build();
    }

    async gameBox(game: IGame, homeCategory: string): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('gameBox');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        await event.setGame(game);
        event.setCustom('homeCategory', homeCategory);
        return event.build();
    }

    async seeAllButton(homeCategory: string): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('SeeAllButton');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        event.setCustom('homeCategory', homeCategory);
        return event.build();
    }

    async backButton(): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('BackButton');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        return event.build();
    }

    private async newEventBuilder(): Promise<AnalyticsEventBuilder> {
        return await new AnalyticsEventBuilder().baseEvent();
    }
}
