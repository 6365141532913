import React from 'react';

import { environment } from '../../config/environment';

export const TagManager = React.memo(() => {
    // Google Tag Manager (noscript)
    const noscript = `<iframe src="https://www.googletagmanager.com/ns.html?id=${environment.TAGMANAGER_SITE_ID}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

    return (
        <>
            <noscript dangerouslySetInnerHTML={{ __html: noscript }} />
        </>
    );
});
