import { LS_COOKIE_CONSTS } from '../../../../models/Enums';
import { LocalStorageService } from '../../../LocalStorage';
import { AnalyticsEventsPair } from '../../AnalyticsEventBuilder';

type ABValue = {
    group: string;
};

export const ABTicTacToe = (event: AnalyticsEventsPair) => {
    const lsv = LocalStorageService.getItem(LS_COOKIE_CONSTS.TIC_TAC_TOE_AB_TEST_SETTINGS, true);
    if (lsv) {
        let v: ABValue;
        try {
            v = JSON.parse(lsv);
        } catch (_) {
            return;
        }

        if (event.ga) {
            event.ga.data.dimension1 = v.group;
        }

        if (event.ai) {
            event.ai.data.ABTestSlot1 = v.group;
            event.ai.data.ABTestName = 'TicTacToe GameEnd';
        }
    }
};
