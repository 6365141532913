import React from 'react';

import window from 'global';

import { ABTestManager } from './ABTestManager';
import { globalErrorHandler } from '../../../utils/LogUtils';
import { MiscUtils } from '../../../utils/MiscUtils';
import { ABTests } from '../../models/ABTests';

export const ABTestContext = React.createContext(new ABTestManager());

export function useABTest<K extends keyof ABTests>(testName: K, defaultValue: ABTests[K]): ABTests[K] {
  const abtests = React.useContext(ABTestContext);

  return useABTestCommon(abtests, testName, defaultValue);
}

export function useABTestClass<K extends keyof ABTests>(abtests, testName: K, defaultValue: ABTests[K]): ABTests[K] {
  return useABTestCommon(abtests, testName, defaultValue);
}

function useABTestCommon<K extends keyof ABTests>(abtests, testName: K, defaultValue: ABTests[K]) {
  if (!MiscUtils.isServer) {
    const { skipTestName, skipCookie, variationvalue } = validateAbtestOptions(abtests, testName);
    const result = abtests.getVariation(testName, defaultValue);
    const { name, value, options } = abtests.getCookie(skipTestName, skipCookie, variationvalue);

    if (options && options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }

    document.cookie = [
      `${name}=${encodeURIComponent(value)}`,
      ...Object.entries(options).map((kv) => kv.join('='))
    ].join('; ');

    if (skipCookie) {
      return defaultValue;
    }

    if (variationvalue) {
      return variationvalue;
    }

    return result;
  }
}

export function ABTestProvider({ children, manager }: React.PropsWithChildren<ABTestProviderProps>) {
  return <ABTestContext.Provider value={manager}>{children}</ABTestContext.Provider>;
}

interface ABTestProviderProps {
  manager: ABTestManager;
}

function getCookie(cookieName) {
  const cookie = {};

  document.cookie.split(';').forEach(function (el) {
    const [key, value] = el.split('=');

    cookie[key.trim()] = value;
  });
  return cookie[cookieName];
}

//validate abtest options and return true if we need to skip the cookie
function validateAbtestOptions(
  abtests,
  testName
): { skipTestName: string; skipCookie: boolean; variationvalue: string } {
  /*get url arkvariation parameter to compare with testName */
  let setVariationValue;

  if (window.location) {
    const urlParams = new URLSearchParams(window.location.search);
    const forceVariation = urlParams.get('arkvariation');
    const variationvalue = urlParams.get('value');

    if (forceVariation && forceVariation == testName) {
      setVariationValue = variationvalue;
    }
  }

  if (!setVariationValue) {
    try {
      const res = abtests.config[testName][0];

      if (res) {
        if (res.options) {
          const arkvisitor = getCookie('ark_visitor_main');
          const arkabtests = getCookie('arkabtests');
          const visitLoadCook = getCookie('arkUserFirstLoad');
          const asCookieValue = arkabtests.toString().includes(testName);
          const { skipUserStatus, onlyPath, firstLoad } = res.options;

          if (
            (arkvisitor == skipUserStatus && firstLoad != visitLoadCook) ||
            !window.location.pathname.toString().includes(onlyPath)
          ) {
            return {
              skipTestName: testName,
              skipCookie: !asCookieValue,
              variationvalue: setVariationValue
            };
          }
        }
      }
    } catch (error) {
      globalErrorHandler({ error, filename: 'ABTestReact.tsx', info: 'validateAbtestOptions()' });
    }
  }

  return { skipTestName: testName, skipCookie: false, variationvalue: setVariationValue };
}
