import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Button } from '../../../FigmaStyleguide/Button/Button';
import { setDialogData } from '../../../store/ducks/dialog/dialog';
import styles from './CategoryPromoDialog.css';

export const CategoryPromoDialog = () => {
    const dispatch = useDispatch();
    const categoryPromoData = useSelector((state) => state.categoryPromoData);

    const handleCloseModal = () => {
        dispatch(setDialogData({ open: false, type: null }));
    };
    return (
        <div className={styles.wrapper}>
            <img src={categoryPromoData?.promoPopupBG?.url} alt="Promo Modal" />
            <div className={styles.content}>
                <p className={styles.title}>{categoryPromoData?.promoPopupTitle}</p>
                <p className={styles.subtitle}>{categoryPromoData?.promoPopupText}</p>
                <Button onClick={handleCloseModal} type="button" className={styles.btn}>
                    {categoryPromoData?.promoPopupButtonText}
                </Button>
            </div>
        </div>
    );
};
