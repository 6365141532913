import { MiscUtils } from '../../../../utils/MiscUtils';
import { environment } from '../../../config/environment';
import { IGame } from '../../../models/Game/Game';
import { UrlService } from '../../UrlService';
import { Analytics } from '../Analytics';
import { AnalyticsEvent, AnalyticsEventBuilder } from '../AnalyticsEventBuilder';

export class GeneralAnalyticsGa {
    displayAd(refresh: boolean, isViewable: boolean, game: IGame, size: number[]): AnalyticsEvent {
        const event = this.newEventBuilder();

        event.setNameGA('Display_Ads');
        const categoryValue = refresh ? 'refresh' : 'notrefresh';

        event.setCustom('event_category', categoryValue);
        let labelValue = '';

        if (game) {
            labelValue = Analytics.generateLabel(environment.ARENA_DOMAIN, 'game start', game.slug);
        } else {
            labelValue = Analytics.generateLabel(environment.ARENA_DOMAIN, UrlService.getPageNameForAnalytics(), '');
        }

        event.setCustom('event_label', labelValue);
        event.setGaNonInteraction(!refresh);
        return event.build();
    }

    topNavAdvantage(): AnalyticsEvent {
        const event = this.newEventBuilder();

        event.setNameGA('TopNav_Advantage_Click');
        event.setCustom('event_label', Analytics.generateLabelForGA4(environment.ARENA_DOMAIN, window.location.pathname));
        event.setGaNonInteraction(false);
        return event.build();
    }

    carouselButtonClick(): AnalyticsEvent {
        const event = this.newEventBuilder();

        event.setNameGA('Carousel_Play_Click');
        event.setCustom('event_label', Analytics.generateLabelForGA4(environment.ARENA_DOMAIN, window.location.pathname));
        event.setGaNonInteraction(false);
        return event.build();
    }

    promoBoxSlotClick(): AnalyticsEvent {
        const event = this.newEventBuilder();

        event.setNameGA('PromoboxSlot_Click');
        event.setCustom('event_label', Analytics.generateLabelForGA4(environment.ARENA_DOMAIN, window.location.pathname));
        event.setGaNonInteraction(false);
        return event.build();
    }

    topNavSearchClick(navType: string): AnalyticsEvent {
        const event = this.newEventBuilder();

        switch (navType) {
            case 'shop':
                event.setNameGA('TopNav_Shop_Click');
                break;
            case 'search':
                event.setNameGA('TopNav_Search_Click');
                break;
            case 'support':
                event.setNameGA('TopNav_Support_Click');
                break;
        }

        event.setCustom('event_label', Analytics.generateLabelForGA4(environment.ARENA_DOMAIN, window.location.pathname));
        event.setGaNonInteraction(false);
        return event.build();
    }

    searchQuery(): AnalyticsEvent {
        const event = this.newEventBuilder();

        event.setNameGA('Search_Query');
        event.setCustom('event_label', Analytics.generateLabelForGA4(environment.ARENA_DOMAIN, window.location.pathname));
        event.setGaNonInteraction(false);
        return event.build();
    }

    searchGameClick(): AnalyticsEvent {
        const event = this.newEventBuilder();

        event.setNameGA('Search_Game_Click');
        event.setCustom('event_label', Analytics.generateLabelForGA4(environment.ARENA_DOMAIN, window.location.pathname));
        event.setGaNonInteraction(false);
        return event.build();
    }

    categoryClick(category: string): AnalyticsEvent {
        const event = this.newEventBuilder();

        event.setNameGA('TopNav_Category_Click');
        event.setCustom('event_category', category);
        event.setCustom('event_label', Analytics.generateLabelForGA4(environment.ARENA_DOMAIN, window.location.pathname));
        event.setGaNonInteraction(false);
        return event.build();
    }


    private newEventBuilder(): AnalyticsEventBuilder {
        return new AnalyticsEventBuilder();
    }

}
