import { ColumnModuleCD, MainModuleCD, PageBuilderAdvantageAnalyticsAi } from './AI/PageBuilderAdvantageAnalyticsAi';
import { AnalyticsEventsPair, makeEventsPair } from './AnalyticsEventBuilder';

export class PageBuilderAdvantageAnalytics {
    aiAnalytics = new PageBuilderAdvantageAnalyticsAi();

    async mainModuleClick(CD: MainModuleCD): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.MainModule(CD));
    }
    async columnModuleImpression(CD: ColumnModuleCD): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.ColumnsModule(CD, true));
    }
    async columnModuleClick(CD: ColumnModuleCD): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.ColumnsModule(CD, false));
    }

    async comingSoonClick(name: string): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.comingSoonCTAClick(name));
    }
}
