import { useCallback, useEffect } from 'react';

type allHandlersRequired = {
    onChange: () => void;
    onVisible: () => void;
    onHidden: () => void;
};

type onlyOnChangeRequired = {
    onChange: () => void;
    onVisible?: () => void;
    onHidden?: () => void;
};

type onlyOnVisibleRequired = {
    onVisible: () => void;
    onChange?: () => void;
    onHidden?: () => void;
};

type onlyOnHiddenRequired = {
    onHidden: () => void;
    onChange?: () => void;
    onVisible?: () => void;
};

type useVisibilityAPIProps = allHandlersRequired | onlyOnChangeRequired | onlyOnVisibleRequired | onlyOnHiddenRequired;

export const useVisibilityAPI = function ({ onVisible, onHidden, onChange }: useVisibilityAPIProps) {
    const handler = useCallback(() => {
        onChange?.();
        document.visibilityState == 'visible' && onVisible?.();
        document.visibilityState === 'hidden' && onHidden?.();
    }, [onChange, onHidden, onVisible]);

    useEffect(() => {
        document.addEventListener('visibilitychange', handler);
        return () => {
            document.removeEventListener('visibilitychange', handler);
        };
    }, [handler]);
};
