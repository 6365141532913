import { Api, ApiGateway, ApiGatewayInitParams, UserApiErrorV1 } from '@arkadium/eagle-user-client';
import { ChangeEmailConfirmDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/change-email-confirm.dto';
import { ChangePasswordDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/change-password.dto';
import { ConfirmResetPasswordDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/confirm-reset-password.dto';
import { EmailConfirmationDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/email-confirmation.dto';
import { EmailLoginDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/email-login.dto';
import { EmailRegistrationDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/email-registration.dto';
import { FacebookLoginDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/facebook-login.dto';
import { GoogleLoginDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/google-login.dto';
import { PasswordComplexityDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/password-complexity.dto';
import { RequstResetPasswordDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/request-reset-password.dto';
import { ResendConfirmationDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/resend-confirmation.dto';
import { UserCheckEmail } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-check-email.dto';
import { UserCheckName } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-check-name.dto';
import { UserProfileForSocialDto } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-profile-for-social.dto';
import { UserUpdateRequestDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-update.dto';
import { HttpError } from '@arkadium/eagle-user-client/dist/types/utils';
import { batch } from 'react-redux';


import AdFreeService from './AdFreeService';
import { Analytics } from './Analytics/Analytics';
import { asyncStoreStateWhenReady } from './Analytics/AnalyticsEventBuilder';
import { LeanplumAnalytics } from './Analytics/LeanplumAnalytics';
import { AppInsightService } from './AppInsight';
import { BaseApiService } from './BaseApiService';
import { CookieService } from './CookieService';
import { setAiIsVirtualItemBuy, setAiIsVirtualItemSpent } from './GemsService';
import HighScoreService from './HighScoreService';
import RecentlyPlayedService from './RecentlyPlayedService';
import { UrlService } from './UrlService';
import { HttpUtils } from '../../utils/HttpUtils';
import { globalErrorHandler } from '../../utils/LogUtils';
import { MiscUtils } from '../../utils/MiscUtils';
import { environment } from '../config/environment';
import { getAvatarAnalyticsKey } from '../constants/AvatarImagesData';
import { HeaderSideMenuTabs } from '../constants/HeaderSideMenuTabs';
import { PageTypes, ProfileRightSideRoutes } from '../constants/Pages';
import { SignInUpComponents } from '../constants/SignInUpComponents';
import { getPlanName, SUBSCRIPTION_PLAN_NAME } from '../constants/SubscriptionPlan';
import { GameState, LS_COOKIE_CONSTS, QUERY_STRING_CONSTS } from '../models/Enums';
import {
  PlatformType,
  SubscriptionAutoRenew,
  SubscriptionLifeCycle,
  SubscriptionSource,
  SubscriptionStatus
} from '../models/Subscription/SubscriptionData';
import { RegistrationSource } from '../models/User/RegistrationSource';
import { UserModel } from '../models/User/UserModel';
import { reduxStore } from '../store';
import { setGameState } from '../store/ducks/games';
import { setGemsAmount } from '../store/ducks/gems/gems';
import { setGiftCardStep } from '../store/ducks/giftCard';
import {
  setLoaderStart,
  setProfilePanelState,
  setSideMenuActivePage,
  setSideMenuOpened,
  setSignInUpCopyState,
  setSignInUpState
} from '../store/ducks/layout';
import { setPageType } from '../store/ducks/pages';
import { setPreLoadData } from '../store/ducks/preLoadData';
import {
  setActiveUserSubscriptions,
  setExpiredUserSubscriptions,
  setSubscriptionSource
} from '../store/ducks/subscription/common';
import { setHostedLoginToken, setPublicUser, setUser, setUserProcessed } from '../store/ducks/user';

enum Events {
  onWidgetStateChanged = 1,
  onAuthStateChanged = 2,
}

export enum AuthType {
  EmailPassword = 'EmailPassword',
  Facebook = 'Facebook',
  Google = 'Google',
}

export enum EmailCodes {
  CONFIRMATION_CODE = 'account_confirmation_code',
  EMAIL_CHANGE_CODE = 'email_change_confirmation_code',
  RESET_PASSWORD_CODE = 'request_reset_password_code',
}

export enum ERRORS_DESCRIPTION {
  InvalidRequestData = 'Your request couldn’t be processed.',
  UserNotFound = 'Sorry, we can\'t locate that email address.',
  UserAlreadyExist = 'This email address is already in use.',
  UserIsNotActive = 'Your request couldn’t be processed.',
  UserIsBanned = 'Your request couldn’t be processed.',
  UserIsSoftDeleted = 'Your request couldn’t be processed.',
  UserRemovedViaGDPR = 'Your request couldn’t be processed.',
  EmailDoesNotSpecified = 'Sorry, your email has not been confirmed.',
  EmailAlreadyUsed = 'This email address is already in use.',
  RefreshTokenIsInvalid = 'Sorry, an error occurred.',
  EmailOrPasswordIsIncorrect = 'Sorry, your email or password is incorrect.',
  PasswordTooEasy = 'Sorry, this password isn\'t secure.',
  CountryIsInvalid = 'Your request couldn’t be processed.',
  LanguageIsInvalid = 'Your request couldn’t be processed.',
  UserOldPasswordIsIncorrect = 'Sorry, that password is incorrect.',
  NewAndOldPasswordAreEquals = 'NewAndOldPasswordAreEquals',
  UserPasswordsAreNotEqual = 'Sorry, that password is incorrect.',
  UserNotConfirmedError = 'Sorry, your email has not been confirmed.',
  UserEmailConfirmationCodeIsInvalid = 'Sorry, that link has expired.',
  UserPasswordResetCodeIsInvalid = 'Sorry, that link has expired.',
  TooManyFailedLoginAttempts = 'Too many unsuccessful sign in attempts.',
  EmailSenderGeneralError = 'Sorry, an error occurred.',
  EmailSenderEmailContentIsEmpty = 'Sorry, an error occurred.',
  ExternalProviderError = 'Sorry, an error occurred.',
  UserNameAlreadyUsed = 'That screen name is already taken – please try another',
  NoEmailChangeRequest = 'NoEmailChangeRequest',
  EmailChangeRequestCodeIsInvalid = 'User already has a subscription',
  CaptchaValidationFailed = 'Please try again or refresh the page.',
  CaptchaScoreIsTooLow = 'Captcha score is too low',
}

const { store } = reduxStore;
const AUTH_TOKEN = 'eagle-access-token';

interface UserServiceInterface {
  userLogin(data: EmailLoginDTO): void;
  loginViaFacebook(data: FacebookLoginDTO): Promise<boolean>;
  loginViaGoogle(data: GoogleLoginDTO): Promise<boolean>;
  userRegister(data: EmailRegistrationDTO): void;
  userLogout(): void;
  userUpdate(user: UserUpdateRequestDTO): void;
  isUserLoggedIn(): boolean;
  getUserFromStore(): UserModel;
  handleLoginRedirect(): void;
  changePassword(data: ChangePasswordDTO): Promise<number>;
  requestResetPassword(data: RequstResetPasswordDTO): Promise<void>;
  resendConfirmationEmail(data: ResendConfirmationDTO): Promise<void>;
  changePasswordByRecoveryCode(data: ConfirmResetPasswordDTO): Promise<number>;
  gameSignListenerAdd(): void;
  gameSignListenerRemove(): void;
  openSignInPanel(): void;
  openResetPanel(): void;
  openRequestEmail(): void;
  openSignUpPanel(): void;
  closePanel(): void;
  getGoogleButton(): Promise<string>;
  getFacebookButton(): Promise<string>;
  getToken(): Promise<string>;
  errorCodeToText(errorCode: number): string;
  errorCodeToEnum(errorCode: number): string;
  manageErrorsEmailVsPass(res: number): void;
}

export class UserServiceClass extends BaseApiService implements UserServiceInterface {
  public authApiService: ApiGateway;

  private adFreeEmail = 'adfree@arkadium.com';
  private googleButtonUrl = 'https://login.arkadium.com/arenax-connect/azure.google.next.index-arkcom20230216.htmlapi2.html';
  private facebookButtonUrl = 'https://login.arkadium.com/arenax-connect/azure.facebook.next.new.index0203.html';

  constructor() {
    super();

    if (!MiscUtils.isServer) {
      this.initAPI();
    }
  }

  public isUserLoggedIn(): boolean {
    if (MiscUtils.isServer) {
      return false;
    }

    return this.getSessionStorage().isAuthorised();
  }

  public getUserFromStore(): UserModel {
    if (MiscUtils.isServer) {
      return null;
    }

    return store.getState().user as UserModel;
  }

  public userLogin = async (data: EmailLoginDTO): Promise<void> => {
    const api = await this.authApiService.getAuthApi(Api.v1);

    try {
      await api.auth.loginViaEmail(data);
      window.history.replaceState(null, '', encodeURI(this.generateUrl()));
      await this.syncLocalWithDB();

      try {
        const userProfile = await api.managment.getUserProfile();

        store.dispatch(setUser(userProfile));
        this.setAdfree(userProfile);
        LeanplumAnalytics.setUserAttributesCustom({ subscriber: this.isUserSubscriber() });
      } catch (err) {
        globalErrorHandler({ error: err, filename: 'UserService.ts', info: 'userLogin.getUserProfile()' });
        AppInsightService.trackAppError(err, { data: 'getUserProfile()' });

      }
    } catch (err) {
      globalErrorHandler({ error: err, filename: 'UserService.ts', info: 'userLogin.loginViaEmail()' });
      const errCode = this.extractErrorCode(err);

      AppInsightService.trackAppError(err, { data: 'loginViaEmail()', errorCode: errCode });
      throw errCode;
    }
  };

  public userRegister = async (data: EmailRegistrationDTO): Promise<string> => {
    const api = await this.authApiService.getAuthApi(Api.v1);

    try {
      const { uid } = await api.auth.registerViaEmail(data);

      return uid;
    } catch (err) {
      globalErrorHandler({ error: err, filename: 'UserService.ts', info: 'userRegister()' });
      const errCode = this.extractErrorCode(err);

      AppInsightService.trackAppError(err, { data: 'registerViaEmail()', errorCode: errCode });
      throw errCode;
    }
  };

  public async requestResetPassword(data: RequstResetPasswordDTO): Promise<void> {
    const api = await this.authApiService.getAuthApi(Api.v1);

    try {
      await api.auth.requestResetPassword(data);
    } catch (err) {
      globalErrorHandler({ error: err, filename: 'UserService.ts', info: 'requestResetPassword()' });
      const errCode = this.extractErrorCode(err);

      AppInsightService.trackAppError(err, { data: 'requestResetPassword()', errorCode: errCode });
      throw errCode;
    }
  }

  public async changePassword(data: ChangePasswordDTO): Promise<number> {
    let res = 200;
    const api = await this.authApiService.getAuthApi(Api.v1);

    await api.auth.changePassword(data).catch((err) => {
      globalErrorHandler({ error: err, filename: 'UserService.ts', info: 'changePassword()' });
      res = this.extractErrorCode(err);
      AppInsightService.trackAppError(err, { data: 'changePassword()', errorCode: res });
    });
    return res;
  }

  public async getUserByEmail(data: UserCheckEmail): Promise<UserProfileForSocialDto> {
    const api = await this.authApiService.getAuthApi(Api.v1);

    return api.managment.checkUserEmail(data);
  }

  public async generateUserName(): Promise<string> {
    const api = await this.authApiService.getAuthApi(Api.v1);

    return api.managment.generateUserName();
  }

  public async checkUserName(name: UserCheckName): Promise<{ result: boolean }> {
    const api = await this.authApiService.getAuthApi(Api.v1);

    return api.managment.checkUserName(name);
  }

  public async checkPasswordComplexity(data: PasswordComplexityDTO): Promise<{ result: boolean }> {
    const api = await this.authApiService.getAuthApi(Api.v1);

    return api.auth.checkPasswordComplexity(data);
  }

  public async confirmUser(data: EmailConfirmationDTO): Promise<void> {
    const api = await this.authApiService.getAuthApi(Api.v1);

    try {
      await api.auth.confirmUser(data);
    } catch (err) {
      globalErrorHandler({ error: err, filename: 'UserService.ts', info: 'confirmUser()' });
      const errorCode = this.extractErrorCode(err);

      AppInsightService.trackAppError(err, { data: 'confirmUser()', errorCode });
      throw errorCode;
    }
  }

  public async emailChangeConfirm(data: ChangeEmailConfirmDTO): Promise<{ resCode: number, errorText: string }> {
    let resCode = 200;
    let errorText: string;
    const api = await this.authApiService.getAuthApi(Api.v1);

    await api.managment.changeEmailConfirm(data).catch((err) => {
      globalErrorHandler({ error: err, filename: 'UserService.ts', info: 'emailChangeConfirm()' });
      resCode = this.extractErrorCode(err);
      errorText = err.body?.details;
      AppInsightService.trackAppError(err, { data: 'changeEmailConfirm()', errorCode: resCode });
    });
    return { resCode, errorText };
  }

  public async changePasswordByRecoveryCode(data: ConfirmResetPasswordDTO): Promise<number> {
    let res = 200;
    const api = await this.authApiService.getAuthApi(Api.v1);

    await api.auth.confirmResetPassword(data).catch((err) => {
      globalErrorHandler({ error: err, filename: 'UserService.ts', info: 'changePasswordByRecoveryCode()' });
      res = this.extractErrorCode(err);
      AppInsightService.trackAppError(err, { data: 'confirmResetPassword()', errorCode: res });
    });
    return res;
  }

  public async resendConfirmationEmail(data: ResendConfirmationDTO): Promise<void> {
    const api = await this.authApiService.getAuthApi(Api.v1);

    try {
      await api.auth.resendConfirmation(data);
    } catch (err) {
      globalErrorHandler({ error: err, filename: 'UserService.ts', info: 'resendConfirmationEmail()' });
      const errorCode = this.extractErrorCode(err);

      AppInsightService.trackAppError(err, { data: 'resendConfirmation()', errorCode });
      throw errorCode;
    }
  }

  public async userUpdate(user: UserUpdateRequestDTO | { [x: string]: string } | Partial<UserModel>): Promise<number> {
    const lang = store.getState().currentLang;
    const languageId = lang.toUpperCase();
    const filterFalsyValues = <T extends object>(obj: T): Partial<T> =>
      Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== undefined && v !== null)) as Partial<T>;
    const data = {
      ...filterFalsyValues({
        name: user.name,
        firstName: user.firstName,
        lastName: user.lastName,
        birthday: user.birthday,
        gender: user.gender,
        phone: user.phone,
        countryId: user.countryId,
        avatar: user.avatar,
        onboardingProgress: user.onboardingProgress,
        avatarBackground: user.avatarBackground,
        subscriberAvatar: user.subscriberAvatar ?
          { ...user.subscriberAvatar as { avatar: string, frame: string } } :
          undefined
      }),
      languageId
    };
    let res = 200;

    try {
      const api = await this.authApiService.getAuthApi(Api.v1);

      await api.managment.updateUser(data as UserUpdateRequestDTO);
    } catch (err) {
      globalErrorHandler({ error: err, filename: 'UserService.ts', info: 'userUpdate()' });
      res = this.extractErrorCode(err);
      AppInsightService.trackAppError(err, { data: 'updateUser()', errorCode: res });
    }

    return res;
  }


  public getCarouselGiftCardParam(): string {
    return UrlService.getQSParam(window.location.search, 'giftcard-carousel');
  }

  public async handleLoginRedirect() {
    const arkPromoCode = UrlService.getQSParam(window.location.search, QUERY_STRING_CONSTS.ARK_PROMO);
    const isLoggedIn = this.isUserLoggedIn();

    if (arkPromoCode === 'registration' && !isLoggedIn) {
      this.openSignUpPanel();
    }

    if (arkPromoCode === 'house_ad') {
      store.dispatch(setSubscriptionSource(SubscriptionSource.HOUSE_AD));
      this.openSubscriptionPanel();
    }

    store.dispatch(setLoaderStart(true));

    const confirmationCode = UrlService.getQSParam(window.location.search, EmailCodes.CONFIRMATION_CODE);

    if (confirmationCode) {
      this.userLogout();
      this.openSignInPanel();

      const state = await asyncStoreStateWhenReady();

      Analytics.trackEvent(
        Analytics.profile.registrationEmailConfirm(
          state?.categoryPageName,
          state?.gameArkadiumSlug,
          RegistrationSource.EMAIL,
          getAvatarAnalyticsKey(state?.user?.avatar),
          state?.user?.avatar?.avatarBackground,
          state?.socialRegistrationLocation
        )
      ).then(r => r);
    }

    const resetCode = UrlService.getQSParam(window.location.search, EmailCodes.RESET_PASSWORD_CODE);

    if (resetCode) {
      this.userLogout();
      this.openResetPanel();
    }

    const hasAuthToken = localStorage.getItem(AUTH_TOKEN);

    hasAuthToken && (await this.userLoad());

    // Open SignIn Panel
    const subscriptionLogout = UrlService.getQSParam(window.location.search, 'subsLogout');

    if (subscriptionLogout) {
      this.openSignInPanel();
    }

    const subscriptionParam = UrlService.getQSParam(window.location.search, 'subscription');

    if (subscriptionParam) {
      store.dispatch(setSubscriptionSource(SubscriptionSource.EXTERNAL));
      this.openSubscriptionPanel();
    }

    const giftCardParam = this.getCarouselGiftCardParam();

    if (giftCardParam) {
      store.dispatch(setSubscriptionSource(SubscriptionSource.HOME_CAROUSEL));
    }

    store.dispatch(setLoaderStart(false));
  }

  public async userLoad() {
    const api = await this.authApiService.getAuthApi(Api.v1);

    await api.managment
      .getUserProfile()
      .then((data) => {
        store.dispatch(setUser(data));
        this.setAdfree(data);
      })
      .catch((err) => {
        globalErrorHandler({ error: err, filename: 'UserService.ts', info: 'userLoad()' });
      });
  }

  public async getPublicUserProfile(userId: string): Promise<void> {
    try {
      const url = `${environment.EAGLE_API_BASE_URL}user/${userId}`;
      const options = {
        method: 'GET'
      };
      const publicUser = await HttpUtils.fetch(url, options);

      store.dispatch(setPublicUser(publicUser));
    } catch (error) {
      store.dispatch(setPageType(PageTypes.NotFound));
      this.trackError(
        error,
        'An error occurred while try to update user profile. See app insight logs for details.'
      );
      throw error;
    }
  }

  public userLogout(): void {
    this.authApiService.logout();
    batch(() => {
      store.dispatch(setUser(null));
      store.dispatch(setGemsAmount(0));
      store.dispatch(setActiveUserSubscriptions(null));
      store.dispatch(setExpiredUserSubscriptions(null));
      store.dispatch(setSubscriptionSource(null));
      store.dispatch(setHostedLoginToken(null));
      store.dispatch(setSignInUpState(SignInUpComponents.SIGN_IN));
    });
    CookieService.setArkCookie(LS_COOKIE_CONSTS.AD_FREE_VER, false);
    CookieService.setArkCookie(LS_COOKIE_CONSTS.SUBSCRIPTION, false);
    CookieService.setArkCookie(LS_COOKIE_CONSTS.SUBSCRIPTION_ID, '');
    store.dispatch(
      setPreLoadData({
        adFree: AdFreeService.adFreeTurnOffFilter(),
        subscription: false
      })
    );
    setAiIsVirtualItemBuy().then(r => r);
    setAiIsVirtualItemSpent().then(r => r);
    LeanplumAnalytics.setUserAttributesCustom({ subscriber: this.isUserSubscriber() });
  }

  public openSignInPanel() {
    setTimeout(() => {
      store.dispatch(setSideMenuActivePage(HeaderSideMenuTabs.LOG_IN_TAB));
      store.dispatch(setSignInUpState(SignInUpComponents.SIGN_IN));
      store.dispatch(setSignInUpCopyState(true));
    }, 300);
  }

  public openResetPanel() {
    setTimeout(() => {
      store.dispatch(setSideMenuActivePage(HeaderSideMenuTabs.LOG_IN_TAB));
      store.dispatch(setSignInUpState(SignInUpComponents.CONFIRM_RESET_PASSWORD));
    }, 300);
  }

  public openRequestEmail() {
    setTimeout(() => {
      store.dispatch(setSideMenuActivePage(HeaderSideMenuTabs.LOG_IN_TAB));
      store.dispatch(setSignInUpState(SignInUpComponents.REQUEST_EMAIL));
    }, 300);
  }

  public openSignUpPanel() {
    setTimeout(() => {
      store.dispatch(setSideMenuActivePage(HeaderSideMenuTabs.LOG_IN_TAB));
      store.dispatch(setSignInUpState(SignInUpComponents.SIGN_UP));
    }, 300);
  }

  public openSubscriptionPanel() {
    setTimeout(() => {
      store.dispatch(setSideMenuActivePage(HeaderSideMenuTabs.SUBSCRIPTION_TAB));
    }, 300);
  }

  public openAfterSignPanel() {
    setTimeout(() => {
      store.dispatch(setSideMenuActivePage(HeaderSideMenuTabs.LOG_IN_TAB));
      store.dispatch(setSignInUpState(SignInUpComponents.AFTER_SIGN));
    }, 300);
  }

  public closePanel() {
    store.dispatch(setSideMenuOpened(false));
    store.dispatch(
      setProfilePanelState({
        isOpened: false,
        target: ProfileRightSideRoutes.NOOP,
        caption: ''
      })
    );
  }

  public async getGoogleButton(): Promise<string> {
    return this.googleButtonUrl;
  }

  public async getFacebookButton(): Promise<string> {
    return this.facebookButtonUrl;
  }

  public async loginViaFacebook(data: FacebookLoginDTO): Promise<boolean> {
    const api = await this.authApiService.getAuthApi(Api.v1);

    try {
      const result = await api.auth.loginViaFacebook(data);
      const isRegistered = result?.isRegistered ? result.isRegistered : false;

      await this.syncLocalWithDB();
      return isRegistered;
    } catch (err) {
      globalErrorHandler({ error: err, filename: 'UserService.ts', info: 'loginViaFacebook()' });
      const errorForAnalytics = {
        errorCode: this.extractErrorCode(err),
        errorEmail: this.extractErrorEmail(err),
        isRegistered: {}
      };

      AppInsightService.trackAppError(err, { data: 'loginViaFacebook()', errorCode: errorForAnalytics });
      throw {
        errCode: this.extractErrorCode(err),
        errEmail: this.extractErrorEmail(err)
      };
    }
  }

  public async loginViaGoogle(data: GoogleLoginDTO): Promise<boolean> {
    const api = await this.authApiService.getAuthApi(Api.v1);

    try {
      const { isRegistered } = await api.auth.loginViaGoogle(data);

      return isRegistered;
    } catch (err) {
      globalErrorHandler({ error: err, filename: 'UserService.ts', info: 'loginViaGoogle()' });
      const errorForAnalytics = {
        errorCode: this.extractErrorCode(err),
        errorEmail: this.extractErrorEmail(err),
        isRegistered: {}
      };

      AppInsightService.trackAppError(err, { data: 'loginViaGoogle()', errorCode: errorForAnalytics });
      throw {
        errCode: this.extractErrorCode(err),
        errEmail: this.extractErrorEmail(err)
      };
    }
  }

  public getToken = async (): Promise<string> => {
    return this.authApiService.getToken();
  };

  public errorCodeToText(errorCode: number): string {
    const errorLabel = this.errorCodeToEnum(errorCode);

    if (ERRORS_DESCRIPTION[errorLabel]) {
      return ERRORS_DESCRIPTION[errorLabel];
    }

    return 'Unknown Server error';
  }

  public errorCodeToEnum(errorCode: number): string {
    return Object.keys(UserApiErrorV1).find((key) => UserApiErrorV1[key] === errorCode);
  }

  public manageErrorsEmailVsPass(res: number) {
    const passwordEnums = ['PasswordTooEasy'];
    const codeEnum = this.errorCodeToEnum(res);
    const message = { email: '', password: '' };

    if (passwordEnums.indexOf(codeEnum) !== -1) {
      message.password = this.errorCodeToText(res);
    } else {
      message.email = this.errorCodeToText(res);
    }

    return message;
  }

  public gameSignListenerAdd() {
    this.authApiService.addEventListener(Events.onWidgetStateChanged, this.handleGameSignListener);
  }

  public gameSignListenerRemove() {
    this.authApiService.removeEventListener(Events.onWidgetStateChanged, this.handleGameSignListener);
  }

  public authStateChangedListenerAdd() {
    this.authApiService.addEventListener(Events.onAuthStateChanged, this.handleAuthStateChanged);
  }

  public authStateChangedListenerRemove() {
    this.authApiService.removeEventListener(Events.onAuthStateChanged, this.handleAuthStateChanged);
  }

  public generateUrl() {
    return `${window.location.origin + window.location.pathname}`;
  }

  public generateHostUrl() {
    return `${window.location.origin}`;
  }

  public getSessionStorage() {
    return this.authApiService.getSessionStorage();
  }

  public isUserSubscriber() {
    const { activeUserSubscriptions } = store.getState();

    if (activeUserSubscriptions?.length === 1 && activeUserSubscriptions[0].platform.id !== PlatformType.INTERNAL) {
      return true;
    }

    if (activeUserSubscriptions?.length > 1) {
      const sortedSubscription = activeUserSubscriptions.sort(function (a, b) {
        return (new Date(b.endDate) as any) - (new Date(a.endDate) as any);
      });

      if (sortedSubscription[0].platform.id !== PlatformType.INTERNAL) {
        return true;
      }
    }

    return false;
  }

  public getSubscriptionId() {
    const { activeUserSubscriptions } = store.getState();

    if (activeUserSubscriptions?.length === 1 && activeUserSubscriptions[0].platform.id !== PlatformType.INTERNAL) {
      return activeUserSubscriptions[0].subscriptionId;
    }

    if (activeUserSubscriptions?.length > 1) {
      const sortedSubscription = activeUserSubscriptions.sort(function (a, b) {
        return (new Date(b.endDate) as any) - (new Date(a.endDate) as any);
      });

      if (sortedSubscription[0].platform.id !== PlatformType.INTERNAL) {
        return activeUserSubscriptions[0].subscriptionId;
      }
    }

    return '';
  }

  public getCurrentSubscription() {
    const { activeUserSubscriptions } = store.getState();

    if (activeUserSubscriptions?.length) {
      return activeUserSubscriptions[0];
    }

    return null;
  }

  public getSubscriptionType(currentUserSubscription?: any): SUBSCRIPTION_PLAN_NAME {
    const currentSubscription =
      currentUserSubscription !== undefined ? currentUserSubscription : this.getCurrentSubscription();
    const currentPlanId = currentSubscription?.planId;

    return getPlanName(currentPlanId);
  }

  public getSubscriptionStatus(activeUserSubscriptions: any, expiredUserSubscriptions: any): SubscriptionStatus {
    const active = activeUserSubscriptions || [];
    const expired = expiredUserSubscriptions || [];
    let status = SubscriptionStatus.NONE;

    if (active.length > 0) {
      status = SubscriptionStatus.ACTIVE;
    } else if (expired.length > 0) {
      status = SubscriptionStatus.EXPIRED;
    }

    return status;
  }

  public checkSubscriptionAutoRenew(currentUserSubscription: any): SubscriptionAutoRenew {
    const isAutoRenewable = currentUserSubscription?.isAutoRenewable;
    let status: SubscriptionAutoRenew;

    if (!currentUserSubscription) {
      status = SubscriptionAutoRenew.NONE;
    } else if (isAutoRenewable) {
      status = SubscriptionAutoRenew.AUTORENEW;
    } else {
      status = SubscriptionAutoRenew.CANCELLED;
    }

    return status;
  }

  public getSubscriptionLifecycleParam(currentUserSubscription: any): SubscriptionLifeCycle | string {
    let lifecycleParam: string;
    const lastPartOfParam = SubscriptionLifeCycle.RENEW;
    const renewalCount = currentUserSubscription?.renewalCount;

    if (renewalCount === 0) {
      lifecycleParam = SubscriptionLifeCycle.NEW;
    } else {
      const subscription_type_letter = this.getSubscriptionType(currentUserSubscription)?.[0].toLowerCase();

      lifecycleParam = `${renewalCount}_${subscription_type_letter}_${lastPartOfParam}`;
    }

    return lifecycleParam;
  }

  public isUserHasBonusOnly() {
    const { activeUserSubscriptions } = store.getState();

    return activeUserSubscriptions?.length === 1 && activeUserSubscriptions[0].platform.id === PlatformType.INTERNAL;
  }

  private handleGameSignListener = (event: any) => {
    if (event?.isOpened) {
      this.openSignInPanel();
    } else {
      this.closePanel();
    }
  };


  private authStateChangedCounter = 0;

  public handleAuthStateChanged = () => {
    this.authStateChangedCounter += 1; // the first time always shows false

    // we need to track the second value
    if (this.authStateChangedCounter === 2) {
      this.handleLoginRedirect().finally(() => {
        store.dispatch(setUserProcessed(true));
        this.authStateChangedListenerRemove(); // and then remove the event listener
      });
    }
  };

  private initAPI() {
    const data: ApiGatewayInitParams = {
      server: new URL(
        environment.EAGLE_API_BASE_URL || 'https://arenacloud.cdn.arkadiumhosted.com/eagle-user-api-dev/'
      ),
      sessionStorageInitParams: {
        broadcastToIframe: true,
        receiveInIframe: false
      }
    };

    this.authApiService = new ApiGateway(data);
    this.authStateChangedListenerAdd();
    this.authApiService.checkAuthorization();
  }

  private setAdfree(user: UserModel) {
    let adFree = false;
    const subscription = CookieService.getArkCookie(LS_COOKIE_CONSTS.SUBSCRIPTION) === 'true';
    const adFreeCookie = CookieService.getArkCookie(LS_COOKIE_CONSTS.AD_FREE_VER) === 'true';

    if (adFreeCookie || subscription) {
      adFree = true;
    } else if (user && user.email === this.adFreeEmail) {
      CookieService.setArkCookie(LS_COOKIE_CONSTS.AD_FREE_VER, adFree);
      store.dispatch(
        setPreLoadData({
          adFree: AdFreeService.adFreeTurnOffFilter()
        })
      );
      adFree = true;
    }

    if (adFree) {
      AdFreeService.vignetteAdsFix();
    }

    if (adFree && UrlService.getPageType() === 'game page') {
      store.dispatch(setGameState(GameState.GAME));
    }
  }

  public extractErrorCode(error: HttpError): number {
    let result = 400;

    if (error.body?.details && error.body?.details[0] && error.body?.details[0]?.ErrorCode) {
      result = error.body?.details[0]?.ErrorCode;
    } else if (error.body?.statusCode) {
      result = error.body?.statusCode;
    }

    return result;
  }

  private extractErrorEmail(error: HttpError): string {
    let result = '';

    if (error.body?.details && error.body?.details[0] && error.body?.details[0]?.Data?.email) {
      result = error.body.details[0].Data.email;
    }

    return result;
  }

  private async syncLocalWithDB() {
    await RecentlyPlayedService.recentlyPlayedSyncLocalWithDB(this.getToken);
    await HighScoreService.scoreSyncLocalWithDB(this.getToken);
  }

  public resetPurchaseFlow() {
    const giftCardStep = store.getState().giftCardStep;

    if (giftCardStep !== 0) {
      store.dispatch(setGiftCardStep(0));
    }
  }
}

export default new UserServiceClass();
