import { AnalyticsEventsPair, makeEventsPair } from '../../services/Analytics/AnalyticsEventBuilder';
import { FavoritesAnalyticsAi, FavoritesAnalyticsCustomDimensions } from './FavoritesAnalyticsAi';

export class FavoritesAnalytics {
    aiAnalytics = new FavoritesAnalyticsAi();

    // adding to / removing game from favorites list

    async addFavoritesGameNav({
        gameFavorited,
        game,
    }: FavoritesAnalyticsCustomDimensions): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            null,
            this.aiAnalytics.addFavoritesGameNav({
                gameFavorited,
                game,
            })
        );
    }

    async addFavoritesGameEnd({
        gameFavorited,
        game,
    }: FavoritesAnalyticsCustomDimensions): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            null,
            this.aiAnalytics.addFavoritesGameEnd({
                gameFavorited,
                game,
            })
        );
    }

    // favorites link clicks

    async clickFavoriteGameTile({ pageType, game }: FavoritesAnalyticsCustomDimensions): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            null,
            this.aiAnalytics.clickFavoriteGameTile({
                pageType,
                game,
            })
        );
    }
}
