import React, { FC, memo } from "react";
import { UrlService } from "../../services/UrlService";
import { IGame } from "../../models/Game/Game";
import { Helmet } from "react-helmet-async";
import IPageMeta from "../../types/pageMeta";

interface IStructuredDataList {
  games: IGame[];
  pageMeta: IPageMeta;
}

export const StructuredDataList: FC<IStructuredDataList> = memo(({ games, pageMeta }) => {
  const itemListElement = games.map((game, i) => {
    return {
      '@type': 'ListItem',
      position: i + 1,
      description: game.metaHTMLDescription,
      image: game.promoTile280x280,
      name: game.name,
      url: UrlService.createURL(UrlService.removeCountryCodeFromUrlBeginning(pageMeta.url), undefined, false, true),
    };
  });

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    numberOfItems: games.length,
    name: pageMeta.name,
    url: UrlService.createURL(UrlService.removeCountryCodeFromUrlBeginning(pageMeta.url), undefined, false, true),
    itemListElement,
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
    </Helmet>
  );
});
