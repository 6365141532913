import React from 'react';

import classNames from 'classnames';
import { batch } from 'react-redux';
import { Action, Dispatch } from 'redux';

import styles from './GamesSearchTab.css';
import { DeviceUtils } from '../../../../utils/DeviceUtils';
import { associativeSearchResults } from '../../../constants/AssociativeSearchResults';
import { IGame } from '../../../models/Game/Game';
import { GameCard, GameCardTypes } from '../../../molecules/GameCard/GameCard';
import { Analytics } from '../../../services/Analytics/Analytics';
import { GameService } from '../../../services/GameService';
import { SearchService } from '../../../services/Search';
import { setSideMenuActivePage, setSideMenuOpened, setSideMenuRequest } from '../../../store/ducks/layout';
import { SearchResultList } from '../SearchResultList/SearchResultList';
import { SuggestionsSection } from '../SuggetstionsSection/SuggestionsSection';

type GamesSearchTabProps = {
  games: IGame[];
  currentRequest: string;
  extendMediaQuery: boolean;
  gamesSearchTab: Array<string>;
  searchValue: string;
  dispatch?: Dispatch<Action>;
};

export const GamesSearchTab = React.memo((props: GamesSearchTabProps) => {
  const {
    searchValue = '',
    games,
    currentRequest,
    gamesSearchTab,
    dispatch,
    extendMediaQuery
  } = props;
  const searchValueRef = DeviceUtils.isStyleguideMobileMode() ? currentRequest : searchValue;
  const cats = filterGames(games, searchValueRef, associativeSearchResults);
  const isEmptySearchText = searchValueRef.length === 0;
  const isEmptySearchResult = searchValueRef.length > 0 && cats.length === 0;
  const hasSearchResult = searchValueRef.length > 0 && cats.length > 0;
  const getPopularGames = (games: IGame[]): IGame[] => {
    return gamesSearchTab
      .map((slug) => {
        return GameService.findGameByArena5Slug(games, slug);
      })
      .filter((item) => !!item);
  };

  function renderItem(games: IGame[], data: any, index: number) {
    if (data instanceof Object) {
      return (
        <div className={classNames({ [styles.groupedByTwoResult]: games.length > 1 })}>
          <GameCard
            key={index}
            gameCardType={GameCardTypes.DETAILED}
            game={GameService.findGameByArena5Slug(games, data.gameData.slug)}
            onClick={() => {
              // TODO: HOMEPAGE pass real data
              void Analytics.trackEvent(Analytics.general.searchBoxClick('search', data.gameData.slug));
              batch(() => {
                dispatch(setSideMenuOpened(false));
                dispatch(setSideMenuRequest(''));
                dispatch(setSideMenuActivePage(null));
              });
            }}
            dataElementDescription="search-result-game"
          />
        </div>
      );
    }

    if (data) {
      return (
        <GameCard
          key={index}
          game={GameService.findGameByArena5Slug(games, data.gameData.slug)}
          gameCardType={GameCardTypes.DETAILED}
          onClick={() => {
            // TODO: HOMEPAGE pass real data
            void Analytics.trackEvent(Analytics.general.searchBoxClick('search', data.gameData.slug));
          }}
          dataElementDescription="search-result-game"
        />
      );
    }
  }

  return (
    <>
      {isEmptySearchText && (
        <SearchResultList
          groupByTwoForSlider
          heading="GAMES_TAB_TRY_THESE"
          items={getPopularGames(games)}
          renderListItem={renderItem.bind(null, games)}
          twoColumnsList
          className={styles.defaultHeading}
          extendMediaQuery={extendMediaQuery}
        />
      )}
      {isEmptySearchResult && (
        <>
          <div className={styles.notFoundWrapper}>
            <img alt="not-found" src="/icons/notfound.svg" />
            <SearchResultList
              groupByTwoForSlider
              heading="SEARCH_TAB_DIDNT_FIND"
              items={cats}
              renderListItem={renderItem.bind(null, games)}
              twoColumnsList
              className={styles.emptySearchHeading}
              extendMediaQuery={extendMediaQuery}
            />
          </div>

          <SearchResultList
            groupByTwoForSlider
            heading="SEARCH_TAB_SUGGEST"
            items={getPopularGames(games)}
            renderListItem={renderItem.bind(null, games)}
            twoColumnsList
            className={styles.defaultHeading}
            extendMediaQuery={extendMediaQuery}
          />
        </>
      )}

      {hasSearchResult && (
        <>
          <SearchResultList
            groupByTwoForSlider
            heading="GAMES_TAB_DID_FIND"
            items={cats}
            renderListItem={renderItem.bind(null, games)}
            twoColumnsList
            className={styles.hasSearchResultHeading}
            extendMediaQuery={extendMediaQuery}
          />
        </>
      )}

      <SuggestionsSection games={games} extendMediaQuery={props.extendMediaQuery} />
    </>
  );
});

function filterGames(
  games: readonly IGame[],
  text: string,
  surfaceResults: Readonly<Record<string, string[]>>
): IGame[] {
  const searchTitle = text.toLowerCase();
  const setOfAssociations = new Set<string>();

  Object.keys(surfaceResults).forEach((i) => {
    if (searchTitle.length && i.includes(searchTitle)) {
      surfaceResults[i].forEach((i) => {
        setOfAssociations.add(i);
      });
    }
  });

  const arrayOfAssociations = Array.from(setOfAssociations);
  const associatedGames = games.filter((game) =>
    arrayOfAssociations.some((el) => game.name.toLowerCase().includes(el.toLowerCase()))
  );
  const gamesFoundByName = SearchService.searchGames(searchTitle);
  const unsortedResults = [...gamesFoundByName];

  associatedGames.forEach((associatedGame) => {
    // check is associated game already in games found by name, add to the results if it isn't
    if (!gamesFoundByName.find((gameFoundByName) => associatedGame.alias === gameFoundByName.alias)) {
      unsortedResults.push(associatedGame);
    }
  });

  return unsortedResults.sort((a, b) => a.orderByGameStarts - b.orderByGameStarts);
}
