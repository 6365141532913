import React from 'react';

import { iconProps } from '../../../models/Icon/IconPropsModel';

export const MinusIcon = React.memo((props: iconProps) => {
    return (
        <svg
            role="img"
            aria-label="minus-icon"
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 34 34"
        >
            <g fill="none" fillRule="evenodd">
                <path d="M0 0H34V34H0z" />
                <rect width="3" height="20" x="15.5" y="7" fill="currentColor" rx="1.5" />
            </g>
        </svg>
    );
});
