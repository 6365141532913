import React from 'react';

import { SubscriptionStarIcon } from '../Icons/SubscriptionStarIcon';
import styles from './SubscriptionStar.css';

export const SubscriptionStar = ({ percentsSaved }) => {
    const SAVE_VALUE_PERCENT = '37%';
    return (
        <div className={styles.container}>
            <SubscriptionStarIcon />
            <div className={styles.content}>
                Save
                <br />
                {percentsSaved ? `${percentsSaved}%` : SAVE_VALUE_PERCENT}
            </div>
        </div>
    );
};
