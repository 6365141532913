import { createAction, createReducer } from 'redux-act';

import { generateCurrentPackName, RecurlyGemsItemNames } from '../../../constants/RecurlyGemPackItems';

export const setChosenGemsPack = createAction<string>('set active purchasable gems pack');
export const chosenGemsPackItemNameReducer = createReducer<string>(
    {},
    generateCurrentPackName(RecurlyGemsItemNames[0])
);
chosenGemsPackItemNameReducer.on(setChosenGemsPack, (_state, payload: string) => payload);

export const setIsGemsAmountLoading = createAction<boolean>('gems amount is loading');
export const gemsAmountLoadingReducer = createReducer<boolean>({}, false);
gemsAmountLoadingReducer.on(setIsGemsAmountLoading, (_state, payload) => payload);

export const setGemsAmount = createAction<number>('set amount of gems');
export const gemsAmountReducer = createReducer<number>({}, 0);
gemsAmountReducer.on(setGemsAmount, (_state, payload) => payload);

export const setTrySpendGems = createAction<string>('user click on smth and try to spend a gems');
export const trySpendGemsReducer = createReducer<string | null>({}, null);
trySpendGemsReducer.on(setTrySpendGems, (_state, payload) => payload);

export const setPrerollSkipPrice = createAction<number | null>('set prices for skip preroll');
export const prerollSkipPriceReducer = createReducer<number | null>({}, null);
prerollSkipPriceReducer.on(setPrerollSkipPrice, (_state, payload) => payload);

export const setIsPrerollSkipPriceLoading = createAction<boolean>('preroll price is loading');
export const prerollSkipPriceLoadingReducer = createReducer<boolean>({}, false);
prerollSkipPriceLoadingReducer.on(setIsPrerollSkipPriceLoading, (_state, payload) => payload);

export type BillingDataForTax = null | {
    first_name: string;
    last_name: string;
    postal_code: string;
    country: string;
};
export const setBillingDataForTax = createAction<BillingDataForTax>('set billing data for tax');
export const billingDataForTaxReducer = createReducer<BillingDataForTax>({}, null);
billingDataForTaxReducer.on(setBillingDataForTax, (_state, payload) => payload);
