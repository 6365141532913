import React from 'react';

import { AuthType } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/resend-confirmation.dto';

export type AuthData = {
  email: string;
  password?: string;
  checkBox?: boolean;
  user_id?: number;
  name?: string;
  avatar?: string;
  avatarBackground?: string;
  auth_provider_id?: number[];
  frame?: string;
  authProvider?: AuthType; // EmailPassword || Facebook || Google
  authProviderToken?: string; // token from google/fb/apple* sdk
};

type AuthDataProviderProps = {
  value: AuthData;
  setAuthData: (authData: AuthData) => void;
};

export const AuthDataContext = React.createContext({
  value: { email: '' } as AuthData,
  setAuthData: (_: AuthData) => {
  }
});

export function AuthDataProvider({ children, value, setAuthData }: React.PropsWithChildren<AuthDataProviderProps>) {
  return <AuthDataContext.Provider value={{ value, setAuthData }}>{children}</AuthDataContext.Provider>;
}
