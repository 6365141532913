import React from 'react';

import styles from './SrOnly.css';

type SrOnlyProps = {
    label?: string;
};

export const SrOnly = React.memo(({ label }: SrOnlyProps) => {
    SrOnly.displayName = 'SrOnly';

    const BASE_LABEL = 'Activating this element will cause content on the page to be updated';

    return <div className={styles.screenReaderOnly}>{label ?? BASE_LABEL}</div>;
});
