import React from 'react';

import { iconProps } from '../../../models/Icon/IconPropsModel';

export const LinkedInIcon = React.memo((props: iconProps) => {
    return (
        <svg
            className={props.className}
            width="19"
            height="21"
            viewBox="0 1 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 2.28158C0 1.02204 1.02204 0 2.28158 0C3.54033 0 4.56238 1.02204 4.56317 2.28158C4.56317 3.54112 3.54113 4.58454 2.28158 4.58454C1.02204 4.58454 0 3.54112 0 2.28158ZM18.9953 19V18.9992H19V12.031C19 8.62204 18.2661 5.99608 14.2809 5.99608C12.365 5.99608 11.0794 7.04742 10.5545 8.04412H10.4991V6.31433H6.72046V18.9992H10.655V12.7181C10.655 11.0643 10.9685 9.46517 13.0166 9.46517C15.0345 9.46517 15.0646 11.3525 15.0646 12.8242V19H18.9953ZM0.3135 6.31512H4.25283V19H0.3135V6.31512Z"
                fill="#231F20"
            />
        </svg>
    );
});
