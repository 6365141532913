import React from 'react';

import { iconProps } from '../../models/Icon/IconPropsModel';

export const RepeatIcon = React.memo((props: iconProps) => {
    return (
        <svg
            className={props.className}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.375 0.25C0.15625 0.25 0 0.4375 0 0.625V6.625C0 6.84375 0.15625 7 0.375 7H6.375C6.5625 7 6.75 6.84375 6.75 6.625V5.78125C6.75 5.5625 6.5625 5.40625 6.34375 5.40625L2.40625 5.5C3.375 3.375 5.5 1.875 8 1.875C11.375 1.875 14.125 4.625 14.125 8C14.125 11.4062 11.375 14.125 8 14.125C6.40625 14.125 5 13.5625 3.90625 12.5938C3.75 12.4688 3.53125 12.4688 3.40625 12.5938L2.78125 13.2188C2.625 13.375 2.625 13.625 2.78125 13.7812C4.15625 15 6 15.75 8 15.75C12.25 15.75 15.7188 12.3125 15.75 8.03125C15.75 3.78125 12.2812 0.28125 8 0.28125C5.3125 0.25 2.90625 1.625 1.53125 3.71875L1.59375 0.65625C1.59375 0.4375 1.4375 0.25 1.21875 0.25H0.375Z"
                fill="#0042BB"
            />
        </svg>
    );
});
