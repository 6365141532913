import React from 'react';

type iconProps = {
    className?: string;
    onClick?: () => void;
    onMouseDown?: () => void;
};

export const CheckmarkIcon = ({ className, onClick, onMouseDown }: iconProps) => {
    return (
        <svg
            width="14"
            height="10"
            viewBox="0 0 14 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            onClick={onClick}
            onMouseDown={onMouseDown}
            aria-label="Checkmark icon"
        >
            <path
                d="M1 3.87277L6.32498 9L13 1"
                stroke="#017C55"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
