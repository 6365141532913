export enum HelpTabSupportFormTypes {
    QUESTIONS_LIST = 'QUESTIONS_LIST',
    SUPPORT_FORM = 'SUPPORT_FORM',
    SUCCESS_FORM = 'SUCCESS_FORM',
}

export class HelpFormStatus {
    supportFormType: HelpTabSupportFormTypes;

    active: boolean;

    constructor(active: boolean, supportFormType: HelpTabSupportFormTypes = null) {
        this.active = active;
        this.supportFormType =
            supportFormType || active ? HelpTabSupportFormTypes.SUPPORT_FORM : HelpTabSupportFormTypes.QUESTIONS_LIST;
    }
}
