import React, { useState } from 'react';

import classNames from 'classnames';

import styles from './Textarea.css';

type TextareaProps = {
    value: string;
    label?: string;
    containerClassName?: string;
    className?: string;
    placeholder?: string;
    onChange: (value: string) => void;
    dataElementDescription?: string;
    maxLength?: number;
};

export const Textarea = React.memo(
    ({
        className,
        value,
        label,
        containerClassName,
        placeholder,
        onChange,
        dataElementDescription,
        maxLength,
    }: TextareaProps) => {
        const [inFocus, setInFocus] = useState(false);

        return (
            <div
                className={classNames(styles.container, containerClassName, {
                    [styles.inFocus]: inFocus,
                })}
            >
                <textarea
                    className={className}
                    value={value}
                    placeholder={inFocus ? '' : placeholder}
                    onFocus={() => setInFocus(true)}
                    onBlur={() => setInFocus(false)}
                    onChange={(e) => {
                        if (onChange) {
                            onChange(e.target.value);
                        }
                    }}
                    data-element-description={dataElementDescription}
                    style={{ resize: 'none' }}
                    maxLength={maxLength}
                    aria-describedby="textarea-description"
                />
                <span
                    id="textarea-description"
                    className={classNames(styles.inputLabel, {
                        [styles.visibleLabel]: inFocus || Boolean(value),
                    })}
                >
                    {label || placeholder}
                </span>
            </div>
        );
    }
);
