import { useSelector } from "react-redux";

import { GameCardSizeMap, GameCardTypes } from "./GameCard";
import { PageTypes } from "../../constants/Pages";
import { IGame } from "../../models/Game/Game";
import { UrlService } from "../../services/UrlService";
import { GameCardCobrandedTypes } from "../GameCardCobranded/GameCardCobranded";

export const getCardImageInfo = (game: IGame, gameCardType: GameCardTypes|GameCardCobrandedTypes, allGamesTile: boolean, doubleSize: boolean) => {
  const cardImageSrc = game?.promoTile(GameCardSizeMap[gameCardType], false, doubleSize);
  const cardImageSrcWebP = game?.promoTile(GameCardSizeMap[gameCardType], true, doubleSize);
  const cardImageAlt = game?.name;


  return { cardImageSrc, cardImageSrcWebP, cardImageAlt };
};

export const getCardHref = (game: IGame, previousGame: string, allGamesTile: boolean, pseudoTile: boolean, headerLink: string) => {
  const prevGame = previousGame ? `/?recPreviousGame=${previousGame}` : '';
  const currentLang = useSelector(({ currentLang }) => currentLang);

  return () => {
    if (allGamesTile)
      return UrlService.createURL(
        `/${UrlService.getPageLocalizedRoute(currentLang, PageTypes.Category)}/`
      );
    if (pseudoTile) return UrlService.createURL(`/${headerLink}`);
    return UrlService.createGameURL(game?.alias, prevGame);
  };
};
