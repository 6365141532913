import React from 'react';

import classNames from 'classnames';

import styles from './ErrorMessage.css';
import { WarningIcon } from '../Icons/WarningIcon';

type ErrorMessageProps = {
  message: string;
  id: string;
  className?: string;
};

export const ErrorMessage = ({ message, id, className }: ErrorMessageProps) => {
  return (
    <div className={classNames(styles.validationBlock, className)}>
      <WarningIcon />
      <span id={id} role="alert">
            {message}
        </span>
    </div>
  );
};
