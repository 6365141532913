import {ABTests, ABTestsSettingsConfig, TYPES} from '../../models/ABTests';
import AbTestService from '../AbTestService';
import {AppInsightService} from '../AppInsight';
export const ABTEST_COOKIE = 'arkabtests';

const VER_DEFAULT = 'no';
const PAIRS_DELIM = ',';
const VALUE_DELIM = ':';

type ABTestsVariations = { ver?: string } & {
    [key in keyof ABTests]: ABTests[key];
};

/**
 * Arbitrary AB Test Manager
 *
 * @export
 * @class ABTestManager
 */
export class ABTestManager {
    public isInitialized = false;

    protected config: ABTestsSettingsConfig;

    protected variations: ABTestsVariations;

    /**
     * Get value for given AB Test name.
     * Returns a value parsed from cookie or calculates a new value
     * Use .getCookie() to store calculated values
     *
     * @template K
     * @param {K} testName
     * @param {ABTests[K]} defaultValue
     * @returns {ABTests[K]}
     * @memberof ABTestManager
     */
    public getVariation<K extends keyof ABTests>(testName: K, defaultValue: ABTests[K]): ABTests[K] {
        if (!this.isInitialized) {
            AppInsightService.trackAppError(
                `[ABTestManager] Tried to get variation before initialization: ${testName}`
            );
            return defaultValue;
        }

        // #111063 Fixing the case of admin has ABtest, but no variants are set
        const isSchemaLegal = (paramsArr): boolean =>
            paramsArr &&
            Array.isArray(paramsArr) &&
            Boolean(paramsArr.filter((param) => param && param !== 'undefined' && param !== 'null').length);

        if (!isSchemaLegal(this?.config?.[testName])) {
            // AppInsightsAnalytics.trackAppError(`[ABTestManager] Tried to get undefined variation: ${testName}`);
            return defaultValue;
        }

        if (!this.variations[testName]) {
            this.variations[testName] = this.runABTest(testName);
        }

        AbTestService.setVariationToStore(this.getCookie().value);

        AppInsightService.setCustomDimensions({
            abvariations: this.getCookie().value,
        });

        return this.variations[testName];
    }

    /**
     * Initialize ABTestManager with configured AB tests a cookie containing
     * previously set AB Test variations
     *
     * TODO: Move this method's functionality to constructor
     *
     * @param {ABTestsSettingsConfig} [config={ ver: 'no' }]
     * @param {string} [cookie='']
     * @memberof ABTestManager
     */
    public init(config: ABTestsSettingsConfig = { ver: VER_DEFAULT }, cookie = '') {
        this.config = config;
        this.variations = this.parseCookie(cookie);

        if (!this.variations) {
            this.variations = { ver: VER_DEFAULT };
        }

        // Remove variations from cookie which is not exist in config if ABTestSettingsConfig has different version
        if (this.variations.ver !== this?.config?.ver) {
            this.variations = Object.keys(this.config)
                .filter((key) => Object.keys(this.variations).includes(key))
                .reduce((obj, key) => {
                     this.variations[key] = obj[key];
                    return obj;
                }, {});
        }

        this.variations.ver = this.config.ver;
        this.isInitialized = true;
    }

    /**
     * Get a cookie to store all the AB Tests variations
     *
     * @returns {{ name: string, value: string, options: any }}
     * @memberof ABTestManager
     */
    public getCookie(): { name: string; value: string; options: any } {
        const expires = new Date(Date.now() + 2529e6); /* 30 days */
        const options = { path: '/', expires };
        const value = Object.keys(this.variations)
            .map((k) => [k, this.encodeValue(this.variations[k])].join(VALUE_DELIM))
            .join(PAIRS_DELIM);

        return { name: ABTEST_COOKIE, value, options };
    }

    /**
     * Get KeyValues with resolved AB Tests variations for Google Publisher Tag
     *
     * @returns {[string, string][]}
     * @memberof ABTestManager
     */
    public getKeyValues(): Array<[string, string]> {
        const values = Object.keys(this.variations)
            .map((k) => [k, this.variations[k]].join('-'))
            .join(' ');

        return [['ab', values]];
    }

    /**
     * Returns 'true' if there are any tests configured
     *
     * @returns {boolean}
     * @memberof ABTestManager
     */
    public hasTests(): boolean {
        return this.isInitialized && this?.config?.ver && this.config.ver !== VER_DEFAULT;
    }

    /**
     * Parse a cookie and populate AB Tests variations from it
     *
     * @protected
     * @param {string} cookie
     * @returns {Partial<ABTests>}
     * @memberof ABTestManager
     */
    protected parseCookie(cookie: string): Partial<ABTests> {
        return cookie
            .split(PAIRS_DELIM)
            .map((pair) => pair.split(VALUE_DELIM))
            .reduce((acc, [key, value]) => {
                if (key) {
                    acc[key] = this.decodeValue(value || 'null');
                }

                return acc;
            }, {});
    }

    /**
     * Arbitrary value encoding escaping any non-compatible chars
     *
     * @protected
     * @param {*} v
     * @returns
     * @memberof ABTestManager
     */
    protected encodeValue(v: any) {
        return v;
    }

    /**
     * Arbitrary value decoding mirroring .encodeValue() function
     *
     * @protected
     * @param {string} v
     * @returns {*}
     * @memberof ABTestManager
     */
    protected decodeValue(v: string): any {
        return v;
    }

    /**
     * Perform a test calculating weighted random across set of variations.
     * https://en.wikipedia.org/wiki/Cumulative_distribution_function
     *
     * @protected
     * @param {keyof ABTests} testName
     * @returns
     * @memberof ABTestService
     */
    protected runABTest<T extends keyof ABTests>(testName: T): ABTests[T] {
        const variations = this?.config?.[testName];
        const totalRate = variations!.reduce((acc, curr) => acc + curr.weight, 0);
        const rand0me = Math.random() * totalRate;
        let cumulativeWeight = 0;

        for (const variation of variations) {
            cumulativeWeight += variation.weight;

            if (cumulativeWeight > rand0me) {
                return variation.val;
            }
        }
    }
}
