import { PaymentMethod } from '../../../models/Subscription/PaymentForm';
import { SubscriptionSourceType } from '../../../models/Subscription/SubscriptionData';
import { LocalStorageService } from '../../LocalStorage';
import { AnalyticsEvent, AnalyticsEventAction, AnalyticsEventBuilder } from '../AnalyticsEventBuilder';

export class SubscriptionAnalyticsAi {
    private async newEventBuilder(): Promise<AnalyticsEventBuilder> {
        return await new AnalyticsEventBuilder().baseEvent();
    }

    async entryPointImpression(location: SubscriptionSourceType): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('Subscription');
        event.setEventAction(AnalyticsEventAction.IMPRESSION);
        event.setCustom('subscriptionLocation', location);
        event.setNonInteraction(true);
        applyLastPlayedGame(event);
        return event.build();
    }

    async entryPointClick(location: SubscriptionSourceType, type: string): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('Subscription');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setCustom('subscriptionLocation', location);
        event.setCustom('subscriptionType', type);
        event.setNonInteraction(false);
        applyLastPlayedGame(event);
        return event.build();
    }

    async removeAdsGameScreen(): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('removeAdsGameScreen');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        return event.build();
    }

    async removeAdsAdBlockScreen(): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('removeAdsAdBlockScreen');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        return event.build();
    }

    async subscriptionNextStep(
        location: SubscriptionSourceType,
        type: string,
        payment: PaymentMethod
    ): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('subscriptionNextStep');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setCustom('subscriptionLocation', location);
        event.setCustom('subscriptionType', type);
        event.setCustom('subscriptionPayment', payment);
        event.setNonInteraction(false);
        applyLastPlayedGame(event);
        return event.build();
    }

    async subscriptionPurchaseButton(
        location: SubscriptionSourceType,
        type: string,
        payment: PaymentMethod
    ): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('subscriptionPurchaseButton');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setCustom('subscriptionLocation', location);
        event.setCustom('subscriptionType', type);
        event.setCustom('subscriptionPayment', payment);
        event.setNonInteraction(false);
        applyLastPlayedGame(event);
        return event.build();
    }

    async subscriptionPurchase(
        location: SubscriptionSourceType,
        type: string,
        payment: PaymentMethod,
        subscriptionId: string,
        gameSlug?: string | boolean
    ): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('subscriptionPurchase');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setCustom('subscriptionLocation', location);
        event.setCustom('subscriptionType', type);
        event.setCustom('subscriptionPayment', payment);
        event.setCustom('subscriptionId', subscriptionId);
        gameSlug && event.setCustom('gameSlug', gameSlug);
        event.setNonInteraction(false);
        applyLastPlayedGame(event);
        return event.build();
    }

    async manageSubscription(type: string): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('manageSubscription');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setCustom('subscriptionType', type);
        event.setNonInteraction(false);
        return event.build();
    }

    async renewSubscription(type: string): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('renewSubscription');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setCustom('subscriptionType', type);
        event.setNonInteraction(false);
        return event.build();
    }

    async cancelAutorenewal(type: string): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('cancelAutorenewal');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setCustom('subscriptionType', type);
        event.setNonInteraction(false);
        return event.build();
    }

    async subscriptionUpgrade(type: string): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('subscriptionUpgrade');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setCustom('subscriptionType', type);
        event.setNonInteraction(false);
        return event.build();
    }

    async subscriptionDowngrade(type: string): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('subscriptionDowngrade');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setCustom('subscriptionType', type);
        event.setNonInteraction(false);
        return event.build();
    }

    // gift redeem
    async giftStart(location: SubscriptionSourceType, type: string, payment: PaymentMethod): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('giftStart');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setCustom('subscriptionLocation', location);
        event.setCustom('subscriptionType', type);
        event.setCustom('subscriptionPayment', payment);
        event.setNonInteraction(false);
        return event.build();
    }

    async giftNextStep(
        location: SubscriptionSourceType,
        type: string,
        payment: PaymentMethod
    ): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('giftNextStep');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setCustom('subscriptionLocation', location);
        event.setCustom('subscriptionType', type);
        event.setCustom('subscriptionPayment', payment);
        event.setNonInteraction(false);
        return event.build();
    }

    async giftPurchaseButton(
        location: SubscriptionSourceType,
        type: string,
        payment: PaymentMethod
    ): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('giftPurchaseButton');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setCustom('subscriptionLocation', location);
        event.setCustom('subscriptionType', type);
        event.setCustom('subscriptionPayment', payment);
        event.setNonInteraction(false);
        return event.build();
    }

    async giftEmailCheck(
        location: SubscriptionSourceType,
        type: string,
        payment: PaymentMethod
    ): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('giftEmailCheck');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setCustom('subscriptionLocation', location);
        event.setCustom('subscriptionType', type);
        event.setCustom('subscriptionPayment', payment);
        event.setNonInteraction(false);
        return event.build();
    }

    async giftRedeemed(
        location: SubscriptionSourceType,
        type: string,
        payment: PaymentMethod,
        subscriptionId: string,
        newRegister: boolean
    ): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('giftRedeemed');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setCustom('subscriptionLocation', location);
        event.setCustom('subscriptionType', type);
        event.setCustom('subscriptionPayment', payment);
        event.setCustom('subscriptionId', subscriptionId);
        event.setCustom('newRegister', newRegister ? 'TRUE' : 'FALSE');
        event.setNonInteraction(false);
        return event.build();
    }

    // gift payment
    async giftPaymentFieldOnBlur(
        name: string,
        location: SubscriptionSourceType,
        type: string
    ): Promise<AnalyticsEvent> | null {
        const convertToEventName = {
            first_name: 'giftPaymentFirstName',
            last_name: 'giftPaymentLastName',
            address1: 'giftPaymentAddress',
            city: 'giftPaymentCity',
            country: 'giftPaymentCountry',
            postal_code: 'giftPaymentZip',
            state: 'giftPaymentState',
            number: 'giftPaymentCardNumber',
            year: 'giftPaymentExpDate',
            month: 'giftPaymentExpDate',
            cvv: 'giftPaymentSecCode',
        };
        if (!convertToEventName[name]) return null;
        const event = await this.newEventBuilder();
        event.setName(convertToEventName[name]);
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setCustom('subscriptionLocation', location);
        event.setCustom('subscriptionType', type);
        event.setCustom('subscriptionPayment', PaymentMethod.CARD);
        event.setNonInteraction(false);
        return event.build();
    }

    async giftPayPal(location: SubscriptionSourceType, type: string): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('giftPayPal');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setCustom('subscriptionLocation', location);
        event.setCustom('subscriptionType', type);
        event.setCustom('subscriptionPayment', PaymentMethod.PAYPAL);
        event.setNonInteraction(false);
        return event.build();
    }

    async gifterPayPalConfirm(): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('gifterPayPalConfirm');
        event.setEventAction(AnalyticsEventAction.CLICK);
        return event.build();
    }

    async gifterPurchaseButton(
        location: SubscriptionSourceType,
        type: string,
        paymentMethod: string
    ): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('gifterPurchaseButton');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setCustom('subscriptionLocation', location);
        event.setCustom('subscriptionType', type);
        event.setCustom('subscriptionPayment', paymentMethod);
        event.setNonInteraction(false);
        return event.build();
    }

    async gifterPrePurchaseButton(
        location: SubscriptionSourceType,
        type: string,
        paymentMethod: string
    ): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('gifterPrePurchaseButton');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setCustom('subscriptionLocation', location);
        event.setCustom('subscriptionType', type);
        event.setCustom('subscriptionPayment', paymentMethod);
        event.setNonInteraction(false);
        return event.build();
    }
}

export const LS_LAST_PLAYED_GAME_PROP = 'LS_LAST_PLAYED_GAME_PROP';

function applyLastPlayedGame(eventLink): void {
    eventLink.setCustom('game', LocalStorageService.getItem(LS_LAST_PLAYED_GAME_PROP) || null);
}

export function setLastPlayedGame(arenaGame5Slug: string): void {
    LocalStorageService.setItem(LS_LAST_PLAYED_GAME_PROP, arenaGame5Slug);
}
