import React from 'react';

import classNames from 'classnames';

import styles from './SearchResultList.css';
import { I18nText } from '../../../atoms/i18nText/i18nText';

type SearchResultListProps = {
    heading: string;
    className: string;
    twoColumnsList?: boolean;
    groupByTwoForSlider?: boolean;
    renderListItem: (data: any, index: number) => any;
    extendMediaQuery: boolean;
    items: any[];
};

export const SearchResultList = (props: SearchResultListProps) => {
    const { heading, className, renderListItem, items } = props;

    return (
        <>
            <I18nText as="h2" keyName={heading} className={classNames(styles.heading, className)} />
            <div className={styles.list}>
                {items.map((item, index) => (
                    <div key={typeof item === 'string' ? (item as any).toString() : (item as any).gameData.id}>
                        {renderListItem(item, index)}
                    </div>
                ))}
            </div>
        </>
    );
};
