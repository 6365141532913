import React from 'react';

import { iconProps } from '../../models/Icon/IconPropsModel';

export const SubscriptionStarIcon = React.memo((props: iconProps) => {
    return (
        <svg
            className={props.className}
            width="112"
            height="112"
            viewBox="0 0 112 112"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d)">
                <path
                    d="M96.1988 53.9137C96.1988 56.4153 91.4442 58.4856 90.9255 60.901C90.4068 63.4026 94.0376 67.0256 93.0867 69.3547C92.1358 71.6838 86.9489 71.6838 85.5657 73.7542C84.1825 75.8245 86.0844 80.5689 84.3554 82.3804C82.6264 84.1057 77.7853 82.2079 75.7106 83.5881C73.6358 84.9683 73.6358 90.1441 71.3017 91.093C69.054 92.0419 65.3367 88.4189 62.8297 88.9364C60.4092 89.3677 58.4209 94.1985 55.8274 94.1985C53.3204 94.1985 51.2456 89.454 48.8251 88.9364C46.3181 88.4189 42.6872 92.0419 40.3531 91.093C38.019 90.1441 38.019 84.9683 35.9443 83.5881C33.8695 82.2079 29.1148 84.1057 27.2994 82.3804C25.5704 80.6552 27.4723 75.8245 26.0891 73.7542C24.706 71.6838 19.519 71.6838 18.5681 69.3547C17.6172 67.1119 21.248 63.4026 20.7293 60.901C20.5564 58.4856 15.8018 56.4153 15.8018 53.9137C15.8018 51.412 20.5564 49.3417 21.0751 46.9264C21.5938 44.4247 17.963 40.8017 18.9139 38.4726C19.8648 36.1435 25.0517 36.1435 26.4349 34.0732C27.8181 32.0029 25.9162 27.2584 27.6452 25.4469C29.3742 23.7216 34.2153 25.6194 36.29 24.2392C38.3648 22.859 38.3648 17.6832 40.6989 16.7343C42.9466 15.7854 46.6639 19.4085 49.1709 18.8909C51.4185 18.5459 53.4933 13.8014 56.0003 13.8014C58.5073 13.8014 60.5821 18.5459 63.0026 19.0634C65.5096 19.581 69.1405 15.958 71.4746 16.9069C73.8087 17.8558 73.8087 23.0315 75.8834 24.4117C77.9582 25.7919 82.7129 23.8942 84.5283 25.6194C86.2573 27.3447 84.3554 32.1754 85.7386 34.2457C87.1217 36.316 92.3087 36.316 93.2596 38.6451C94.2105 40.888 90.5797 44.5973 91.0984 47.0989C91.4442 49.3417 96.1988 51.412 96.1988 53.9137Z"
                    fill="url(#paint0_linear)"
                    stroke="#F9F9F9"
                    strokeWidth="2"
                />
                <path
                    d="M31.0536 79.0536C29.4979 77.4979 31.1673 73.2533 29.9877 71.4286C28.7545 69.5502 24.2432 69.5551 23.3861 67.5152C22.529 65.4753 25.7548 62.2495 25.3275 60.1017C24.9002 57.9539 20.7667 56.1861 20.7154 53.9842C20.7177 51.836 24.9087 50.0055 25.3406 47.8568C25.7726 45.7081 22.5537 42.4892 23.4152 40.4475C24.2229 38.4595 28.788 38.4009 30.0252 36.5198C31.2623 34.7462 29.4946 30.5054 31.1075 28.8925C32.6667 27.3333 36.9076 28.9936 38.7349 27.8102C40.6159 26.5729 40.6208 22.0616 42.6625 21.2001C44.7043 20.3387 47.9231 23.5575 50.0718 23.1256C52.2205 22.6936 53.9972 18.5564 56.1992 18.5003C58.3475 18.498 60.169 22.6851 62.3167 23.1124C64.4645 23.5398 67.6903 20.314 69.7302 21.1711C71.7165 21.9745 71.7653 26.5395 73.6436 27.7727C75.2533 29.1673 79.4979 27.4979 81.0536 29.0536C82.6094 30.6094 80.94 34.854 82.1196 36.6787C83.3528 38.5571 87.8641 38.5522 88.7212 40.5921C89.5783 42.632 86.3525 45.8578 86.7798 48.0056C87.2071 50.1534 91.3406 51.9212 91.3919 54.1231C91.3896 56.2713 87.1986 58.1018 86.7666 60.2505C86.3347 62.3992 89.5536 65.6181 88.6921 67.6598C87.8844 69.6478 83.3193 69.7064 82.0821 71.5875C80.8988 73.1999 82.5591 77.4409 81 79C79.4409 80.5591 75.1999 78.8988 73.3726 80.0823C71.4916 81.3196 71.4868 85.8308 69.445 86.6923C67.4033 87.5538 64.1844 84.3349 62.0357 84.7669C59.887 85.1988 58.1103 89.3361 55.9083 89.3922C53.76 89.3945 51.9385 85.2074 49.7908 84.78C47.643 84.3527 44.4172 87.5785 42.3773 86.7214C40.3911 85.9179 40.3423 81.353 38.4639 80.1198C36.854 78.94 32.6094 80.6094 31.0536 79.0536Z"
                    fill="url(#paint1_linear)"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d"
                    x="0.801758"
                    y="0.801392"
                    width="110.397"
                    height="110.397"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="7" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
                <linearGradient
                    id="paint0_linear"
                    x1="86.1442"
                    y1="83.6093"
                    x2="27.8737"
                    y2="25.8733"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C28C2B" />
                    <stop offset="0.498397" stopColor="#E6C14E" />
                    <stop offset="1" stopColor="#C28C2B" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="84.0907"
                    y1="79.1846"
                    x2="45.3451"
                    y2="45.7666"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F9F9F9" stopOpacity="0.6" />
                    <stop offset="0.374998" stopColor="#F9F9F9" stopOpacity="0" />
                    <stop offset="0.651043" stopColor="#F9F9F9" stopOpacity="0.2" />
                    <stop offset="1" stopColor="#F9F9F9" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
});
