export interface FileUpload {
    filesize: number;
    filesizeString: string;
    filetypes: any;
    filetypesMime: any;
}

export const FileUploadConstants: FileUpload = {
    filesize: 10 * 1024 * 1024,
    filesizeString: '10mb',
    filetypes: ['.png', '.jpg', '.gif', '.jpeg', '.doc', '.docx', '.pdf'],
    filetypesMime: [
        'image/png',
        'image/jpeg',
        'image/gif',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
    ],
};
