import { CookieService } from './CookieService';
import { DeviceType } from './DeviceDetector';
import { LS_COOKIE_CONSTS } from '../models/Enums';
import { reduxStore } from '../store';
import { MiscUtils } from '../../utils/MiscUtils';

const { store } = reduxStore;

class AdFreeService {
  public showVignette(): boolean {
    const state = store.getState();
    const { adFree, subscription } = state.preLoadData;
    const returningVisitor =
      (CookieService.getArkCookie(LS_COOKIE_CONSTS.ARK_VISITOR_MAIN) as string) === 'returning';
    const isDesktop = state.deviceType === DeviceType.DESKTOP;
    const isAdFree = CookieService.getArkCookie(LS_COOKIE_CONSTS.AD_FREE_VER) === 'true';
    const isSubscriber = CookieService.getArkCookie(LS_COOKIE_CONSTS.SUBSCRIPTION) === 'true';
    const isSpecialPage = MiscUtils.isPagesToRemoveGoogleVignette(window.location.pathname, window.location.origin);

    if (adFree || subscription || isAdFree || isSubscriber || isSpecialPage) {
      return false;
    }

    if (!isDesktop) {
      return true;
    }

    return isDesktop && returningVisitor;
  }

  public vignetteAdsFix() {
    if (document.getElementById('aswift_1')) {
      document.getElementById('aswift_1').parentElement.innerHTML = '';
    }

    if (document.getElementById('aswift_2')) {
      document.getElementById('aswift_2').parentElement.innerHTML = '';
    }
  }

  public adFreeTurnOffFilter() {
    return false;
  }
}

export default new AdFreeService();
