import { IGame } from '../../models/Game/Game';
import { GamesAnalyticsAi } from './AI/GamesAnalyticsAi';
import { AnalyticsEventsPair, makeEventsPair } from './AnalyticsEventBuilder';
import { GamesAnalyticsGa } from './GA/GamesAnalyticsGa';

export class GamesAnalytics {
    gaAnalytics = new GamesAnalyticsGa();

    aiAnalytics = new GamesAnalyticsAi();

    async gameStart(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(this.gaAnalytics.gameStart(game), this.aiAnalytics.gameStart(game));
    }

    async gameEnd(game: IGame, duration: number): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(this.gaAnalytics.funnelEnd(game), this.aiAnalytics.gameEnd(game, duration));
    }

    async numberTimesPlay(game: IGame, times: string): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            null,
            this.aiAnalytics.numberTimesPlay(game, times)
        );
    }

    async numberPlayVisits(game: IGame, visits: string): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            null,
            this.aiAnalytics.numberPlayVisits(game, visits)
        );
    }

    async prerollStart(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(this.gaAnalytics.prerollStart(game), this.aiAnalytics.prerollStart(game));
    }

    async funnelZero(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.funnelZero(game));
    }

    async playNow(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(this.gaAnalytics.playNow(game), null);
    }

    async prerollEnd(game: IGame, duration: number): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.prerollEnd(game, duration));
    }

    async rewardVideoStart(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(this.gaAnalytics.rewardVideoStart(game), this.aiAnalytics.rewardVideoStart(game));
    }

    async rewardVideoEnd(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.rewardVideoEnd(game));
    }

    async interstitialVideoStart(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.interstitialVideoStart(game),
            this.aiAnalytics.interstitialVideoStart(game)
        );
    }

    async interstitialVideoEnd(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.interstitialVideoEnd(game));
    }

    async playAgainImpression(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(this.gaAnalytics.playAgainImpression(game), null);
    }

    async playAgainClick(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(this.gaAnalytics.playAgainClick(game), this.aiAnalytics.playAgainClick(game));
    }

    async saveScoreClick(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.saveScoreClick(game));
    }

    async gameNavigationLeaderboardIconClick(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.gameNavigationLeaderboardIconClick(game));
    }

    async allGamesImpression(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, null);
    }

    async allGamesClick(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.allGamesClick(game));
    }

    async adblockImpression(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.adblockImpression(game));
    }

    async adblockClick(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.adblockClick(game));
    }

    async adblockDisabled(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.adblockDisabled(game));
    }

    async gamePageFeedbackImpression(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.gamePageFeedbackImpression(game));
    }

    async gamePageFeedbackClick(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.gamePageFeedbackClick(game));
    }

    async gamePageAdDividerImpression(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.gamePageAdDividerImpression(game));
    }

    async gamePageAdDividerClick(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.gamePageAdDividerClick(game));
    }

    async recommendedGamesImpressionNew(game: IGame, games: string): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.recommendedGamesImpressionNew(game, games));
    }

    async recommendedGamesClick(game: IGame, clickedOnGame: string): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(this.gaAnalytics.recommendedGamesClick(game), this.aiAnalytics.recommendedGamesClick(game, clickedOnGame));
    }

    async earlyAccessJoinButton(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.earlyAccessJoinButton(game),
            this.aiAnalytics.earlyAccessJoinButton(game)
        );
    }

    async earlyAccessJoinButtonImpression(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.earlyAccessJoinButtonImpression(game),
            this.aiAnalytics.earlyAccessJoinButtonImpression(game)
        );
    }

    async fullScreenButtonClick(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.fullScreenButtonClick(game),
            this.aiAnalytics.fullScreenButtonClick(game)
        );
    }

    async fullScreenExitButtonClick(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            null,
            this.aiAnalytics.fullScreenExitButtonClick(game)
        );
    }

    async fullScreenToolTipImpression(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            null,
            this.aiAnalytics.fullScreenToolTipImpression(game)
        );
    }

    async fullScreenToolTipClick(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            null,
            this.aiAnalytics.fullScreenToolTipClick(game)
        );
    }

    async sendEmote(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.sendEmote(game));
    }

    async returnEmote(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.returnEmote(game));
    }

    async sendEmoteExpire(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.sendEmoteExpire(game));
    }

    async gameNavigationCollectionButton(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.gameNavigationCollectionButton(game));
    }

    async visitCollection(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.visitCollection(game));
    }

    async saveCollectible(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.saveCollectible(game));
    }

    async receivedCollectible(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.receivedCollectible(game));
    }

    async commentsButton(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.commentsButton(game));
    }

    async commentSubmit(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.commentSubmit(game));
    }

    async commentSignInUp(game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.commentSignInUp(game));
    }
}
