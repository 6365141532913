import * as yup from 'yup';

import { TranslationService } from '../../../../../services/TranslationService';

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required(TranslationService.translateIt('REGISTER_AN_EMAIL_ADDRESS_IS_REQUIRED'))
    .email(TranslationService.translateIt('REGISTER_PLEASE_ENTER_A_VALID_EMAIL_ADDRESS')),
  password: yup
    .string()
    .required('Password is required')
    .matches(
      /^[A-Za-z0-9!@#$%^&*()_[\]{},.<>+=-]*$/,
      TranslationService.translateIt('REGISTER_YOUR_PASSWORD_CONTAINS')
    )
    .min(8)
    .matches(/(.*[A-Z].*)/, ' ')
});
export const defaultValues = {
  email: '',
  password: ''
};
