import React from 'react';

import { RouteComponentProps } from 'react-router-dom';

import { HeaderSideMenuTabs } from '../../constants/HeaderSideMenuTabs';
import { GameState } from '../../models/Enums';
import { IGame } from '../../models/Game/Game';
import { CategoryPageSEO, CoBrandedPageSEO, GeneralPageSEO } from '../../models/PagesData';
import { UserModel } from '../../models/User/UserModel';
import { LeaderboardNotificationType } from '../Leaderboard/Leaderboard';

export enum MobileNavButtonsNames {
    GAME = 'Game',
    LEADERBOARD = 'Leaderboard',
    COMMENTS = 'Comments',
    COLLECTIONS = 'Collections',
    HOME = 'Home',
    SEARCH = 'Search',
    SUPPORT = 'Support',
    ADVANTAGE = 'Advantage',
    MENU = 'Menu',
    SHOP = 'Shop',
    ADD_GAME = 'AddGame',
    BLOG = 'Blog',
}

export type MobileNavButtonProps = {
    name?: MobileNavButtonsNames;
    iconFuncComp: () => React.ReactElement;
    textKey: string;
    onClick: (e?) => void;
    usesSideMenu?: boolean;
    isActive?: boolean;
    indexKey?: number;
    activeButtonName?: MobileNavButtonsNames;
    isItGamePage?: boolean;
    isHomePage?: boolean;
    className?: string;
    isMenuButton?: boolean;
};

export type MobileNavButtonsProps = {
    [value in MobileNavButtonsNames]: MobileNavButtonProps;
};

export type MobileNavigationUxRedesignedProps = {
    leaderboardNotificationType?: LeaderboardNotificationType;
};

export interface MobileBottomNavProps extends RouteComponentProps {
    isSideMenuOpened: boolean;
    isHelpFormActive: boolean;
    activePage: HeaderSideMenuTabs;
    user: UserModel;
    isHomePage: boolean;
    isItGamePage: boolean;
    isItProfilePage: boolean;
    isOpenedProfilePanel: boolean;
    activeGameNavigationTab: string;
    gameState: GameState;
    gameArkadiumSlug: string;
    games: IGame[];
    pages: (GeneralPageSEO | CategoryPageSEO | CoBrandedPageSEO)[];
    activeUserSubscriptions: string[];
    activeButtonName: MobileNavButtonsNames;
}
