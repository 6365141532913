import React, { PureComponent } from 'react';

import styles from './ArkadiumLogo.css';

type ArkadiumLogoProps = {
  className?: string;
  arrowInheritColor?: boolean;
  textInheritColor?: boolean;
  arrowHoverColor?: string;
  textHoverColor?: string;
  focusable?: boolean;
  ariaHidden?: boolean;
  role?: string;
};

type ArkadiumLogoState = {
  hover: boolean;
};

export class ArkadiumFullLogo extends PureComponent<ArkadiumLogoProps, ArkadiumLogoState> {
  state: ArkadiumLogoState = { hover: false };

  onMouseOver = () => {
    this.setState({ hover: true });
  };

  onMouseOut = () => {
    this.setState({ hover: false });
  };

  render() {
    const { hover } = this.state;
    const arrowColor = this.props.arrowInheritColor ? 'currentColor' : '#DC1E34';
    const arrowHoverColor = this.props.arrowHoverColor ? this.props.arrowHoverColor : arrowColor;
    const textColor = this.props.textInheritColor ? 'currentColor' : '#000000';
    const textHoverColor = this.props.textHoverColor ? this.props.textHoverColor : textColor;

    return (
      <svg
        className={this.props.className}
        xmlns="http://www.w3.org/2000/svg"
        width="139"
        height="40"
        viewBox="0 0 139 40"
        onMouseOut={this.onMouseOut}
        onMouseOver={this.onMouseOver}
        focusable={this.props.focusable}
        aria-hidden={this.props.ariaHidden}
        role={this.props.role}
      >
        <g>
          <g id="Layer_1">
            <g id="Layer_1-2">
              <g id="Layer_2-2">
                <g id="Layer_1-2-2">
                  <path
                    className={styles.logoPathText}
                    fill={hover ? textHoverColor : textColor}
                    d="M27.6,24.6h-.1c-1.3,0-2.4.4-3.4,1.1v-.9s-3.1,0-3.1,0v15.1c0,0,3.2,0,3.2,0v-7.8c0-2.5,1.4-4.4,3.3-4.4h0c.5,0,1.1.1,1.6.4h0s0,0,0,0h.3c0,.1,1.3.7,1.3.7v-3.4s-.5-.2-.5-.2h-.1c0,0,0,0,0,0h0c-.8-.3-1.6-.5-2.4-.5h0Z" />
                  <path
                    className={styles.logoPathText}
                    fill={hover ? textHoverColor : textColor}
                    d="M54.2,24.5c-2.4,0-4.4.7-5.8,2.1-1.5,1.4-2.2,3.3-2.2,5.6s.8,4.2,2.2,5.6c1.4,1.4,3.5,2.1,5.8,2.1s2.9-.3,4.1-.8v.7s3.1,0,3.1,0v-15.1c0,0-3.2,0-3.2,0v.7c-1.2-.5-2.6-.8-4.1-.8h0ZM54.3,36.9c-3.1,0-4.9-1.7-5-4.7s1.8-4.6,4.9-4.6,3,.4,4,1.3v6.8c-.9.8-2.3,1.2-4,1.2h0Z" />
                  <path
                    className={styles.logoPathText}
                    fill={hover ? textHoverColor : textColor}
                    d="M78.7,17.4h-3.1s0,7.9,0,7.9c-1.2-.5-2.6-.8-4.1-.8-2.4,0-4.4.7-5.8,2.1-1.5,1.4-2.2,3.3-2.2,5.6s.8,4.2,2.2,5.6c1.4,1.4,3.5,2.1,5.8,2.1s2.9-.3,4.1-.8v.7s3.1,0,3.1,0v-22.3h0ZM71.7,36.8c-3.1,0-4.9-1.7-5-4.7s1.8-4.6,4.9-4.6,3,.4,4,1.3v6.8c-1,.8-2.4,1.3-4,1.3h0Z" />
                  <path
                    className={styles.logoPathText}
                    fill={hover ? textHoverColor : textColor}
                    d="M83.6,17.3c-1.1,0-2,.9-2,2s.9,2,2,2,2-.9,2-2-.9-2-2-2h0ZM85.2,24.5h-3.1s0,15.1,0,15.1h3.1s0-15.1,0-15.1h0Z" />
                  <path
                    className={styles.logoPathText}
                    fill={hover ? textHoverColor : textColor}
                    d="M102.1,24.5h-3.1s0,10.9,0,10.9c-1.1.8-2.6,1.3-4.2,1.3-2.6,0-3.6-.9-3.6-3.6v-8.6s-3.1,0-3.1,0v8.6c0,4.5,2.2,6.7,6.7,6.6s3.1-.4,4.2-1v.8s3.1,0,3.1,0v-15.1h0Z" />
                  <path
                    className={styles.logoPathText}
                    fill={hover ? textHoverColor : textColor}
                    d="M122,24.2h0c-1.8,0-3.7.5-5.2,1.5-1-1-2.5-1.5-4.4-1.5s-3.1.5-4.1,1v-.8s-3.1,0-3.1,0v15.1c0,0,3.2,0,3.2,0v-10.9c.9-.6,2.2-1.3,4-1.3s3,.6,3,3.5v8.7s3.1,0,3.1,0v-8.6c0-.8-.1-1.7-.3-2.5,1.2-.7,2.5-1.1,3.8-1.1,1.9,0,3,.6,3.1,3.5v8.7s3.1,0,3.1,0v-8.6c0-4.4-2-6.6-6.1-6.6,0,0,0,0,0,0Z" />
                  <path
                    className={styles.logoPathText}
                    fill={hover ? textHoverColor : textColor}
                    d="M11,20.9h-2.1s-.2.5-.2.5L.7,38.7l-.6,1.3h3.4s.2-.6.2-.6l1.9-4.3h8.5s2,4.2,2,4.2l.2.5h3.4s-.6-1.3-.6-1.3l-8-17h0c0-.1-.3-.6-.3-.6h0s0,0,0,0ZM7.1,32.1l2.8-6.3,2.9,6.2h-5.7s0,0,0,0Z" />
                  <path
                    className={styles.logoPathText}
                    fill={hover ? textHoverColor : textColor}
                    d="M35.6,17.6h-3.1s0,22.3,0,22.3h3.1s0-22.3,0-22.3h0Z" />
                  <path
                    className={styles.logoPathText}
                    fill={hover ? textHoverColor : textColor}
                    d="M46.8,24.9h-4.9s-.3.2-.3.2l-6.8,5.9-.8.7.8.7,7.7,6.9.3.2h4.9s-1.8-1.6-1.8-1.6l-7-6.3,6-5.2,1.9-1.6h0Z" />
                </g>
              </g>
              <path
                className={styles.logoPathArrow}
                fill={arrowHoverColor}
                d="M134.3,2.9s.5,0,.9.2c1.3.6,1.4,1.7,1.4,3.5v14.1c0,.9-.3,2.3-1.6,2.3-.3,0-.6,0-.9-.2-.9-.4-1.3-1.8-1.7-2.5-.5-1-1.4-2.8-3.5-3.8-.9-.4-1.9-.6-2.8-.6h0c-.8,0-1.5.1-2.2.3-.6.1-1.4.4-2.2.4h0c-.3,0-.5,0-.8-.2-1.9-.9-.8-2.6,0-3.2l10.9-8.8c1.1-.9,1.8-1.5,2.8-1.5M134.3.6c-1.7,0-3,1-4.2,2h0s-10.9,8.8-10.9,8.8c-1,.8-2,2.3-1.8,4.1,0,.8.5,2.2,2.4,3h0s0,0,0,0c.5.2,1.1.3,1.7.3h0c.8,0,1.5-.2,2.1-.4.2,0,.4,0,.5-.1h0s0,0,0,0c.6-.1,1.2-.2,1.8-.2h0c.6,0,1.3.1,1.8.4,1.3.6,2,1.8,2.4,2.7h0c0,.2.1.3.2.5.4.9,1.1,2.5,2.6,3.1.6.2,1.2.4,1.8.4h0c2.3,0,4-2,4-4.6V6.6c0-1.8,0-4.3-2.8-5.6-.9-.4-1.7-.5-1.9-.4h0s0,0,0,0Z" />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
