import React from 'react';

import { iconProps } from '../../../../models/Icon/IconPropsModel';

export const SubscriptionTabIcon = React.memo((props: iconProps) => {
    return (
        <svg
            aria-label="subscription-icon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.11859 3.5293H20.0668C21.7694 3.5293 22.8699 4.90072 22.5248 6.59256L20.5889 16.7013C20.2438 18.3931 18.5837 19.7646 16.8811 19.7646H3.93292C2.23023 19.7646 1.12984 18.3931 1.47492 16.7013L3.41078 6.59256C3.75586 4.90072 5.4159 3.5293 7.11859 3.5293Z"
                stroke="#231F20"
                strokeWidth="2"
            />
            <path
                d="M11.4117 13.4728C11.3573 13.4434 11.3014 13.4195 11.2446 13.4011C11.0229 13.3151 10.7889 13.2619 10.5534 13.245C10.5401 13.244 10.5269 13.2433 10.5136 13.2429L10.4119 13.2396C10.395 13.2391 10.3781 13.239 10.3612 13.2393C10.0655 13.2448 9.77119 13.2806 9.48272 13.3462C9.44656 13.3544 9.41129 13.3645 9.37701 13.3763L9.33424 13.3885C9.12201 13.4444 8.90653 13.4864 8.68912 13.5143L8.52187 13.5328L8.47794 13.5354C8.32574 13.5376 8.16694 13.5084 7.98765 13.4297C7.4712 13.18 7.286 12.7494 7.37465 12.3067C7.43835 11.9886 7.64447 11.6673 7.91093 11.4496C7.91117 11.4494 7.91141 11.4492 7.91165 11.449L12.4807 7.73711L12.4807 7.73716L12.4888 7.73048C13.0626 7.25426 13.1692 7.19052 13.4165 7.09099C13.5691 7.02955 13.7149 7.00047 13.8606 7.00001L13.8734 7.00011C14.0657 7.00395 14.247 7.0528 14.3816 7.12228L14.4014 7.13223C14.7906 7.32231 14.9589 7.51388 15.0559 7.70945C15.1682 7.93574 15.2411 8.28154 15.235 8.87287L15.235 8.87286V8.88314V14.9478C15.235 15.3219 15.1123 15.6785 14.9148 15.922C14.7374 16.1407 14.4867 16.2909 14.1104 16.2941C13.9338 16.2881 13.7669 16.2477 13.6406 16.1917L13.6203 16.1831C13.3348 16.0641 13.1628 15.8924 12.9294 15.378C12.9163 15.3492 12.8833 15.2727 12.845 15.1839L12.7949 15.0679L12.7743 15.0209L12.774 15.0202C12.7706 15.0125 12.7629 14.9951 12.7543 14.9769L12.6647 14.7849L12.6545 14.7637C12.3836 14.2167 11.951 13.7641 11.4117 13.4728Z"
                stroke="#231F20"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
});
