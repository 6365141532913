import React, { FC } from 'react';

import { useSelector } from 'react-redux';

import { environment } from '../../../../../config/environment';
import { MonthsShort } from '../../../../../constants/Months';
import { Button } from '../../../../../FigmaStyleguide/Button/Button';
import { Analytics } from '../../../../../services/Analytics/Analytics';
import styles from './StatusBlock.css';

interface StatusBlockProps {
    date: string;
    planId: string;
}

export const StatusBlock: FC<StatusBlockProps> = React.memo(({ date, planId }: StatusBlockProps) => {
    const hostedLoginToken = useSelector((state) => state.hostedLoginToken);
    const d = new Date(date);
    const dateNum = d.getDate();
    const month = MonthsShort[d.getMonth()];
    const year = d.getFullYear();

    const manageSubscription = () => {
        Analytics.trackEvent(Analytics.subscription.manageSubscription());
        setTimeout(() => window.open(`${environment.RECURLY_BILLING_PAGE}${hostedLoginToken}`, '_blank'), 300);
    };

    return (
        <div className={styles.container}>
            <div className={styles.textBlock}>
                <p>
                    Your subscription ends:{' '}
                    <span>
                        {month} {dateNum}, {year}
                    </span>
                </p>
                <p>
                    Your subscription plan:{' '}
                    <span className={styles.plan}>{planId.includes('monthly') ? 'Monthly' : 'Annual'}</span>
                </p>
            </div>
            <Button disabled={!hostedLoginToken} className={styles.btnSubscribe} onClick={manageSubscription}>
                Manage subscription
            </Button>
        </div>
    );
});
