import React from 'react';

export const MadeInNYIcon = React.memo(() => {
    return (
        <svg
            role="img"
            aria-labelledby="made-in-ny-title"
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title id="made-in-ny-title">'Made in NY' logo</title>
            <path
                opacity="0.3"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M57.1379 2.8759C56.5752 2.3075 55.8083 1.99179 55.0111 2.00037C54.2221 1.98791 53.4607 2.29251 52.8957 2.8467C52.3306 3.4009 52.0084 4.15894 52.0004 4.95294C51.9715 6.60012 53.3672 8 55.0227 8C56.6155 7.99883 57.98 6.63162 57.9997 5.01943C58.0113 4.21723 57.7005 3.4443 57.1379 2.8759ZM57.4038 4.9961C57.3955 6.33207 56.3132 7.40866 54.9856 7.40155C53.6563 7.39321 52.5834 6.30578 52.5847 4.9681C52.5952 3.65105 53.7115 2.53465 55.0041 2.54632C56.3303 2.57653 57.3929 3.66127 57.4038 4.9961Z"
                fill="#F9F9F9"
            />
            <path
                opacity="0.3"
                d="M15.0064 33.9012V48.4851C15.0064 48.9986 15.0064 48.9997 14.4803 48.9997C13.7913 48.9997 13.1012 48.9888 12.4122 48.9997C12.0974 49.0073 11.9951 48.8816 12.0006 48.5859C12.0127 47.9294 12.0006 47.273 12.0006 46.6165V29.6613C12.0006 29.553 12.0061 29.4393 12.0006 29.3277C11.9918 29.111 12.0798 29.0027 12.3187 29.0027C13.121 29.0103 13.9234 29.0125 14.7257 29.0027C14.9656 29.0027 15.0933 29.1327 15.2221 29.2941C16.4533 30.8453 17.6853 32.3958 18.9179 33.9456L26.7323 43.773C26.7862 43.8412 26.8269 43.9268 26.9447 43.956C27.0184 43.825 26.9843 43.6787 26.9843 43.5411C26.9843 38.8795 26.9843 34.2183 26.9843 29.5573C26.9843 29.0071 26.9843 29.0071 27.5401 29.006C28.2181 29.006 28.8972 29.0201 29.5752 29.0006C29.9053 28.9908 30.0011 29.1089 30 29.4241C29.989 31.8604 30 34.2988 30 36.7329C30 40.6608 30 44.5879 30 48.5144C30 48.991 30 48.9921 29.5069 48.9932C28.7948 48.9932 28.0827 48.9856 27.3706 48.9932C27.1554 49.0073 26.9487 48.9084 26.8269 48.7332C25.085 46.5313 23.3384 44.3319 21.5869 42.1351L15.221 34.119C15.177 34.0778 15.1274 34.029 15.0064 33.9012Z"
                fill="#F9F9F9"
            />
            <path
                opacity="0.3"
                d="M41.9179 44.6236C41.9179 43.3344 41.9179 42.0451 41.9179 40.7548C41.9257 40.5329 41.8596 40.3147 41.7301 40.1346C39.2458 36.6296 36.7643 33.122 34.2856 29.6119C34.1778 29.4528 33.9382 29.2872 34.0148 29.1107C34.1012 28.9213 34.3752 29.0328 34.5651 29.0306C35.4066 29.0198 36.2493 29.0306 37.0919 29.022C37.3037 29.0103 37.5047 29.1164 37.6152 29.298C39.4731 31.9147 41.3345 34.5285 43.1996 37.1394C43.4294 37.4641 43.4305 37.4641 43.6668 37.1318C45.4696 34.5848 47.2768 32.0442 49.0613 29.4842C49.2955 29.1486 49.5382 29.0003 49.9547 29.0155C50.862 29.049 51.7716 29.0241 52.68 29.0285C52.7879 29.0285 52.926 28.9798 52.9843 29.0956C53.0425 29.2114 52.9249 29.2991 52.8623 29.3846C51.4885 31.2804 50.1122 33.1747 48.7333 35.0676C47.5048 36.7548 46.2745 38.4409 45.0424 40.1259C44.9137 40.3066 44.8495 40.5255 44.8601 40.7473C44.8644 43.2932 44.8644 45.8396 44.8601 48.3862C44.8601 48.9989 44.8601 49 44.2321 49C43.6042 49 42.9914 49 42.371 49C41.9189 49 41.9157 49 41.9146 48.528L41.9179 44.6236Z"
                fill="#F9F9F9"
            />
            <path
                opacity="0.3"
                d="M15.1597 13.6094L15.8709 14.6844C16.194 15.168 16.514 15.6558 16.8454 16.1341C16.9703 16.314 16.9703 16.4346 16.7731 16.5574C16.5916 16.67 16.4168 16.7936 16.2498 16.9277C16.0939 17.0494 15.9907 17.0124 15.8864 16.8547C14.9504 15.4438 14.0114 14.0351 13.0692 12.6285C12.9536 12.456 12.9867 12.366 13.1394 12.2285C13.5833 11.8317 14.0066 11.6846 14.594 11.9883C15.2329 12.3195 15.9359 12.5258 16.609 12.784C16.9558 12.9163 16.962 12.911 16.9641 12.5406C16.9641 11.6941 16.9848 10.8476 16.9693 10.0011C16.9411 9.70679 17.0864 9.42353 17.3388 9.28047C17.9448 8.88896 17.9427 8.86991 18.3433 9.46353C19.2052 10.7418 20.0569 12.0274 20.9178 13.3057C21.0314 13.4739 21.0427 13.5924 20.8528 13.7004C20.733 13.7691 20.6246 13.858 20.5101 13.9353C20.0806 14.2241 20.1642 14.3268 19.805 13.7871C19.2888 13.0179 18.7727 12.2486 18.2256 11.4857C18.1925 12.0042 18.1781 12.5174 18.1502 13.0284C18.1254 13.4729 18.1358 13.9205 18.1502 14.3649C18.1574 14.6273 18.0893 14.6919 17.8405 14.5913C17.0322 14.2654 16.2178 13.9564 15.4053 13.639C15.3382 13.6051 15.2753 13.5459 15.1597 13.6094Z"
                fill="#F9F9F9"
            />
            <path
                opacity="0.3"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.4313 11.8429C38.7807 11.3495 38.9758 10.783 38.9964 10.2011C39.0538 9.10082 38.4164 8.21656 37.3022 7.83324C36.4992 7.55604 35.6561 7.40801 34.8146 7.26024C34.3754 7.18314 33.9367 7.10611 33.5043 7.01083C33.276 6.95986 33.2426 7.09768 33.2085 7.23832C33.2066 7.24599 33.2047 7.25366 33.2029 7.26131C32.8108 8.83919 32.4199 10.411 32.0187 11.9828C31.9648 12.1917 32.0221 12.2911 32.2606 12.3357C32.6558 12.4098 33.0502 12.4877 33.4447 12.5655C34.129 12.7005 34.8133 12.8354 35.5013 12.9512C36.7015 13.153 37.8134 12.718 38.4313 11.8429ZM36.5249 8.8037C37.3171 8.99536 37.6862 9.44155 37.6804 10.1108C37.6747 10.987 37.1749 11.6289 36.3736 11.7932C36.1322 11.8426 35.8814 11.8451 35.6388 11.8003C35.3345 11.7445 35.0307 11.6852 34.7269 11.6258C34.4231 11.5665 34.1193 11.5072 33.815 11.4514C33.5972 11.4119 33.5215 11.3287 33.5743 11.1259C33.7662 10.3813 33.95 9.6352 34.1336 8.88957C34.1573 8.79333 34.181 8.6971 34.2047 8.60089C34.2449 8.43965 34.3068 8.34636 34.536 8.39807C34.8087 8.45712 35.0835 8.50925 35.3584 8.56138C35.7492 8.6355 36.14 8.70964 36.5249 8.8037Z"
                fill="#F9F9F9"
            />
            <path
                opacity="0.3"
                d="M33.8692 21.8475C33.8692 20.6819 33.8692 19.6148 33.8692 18.5465C33.8692 18.0248 33.8692 18.0203 34.3243 18.0248C35.0904 18.0339 34.9901 17.9117 34.993 18.7808C34.998 20.373 34.9881 21.9652 35 23.5562C35 23.8753 34.9076 23.9579 34.6343 23.9896C34.1216 24.0507 33.7897 23.8414 33.4787 23.3718C32.7832 22.316 32.0221 21.3077 31.2927 20.279C31.2612 20.2271 31.233 20.1726 31.2083 20.1161C31.098 20.2836 31.1357 20.4148 31.1357 20.5359C31.1357 21.5465 31.1258 22.5581 31.1357 23.5687C31.1357 23.864 31.0592 23.9862 30.788 23.9828C29.8857 23.9681 30.0099 24.0564 30.007 23.1171C30.001 21.5713 30.0119 20.0267 30 18.4775C30 18.138 30.0805 18.0305 30.3975 18.0056C30.9151 17.9649 31.2609 18.1437 31.5789 18.6314C32.2218 19.6193 32.9293 20.5552 33.6109 21.508C33.6705 21.603 33.7411 21.6834 33.8692 21.8475Z"
                fill="#F9F9F9"
            />
            <path
                opacity="0.3"
                d="M46.3831 13.5722C46.3021 13.522 46.2303 13.4818 46.1626 13.4357C45.3093 12.8495 44.4529 12.2695 43.6088 11.6713C43.4109 11.5308 43.3155 11.5589 43.1883 11.7446C42.778 12.3467 42.815 12.1811 43.3175 12.5475C43.6037 12.7572 43.897 12.9589 44.1975 13.1496C44.3873 13.2711 44.4139 13.3805 44.2837 13.5742C43.8057 14.2767 43.8119 14.2847 43.1114 13.807C42.8868 13.6535 42.6652 13.4969 42.4406 13.3433C42.3811 13.3012 42.3083 13.2219 42.257 13.2329C41.9052 13.3082 41.856 13.6675 41.6529 13.8853C41.5134 14.0358 41.6806 14.1211 41.7893 14.1954C42.656 14.7976 43.5196 15.3998 44.3965 15.9919C44.6221 16.1435 44.658 16.2539 44.4929 16.4787C44.0119 17.133 44.0221 17.1381 43.3565 16.6794C42.3001 15.9477 41.2447 15.2141 40.177 14.4915C39.9781 14.356 39.9432 14.2506 40.0909 14.0479C41.031 12.7652 41.9613 11.4779 42.8816 10.1859C43.0427 9.9591 43.1575 9.94906 43.3801 10.1056C44.5158 10.9085 45.6584 11.6994 46.8078 12.4782C47.0201 12.6227 47.0693 12.7402 46.8908 12.9419C46.7473 13.1045 46.6314 13.2911 46.5001 13.4648C46.4646 13.504 46.4254 13.54 46.3831 13.5722Z"
                fill="#F9F9F9"
            />
            <path
                opacity="0.3"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.4538 7.21674C26.3774 7.10268 26.2945 6.97894 26.0622 7.00304C25.9671 7.0197 25.8682 7.03766 25.7667 7.05609C25.5102 7.10269 25.237 7.15231 24.966 7.19153C24.7942 7.20456 24.6538 7.32431 24.625 7.48237C24.5165 7.86148 24.3998 8.24231 24.2849 8.61694C24.2751 8.64888 24.2653 8.68077 24.2556 8.71262C23.8531 10.0219 23.4536 11.3315 23.057 12.6415C22.9425 13.0063 22.9763 13.0448 23.3839 12.9658C23.9536 12.8568 24.1673 12.8711 24.2823 12.7716C24.3919 12.6767 24.412 12.4784 24.5651 11.9717C24.5671 11.9641 24.5702 11.9567 24.5733 11.9493C24.5784 11.9369 24.5836 11.9246 24.5836 11.9119C24.6217 11.6828 24.7699 11.5845 25.0118 11.5481C25.1733 11.5233 25.3351 11.4995 25.497 11.4757C26.0968 11.3874 26.6977 11.299 27.2838 11.161C27.7959 11.0404 28.1173 11.1468 28.332 11.5815C28.5467 12.0163 28.8834 12.0993 29.3672 11.9869C29.773 11.8921 29.9684 11.8749 29.9964 11.7972C30.0233 11.7228 29.8968 11.593 29.6549 11.2866C29.2482 10.7711 28.8421 10.2548 28.4361 9.73843C27.7933 8.92109 27.1504 8.10362 26.5046 7.28881C26.487 7.26637 26.4705 7.24179 26.4538 7.21674ZM25.5174 8.88141C25.5785 8.68098 25.6412 8.4749 25.707 8.26065L26.9514 9.84659C27.0604 9.98441 27.0048 10.0523 26.8424 10.0787C26.6408 10.1116 26.4394 10.1449 26.238 10.1782C25.9441 10.2268 25.6503 10.2754 25.3561 10.3229C25.1796 10.3513 25.0707 10.3219 25.1382 10.1101C25.265 9.71002 25.3873 9.30856 25.5174 8.88141Z"
                fill="#F9F9F9"
            />
            <path
                opacity="0.3"
                d="M25.0014 20.9768C25.0014 20.1137 25.0086 19.2495 25.0014 18.3864C25.0014 18.1017 25.0706 17.9924 25.3045 18.0004C26.1238 18.0255 25.9882 17.8774 25.9927 18.8418C26.0009 20.4166 25.9927 21.9924 26 23.5683C26 23.8837 25.9263 24.0055 25.6677 23.9998C24.8757 23.9839 25.015 24.0624 25.0068 23.1823C24.9959 22.4479 25.0014 21.7123 25.0014 20.9768Z"
                fill="#F9F9F9"
            />
            <path
                opacity="0.3"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M55.8634 6.56934C55.7218 6.2461 55.5426 5.94913 55.331 5.68753C55.3765 5.65898 55.4072 5.64418 55.4405 5.62832C55.7662 5.43695 55.9369 5.10918 55.8932 4.75709C55.845 4.36377 55.6296 4.1301 55.2522 4.04657C55.0053 3.99143 54.7585 3.99773 54.5116 4.00404C54.4082 4.00668 54.3048 4.00932 54.2014 4.00745C54.0499 4.00428 54 4.10684 54 4.27601V6.68564C53.9991 6.86856 54.0333 6.96477 54.2145 6.96794C54.3958 6.97112 54.451 6.86644 54.451 6.67084V6.01953C54.4501 5.83661 54.5315 5.77635 54.6734 5.77635C54.8713 5.77635 54.9912 5.92014 55.0876 6.09354C55.157 6.21734 55.2204 6.34584 55.2839 6.47437C55.3445 6.59722 55.4052 6.72011 55.4711 6.83895C55.5919 7.05464 55.8073 7.01764 55.9343 6.93517C56.0581 6.85555 55.9808 6.74472 55.9145 6.64969C55.8945 6.62104 55.8756 6.59383 55.8634 6.56934ZM54.6948 4.48687C54.7388 4.49186 54.7817 4.49672 54.817 4.48219C54.948 4.47339 55.0792 4.49382 55.204 4.54246C55.3272 4.59138 55.4086 4.73284 55.4037 4.88926C55.4082 5.04731 55.32 5.18737 55.1926 5.22443C55.0983 5.25813 55.0016 5.28079 54.9037 5.29209C54.4834 5.34073 54.3547 5.15781 54.4562 4.6577C54.4954 4.46428 54.5979 4.47589 54.6948 4.48687Z"
                fill="#F9F9F9"
            />
            <path
                opacity="0.3"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.28361 18.5173C6.92847 7.30076 17.8749 -0.00916283 30.0127 5.57431e-05C34.0243 -0.0077035 37.9962 0.794659 41.6904 2.35907C52.8374 7.07661 60.058 18.0324 59.9993 30.1388C59.9405 42.2453 52.6139 53.1304 41.4216 57.7395C30.2294 62.3486 17.3644 59.7785 8.80076 51.2229C0.211476 42.6447 -2.36126 29.7337 2.28361 18.5173ZM57.8298 30.006C57.8298 14.6397 45.3753 2.1823 30.0127 2.1823C14.65 2.1823 2.19557 14.6397 2.19557 30.006C2.19557 45.3723 14.65 57.8296 30.0127 57.8296C45.3753 57.8296 57.8298 45.3723 57.8298 30.006Z"
                fill="#F9F9F9"
            />
        </svg>
    );
});
