export type PageName = string;

export enum PageTypes {
  // main routed page types
  Home = 'Home',
  Category = 'Category',
  AllCategories = 'AllCategories',
  Game = 'Game',
  Help = 'Help',
  Profile = 'Profile',
  Subscription = 'Subscription',
  GiftCard = 'GiftCard',
  CoBranded = 'CoBranded',
  NotFound = 'NotFound',
  // generic page types
  PrivacyPolicy = 'PrivacyPolicy',
  PrivacyPolicyCalifornia = 'PrivacyPolicyCalifornia',
  Shop = 'Shop',
  AboutUs = 'AboutUs',
  SiteMap = 'SiteMap',
  Dynamic = 'Dynamic',
  BlogArchive = 'BlogArchive',
  BlogPost = 'BlogPost',
  GameSubmissionDisclaimer = 'GameSubmissionDisclaimer',
  ComingSoonGame = 'ComingSoonGame',
}

export enum ProfileRightSideRoutes {
  NOOP = 'Noop',
  AVATARS_PANEL = 'AvatarsPanel',
  AVATAR_CUSTOMIZE_PANEL = 'AvatarCustomizePanel',
  SCREEN_NAME_PANEL = 'ScreenNamePanel',
  COUNTRY_PICKER_PANEL = 'CountryPickerPanel',
  FIRST_NAME_PANEL = 'FirstNamePanel',
  LAST_NAME_PANEL = 'LastNamePanel',
  MY_LANGUAGE_PANEL = 'MyLanguagePanel',
  BIRTHDAY_PANEL = 'BirthdayPanel',
  PHONE_NUMBER_PANEL = 'PhoneInputPanel',
  GENDER_PANEL = 'GenderPanel',
  EMAIL_PANEL = 'EmailPanel',
  SIGN_IN = 'SignInPanel',
  SIGN_UP = 'SignUpPanel',
  AFTER_SIGN = 'AfterSignPanel',
  AFTER_SIGN_SOCIAL = 'AfterSignSocialPanel',
  THANK_YOU = 'ThankYouPanel',
  RESET_PASSWORD = 'ResetPasswordPanel',
  CONFIRM_RESET_PASSWORD = 'ConfirmResetPasswordPanel',
  REQUEST_EMAIL = 'RequestEmailPanel',
  REQUEST_ACTIVATION = 'RequestActivationPanel',
  SUBSCRIPTION_PANEL = 'SubscriptionPanel',
  AFTER_SIGN_UP = 'AFTER_SIGN_UP',
}
