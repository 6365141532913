import { LocalStorageService } from './LocalStorage';

export enum LocalStorageListenedProps {
    GAME_PURCHASE_REQUEST = 'LS_LISTENING_GAME_PURCHASE_REQUEST', // used for gems-game interaction in GameUnit.tsx
    GEMS_PURCHASE_NEW_TAB = 'LS_LISTENING_GEMS_PURCHASE_FINISHED', // used for purchasing gems in new tab
}

export const lsSetGamePurchaseRequest = (value: 'pending' | 'success' | 'fail') => {
    LocalStorageService.setItem(LocalStorageListenedProps.GAME_PURCHASE_REQUEST, value);
};
export const lsRemoveGamePurchaseRequest = () => {
    LocalStorageService.removeItem(LocalStorageListenedProps.GAME_PURCHASE_REQUEST);
};
export const lsDispatchGamePurchaseRequestFail = () => {
    LocalStorageService.dispatchEvent(LocalStorageListenedProps.GAME_PURCHASE_REQUEST, 'fail');
};

export const lsSetGemsPurchaseFinished = (value: string) => {
    LocalStorageService.setItem(LocalStorageListenedProps.GEMS_PURCHASE_NEW_TAB, value);
};
export const lsRemoveGemsPurchaseFinished = () => {
    LocalStorageService.removeItem(LocalStorageListenedProps.GEMS_PURCHASE_NEW_TAB);
};
