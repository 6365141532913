import React, { FC, useEffect } from 'react';

import classNames from 'classnames';
import { useTranslation, WithTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import styles from './AvatarCardBlock.css';
import { globalErrorHandler } from '../../../../../../utils/LogUtils';
import { Avatar, AvatarTypes, BorderTypes } from '../../../../../atoms/Avatar/Avatar';
import { environment } from '../../../../../config/environment';
import { MonthsShort } from '../../../../../constants/Months';
import { Button } from '../../../../../FigmaStyleguide/Button/Button';
import { ArkadiumAdvantageLogo } from '../../../../../FigmaStyleguide/Icons/ArkadiumAdvantageLogo';
import { SubscriptionSource } from '../../../../../models/Subscription/SubscriptionData';
import { Analytics } from '../../../../../services/Analytics/Analytics';
import { AppInsightService } from '../../../../../services/AppInsight';
import PaymentService from '../../../../../services/PaymentService';
import UserService from '../../../../../services/UserService';
import { setSnackbarData } from '../../../../../store/ducks/layout';
import { setSubscriptionSource } from '../../../../../store/ducks/subscription/common';
import { setHostedLoginToken } from '../../../../../store/ducks/user';
import { SubscriptionActions } from '../PaymentAndSubscription';

interface AvatarCardBlockProps {
  type: SubscriptionActions;
  date: string;
}

export const AvatarCardBlock: FC<AvatarCardBlockProps> = React.memo(({ type, date }: AvatarCardBlockProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(({ user }) => user);
  const userAvatar = user?.avatar || user?.subscriberAvatar?.avatar || '';
  const hostedLoginToken = useSelector(({ hostedLoginToken }) => hostedLoginToken);
  const d = new Date(date);
  const dateNum = d.getDate();
  const month = MonthsShort[d.getMonth()];
  const year = d.getFullYear();

  useEffect(() => {
    if (!hostedLoginToken) {
      PaymentService.getRecurlyData()
        .then((res) => {
          dispatch(setHostedLoginToken(res.hostedLoginToken));
        })
        .catch((err) => {
          globalErrorHandler({ error: err, filename: 'AvatarCardBlock.tsx', info: 'PaymentService.getRecurlyData()' });
          AppInsightService.trackAppError(err, { data: 'getRecurlyData()' });
        });
    }
  }, []);

  const renewSubscription = () => {
    void Analytics.trackEvent(Analytics.subscription.renewSubscription());
    dispatch(
      setSnackbarData({
        isOpened: true,
        message: t('AVATAR_CARD_BLOCK.REFRESH_PAGE'),
        type: 'success',
        static: true
      })
    );
  };

  return (
    <div
      className={classNames(styles.containerExtended, {
        [styles.restart]: type === SubscriptionActions.RESTART,
        [styles.renew]: type === SubscriptionActions.RENEW
      })}
    >
      <div className={styles.container}>
        <div className={styles.containerBg} />
        <div className={styles.avatarBlock}>
          <Avatar
            image={userAvatar}
            selectable={false}
            selected={false}
            size={AvatarTypes.REGULAR_ADVANTAGE2}
            onSelect={() => null}
            disabled
            border={borderMapping(type)}
            noFrame
          />
        </div>
        <ArkadiumAdvantageLogo className={styles.logoImg} />
        <div className={styles.bottomText}>
          {[
            SubscriptionActions.STANDARD,
            SubscriptionActions.SUBSCRIBED_BOTH,
            SubscriptionActions.SUBSCRIBED_EXTERNAL
          ].includes(type) && <p>Subscriber since</p>}
          {type === SubscriptionActions.RENEW && <p>{t('AVATAR_CARD_BLOCK.SUBSCRIPTION_ENDS')}</p>}
          {type === SubscriptionActions.RESTART && <p>{t('AVATAR_CARD_BLOCK.SUBSCRIPTION_ENDED_ON')}</p>}
          <p className={styles.date}>
            {month} {dateNum}, {year}
          </p>
          <div className={styles.bottomBlock}>
            {type === SubscriptionActions.RENEW && (
              <LinkBlock t={t} hostedLoginToken={hostedLoginToken} handleRenew={renewSubscription} />
            )}
            {type === SubscriptionActions.RESTART && <ButtonRestart t={t} dispatch={dispatch} />}
          </div>
        </div>
      </div>
    </div>
  );
});

const LinkBlock: FC<{ hostedLoginToken: string; handleRenew: () => void, t: WithTranslation['t'] }> = React.memo(
  ({ hostedLoginToken, handleRenew, t }) => {

    return (
      <a
        className={styles.link}
        target="_blank"
        rel="noopener noreferrer"
        href={`${environment.RECURLY_BILLING_PAGE}${hostedLoginToken}`}
        onClick={handleRenew}
      >
        {t('AVATAR_CARD_BLOCK.RENEW_SUBSCRIPTION')}
      </a>
    );
  }
);
const ButtonRestart: FC<{ dispatch: any, t: WithTranslation['t'] }> = React.memo(({ dispatch, t }) => {
  return (
    <Button
      className={styles.btnSubscribe}
      onClick={() => {
        dispatch(setSubscriptionSource(SubscriptionSource.AVATAR_PANEL));
        UserService.openSubscriptionPanel();
      }}
    >
      {t('AVATAR_CARD_BLOCK.RESTART_SUBSCRIPTION')}
    </Button>
  );
});
const borderMapping = (type: SubscriptionActions) => {
  return {
    [SubscriptionActions.NONE]: BorderTypes.DEFAULT,
    [SubscriptionActions.STANDARD]: BorderTypes.GOLD,
    [SubscriptionActions.RENEW]: BorderTypes.GOLD,
    [SubscriptionActions.SUBSCRIBED_BOTH]: BorderTypes.GOLD,
    [SubscriptionActions.SUBSCRIBED_EXTERNAL]: BorderTypes.GOLD,
    [SubscriptionActions.RESTART]: BorderTypes.DISABLED
  }[type];
};
