import {
  AnalyticsEventAction,
  AnalyticsEventBuilder,
  AnalyticsEventsPair,
  makeEventsPair
} from './AnalyticsEventBuilder';
import { BlogAnalytics } from './BlogAnalytics';
import { CategoriesAnalytics } from './CategoriesAnalytics';
import { AnalyticsExtension, extendersList } from './Extensions';
import { GamesAnalytics } from './GamesAnalytics';
import { GemsAnalytics } from './GemsAnalytics';
import { GeneralAnalytics } from './GeneralAnalytics';
import { GiftCardAnalytics } from './GiftCardAnalytics';
import { shouldDoUpdate } from './LeanplumAnalytics';
import { NewHomeAnalytics } from './NewHomeAnalytics';
import { PageBuilderAdvantageAnalytics } from './PageBuilderAdvantageAnalytics';
import { ProfileAnalytics } from './ProfileAnalytics';
import { SubscriptionAnalytics } from './SubscriptionAnalytics';
import { devConsole } from '../../../utils/DevConsole';
import { DeviceUtils } from '../../../utils/DeviceUtils';
import { globalErrorHandler } from '../../../utils/LogUtils';
import { MiscUtils } from '../../../utils/MiscUtils';
import { environment } from '../../config/environment';
import { EnvironmentName } from '../../constants/Environment';
import { FavoritesAnalytics } from '../../features/Favorites/FavoritesAnalytics';
import { LS_COOKIE_CONSTS, QUERY_STRING_CONSTS } from '../../models/Enums';
import { IGame } from '../../models/Game/Game';
import { GameEvents } from '../../models/Game/GameObservable';
import {
  MobileNavMenuUxRedesignedAnalytics
} from '../../organisms/MobileNavigationUxRedesigned/MobileNavUxRedesignedAnalytics';
import { AppInsightService } from '../AppInsight';
import { CookieService } from '../CookieService';
import { DeviceDetector } from '../DeviceDetector';
import { GoogleAnalyticsService } from '../GoogleAnalyticsService';
import { LocalStorageService } from '../LocalStorage';
import { StartupService } from '../StartupService';

enum LS_AI_PROPS {
  LAST_TRACKED = 'AppInsightsLastTrackedEvent',
}

class AnalyticsService {
  general = new GeneralAnalytics();

  newHome = new NewHomeAnalytics();

  games = new GamesAnalytics();

  categories = new CategoriesAnalytics();

  profile = new ProfileAnalytics();

  subscription = new SubscriptionAnalytics();

  giftCard = new GiftCardAnalytics();

  gems = new GemsAnalytics();

  favorites = new FavoritesAnalytics();

  mobileNavMenuUxRedesigned = new MobileNavMenuUxRedesignedAnalytics();

  globalAIData: any = {
    targetingConsent: LocalStorageService.getItem(LS_COOKIE_CONSTS.TARGETING_CONSENT),
    environment: EnvironmentName[environment.Name]?.toLowerCase()
  };

  pageBuilderAdvantageAnalytics = new PageBuilderAdvantageAnalytics();

  blogAnalytics = new BlogAnalytics();

  private extensionManager: AnalyticsExtension = new AnalyticsExtension(extendersList);

  async trackEvent(eventPair: Promise<AnalyticsEventsPair>, forceUpdate: boolean = true) {
    if (!MiscUtils.isServer) {
      const event = await eventPair;

      this.extensionManager.extend(event);

      if (event.ai) {
        const ev = event.ai.name;
        const payload = {
          ...event.ai.data,
          ...this.globalAIData
        };

        if (shouldDoUpdate({ [ev]: { ...payload } }, LS_AI_PROPS.LAST_TRACKED) || forceUpdate) {
          devConsole('AI - trackEvent', ev, payload);
          AppInsightService.trackEvent(ev, { ...payload });
        }
      }

      if (event.ga) {
        GoogleAnalyticsService.trackEvent(event.ga.data);
      }
    }
  }

  getPlayAgainStart = function (): string {
    if (window.location.href.indexOf('playAgain') !== -1) {
      return AnalyticsEventAction.YES;
    }

    return AnalyticsEventAction.NO;
  };

  getRecPreviousGame = function (): string {
    const param = StartupService.getParameterByName('recPreviousGame', window.location.href);

    if (param) {
      return param;
    }

    return '';
  };

  //
  // Get bookmark type desktop,android,ios
  //
  getBookmarkType = function () {
    if (!MiscUtils.isServer) {
      this.deviceDetector = new DeviceDetector();

      if (sessionStorage.getItem('arkBookmarkType')) {
        return sessionStorage.getItem('arkBookmarkType');
      }

      if (
        window.location.href.indexOf(`${QUERY_STRING_CONSTS.UTM_SOURCE}=desktopIcon`) !== -1 ||
        (DeviceUtils.isDesktopDevice() &&
          (window.matchMedia('(display-mode: standalone)').matches ||
            (window as any).navigator.standalone === true))
      ) {
        return 'desktop';
      }

      if (
        (window as any).navigator.standalone === true &&
        this.deviceDetector.DetectBrowser().name === 'Safari'
      ) {
        return 'ios';
      }

      if (window.matchMedia('(display-mode: standalone)').matches) {
        if (this.deviceDetector.DetectBrowser().name === 'Safari') {
          return 'ios';
        }

        return 'android';
      }

      return '';
    }
  };

  generateLabel = function (domain: string, name: string, game?: string): string {
    if (game) {
      return `${domain} | ${name}/${game}`;
    }

    return `${domain} | ${name}`;
  };

  generateLabelForGA4 = function (domain: string, url: string): string {
    return `${domain} | ${url}`;
  };

  gamePlayNumTrack = function (game) {
    let gamePlayNum: number = parseInt(LocalStorageService.getItem(LS_COOKIE_CONSTS.GAME_PLAY_NUM), 10);

    if (gamePlayNum > 0) {
      gamePlayNum++;
      LocalStorageService.setItem(LS_COOKIE_CONSTS.GAME_PLAY_NUM, gamePlayNum);
      CookieService.setArkCookie(LS_COOKIE_CONSTS.ARK_PC_OVERALL_GAMES_PLAYED, gamePlayNum);
    } else {
      gamePlayNum = 1;
      LocalStorageService.setItem(LS_COOKIE_CONSTS.GAME_PLAY_NUM, gamePlayNum);
      CookieService.setArkCookie(LS_COOKIE_CONSTS.ARK_PC_OVERALL_GAMES_PLAYED, gamePlayNum);
    }

    CookieService.setArkCookie(LS_COOKIE_CONSTS.ARK_PC_LAST_GAME_PLAYED, game.slug);

    if (gamePlayNum > 4 && gamePlayNum <= 100) {
      if (gamePlayNum % 5 === 0) {
        Analytics.trackEvent(Analytics.games.numberTimesPlay(game, `${gamePlayNum}th Gameplay`));
      }
    }
  };

  gamePlayVisitTrack = function (game) {
    const gamePlayLastVisitTime: number = parseInt(
      LocalStorageService.getItem(LS_COOKIE_CONSTS.LAST_TIME_GAME_PLAYED),
      10
    );
    const currentTime = Math.round(new Date().getTime() / 1000);

    if ((currentTime - gamePlayLastVisitTime) / 60 / 60 / 24 > 1) {
      // since last time passed more than 1 day, count new visit
      let gamePlayVisit: number =
        parseInt(LocalStorageService.getItem(LS_COOKIE_CONSTS.GAME_PLAY_VISIT), 10) || 0;

      gamePlayVisit++;

      if (gamePlayVisit > 1 && gamePlayVisit <= 25) {
        if (gamePlayVisit % 5 === 0 || gamePlayVisit === 2) {
          Analytics.trackEvent(Analytics.games.numberPlayVisits(game, `${gamePlayVisit}th visit`));
        }
      }

      LocalStorageService.setItem(LS_COOKIE_CONSTS.GAME_PLAY_VISIT, gamePlayVisit);
    }

    LocalStorageService.setItem(LS_COOKIE_CONSTS.LAST_TIME_GAME_PLAYED, currentTime);
  };
}

export const Analytics = new AnalyticsService();

export async function trackGameEvent(eventName: string, payload: any, game: IGame) {
  // #137921 AI and GA for game events
  // PDO-1028 remove GA game analytics 12/09/24
  if (!eventName || !payload) {
    return;
  }

  try {
    // generate Game events pair
    const { ai } = payload || {};

    devConsole(`Games AI event (${eventName}) data passed from game: `, { ai });
    const aiData = ai && typeof ai === 'object' && true && !Array.isArray(ai);

    if (!aiData) {
      return;
    }

    let eventAi;

    // generate AI event
    if (aiData) {
      eventAi = await new AnalyticsEventBuilder().baseEvent();
      eventAi.setName(eventName);
      eventAi.setCustom('game_event_test', 'ai track from game');

      if (
        eventName.toLowerCase() === 'gameend' ||
        eventName.toLowerCase() === 'round' ||
        eventName.toLowerCase() === 'round_end'
      ) {
        const gameAnalyticsSlug = this.props?.game?.analyticsSlug || this.props?.game?.arkadiumSlug;
        const sdkInfo = await MiscUtils.getSdKInfo();

        eventAi.setCustom('game', gameAnalyticsSlug);
        eventAi.setGame(game, sdkInfo);
      }

      const { customDimensions } = ai;

      if (customDimensions) {
        Object.keys(customDimensions).forEach((key) => {
          eventAi.setCustom(key, customDimensions[key]);
        });
      }

    }

    // track GA and AI events pair
    const aiEvent = eventAi ? eventAi?.build?.() : null;

    // send at least 1 event provided
    if (aiEvent) {
      const eventPair = makeEventsPair(null, aiEvent).then(async (evPair) => {
        devConsole(`Games AI and GA event (${eventName}) track passed and fired: `, await evPair);
        return evPair;
      });

      Analytics.trackEvent(eventPair);
    }
  } catch (err) {
    globalErrorHandler({
      error: err,
      filename: 'Analytics.ts',
      info: `trackGameEvent() / (could not send ${GameEvents.ANALYTICS_EVENT} to AppInsights / GA)`
    });
  }
}
