import { MiscUtils } from '../../../utils/MiscUtils';
import { IGame } from '../../models/Game/Game';
import {
    AnalyticsEvent,
    AnalyticsEventAction,
    AnalyticsEventBuilder,
} from '../../services/Analytics/AnalyticsEventBuilder';

export enum FavoritesAnalyticsEvents {
    addFavoritesGameNav = 'addFavoritesGameNav',
    addFavoritesGameEnd = 'addFavoritesGameEnd',
}

export enum FavoritesAnalyticsClickPageTypes {
    HOME = 'clickFavoriteGameHomePage',
    CATEGORY = 'clickFavoriteGameCategoryPage',
    PROFILE = 'profileFavoriteGames',
}

export type FavoritesAnalyticsCustomDimensions = {
    gameFavorited?: boolean;
    game: IGame;
    pageType?: FavoritesAnalyticsClickPageTypes;
};

export class FavoritesAnalyticsAi {
    // adding to /removing game from favorites list

    async addFavoritesGameNav({ gameFavorited, game }: FavoritesAnalyticsCustomDimensions): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName(FavoritesAnalyticsEvents.addFavoritesGameNav);
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        event.setCustom('gameSlug', game.slug);
        event.setCustom('gameFavorited', gameFavorited);
        await event.setGame(game);
        return event.build();
    }

    async addFavoritesGameEnd({ gameFavorited, game }: FavoritesAnalyticsCustomDimensions): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName(FavoritesAnalyticsEvents.addFavoritesGameEnd);
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        event.setCustom('gameSlug', game.slug);
        event.setCustom('gameFavorited', gameFavorited);
        await event.setGame(game);
        return event.build();
    }

    // favorites link clicks

    async clickFavoriteGameTile({ pageType, game }: FavoritesAnalyticsCustomDimensions): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName(pageType);
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        event.setCustom('gameSlug', game.slug);
        await event.setGame(game);
        return event.build();
    }

    private async newEventBuilder(): Promise<AnalyticsEventBuilder> {
        return await new AnalyticsEventBuilder().baseEvent();
    }
}
