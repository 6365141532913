import React, { FC, memo } from 'react';

import { Helmet } from 'react-helmet-async';

export const StructuredDataWebSite: FC = memo(() => {
    const structuredData = {
        '@context': 'https://schema.org/',
        '@type': 'WebSite',
        url: 'https://www.arkadium.com/',
        name: 'Arkadium',
        '@id': 'https://www.arkadium.com/#website',
        publisher: { '@type': 'Organization', '@id': 'https://www.arkadium.com/#organization' },
    };

    return (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
        </Helmet>
    );
});
