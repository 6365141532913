import React, { useCallback, useContext, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';


import styles from './PersonalizeProfile.css';
import { validationSchema } from './validationSchemas.ts/PresonalizeProfileSchema';
import { MiscUtils } from '../../../../../utils/MiscUtils';
import { ProfanityUtils } from '../../../../../utils/ProfanityUtils';
import { Input } from '../../../../atoms/HookFormInput/Input';
import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { getAvatarAnalyticsKey } from '../../../../constants/AvatarImagesData';
import { ProfileRightSideRoutes } from '../../../../constants/Pages';
import { SignInUpComponents } from '../../../../constants/SignInUpComponents';
import { Button } from '../../../../FigmaStyleguide/Button/Button';
import { RepeatIcon } from '../../../../FigmaStyleguide/Icons/RepeatIcon';
import { TextWithLink } from '../../../../FigmaStyleguide/TextWithLink/TextWithLink';
import { RegistrationSource } from '../../../../models/User/RegistrationSource';
import CaptchaIframe from '../../../../molecules/Captcha/CaptchaIframe';
import useCaptcha, {
  RECAPTCHA_ACTIONS,
  RECAPTCHA_MODES
} from '../../../../molecules/Captcha/hooks/useCaptcha';
import { Analytics } from '../../../../services/Analytics/Analytics';
import { LEANPLUM_EVENTS, LeanplumAnalytics } from '../../../../services/Analytics/LeanplumAnalytics';
import { AuthDataContext } from '../../../../services/AuthDataReact';
import { GameService } from '../../../../services/GameService';
import { LocalStorageService } from '../../../../services/LocalStorage';
import { TranslationService } from '../../../../services/TranslationService';
import UserService, { AuthType } from '../../../../services/UserService';
import { setProfilePanelState, setSignInUpState, setSnackbarData } from '../../../../store/ducks/layout';
import { setShowRecaptcha } from '../../../../store/ducks/recaptcha';
import ChooseAvatar from '../ChooseAvatar/ChooseAvatar';


type PersonalizeProfileProps = {
  onSignInUpFinished: () => unknown;
};

export const PersonalizeProfile = React.memo(({ onSignInUpFinished }: PersonalizeProfileProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const authDataContext = useContext(AuthDataContext);
  const { email, password, checkBox, authProvider } = authDataContext.value;
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedAvatar, setSelectedAvatar] = useState<string>('avatar_blank');
  const categoryPageName = useSelector(({ categoryPageName }) => categoryPageName);
  const gameArkadiumSlug = useSelector(({ gameArkadiumSlug }) => gameArkadiumSlug);
  const games = useSelector(({ games }) => games);
  const user = useSelector(({ user }) => user);
  const socialRegistrationLocation = useSelector(({ socialRegistrationLocation }) => socialRegistrationLocation);
  const game = gameArkadiumSlug
    ? GameService.findGameByArkadiumSlug(GameService.gameModelToGame(games), gameArkadiumSlug)
    : '';
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: authDataContext.value.name ?? ''
    }
  });
  const values = watch();
  const { name } = values;
  const {
    iframeRef,
    iframeURL,
    setIsIframeCaptchaLoaded,
    getCaptchaToken,
    clearCaptchaData,
    showChallengeRecaptcha,
    captchaToken
  } = useCaptcha();

  useEffect(() => {
    if (captchaToken) {
      void fetchCheckUsername();
    }
  }, [captchaToken]);

  const handleSkip = () => {
    const avatarAnalyticsKey = getAvatarAnalyticsKey(selectedAvatar);

    void Analytics.trackEvent(
      Analytics.profile.registrationAvatarSkip(avatarAnalyticsKey, MiscUtils.getAuthSource(authProvider))
    );
    handleSubmit(onSubmit);
  };
  const fetchCheckUsername = useCallback(async () => {
    const { result } = await UserService.checkUserName({ name });

    if (result) {
      if (!user) {
        void fetchUserRegister();
      } else {
        try {
          await UserService.userUpdate({ name, avatar: selectedAvatar });
          await UserService.userLoad();
          clearCaptchaData();
          onSignInUpFinished();
        } catch (err) {
          dispatch(
            setSnackbarData({
              isOpened: true,
              message: UserService.errorCodeToText(err),
              type: 'error',
              parentNode: 'rightSideMenu'
            })
          );
        }
      }
    } else {
      setLoading(false);
      setError('name', {
        type: 'manual',
        message: t('CHECK_USERNAME_ERROR')
      });
    }
  }, [onSignInUpFinished, captchaToken]);
  const fetchUserRegister = async () => {
    try {
      const result = await UserService.userRegister({
        email,
        password,
        emailRetrievalConsent: checkBox,
        user: {
          name,
          avatar: selectedAvatar
        },
        registrationPlaceUrl: new URL(UserService.generateHostUrl()),
        captchaToken,
        captchaMode: showChallengeRecaptcha ? RECAPTCHA_MODES.CHALLENGE : undefined
      });

      handleUserRegisterSuccess(result);
    } catch (err) {
      handleUserRegisterError(err);
    }
  };
  const handleUserRegisterSuccess = (uid: string) => {
    const avatarAnalyticsKey = getAvatarAnalyticsKey(selectedAvatar);

    LocalStorageService.setItem('authProvider', AuthType.EmailPassword);
    const { avatarBackground } = authDataContext.value;

    LeanplumAnalytics.trackEvent(LEANPLUM_EVENTS.REGISTRATION);
    // To have LP userAttribute {registered: true} set here we don't have email confirmed,
    // final Eagle user id, and LP session started with this id to set it in LP to correct user
    void Analytics.trackEvent(
      Analytics.profile.registration(
        categoryPageName,
        game,
        RegistrationSource.EMAIL,
        avatarAnalyticsKey,
        avatarBackground,
        socialRegistrationLocation,
        uid
      )
    );

    void Analytics.trackEvent(Analytics.profile.createAccountSuccess());

    clearCaptchaData();
    dispatch(setSignInUpState(SignInUpComponents.AFTER_SIGN));
  };
  const handleUserRegisterError = (err: number) => {
    dispatch(
      setSnackbarData({
        isOpened: true,
        message: UserService.errorCodeToText(err),
        type: 'error',
        parentNode: 'rightSideMenu'
      })
    );
    dispatch(setShowRecaptcha(err === 1023));
  };
  const onSubmit = async () => {
    setLoading(true);
    const isNotProfanity = await ProfanityUtils.checkWord(name);

    // if the username is profanity, then we show the error
    if (!isNotProfanity) {
      setLoading(false);
      dispatch(
        setSnackbarData({
          isOpened: true,
          message: TranslationService.translateIt('SORRY_THAT_SCREEN_NAME_IS_NOT_AVAILABLE'),
          type: 'error',
          parentNode: 'rightSideMenu'
        })
      );
    }

    // if the username is not profanity, then we check it for existence
    if (isNotProfanity) {
      getCaptchaToken(RECAPTCHA_ACTIONS.SIGN_UP);
    }
  };
  const moreBtnClickHandler = useCallback(async () => {
    const avatarAnalyticsKey = getAvatarAnalyticsKey(selectedAvatar);

    void Analytics.trackEvent(
      Analytics.profile.registrationMoreAvatars(avatarAnalyticsKey, MiscUtils.getAuthSource(authProvider))
    );
    const { result } = await UserService.checkUserName({ name });

    if (result) {
      authDataContext.setAuthData({ ...authDataContext.value, name });
      dispatch(
        setProfilePanelState({
          isOpened: true,
          caption: TranslationService.translateIt('CHOOSE_YOUR_AVATAR'),
          target: ProfileRightSideRoutes.AVATARS_PANEL
        })
      );
      onSignInUpFinished();
    } else {
      setError('name', {
        type: 'manual',
        message: t('CHECK_USERNAME_ERROR')
      });
    }
  }, [onSignInUpFinished, name]);
  const onGenerateNameClick = async () => {
    if (!loading) {
      setLoading(true);
      const name = await UserService.generateUserName();

      setValue('name', name);
      setLoading(false);
    }
  };

  return (
    <>
      {!showChallengeRecaptcha && (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.tabContent}>
          <div className={styles.header}>
            <I18nText keyName="PERSONALIZE_YOUR_PROFILE" />
          </div>
          <div className={styles.inputWrapper}>
            <Input
              className={styles.input}
              isValid={!errors.name}
              ariaInvalid={errors.name ? 'true' : 'false'}
              register={register}
              name="name"
              type="text"
              placeholder={t('CREATE_YOUR_SCREEN_NAME')}
              value={name}
              showError={errors?.name}
              errorMessage={errors?.name?.message ?? ''}
            />
          </div>
          <Button
            isLink
            className={styles.generateName}
            onClick={onGenerateNameClick}
            type="button"
          >
            <I18nText keyName="GENERATE_SCREEN_NAME" as="span" />
            <RepeatIcon className={styles.repeatIcon} />
          </Button>
          <ChooseAvatar
            selectedAvatar={selectedAvatar}
            setSelectedAvatar={setSelectedAvatar}
            moreBtnClickHandler={moreBtnClickHandler}
          />

          <Button type="submit" disabled={loading} className={styles.submitButton} loading={loading}>
            <I18nText keyName="SAVE_CHANGES" as="span" />
          </Button>
          <TextWithLink
            className={styles.textWithLink}
            textKeyName="Do you want to finish later? "
            linkKeyName=" Skip this step"
            onClick={handleSkip}
          />
        </form>
      )}
      <div className={styles.tabContent}>
        <CaptchaIframe
          iframeRef={iframeRef}
          iframeURL={iframeURL}
          setIsIframeCaptchaLoaded={setIsIframeCaptchaLoaded}
        />
      </div>
    </>
  );
});
