import React from 'react';

import { ExclamationIcon } from '../../atoms/Icons/Styleguide/ExclamationIcon';
import { TranslationService } from '../../services/TranslationService';
import styles from './NotifyMessage.css';

export const NotifyMessage = () => {
    return (
        <div className={styles.notifyMessage}>
            <ExclamationIcon />
            <p className={styles.message}>
                {TranslationService.translateIt('YOU_NEED_MORE_GEMS_TO_COMPLETE_THIS_ACTION')}
            </p>
        </div>
    );
};
