import React from 'react';

import classNames from 'classnames';

import styles from './Divider.css';
import { I18nText } from '../../atoms/i18nText/i18nText';

type DividerProps = {
  className?: string;
  t?: any;
};

export const Divider = React.memo((props: DividerProps) => {
  return (
    <div className={classNames(styles.divider, props.className)}>
      <div className={classNames(styles.dividerLine, styles.left)}/>
      {props.t ? <span>{props.t('OR')}</span> : <I18nText keyName={'OR'} as={'span'}/>}
      <div className={classNames(styles.dividerLine, styles.right)}/>
    </div>
  );
});
