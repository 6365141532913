import React, { LegacyRef, useEffect } from 'react';

import classNames from 'classnames';

import styles from './CaptchaIframe.css';
import useCaptcha from './hooks/useCaptcha';
import { useScript } from '../../../hooks/useScript';
import { environment } from '../../config/environment';


type TCaptchaIframeProps = {
  iframeRef: LegacyRef<HTMLIFrameElement>;
  iframeURL: string;
  setIsIframeCaptchaLoaded: (value: boolean) => void
};

const CaptchaIframe = ({
  iframeRef,
  iframeURL,
  setIsIframeCaptchaLoaded
}: TCaptchaIframeProps) => {
  useScript(`https://www.google.com/recaptcha/enterprise.js?render=${environment.RECAPTCHAAPPKEY}`);
  const {
    showChallengeRecaptcha
  } = useCaptcha();

  useEffect(() => {
    return () => {
      const elements = document.getElementsByClassName('grecaptcha-badge');

      while (elements.length > 0) {
        const element = elements[0];

        element.parentNode.removeChild(element);
      }
    };
  }, []);

  return (
    <div
      className={classNames({
        [styles.contentWrapper]: showChallengeRecaptcha,
        [styles.invisibleCaptcha]: !showChallengeRecaptcha
      })}
    >
      <div className="modal-content">
        <h2><strong>Verify you're human</strong></h2>
        <iframe
          ref={iframeRef}
          onLoad={() => setIsIframeCaptchaLoaded(true)}
          src={iframeURL}
          title="Recaptcha for Eagle"
          className={styles.recaptchaIframe}
        />
      </div>
    </div>
  );
};

export default CaptchaIframe;
