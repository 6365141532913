import { createAction, createReducer } from 'redux-act';

import { DIALOG_CONTENT_TYPES } from '../../../models/Enums';

type DialogProps = {
    open: boolean;
    type: DIALOG_CONTENT_TYPES | null;
};

const initialData = {
    open: false,
    type: null,
};

export const setDialogData = createAction<DialogProps>('set dialog data');
export const dialogDataReducer = createReducer<DialogProps>({}, initialData);
dialogDataReducer.on(setDialogData, (_state, payload) => payload);
