import { environment } from '../client/config/environment';

export const OverflowHiddenBodyClassname = 'overflowHidden';

export const getOriginUrlForCaptchaIframe = (): string => {
  switch (window.location.host) {
    case 'localhost:4200':
      return 'http://localhost:4200/iframe-captcha.html';
    case 'www-dev.arkadium.com:4200':
      return 'http://www-dev.arkadium.com:4200/iframe-captcha.html';
    case 'www-dev2.arkadium.com:4200':
      return 'http://www-dev2.arkadium.com:4200/iframe-captcha.html';
    case 'www-dev3.arkadium.com:4200':
      return 'http://www-dev3.arkadium.com:4200/iframe-captcha.html';
    default:
      return environment.CDN_CAPTCHA_IFRAME;
  }
};

export const setBodyOverflowHidden = () => {
  document.body.classList.add(OverflowHiddenBodyClassname);
};

export const setBodyOverflowAuto = () => {
  document.body.classList.remove(OverflowHiddenBodyClassname);
};
