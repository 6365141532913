import { AnalyticsEvent, AnalyticsEventAction, AnalyticsEventBuilder } from '../AnalyticsEventBuilder';

export class BlogAnalyticsAi {
    // used for both blog archive and blog post analytics
    async BlogtCTA(CTAtag: string): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName(CTAtag);
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        return event.build();
    }


    private async newEventBuilder(): Promise<AnalyticsEventBuilder> {
        return await new AnalyticsEventBuilder().baseEvent();
    }
}
