import { Api, ApiGateway, ApiGatewayInitParams, ApiSet } from '@arkadium/eagle-virtual-items-api-client';
import {
  UserInventoryDto,
  UserInventoryUpdateRequestDto
} from '@arkadium/eagle-virtual-items-api-client/dist/types/api/v1/dto/inventory-item';
import {
  PurchasableItemDto,
  PurchasableItemPurchaseItemRequestDto
} from '@arkadium/eagle-virtual-items-api-client/dist/types/api/v1/dto/purchasable-item';
import {
  UserVirtualCurrencyTransactionListDto,
  VirtualCurrencyDto
} from '@arkadium/eagle-virtual-items-api-client/dist/types/api/v1/dto/virtual-currency';

import { LeanplumAnalytics } from './Analytics/LeanplumAnalytics';
import { LocalStorageService } from './LocalStorage';
import UserService from './UserService';
import { MiscUtils } from '../../utils/MiscUtils';
import { environment } from '../config/environment';
import { GEMS_TEST_SKU } from '../constants/GemsConstants';
import { LOCALSTORAGE_ITEMS } from '../models/Enums';

interface GemsServiceInterface {
  gemsApiService: ApiGateway;
  getUserVirtualCurrenciesList: () => Promise<VirtualCurrencyDto[]>;
  getUserGemsAmount: () => Promise<number>;
  getPurchasableItemsList: (gameArena5Slug: string) => Promise<PurchasableItemDto[]>;
  getPurchasableItemsBySkuList: (purchasableItemsSkuList: string[]) => Promise<PurchasableItemDto[]>;
  getPurchasableItemsBySku: (purchasableItemsSku: string) => Promise<PurchasableItemDto>;
  purchaseItem: (
    items: PurchasableItemPurchaseItemRequestDto[],
    currency: string,
    amount: number,
    gameArena5Slug: string
  ) => Promise<void>;
  getVirtualCurrencyTransactionHistory: (
    rowsNumberToSkip: number,
    rowsNumberToTake: number
  ) => Promise<UserVirtualCurrencyTransactionListDto>;
  consumeUserInventory: (updateRequest: UserInventoryUpdateRequestDto) => Promise<UserInventoryDto[]>;
}

class GemsServiceClass implements GemsServiceInterface {
  public gemsApiService: ApiGateway;

  constructor() {
    if (!MiscUtils.isServer) {
      this.initAPI();
    }
  }

  /**
   * Get list of currencies current user has
   * @return virtualCurrenciesList - list of currencies
   */
  public getUserVirtualCurrenciesList = async () => {
    const api: ApiSet = await this.gemsApiService.getApi(Api.v1);

    return api.virtualCurrency.getVirtualCurrency();
  };

  /**
   * Get balance of user's current currency
   * @return amount - amount of current virtual currency
   */
  public getUserGemsAmount = async () => {
    const api: ApiSet = await this.gemsApiService.getApi(Api.v1);
    const virtualCurrencyState = await api.virtualCurrency.getVirtualCurrencyBySku(GEMS_TEST_SKU);
    const gemsAmount = virtualCurrencyState.amount;

    LeanplumAnalytics.setUserAttributesCustom({ userInventory: { [environment.SKU_GEMS_NAME]: gemsAmount } });
    return gemsAmount;
  };

  /**
   * Get list of all available purchasable items for current game
   * @param gameArena5Slug - ID of game to filter available items
   * @return purchasableItemsList - list of purchasable items
   */
  public getPurchasableItemsList = async (gameArena5Slug: string) => {
    const api: ApiSet = await this.gemsApiService.getApi(Api.v1);

    return api.purchasableItems.getPurchasableItemsByGame({
      gameKeyFilter: gameArena5Slug
    });
  };

  /**
   * Get information about purchasable items specified as parameters
   * @param purchasableItemsSkuList - array of purchasable items sku's
   * @param purchasableItemsSkuList - array of objects storing information about purchasable items
   */
  public getPurchasableItemsBySkuList = async (purchasableItemsSkuList: string[]) => {
    const api: ApiSet = await this.gemsApiService.getApi(Api.v1);

    // as test SKU use this: "eagle_test_for_games1"
    return api.purchasableItems.getPurchasableItemsBySkuList({
      skuList: purchasableItemsSkuList
    });
  };

  /**
   * Get information about one purchasable item
   * @param purchasableItemsSku - purchasable items sku
   * @param purchasableItemsSku - array of objects storing information about purchasable items
   */
  public getPurchasableItemsBySku = async (purchasableItemsSku: string) => {
    const api: ApiSet = await this.gemsApiService.getApi(Api.v1);

    return api.purchasableItems.getPurchasableItem({ sku: purchasableItemsSku });
  };

  /**
   * Purchase items
   * @param items - list of items to purchase
   * @param currency - ID of virtual currency
   * @param amount - amount of virtual currency
   * @param gameArena5Slug - ID of game to filter available items
   */
  public purchaseItem = async (
    items: PurchasableItemPurchaseItemRequestDto[],
    currency: string,
    amount: number,
    gameArena5Slug: string
  ) => {
    const api: ApiSet = await this.gemsApiService.getApi(Api.v1);
    const timeOffset = new Date().getTimezoneOffset();

    return api.purchasableItems.purchaseItem({
      items,
      currency,
      amount,
      gameKey: gameArena5Slug,
      // @ts-ignore
      application: environment.ARENA_DOMAIN,
      timeOffset
    });
  };

  /**
   * Get history of user's virtual currency transactions
   * @param rowsNumberToSkip - number of rows to skip
   * @param rowsNumberToTake - number of rows to take
   * @return history - history object
   */
  public getVirtualCurrencyTransactionHistory = async (rowsNumberToSkip: number, rowsNumberToTake: number) => {
    const api: ApiSet = await this.gemsApiService.getApi(Api.v1);

    return api.virtualCurrency.getVirtualCurrencyTransactions({
      sku: GEMS_TEST_SKU,
      skip: rowsNumberToSkip,
      take: rowsNumberToTake
    });
  };

  /**
   * Consume user inventory item
   * @param updateRequest - contains items which contains sku and amount
   * @return status of consuming
   */
  public consumeUserInventory = async (updateRequest: UserInventoryUpdateRequestDto) => {
    const api: ApiSet = await this.gemsApiService.getApi(Api.v1);
    const consume = await api.userInventory.consumeUserInventory(updateRequest);

    LeanplumAnalytics.setUserAttributesCustom({ virtualItemSpent: true });
    return consume;
  };

  /**
   * Initialize top level API
   * @private
   */
  private initAPI() {
    const params: ApiGatewayInitParams = {
      server: new URL(
        environment.EAGLE_API_COLLECTIONS_URL ||
        'https://eagle-virtual-item-api.uup-aks-dev.arkadiumhosted.com/'
      ),
      // to fix npm tests when old build error (circular dependencies) reaches us in UT
      sessionStorage: UserService?.getSessionStorage?.() || {}
    };

    this.gemsApiService = new ApiGateway(params);
  }
}

const GemsService = new GemsServiceClass();

export default GemsService;

export async function gemsGetCurrentAmount(): Promise<any> {
  if (UserService.isUserLoggedIn()) {
    return { [GEMS_TEST_SKU]: await GemsService.getUserGemsAmount() };
  }

  // ToDo: add another virtualItems to returned inventory if added
  return null;
}

export async function gemsIsFirstPurchase(): Promise<boolean | null> {
  if (UserService.isUserLoggedIn()) {
    const transactions = await GemsService.getVirtualCurrencyTransactionHistory(0, 1);
    const isNotFirst = Boolean(transactions?.total ?? 0 >= 1);

    return !isNotFirst; // assume first transaction is gems purchase to spend them
  }

  return null;
}

const LS_AI_VIRTUAL_ITEM_BUY_PROP = 'LS_AI_VIRTUAL_ITEM_BUY_PROP';

export async function checkAiIsVirtualItemBuy(): Promise<boolean | null> {
  if (UserService.isUserLoggedIn()) {
    const transactions = await GemsService.getVirtualCurrencyTransactionHistory(0, 1);

    return Boolean(transactions?.total && transactions.total >= 1);
  }

  return false;
}


export async function setAiIsVirtualItemBuy(): Promise<void> {
  const virtualItemBuy = await checkAiIsVirtualItemBuy();

  if (Boolean(virtualItemBuy)) {
    LocalStorageService.setItem(LS_AI_VIRTUAL_ITEM_BUY_PROP, true);
  } else {
    LocalStorageService.removeItem(LS_AI_VIRTUAL_ITEM_BUY_PROP);
  }
}

export function getAiIsVirtualItemBuy(): boolean {
  return Boolean(LocalStorageService.getItem(LS_AI_VIRTUAL_ITEM_BUY_PROP));
}

export async function setAiIsVirtualItemSpent(): Promise<void> {
  const spent = (await gemsVirtualItemsSpent()) > 0;

  if (spent) {
    LocalStorageService.setItem(LOCALSTORAGE_ITEMS.VIRTUAL_ITEM_SPENT, true);
  } else {
    LocalStorageService.removeItem(LOCALSTORAGE_ITEMS.VIRTUAL_ITEM_SPENT);
  }
}

export async function gemsVirtualItemsSpent(): Promise<number | null> {
  if (UserService.isUserLoggedIn()) {
    const transactions = await GemsService.getVirtualCurrencyTransactionHistory(0, 20); // 20 enough

    return transactions?.items.filter((transaction) => transaction.amount < 0).length; // minus gems transactions
  }

  return null;
}

export function getGemsVirtualItemsSpentFromLocalStorage() {
  const spent = LocalStorageService.getItem(LOCALSTORAGE_ITEMS.VIRTUAL_ITEM_SPENT);

  return spent === 'true';
}

export function getUserInventory() {
  const userInventory = { [environment.SKU_GEMS_NAME]: 0 };

  if (window !== undefined) {
    const StoreState = (window as any)?.STORE?.getState?.();
    const gemsBalance = StoreState?.gemsAmount;

    if (gemsBalance) {
      userInventory[environment.SKU_GEMS_NAME] = gemsBalance;
    }
  }

  return userInventory;
}
