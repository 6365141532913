export const associativeSearchResults = {
    scrabble: ['Outspell'],
    boggle: ['Word Wipe', 'Wander Words'],
    'word zen': ['Scramble Words', 'Wander Words'],
    'candy crush': ['Sweet Shuffle', 'Jewel Shuffle'],
    'cookie jam': ['Sweet Shuffle', 'Jewel Shuffle'],
    gardenscapes: ['Sweet Shuffle', 'Jewel Shuffle'],
    homescapes: ['Sweet Shuffle', 'Jewel Shuffle'],
    bejeweled: ['Sweet Shuffle', 'Jewel Shuffle'],
    billiards: ['8 Ball Pool', '9 Ball Pool'],
    snooker: ['8 Ball Pool', '9 Ball Pool'],
    tetris: ['Block Champ', 'Block Champ Arena', '10x10'],
    '10x10': ['10x10', 'Block Champ', 'Block Champ Arena'],
    'brick breaker': ['Ballistic'],
    'bricks and balls': ['Ballistic'],
    'triple town': ['Trizzle'],
    chuzzle: ['Trizzle'],
    dots: ['Lumeno'],
    'two dots': ['Lumeno'],
    'jelly splash': ['Lumeno'],
    kenken: ['Mathdoku'],
    hearts: ['Bridge', 'Advanced Bridge'],
    spades: ['Bridge', 'Advanced Bridge'],
    'puzzle bobble': ['Bubble Shooter'],
    'bust a move': ['Bubble Shooter'],
    wordscapes: ['Scramble Words', 'Wander Words'],
    'bubble witch': [`Arkadium's Bubble Shooter`],
    patience: ['Klondike Solitaire', 'Spider Solitaire'],
    'code word': [`Arkadium's Codeword`],
    'tetris 99': ['Block Champ Arena'],
    yahtzee: ['5 Roll'],
};
