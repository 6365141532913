import React from 'react';

type iconProps = {
    className?: string;
};

export const CommentsIcon = React.memo((props: iconProps) => {
    return (
        <svg
            className={props.className}
            width="30"
            height="30"
            aria-label="Comments"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M27 15C27 16.6228 26.6784 18.168 26.0964 19.5772C25.8427 20.1915 25.8603 20.8911 25.9202 21.4649C25.9707 21.9481 26.0689 22.4723 26.1581 22.9481C26.1781 23.0546 26.1976 23.1587 26.216 23.2593C26.3235 23.8467 26.3976 24.3342 26.3951 24.7277C26.3925 25.1368 26.3063 25.2453 26.2955 25.2567C26.2525 25.302 26.0779 25.418 25.5878 25.4902C25.1263 25.5583 24.5551 25.5653 23.9005 25.5546C23.7496 25.5521 23.5931 25.5487 23.4336 25.5451C22.9355 25.5341 22.4081 25.5224 21.9307 25.5385C21.3193 25.5592 20.6164 25.6262 20.0458 25.891C18.5131 26.6024 16.8044 27 15 27C8.37258 27 3 21.6274 3 15C3 8.37257 8.37258 2.99998 15 2.99998C21.6274 2.99998 27 8.37257 27 15Z"
                stroke="#231F20"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                aria-label="Comments"
            />
        </svg>
    );
});
