import { PaymentMethod } from '../../../models/Subscription/PaymentForm';
import { SubscriptionSourceType } from '../../../models/Subscription/SubscriptionData';
import { AnalyticsEvent, AnalyticsEventAction, AnalyticsEventBuilder } from '../AnalyticsEventBuilder';
import { Analytics } from '../Analytics';
import { environment } from '../../../config/environment';
import { IGame } from '../../../models/Game/Game';

export class SubscriptionAnalyticsGa {
    private newEventBuilder(): AnalyticsEventBuilder {
        return new AnalyticsEventBuilder();
    }

    entryPointImpression(location: SubscriptionSourceType): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('Subscription');
        event.setEventGaAction(AnalyticsEventAction.IMPRESSION);
        event.setEventGaLabel(location);
        event.setGaNonInteraction(true);
        return event.build();
    }

    entryPointClick(location: SubscriptionSourceType, type: string): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('Subscription');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel([type, location].join(' | '));
        event.setGaNonInteraction(false);
        return event.build();
    }

    subscriptionNextStep(location: SubscriptionSourceType, type: string, payment: PaymentMethod): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('subscriptionNextStep');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel([type, location, payment].join(' | '));
        event.setGaNonInteraction(false);
        return event.build();
    }

    subscriptionPurchaseButton(location: SubscriptionSourceType, type: string, payment: PaymentMethod): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('subscriptionPurchaseButton');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel([type, location, payment].join(' | '));
        event.setGaNonInteraction(false);
        return event.build();
    }

    subscriptionPurchase(location: SubscriptionSourceType, type: string, payment: PaymentMethod, price?: any, gameSlug?: string | boolean): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setNameGA('SubscriptionPurchase');
        gameSlug && event.setCustom('event_category', gameSlug);
        event.setCustom('event_label', Analytics.generateLabelForGA4(environment.ARENA_DOMAIN, location));
        if (typeof price === 'number' || price) {
            event.setCustom('event_value', price);
        }
        event.setGaNonInteraction(false);
        return event.build();
    }

    manageSubscription(type: string): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('manageSubscription');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel(type);
        event.setGaNonInteraction(false);
        return event.build();
    }

    renewSubscription(type: string): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('renewSubscription');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel(type);
        event.setGaNonInteraction(false);
        return event.build();
    }

    cancelAutorenewal(type: string): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('cancelAutorenewal');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel(type);
        event.setGaNonInteraction(false);
        return event.build();
    }

    subscriptionUpgrade(type: string): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('subscriptionUpgrade');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel(type);
        event.setGaNonInteraction(false);
        return event.build();
    }

    subscriptionDowngrade(type: string): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('subscriptionDowngrade');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel(type);
        event.setGaNonInteraction(false);
        return event.build();
    }

    giftStart(location: SubscriptionSourceType, type: string, payment: PaymentMethod): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('giftStart');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel([type, location, payment].join(' | '));
        event.setGaNonInteraction(false);
        return event.build();
    }

    giftNextStep(location: SubscriptionSourceType, type: string, payment: PaymentMethod): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('giftNextStep');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel([type, location, payment].join(' | '));
        event.setGaNonInteraction(false);
        return event.build();
    }

    giftPurchaseButton(location: SubscriptionSourceType, type: string, payment: PaymentMethod): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('giftPurchaseButton');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel([type, location, payment].join(' | '));
        event.setGaNonInteraction(false);
        return event.build();
    }

    giftEmailCheck(location: SubscriptionSourceType, type: string, payment: PaymentMethod): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('giftEmailCheck');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel([type, location, payment].join(' | '));
        event.setGaNonInteraction(false);
        return event.build();
    }

    giftRedeemed(
        location: SubscriptionSourceType,
        type: string,
        payment: PaymentMethod,
        subscriptionId: string,
        newRegister: boolean
    ): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('giftRedeemed');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel([type, location, payment, subscriptionId, newRegister].join(' | '));
        event.setGaNonInteraction(false);
        return event.build();
    }

    giftPaymentFieldOnBlur(name: string, location: SubscriptionSourceType, type: string): AnalyticsEvent {
        const convertToEventName = {
            first_name: 'giftPaymentFirstName',
            last_name: 'giftPaymentLastName',
            address1: 'giftPaymentAddress',
            city: 'giftPaymentCity',
            country: 'giftPaymentCountry',
            postal_code: 'giftPaymentZip',
            state: 'giftPaymentState',
            number: 'giftPaymentCardNumber',
            year: 'giftPaymentExpDate',
            month: 'giftPaymentExpDate',
            cvv: 'giftPaymentSecCode',
        };
        if (!convertToEventName[name]) return null;
        const event = this.newEventBuilder();
        event.setEventGaCategory(convertToEventName[name]);
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel([type, location].join(' | '));
        event.setGaNonInteraction(false);
        return event.build();
    }

    giftPayPal(location: SubscriptionSourceType, type: string): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('giftPayPal');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel([type, location].join(' | '));
        event.setGaNonInteraction(false);
        return event.build();
    }

    gifterPayPalConfirm(): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('gifterPayPalConfirm');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setGaNonInteraction(false);
        return event.build();
    }

    gifterPurchaseButton(location: SubscriptionSourceType, type: string, paymentMethod: string): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('gifterPurchaseButton');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel([type, location, paymentMethod].join(' | '));
        event.setGaNonInteraction(false);
        return event.build();
    }

    gifterPrePurchaseButton(location: SubscriptionSourceType, type: string, paymentMethod: string): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('gifterPrePurchaseButton');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel([type, location, paymentMethod].join(' | '));
        event.setGaNonInteraction(false);
        return event.build();
    }

    removeAdsAdvantage(game: IGame): AnalyticsEvent {
        const event = this.newEventBuilder();

        event.setNameGA('RemoveAds_Advantage');
        event.setCustom('event_category', game.slug);
        event.setCustom('event_label', Analytics.generateLabelForGA4(environment.ARENA_DOMAIN, `/games/${game.slug}`));
        event.setGaNonInteraction(false);
        return event.build();
    }
}
