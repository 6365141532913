import { Api, ApiGateway, ApiGatewayInitParams } from '@arkadium/eagle-payments-api-client';
import { PlanDto } from '@arkadium/eagle-payments-api-client/dist/types/api/v1/dto/plan.dto';
import {
  PurchaseItemDto,
  PurchaseSubscriptionDto
} from '@arkadium/eagle-payments-api-client/dist/types/api/v1/dto/purchase-subscription.dto';
import { RecurlyItemDto } from '@arkadium/eagle-payments-api-client/dist/types/api/v1/dto/recurly-data.dto';
import { UserSubscription } from '@arkadium/eagle-payments-api-client/dist/types/api/v1/dto/subscription.dto';

import UserService from './UserService';
import { globalErrorHandler } from '../../utils/LogUtils';
import { MiscUtils } from '../../utils/MiscUtils';
import { environment } from '../config/environment';


class PaymentService {
  public paymentApiService: ApiGateway;

  constructor() {
    if (!MiscUtils.isServer) {
      this.initAPI();
    }
  }

  public purchaseSubscription = async (email: string, data: PurchaseSubscriptionDto): Promise<string> => {
    const api = await this.paymentApiService.getApi(Api.v1);

    try {
      const result = await api.recurlySubscriptions.purchaseSubscriptionByEmail(email, data);

      return result?.length ? result : false;
    } catch (err) {
      globalErrorHandler({ error: err, filename: 'PaymentService.ts', info: 'purchaseSubscription()' });

      throw Array.isArray(err.body?.details) ? err.body.details[0] : undefined;
    }
  };

  public purchaseGiftCard = async (
    currency: string, // USD
    currencyAmountInCents: number, // gift card cost in cents: 2999
    customMessage: string,
    deliveryDate: string,
    productCode: string, // code for gift card in Recurly constant: test_gift
    recipientEmail: string,
    recipientName: string,
    senderEmail: string,
    senderName: string,
    tokenId: string
  ): Promise<any> => {
    const api = await this.paymentApiService.getApi(Api.v1);

    return api.recurlySubscriptions.purchaseGiftCard({
      currency,
      currencyAmountInCents,
      customMessage,
      deliveryDate,
      productCode,
      recepientEmail: recipientEmail,
      recepientName: recipientName,
      senderEmail,
      senderName,
      tokenId
    });
  };

  public cancelSubscription = async (subscriptionId: string) => {
    const api = await this.paymentApiService.getApi(Api.v1);
    const data = {
      subscriptionId
    };

    return api.recurlySubscriptions.cancelSubscription(data);
  };

  public getPlans = async (): Promise<PlanDto> => {
    const api = await this.paymentApiService.getApi(Api.v1);

    return api.recurlySubscriptions.getPlans();
  };

  public getSubscriptions = async (): Promise<UserSubscription[]> => {
    const api = await this.paymentApiService.getApi(Api.v1);

    return api.generalSubscriptions.getSubscriptions();
  };

  public getLastDateSubscription = (subscriptions: UserSubscription[]) => {
    const sortedSubscription = subscriptions.sort(function (a, b) {
      return (new Date(b.endDate) as any) - (new Date(a.endDate) as any);
    });

    return sortedSubscription[0];
  };

  public getRecurlyData = async (): Promise<{ hostedLoginToken: string }> => {
    const api = await this.paymentApiService.getApi(Api.v1);

    return api.recurlySubscriptions.getRecurlyData();
  };

  public getExpiredSubscriptions = async () => {
    const api = await this.paymentApiService.getApi(Api.v1);
    const expiredSubscriptions = await api.generalSubscriptions.getExpiredSubscriptions();

    return expiredSubscriptions as UserSubscription[];
  };

  public getPurchasableItems = async (purchasableItemsSkuList: string[]): Promise<RecurlyItemDto[]> => {
    const api = await this.paymentApiService.getApi(Api.v1);

    return api.recurlySubscriptions.getPurchasableItems({ ids: purchasableItemsSkuList });
  };

  public purchaseItem = async (data: PurchaseItemDto): Promise<void> => {
    const api = await this.paymentApiService.getApi(Api.v1);

    try {
      await api.recurlySubscriptions.purchaseItem(data);
    } catch (err) {
      globalErrorHandler({ error: err, filename: 'PaymentService.ts', info: 'purchaseItem()' });
      throw err.body.details[0];
    }
  };

  private initAPI() {
    const params: ApiGatewayInitParams = {
      // Base url of Eagle API
      server: new URL(
        environment.EAGLE_API_PAYMENT || 'https://eagle-payment-api.uup-aks-dev.arkadiumhosted.com'
      ),
      sessionStorage: UserService?.getSessionStorage?.() ?? {}
    };

    this.paymentApiService = new ApiGateway(params);
  }
}

export default new PaymentService();
