import { AnyAction, Dispatch, Middleware } from 'redux';

import { GameEvents } from '../models/Game/GameObservable';
import { setActiveGameView } from './ducks/games';

export const gameEventMiddleware: Middleware<{}, any, Dispatch<AnyAction>> =
    ({ getState }) =>
    (next) =>
    (action) => {
        const { gameObservable } = getState();

        if (action.type === setActiveGameView.getType()) {
            switch (action.payload.activeTab) {
                case 'game':
                    gameObservable?.dispatch({ type: GameEvents.GAME_RESUME });
                    break;
                case 'leaderboard':
                    gameObservable?.dispatch({ type: GameEvents.GAME_PAUSE });
            }
        }
        next(action);
    };
