import { createAction, createReducer } from 'redux-act';

import { MiscUtils } from '../../../../utils/MiscUtils';
import { LocalStorageService } from '../../../services/LocalStorage';

export enum GemsAnalyticsShopLocations {
  PAGE_DIRECTLY_OPENED = 'gems purchase page was directly opened with url', // initial value
  PREROLL = 'preroll skip', // when tried to skip ad but had no gems and offered to buy
  COMMON_HEADER_ICON = 'common header icon', // button in header
  AVATAR_HEADER_ICON = 'avatar header icon', // button near avatar
  GAME = 'game event', // gems buy offer in game
  URL_SEARCH_PARAM = 'url search param', // marketing url
  PROFILE = 'profile purchases', // only for manage gems tracking
}

export const LS_SHOP_LOCATION_PROP = 'lastGemsAnalyticsShopLocation';

const storageSaveGemsAnalyticsShopLocations = (shopLocation: GemsAnalyticsShopLocations): void => {
  if (!MiscUtils.isServer) {
    LocalStorageService.setItem(LS_SHOP_LOCATION_PROP, shopLocation);
  }
};

export enum GemsAnalyticsRegistrationLocations {
  SHOP = 'Shop',
  HEADER_BUTTON = 'Header Button',
  SIDEMENU_BUTTON = 'Sidemenu Button',
  PURCHASE_PAGE = 'Purchase page',
  PROFILE_PAGE = 'Profile page',
  COLLECTIONS = 'Collections',
  GAME_END = 'Game end',
}

export const setGemsShopLocation = createAction<GemsAnalyticsShopLocations>('last clicked gems shop opener');
export const gemsShopLocationReducer = createReducer<GemsAnalyticsShopLocations>(
  {},
  GemsAnalyticsShopLocations.PAGE_DIRECTLY_OPENED
);
gemsShopLocationReducer.on(setGemsShopLocation, (_state, payload) => {
  storageSaveGemsAnalyticsShopLocations(payload);
  return payload;
});

export const setSocialRegistrationLocation = createAction<GemsAnalyticsRegistrationLocations>(
  'last clicked registration source'
);
export const socialRegistrationLocationReducer = createReducer<GemsAnalyticsRegistrationLocations>(
  {},
  GemsAnalyticsRegistrationLocations.HEADER_BUTTON
);
socialRegistrationLocationReducer.on(setSocialRegistrationLocation, (_state, payload) => payload);
