import { AnalyticsEvent, AnalyticsEventAction, AnalyticsEventBuilder } from '../AnalyticsEventBuilder';
import { RegistrationSource } from '../../../models/User/RegistrationSource';

export class CategoriesAnalyticsAi {
    async categoryLeftNavigationClick(category: string): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('CatNav');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        event.setCustom('category', category);
        return event.build();
    }

    async claimDailyEntry(source?: RegistrationSource): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('claimDailyEntry');
        event.setEventAction(AnalyticsEventAction.CLICK);
        if (source) event.setCustom('loginRegisterType', source);
        event.setNonInteraction(false);
        return event.build();
    }

    async seeAllCategoryClick(category: string): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('seeAllCategoryCTA');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setCustom('category', category);
        event.setNonInteraction(false);
        return event.build();
    }

    async seeAllBannerClick(): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('seeAllCTA');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        return event.build();
    }

    private async newEventBuilder(): Promise<AnalyticsEventBuilder> {
        return await new AnalyticsEventBuilder().baseEvent();
    }
}
