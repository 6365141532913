import React from 'react';

import { iconProps } from '../../models/Icon/IconPropsModel';

export const TickIcon = (props: iconProps) => {
    return (
        <svg
            className={props.className}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17 31C19.5577 31 21.9247 30.3606 24.101 29.0817C26.2099 27.8702 27.8702 26.2099 29.0817 24.101C30.3606 21.9247 31 19.5577 31 17C31 14.4423 30.3606 12.0753 29.0817 9.89904C27.8702 7.79006 26.2099 6.12981 24.101 4.91827C21.9247 3.63942 19.5577 3 17 3C14.4647 3 12.109 3.63942 9.93269 4.91827C7.82372 6.15224 6.15224 7.82372 4.91827 9.93269C3.63942 12.109 3 14.4647 3 17C3 19.5353 3.63942 21.891 4.91827 24.0673C6.12981 26.1763 7.79006 27.8478 9.89904 29.0817C12.0753 30.3606 14.4423 31 17 31Z"
                fill="#F9F9F9"
            />
            <path
                d="M11 15.8728L16.325 21L23 13"
                stroke="#039164"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
