import React, { FC, memo } from 'react';

import { Helmet } from 'react-helmet-async';

import { IGame } from '../../models/Game/Game';
import { UrlService } from '../../services/UrlService';

interface IStructuredDataGame {
    game: IGame;
    pageUrl: string;
}

export const StructuredDataGame: FC<IStructuredDataGame> = memo(({ game, pageUrl }) => {
    const structuredData = {
        '@context': 'https://schema.org/',
        '@type': 'VideoGame',
        name: `${game.name}`,
        url: UrlService.createURL(UrlService.removeCountryCodeFromUrlBeginning(pageUrl), undefined, false, true),
        playMode: 'SinglePlayer',
        gamePlatform: ['Mobile game', 'Mac game', 'PC game'],
        genre: [''],
        author: 'Arkadium',
        publisher: 'Arkadium',
        inLanguage: ['English'],
        image: `${game.promoTile280x280}`,
        applicationCategory: 'GameApplication',
        operatingSystem: ['Android', 'macOS', 'Windows', 'Unix-like'],
        description: `${game.description.replace(/(<([^>]+)>)/gi, '').replace(/\x22/g, '\x27')},`,
        offers: 0,
    };

    return (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
        </Helmet>
    );
});
