import { environment } from '../client/config/environment';
import { EnvironmentName } from '../client/constants/Environment';
import { MiscUtils } from './MiscUtils';

export const devConsole = (...args): void => {
    const isDevelopmentMode = environment.Name !== EnvironmentName.PRODUCTION;
    if (!MiscUtils.isServer && isDevelopmentMode) {
        console.info('%c  DEV-ENV-LOG:  ', 'background-color: purple; color: white;', '\n', ...args);
    }
};
