import React, { ReactNode } from 'react';

import classNames from 'classnames';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import { useSelector } from 'react-redux';

import { ArrowIcon } from '../../atoms/Icons/Styleguide/ArrowIcon';
import styles from './Dropdown.css';

type DropDownItem = {
    name: {
        en: string;
        fr: string;
    };
};

type DropdownProps = {
    label: ReactNode | string;
    items: DropDownItem[];
    handleSelect: (item: any) => void;
};

export const Dropdown = React.memo(({ label, items, handleSelect }: DropdownProps) => {
    const { buttonProps, itemProps, isOpen } = useDropdownMenu(items.length);
    const currentLang = useSelector((state) => state.currentLang);

    return (
        <div className={styles.dropDownContainer}>
            <button
                {...buttonProps}
                className={classNames(styles.dropDownHandler, isOpen && styles.dropDownHandlerActive)}
            >
                {label}
                <ArrowIcon className={classNames(styles.dropdownArrow, isOpen && styles.arrowUp)} />
            </button>
            <div role="menu" className={classNames(styles.dropDownBlock, isOpen && [styles.visible])}>
                <div className={styles.dropDownItems}>
                    {items.map((item, index) => (
                        <a
                            {...itemProps[index]}
                            className={styles.dropDownItem}
                            key={index}
                            onClick={() => handleSelect(item)}
                        >
                            {item.name?.[currentLang] || item.name?.['en']}
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
});
