import React, { ReactNode } from 'react';

import classNames from 'classnames';

import { I18nText } from '../../../../atoms/i18nText/i18nText';
import styles from '../RightPanelCommonStyles/RightPanelCommonStyles.css';

type RightPanelWrapperProps = {
    headerLabelKey: string;
    children: ReactNode;
    topBlock?: ReactNode;
    fullWidth?: boolean;
};

export const RightPanelWrapper = React.memo(
    ({ children, headerLabelKey, topBlock, fullWidth }: RightPanelWrapperProps) => {
        return (
            <div className={styles.wrapper}>
                <div className={classNames(styles.tabContent, fullWidth && styles.tabContentFullWidth)}>
                    {topBlock}
                    <I18nText as="div" className={styles.header} keyName={headerLabelKey} />
                    {children}
                </div>
            </div>
        );
    }
);
