import React from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';

import GemsShopBanner from '../../../atoms/GemsShopBanner/GemsShopBanner';
import { HeaderSideMenuTabs } from '../../../constants/HeaderSideMenuTabs';
import UserService from '../../../services/UserService';
import { activePageSelector } from '../../../store/ducks/layoutSelectors';
import { GemsShopContainer } from '../../GemsShopContainer/GemsShopContainer';
import styles from './ShopTab.css';

export const ShopTab = React.memo(() => {
    const isLoggedIn = UserService.isUserLoggedIn();
    const isSubscriber = UserService.isUserSubscriber();
    const currentTab = useSelector(activePageSelector);
    const isGemsTabDarkBackground = isLoggedIn && currentTab === HeaderSideMenuTabs.SHOP_TAB;
    const isAdvantageTabDarkBackground = isSubscriber && currentTab === HeaderSideMenuTabs.SUBSCRIPTION_TAB;
    return (
        <div
            className={classNames(styles.tabContainer, {
                [styles.gemsTabDarkBackground]: isGemsTabDarkBackground,
                [styles.advantageTabDarkBackground]: isAdvantageTabDarkBackground,
            })}
            role="region"
            aria-label="shop"
        >
            <GemsShopBanner />
            <GemsShopContainer />
        </div>
    );
});
ShopTab.displayName = 'ShopTab';
