import React from 'react';

import classNames from 'classnames';

import { I18nText } from '../../atoms/i18nText/i18nText';
import { Button } from '../../FigmaStyleguide/Button/Button';
import styles from './TextWithLink.css';

type Props = {
    textKeyName: string;
    linkKeyName: string;
    onClick: (any) => void;
    className?: string;
};

export const TextWithLink = React.memo(({ textKeyName, linkKeyName, onClick, className }: Props) => {
    return (
        <div className={classNames(styles.text, className)}>
            <I18nText keyName={textKeyName} />
            <Button isLink onClick={onClick}>
                <I18nText keyName={linkKeyName} />
            </Button>
        </div>
    );
});
