import { IRequestStats } from '../server/middleware/requestStats';

export function globalErrorHandler({ error, filename, info }: { error: Error, filename: string, info: string | null }) {
  const errorInfo = {
    level: 'Error',
    type: error.name || 'Error',
    message: error.message || 'Unknown error',
    filename: filename || 'Unknown file name',
    info: info || 'Unknown info',
    timestamp: new Date().toISOString(),
    stack: error.stack || 'No stack trace available'
  };

  console.error(JSON.stringify(errorInfo));
}

export function globalLogHandler({ msg, value, filename }: {
  msg: string | IRequestStats,
  value: string,
  filename: string
}) {
  const logInfo = {
    level: 'Info',
    message: msg || 'Unknown message',
    value: value || 'Unknown value',
    filename: filename || 'Unknown file',
    timestamp: new Date().toISOString()
  };

  console.log(JSON.stringify(logInfo));
}

