import {LocalStorageService} from './LocalStorage';
// Add this interface to extend the Window type
declare global {
    interface Window {
        __tcfapi?: (command: string, version: number, callback: (tcData: any, success: boolean) => void) => void;
    }
}
class QuantcastServiceFactory {
    activeProvider: 'inMobi|Quantcast' | null = null;
    isGDPRUser: boolean = true;
    private readonly analyticsEnabled = 'analyticsEnabled';
    readonly gdprCookieConsent = 'gdprCookieConsent';

    async init() {
        if (window.__tcfapi) {
            // if script loaded we have gdpr support and can set activeprovider
            this.isGDPRUser = true;
            this.activeProvider = 'inMobi|Quantcast';
            window.__tcfapi('getTCData', 2, this.onChanges)
            window.__tcfapi('addEventListener', 2, this.onChanges);
        } else {
            // if script failed to load we have no gdpr support
            this.isGDPRUser = false;
            this.activeProvider = null;
            // we should disable analytics because we don't know where user is
            this.setEnableAnalytics(false);
        }
    }

    onChanges = (tcData) => {
        if (tcData.gdprApplies) {
            const consent = tcData.purpose.consents[1] && tcData.vendor.consents[11];

            this.setGdprCookieConsent(consent);
            this.isGDPRUser = true;
            this.setEnableAnalytics(false);
        } else {
            this.setGdprCookieConsent(false);
            this.isGDPRUser = false;
            this.setEnableAnalytics(true); // Enable analytics if GDPR does not apply
        }
    }

    isBlockedByGdprConsent() {
        if (this.isGDPRUser) {
            return !this.getGdprCookieConsent();
        }

        return !this.isAnalyticsEnabled;
    }
    private setGdprCookieConsent(consent: boolean) {
        LocalStorageService.setItem(this.gdprCookieConsent, consent);
    }

    private getGdprCookieConsent = () => {
        const consent = LocalStorageService.getItem(this.gdprCookieConsent) === 'true';

        return consent;
    }

    private setEnableAnalytics (enable: boolean) {
        LocalStorageService.setItem(this.analyticsEnabled, enable);
    }

    public isAnalyticsEnabled = () => {
        return LocalStorageService.getItem(this.analyticsEnabled) === 'true';
    }
}

export const QuantcastService = new QuantcastServiceFactory();
