import { reduxStore } from '../store';

export class TranslationService {
  static translateIt(key: string): string {
    const { texts } = reduxStore.store.getState();

    if (key in texts) {
      return texts[key];
    }

    return key;
  }
}
