import { HttpUtils } from './HttpUtils';
import { StringUtils } from './StringUtils';

export class ProfanityUtils {
    public static async checkWord(word: string) {
        const options = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify({ screenName: StringUtils.trimWhiteSpaces(word) }),
        };
        const { isClean } = await HttpUtils.fetch('/profanity-filter/', options);
        return isClean;
    }
}
