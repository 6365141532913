import { useEffect, useState } from 'react';

export enum ScriptState {
  loading = 'loading',
  idle = 'idle',
  ready = 'ready',
  error = 'error',
}

export const useScript = (url: string) => {
  const [state, setState] = useState<ScriptState>(url ? ScriptState.loading : ScriptState.idle);

  useEffect(() => {
    if (!url) {
      return setState(ScriptState.idle);
    }

    const handleScript = (e: { type: string }) => {
      setState(e.type === 'load' ? ScriptState.ready : ScriptState.error);
    };
    // check if script is already present
    let script = document.querySelector<HTMLScriptElement>(`script[src="${url}"]`);

    if (script) {
      script.addEventListener('load', handleScript);
      script.addEventListener('error', handleScript);
    } else {
      script = document.createElement('script');
      script.type = 'application/javascript';
      script.src = url;
      script.async = true;
      document.body.appendChild(script);
      script.addEventListener('load', handleScript);
      script.addEventListener('error', handleScript);
    }

    return () => {
      script.removeEventListener('load', handleScript);
      script.removeEventListener('error', handleScript);
      script.remove();
    };
  }, [url]);

  return state;
};
