import { Analytics } from './Analytics/Analytics';
import { CookieService } from './CookieService';
import { GameService } from './GameService';
import { LocalStorageService } from './LocalStorage';
import { UrlService } from './UrlService';
import { HttpUtils } from '../../utils/HttpUtils';
import { globalLogHandler } from '../../utils/LogUtils';
import { StringUtils } from '../../utils/StringUtils';
import { environment } from '../config/environment';
import { PageTypes } from '../constants/Pages';
import { LS_COOKIE_CONSTS, QUERY_STRING_CONSTS } from '../models/Enums';
import { IGame } from '../models/Game/Game';
import { reduxStore } from '../store';

enum PushCampaigns {
  GAME = 'Game',
  CATEGORY = 'Category',
  HOME = 'Home',
  NONE = 'None',
}

enum PushCampaignsCookies {
  STARTED_CAMPAIGN = '_p_startedCampaign',
  SELECTED_CAMPAIGN = '_p_selectedCampaign',
  PUSH_STATUS = 'PushSubscriberStatus',
  NEXT_RESTART_DAY = '_p_nextRestartDay',
}

const { store } = reduxStore;

class PushNotificationsService {
  get selectedCampaign() {
    return this.getArkCookie(PushCampaignsCookies.SELECTED_CAMPAIGN);
  }

  get _peq() {
    return (window as any)._peq || [];
  }

  get messageParamToSend() {
    return (window as any).__PUSH_CAMPAIGNS__;
  }

  currentCampaignPage: PushCampaigns = PushCampaigns.NONE;

  startedCampaign: PushCampaigns = this.getArkCookie(
    PushCampaignsCookies.STARTED_CAMPAIGN,
    PushCampaigns.NONE
  ) as PushCampaigns;

  gameName: string;

  categoryName: string;

  init() {
    const startedCampaign = this.getArkCookie(PushCampaignsCookies.STARTED_CAMPAIGN);

    this.shouldWeRestartCampaignCheck();

    if (this.shouldWeSkipCampaign() || startedCampaign === PushCampaigns.GAME) {
      return false;
    }

    this.optIn();

    if (startedCampaign === null && !this.messageParamToSend) {
      return false;
    }

    if (this.getArkCookie(PushCampaignsCookies.PUSH_STATUS) === 'SUBSCRIBED' && this.getCurrentPage() !== '') {
      this.stopCampaign();
      this.startNotificationCampaign();
    }
  }

  stopCampaign() {
    if (this.getCurrentPage() === PushCampaigns.CATEGORY && this.selectedCampaign === PushCampaigns.HOME) {
      this.stopHomeCampaign();
    }

    if (this.getCurrentPage() === PushCampaigns.GAME) {
      if (this.selectedCampaign === PushCampaigns.HOME) {
        this.stopHomeCampaign();
      }

      if (this.selectedCampaign === PushCampaigns.CATEGORY) {
        this.stopCategoryCampaign();
      }
    }
  }

  shouldWeRestartCampaignCheck() {
    const PeStorage = LocalStorageService.getItem(LS_COOKIE_CONSTS.PE_SUB_DATA, true);

    if (
      PeStorage !== null &&
      typeof JSON.parse(PeStorage) !== 'undefined' &&
      typeof JSON.parse(PeStorage).ip !== 'undefined' &&
      typeof JSON.parse(PeStorage).ip.d !== 'undefined'
    ) {
      const PeSubData = JSON.parse(PeStorage);
      const nextRestartDate = this.getArkCookie(PushCampaignsCookies.NEXT_RESTART_DAY);
      const restartAfterDays = nextRestartDate || this.getCampaightLength();
      const daysSinceStart = Math.floor((Date.now() - PeSubData.ip.d) / 1000 / 60 / 60 / 24);

      if (daysSinceStart > restartAfterDays) {
        this.restartCampaign(daysSinceStart);
      }
    }
  }

  getCampaightLength() {
    let restartDays = 125;
    const { message } = (window as any).__PUSH_CAMPAIGNS__;
    const countedDays = message ? Object.keys(message).length * 2 + 1 : 0;

    if (message && countedDays > 1) {
      restartDays = countedDays;
    }

    return restartDays;
  }

  restartCampaign(restartAfterDays) {
    globalLogHandler({ msg: 'Push Campaign Restarted', filename: 'PushNotifications.ts', value: null });
    this.stopCategoryCampaign();
    this.stopHomeCampaign();
    this.stopGameCampaign();
    CookieService.setArkCookie(PushCampaignsCookies.SELECTED_CAMPAIGN, '');
    CookieService.setArkCookie(PushCampaignsCookies.STARTED_CAMPAIGN, '');
    CookieService.setArkCookie(PushCampaignsCookies.NEXT_RESTART_DAY, restartAfterDays + this.getCampaightLength());

    if (this.messageParamToSend && this.getCurrentPage() !== '') {
      this.startNotificationCampaign();
    }
  }

  shouldWeSkipCampaign() {
    switch (this.getCurrentPage()) {
      case PushCampaigns.HOME: {
        return this.selectedCampaign === PushCampaigns.HOME;
      }

      case PushCampaigns.CATEGORY: {
        if (this.selectedCampaign === PushCampaigns.GAME) {
          return true;
        }

        return this.selectedCampaign === PushCampaigns.CATEGORY;


      }

      case PushCampaigns.GAME: {
        return this.selectedCampaign === PushCampaigns.GAME;
      }
    }

    return false;
  }

  optIn() {
    (window as any)._peq = (window as any)._peq || [];
    (window as any)._peq.push([
      'init',
      undefined,
      (res) => {
        switch (res.statuscode) {
          case 1: {
            globalLogHandler({ msg: 'Impression PN', filename: 'PushNotifications.ts', value: null });
            this.psendEvent('Impression', true);
            this.psendEvent('Subscribed');

            if (this.isCampaignAlreadyStarted() !== true) {
              this.startNotificationCampaign();
            }

            break;
          }

          case 2: {
            this.psendEvent('Impression');
            this.psendEvent('Denied');
            break;
          }

          case 3: {
            this.psendEvent('Impression');
            this.psendEvent('Closed');
            break;
          }
        }
      }
    ]);
  }

  isCampaignAlreadyStarted() {
    return this.getArkCookie(PushCampaignsCookies.STARTED_CAMPAIGN) !== '';
  }

  // TODO AFTER REFACTORING: review whether updated code work as expcted;
  getCurrentPage() {
    let page = '';

    switch (UrlService.getPageType()) {
      case 'category page':
        const { categoryPageName } = store.getState();

        if (!StringUtils.equalIgnoreCase(categoryPageName, 'All')) {
          page = PushCampaigns.CATEGORY;
        } else {
          page = PushCampaigns.HOME;
        }

        break;
      case 'game page':
        page = PushCampaigns.GAME;
        break;
      case 'Home page':
        page = PushCampaigns.HOME;
    }

    return page;
  }

  psendEvent(status: string, nonInteration = false) {
    switch (this.getCurrentPage()) {
      case PushCampaigns.GAME: {
        const gameSlug = store.getState().gameArkadiumSlug;
        const game = GameService.findGameByArkadiumSlug(
          GameService.gameModelToGame(store.getState().games),
          gameSlug
        );

        this.sendEvent(status, `${this.gameName}Gamepage`, nonInteration, null, game);
        break;
      }

      case PushCampaigns.CATEGORY: {
        this.sendEvent(status, `${this.categoryName}Category`, nonInteration, this.categoryName, null);
        break;
      }

      case PushCampaigns.HOME: {
        this.sendEvent(status, 'Homepage', nonInteration, 'All games', null);
        break;
      }
    }
  }

  sendEvent(action: string, label: string, nonInteraction: boolean, category?: string, game?: IGame) {
    Analytics.trackEvent(
      Analytics.general.pushNotifications({
        action,
        label,
        nonInteraction,
        category,
        game
      })
    );
  }

  startNotificationCampaign() {
    if (this.messageParamToSend) {
      globalLogHandler({ msg: 'Notification campaign started', filename: 'PushNotifications.ts', value: null });
      (window as any)._peq = (window as any)._peq || [];
      (window as any)._peq.push(['add-to-trigger', this.messageParamToSend]);
      CookieService.setArkCookie(PushCampaignsCookies.SELECTED_CAMPAIGN, this.getCurrentPage());
      CookieService.setArkCookie(PushCampaignsCookies.STARTED_CAMPAIGN, this.getCurrentPage());
    }
  }

  stopCategoryCampaign() {
    globalLogHandler({ msg: 'Category campaign stopped', filename: 'PushNotifications.ts', value: null });
    (window as any)._peq = (window as any)._peq || [];
    (window as any)._peq.push([
      'add-to-trigger',
      {
        campaign_name: 'Category Page Campaign',
        event_name: 'stop_category_campaign'
      }
    ]);
  }

  stopHomeCampaign() {
    globalLogHandler({ msg: 'Home campaign stopped', filename: 'PushNotifications.ts', value: null });
    (window as any)._peq = (window as any)._peq || [];
    (window as any)._peq.push([
      'add-to-trigger',
      {
        campaign_name: 'Home Page Campaign',
        event_name: 'stop_home_campaign'
      }
    ]);
  }

  stopGameCampaign() {
    globalLogHandler({ msg: 'Game campaign stopped', filename: 'PushNotifications.ts', value: null });
    (window as any)._peq = (window as any)._peq || [];
    (window as any)._peq.push([
      'add-to-trigger',
      {
        campaign_name: 'Game Page Campaign',
        event_name: 'stop_campaign'
      }
    ]);
  }

  public async pushInit(pathname) {
    (window as any).__PUSH_CAMPAIGNS__ = await this.getPushJson(pathname);
  }

  // TODO AFTER REFACTORING: review whether updated code work as expcted;
  private async getPushJson(pathname) {
    let type: string;
    let filename: string;

    switch (UrlService.getPageType()) {
      case 'category page':
        if (pathname === `/${UrlService.getPageLocalizedRoute(UrlService.currentLang, PageTypes.Category)}/`) {
          type = 'home';
          filename = 'home';
        } else {
          type = 'category';
          const { categoryPageName } = store.getState();

          filename = categoryPageName?.toLowerCase?.();
          this.categoryName = filename;
        }

        break;
      case 'Home page':
        type = 'home';
        filename = 'home';
        break;
      case 'game page':
        type = 'game';
        filename = store.getState().gameArkadiumSlug;
        this.gameName = filename;
        break;
    }

    if (filename && type) {
      return HttpUtils.fetch(
        `${environment.SITE_BASE_URL}/push-notifications?${QUERY_STRING_CONSTS.FILENAME}=${filename}&${QUERY_STRING_CONSTS.TYPE}=${type}`,
        null,
        true
      );
    }

    return '';
  }

  private getArkCookie(name: string, def: any = ''): string {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }

    return def;
  }
}

export const PushNotifications = new PushNotificationsService();
