import { Api } from '@arkadium/eagle-user-client';

import { IGame } from '../../models/Game/Game';
import UserService from '../../services/UserService';
import { reduxStore } from '../../store';
import { setUserFavoritesList } from '../../store/ducks/favorites/favorites';

const { store } = reduxStore;

class FavoritesService {
    private favoritesApiService = UserService.authApiService;

    /* FOR TESTING */

    public isOn = true;

    /* FUNCTIONALITY */
    public favoritesListSorter = (passedGamesList: IGame[], favoritesGamesList: string[]) => {
        if (!this.isOn) {
            return passedGamesList;
        }
        return favoritesGamesList && favoritesGamesList.length
            ? passedGamesList
                  .map((game: IGame) => {
                      game.isFavorite = favoritesGamesList.includes(game.slug);
                      return game;
                  })
                  .sort((a, b) => (a.isFavorite === b.isFavorite ? 0 : a.isFavorite ? -1 : 1))
            : passedGamesList;
    };

    public favoritesListFilter = (allGamesList: IGame[], favoritesGamesList: string[]) => {
        if (!this.isOn) {
            return [];
        }
        return favoritesGamesList && favoritesGamesList.length
            ? allGamesList
                  .filter((game: IGame) => favoritesGamesList.includes(game.slug))
                  .map((game: IGame) => {
                      game.isFavorite = true;
                      return game;
                  })
            : [];
    };

    public async getFavoritesList(): Promise<string[]> {
        const api = await this.favoritesApiService.getAuthApi(Api.v1);
        const res = await api.userFavoriteGame.getUserFavoriteGames().catch(() => []);
        return this.mapRes(res);
    }

    public async addFavorite(favoriteGameSlug: string) {
        const userId = UserService.getUserFromStore()?.uid;
        if (!userId) {
            return;
        }
        const api = await this.favoritesApiService.getAuthApi(Api.v1);
        // add game to favorites list remotely on Eagle
        const updatedList = await api.userFavoriteGame.addFavoriteGame({ gameKey: favoriteGameSlug });
        // update favorites list from Eagle
        updatedList && store.dispatch(setUserFavoritesList(this.mapRes(updatedList)));
    }
    public async removeFavorite(favoriteGameSlug: string) {
        const userId = UserService.getUserFromStore()?.uid;
        if (!userId) {
            return;
        }
        const api = await this.favoritesApiService.getAuthApi(Api.v1);
        // remove game to favorites list remotely on Eagle
        const updatedList = await api.userFavoriteGame.deleteFavoriteGame(favoriteGameSlug);
        // update favorites list from Eagle
        updatedList && store.dispatch(setUserFavoritesList(this.mapRes(updatedList)));
    }

    public async updateFavorites() {
        const user = UserService.getUserFromStore();
        const userId = user?.uid;
        let favoriteGamesList;
        if (userId) {
            favoriteGamesList = await this.getFavoritesList().catch(() => []);
        } else {
            favoriteGamesList = [];
        }
        store.dispatch(setUserFavoritesList(favoriteGamesList));
    }

    public async isFavorite(gameSlug: string): Promise<boolean> {
        const actualFavoritesList: string[] = await this.getFavoritesList().catch(() => []);
        const favoritesSupported: boolean = !!actualFavoritesList?.length;
        return favoritesSupported ? actualFavoritesList?.includes?.(gameSlug) : undefined;
    }

    public mapRes(res: any): string[] {
        // this is needed because of responce has gameKey (same as game id) but on arena we use slugs (arkadiumSlug, arena5Slug)
        return (
            res &&
            res?.length &&
            res.map((dto) => {
                const gameId = dto.gameKey;
                const game = store?.getState?.()?.games?.find?.((game) => game?.id === gameId);
                const gameAlias = game?.meta?.alias;
                const gameSlug = game?.slug;
                return gameAlias || gameSlug || gameId;
            })
        );
    }

    constructor() {
        this.updateFavorites = this.updateFavorites.bind(this);
    }
}

export const favoritesService = new FavoritesService();
