import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import { useSelector, useDispatch } from 'react-redux';

import { CategoryPromoDialog } from './CategoryPromoDialog/CategoryPromoDialog';
import styles from './Dialog.css';
import { CloseIcon } from '../../FigmaStyleguide/Icons/CloseIcon';
import { DIALOG_CONTENT_TYPES } from '../../models/Enums';
import { setDialogData } from '../../store/ducks/dialog/dialog';

export const DialogWrapper = () => {
  const dialogData = useSelector(({ dialogData }) => dialogData);
  const { open, type } = dialogData;
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setDialogData({ open: false, type: null }));
  };
  const renderContent = (type: DIALOG_CONTENT_TYPES) => {
    switch (type) {
      case DIALOG_CONTENT_TYPES.CATEGORY_PROMO_DIALOG:
        return <CategoryPromoDialog/>;
      default:
        break;
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '10px'
        },
        ['@media (max-width:600px)']: {
          '& .MuiDialog-paper': {
            minHeight: '100%',
            minWidth: '100%',
            margin: 0,
            maxHeight: '100%',
            borderRadius: 0
          }
        }
      }}
    >
      <CloseIcon onClick={handleClose} className={styles.closeIcon}/>
      {renderContent(type)}
    </Dialog>
  );
};
