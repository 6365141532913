import React from 'react';

import { replace } from 'connected-react-router';
import { connect } from 'react-redux';

import { MiscUtils } from '../../../utils/MiscUtils';
import { PageTypes } from '../../constants/Pages';
import { GameState, LS_COOKIE_CONSTS } from '../../models/Enums';
import { UserModel } from '../../models/User/UserModel';
import {
    getCurrentPageUserAttribute,
    LEANPLUM_EVENTS,
    LeanplumAnalytics
} from '../../services/Analytics/LeanplumAnalytics';
import { AppInsightService } from '../../services/AppInsight';
import { CookieService } from '../../services/CookieService';
import { PushNotifications } from '../../services/PushNotifications';
import { AppState } from '../../store';
import { setSideMenuOpened } from '../../store/ducks/layout';
import { setShouldTrackPageview } from '../../store/ducks/pages';

type StateProps = {
    pathname: string;
    gameState: GameState;
    pageNotFound: boolean;
    shouldTrackPageview: boolean;
    pageType: string;
    adFree: boolean;
    subscription: boolean;
    user: UserModel;
};

type DispatchProps = {
    replace: typeof replace;
    setShouldTrackPageview?: typeof setShouldTrackPageview;
    setSideMenuOpened?: typeof setSideMenuOpened;
};

type ComponentProps = {
    children?: React.ReactNode;
};

type RouterWrapperProps = StateProps & DispatchProps & ComponentProps;

export class RouterWrapperBase extends React.PureComponent<RouterWrapperProps> {
    static displayName = 'RouterWrapper';

    scrollTop = false;

    wasReloaded = false;

    adTimer: any = -1;

    componentDidUpdate(prevProps: RouterWrapperProps) {
        LeanplumAnalytics.setUserAttributesCustom({ current_page: getCurrentPageUserAttribute() });
        if (this.props.shouldTrackPageview) {
            this.props.setShouldTrackPageview(false);
            AppInsightService.trackPageView();
            LeanplumAnalytics.trackEvent(LEANPLUM_EVENTS.PAGEVIEW, { url: getCurrentPageUserAttribute() });
        }

        if (this.scrollTop) {
            window.scrollTo(0, 0);
            this.scrollTop = false;
        }

        if (this.props.pathname !== prevProps.pathname) {
            this.props.setSideMenuOpened(false);
        }

        if (this.props.pathname !== prevProps.pathname) {
            const adFreeCookie = CookieService.getArkCookie(LS_COOKIE_CONSTS.AD_FREE_VER) === 'true';
            const subscriptionCookie = CookieService.getArkCookie(LS_COOKIE_CONSTS.SUBSCRIPTION) === 'true';

            if (this.props.adFree !== adFreeCookie) {
                CookieService.setArkCookie(LS_COOKIE_CONSTS.AD_FREE_VER, this.props.adFree);
            }
            if (this.props.subscription !== subscriptionCookie) {
                CookieService.setArkCookie(LS_COOKIE_CONSTS.SUBSCRIPTION, this.props.subscription);
            }
            if (!MiscUtils.isServer && this.props.pageNotFound !== true) {
                PushNotifications.pushInit(this.props.pathname).then(function () {
                    PushNotifications.init();
                });
            }
        }
    }

    componentDidMount() {
        LeanplumAnalytics.setUserAttributesCustom({ current_page: getCurrentPageUserAttribute() });
        if (!MiscUtils.isServer && this.props.pageNotFound !== true) {
            PushNotifications.pushInit(this.props.pathname).then(function () {
                PushNotifications.init();
            });
        }
        if (!MiscUtils.isServer) {
            if ('scrollRestoration' in window.history) {
                window.history.scrollRestoration = 'manual';
                // workaround for IE11
            } else {
                setTimeout(() => {
                    window.scrollTo(0, 0);
                }, 800);
            }
        }
        LeanplumAnalytics.trackEvent(LEANPLUM_EVENTS.PAGEVIEW, { url: getCurrentPageUserAttribute() });
    }

    render() {
        return <div>{this.props.children}</div>;
    }
}

export const RouterWrapper = connect<StateProps, DispatchProps, ComponentProps, AppState>(
    (state: AppState) => ({
        pathname: state.router.location.pathname,
        gameState: state.gameState,
        pageNotFound: state.pageType === PageTypes.NotFound,
        shouldTrackPageview: state.shouldTrackPageview,
        pageType: state.pageType,
        adFree: state.preLoadData.adFree,
        subscription: state.preLoadData.subscription,
        user: state.user,
    }),
    { replace, setShouldTrackPageview, setSideMenuOpened }
)(RouterWrapperBase);
