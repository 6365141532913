import React, { useContext, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { validationSchema } from './validationSchemas/RequestActivationSchema';
import { Input } from '../../../../atoms/HookFormInput/Input';
import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { HeaderSideMenuTabs } from '../../../../constants/HeaderSideMenuTabs';
import { SignInUpComponents } from '../../../../constants/SignInUpComponents';
import { Button } from '../../../../FigmaStyleguide/Button/Button';
import CaptchaIframe from '../../../../molecules/Captcha/CaptchaIframe';
import useCaptcha, {
  RECAPTCHA_ACTIONS,
  RECAPTCHA_MODES
} from '../../../../molecules/Captcha/hooks/useCaptcha';
import { Analytics } from '../../../../services/Analytics/Analytics';
import { AuthDataContext } from '../../../../services/AuthDataReact';
import { TranslationService } from '../../../../services/TranslationService';
import UserService, { AuthType } from '../../../../services/UserService';
import { setSideMenuActivePage, setSignInUpState, setSnackbarData } from '../../../../store/ducks/layout';
import { setShowRecaptcha } from '../../../../store/ducks/recaptcha';
import styles from '../RightPanelCommonStyles/RightPanelCommonStyles.css';
import { RightPanelWrapper } from '../RightPanelWrapper/RightPanelWrapper';

export const RequestActivation = React.memo(() => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [buttonCancelLabelKey, setButtonCancelLabelKey] = useState<string>('CANCEL_BTN');
  const [buttonResendLabelKey, setButtonResendLabelKey] = useState<string>('REGISTER_RESEND');
  const [emailWasResent, setEmailWasResent] = useState<boolean>(false);
  const authDataContext = useContext(AuthDataContext);
  const { authProvider } = authDataContext.value;
  const {
    iframeRef,
    iframeURL,
    setIsIframeCaptchaLoaded,
    getCaptchaToken,
    clearCaptchaData,
    showChallengeRecaptcha,
    captchaToken
  } = useCaptcha();
  const {
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: ''
    }
  });
  const values = watch();

  useEffect(() => {
    if (captchaToken) {
      void fetchResendConfirmation();
    }
  }, [captchaToken]);

  const fetchResendConfirmation = async () => {
    try {
      await UserService.resendConfirmationEmail({
        email: values.email,
        authType: authProvider ?? AuthType.EmailPassword,
        captchaToken,
        captchaMode: showChallengeRecaptcha ? RECAPTCHA_MODES.CHALLENGE : undefined
      });
      handleResendConfirmationSuccess();
    } catch (err) {
      handleResendConfirmationError(err);
    }
  };
  const handleResendConfirmationSuccess = () => {
    setLoading(false);
    clearCaptchaData();
    setMessage(TranslationService.translateIt('REGISTER_THE_EMAIL_HAS_BEEN_SENT_AGAIN'));
    setButtonResendLabelKey('REGISTER_OK');
    setButtonCancelLabelKey('AD_DIVIDER_HELP_CAPTION');
    setEmailWasResent(true);
    void Analytics.trackEvent(Analytics.profile.resendConfirmation());
  };
  const handleResendConfirmationError = (error: number) => {
    setLoading(false);
    dispatch(setShowRecaptcha(error === 1023));
    dispatch(
      setSnackbarData({
        isOpened: true,
        message: UserService.errorCodeToText(error),
        type: 'error',
        parentNode: 'rightSideMenu'
      })
    );
  };
  const onResendActivationClick = () => {
    setLoading(true);
    getCaptchaToken(RECAPTCHA_ACTIONS.CONFIRMATION_RESEND);
  };
  const cancelBtn = () => {
    dispatch(setSignInUpState(SignInUpComponents.SIGN_IN));
  };
  const goToHelpTab = () => {
    dispatch(setSideMenuActivePage(HeaderSideMenuTabs.HELP_TAB));
  };

  return (
    <RightPanelWrapper headerLabelKey="RESEND_YOUR_CONFIRMATION_EMAIL">
      <I18nText as="div" className={styles.subHeader} keyName={'REGISTER_PLEASE_ENTER_THE_EMAIL'}/>&nbsp;
      {!showChallengeRecaptcha && (
        <form onSubmit={handleSubmit(onResendActivationClick)} className={styles.formContent}>
          <div className={styles.inputRow}>
            <Input
              required
              className={styles.input}
              isValid={!errors.email}
              ariaInvalid={errors.email ? 'true' : 'false'}
              register={register}
              name="email"
              type="text"
              maxLength={50}
              placeholder={TranslationService.translateIt('REGISTER_ENTER_YOUR_EMAIL_ADDRESS')}
              value={values.email}
              showError={errors?.email}
              errorMessage={errors?.email?.message ?? ''}
              onChange={() => trigger('email')}
              disabled={emailWasResent}
            />
          </div>
          <div className={styles.receiveText}>{message}</div>
          <div className={styles.buttonsRow}>
            <Button outlined onClick={emailWasResent ? goToHelpTab : cancelBtn} style={{ marginRight: '1rem' }}>
              <I18nText keyName={buttonCancelLabelKey}/>
            </Button>
            <Button
              loading={loading}
              onClick={emailWasResent ? cancelBtn : () => {
              }}
              type="submit"
              disabled={!values.email || !isValid || loading}
            >
              <I18nText keyName={buttonResendLabelKey}/>
            </Button>
          </div>
        </form>
      )}
      <CaptchaIframe iframeRef={iframeRef} iframeURL={iframeURL} setIsIframeCaptchaLoaded={setIsIframeCaptchaLoaded}/>
    </RightPanelWrapper>
  );
});
