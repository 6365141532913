import { createAction, createReducer } from 'redux-act';

import { MobileNavButtonsNames } from './MobileNavigationUxRedesigned.types';

export const setMobileNavigationUxRedesignedActiveButton = createAction<MobileNavButtonsNames | null>(
    'set mobile nav ux redesigned active button'
);
export const mobileNavigationUxRedesignedActiveButtonReducer = createReducer<MobileNavButtonsNames | null>({}, null);
mobileNavigationUxRedesignedActiveButtonReducer.on(
    setMobileNavigationUxRedesignedActiveButton,
    (_state, payload: MobileNavButtonsNames) => payload
);
