import { IframeMessageTypes } from '../Enums';

export enum GameEvents {
    TEST_READY = 'TEST_READY', // game is loaded
    GAME_START = 'GAME_START', // game started
    UPDATE_LOCAL_STORAGE = 'UPDATE_LOCAL_STORAGE', // game => arena to update local storage
    EVENT_CHANGE = 'EVENT_CHANGE', // is triggered every time user interact with a game
    AD_START = 'AD_START', // is triggered when user is about to see reward video
    CHANGE_SCORE = 'CHANGE_SCORE', // is triggered right before GAME_END // score: event.payload
    GAME_END = 'GAME_END', // game is finished
    REWARD_START = 'REWARD_START',
    INTERSTITIAL_START = 'INTERSTITIAL_START',
    GAME_PAUSE = 'GAME_PAUSE',
    GAME_RESUME = 'GAME_RESUME',
    AB_TEST_GROUP = 'AB_TEST_GROUP',
    AD_REFRESH = 'AD_REFRESH',
    PURCHASE_UPDATE = 'PURCHASE_UPDATE',
    PURCHASE_REQUEST = 'PURCHASE_REQUEST',
    PURCHASE_RESPONSE = 'PURCHASE_RESPONSE',
    USER_EVENT = 'USER_EVENT', // game => arena to sent leanplum analytics
    MARKETING_ACTION = 'MARKETING_ACTION', // arena => game to react on leanplum call-to-action
    ANALYTICS_EVENT = 'ANALYTICS_EVENT', // game => arena to pass AI event into leanplum
    AUTH_API_EVENT = 'AUTH_API_EVENT',
}

export const SS_HURDLE_BOOST_PROPS = {
    HINT_NAME: 'hurdle_boost_clue',
    SENT_EnterSecondInvalidTry: 'SS_HURDLE_SENT_EnterSecondInvalidTry',
    USED_hurdle_boost_clue: 'SS_HURDLE_USED_hurdle_boost_clue',
};

export type PurchaseRequestEvent = {
    type: GameEvents.PURCHASE_REQUEST;
    payload: {
        payload: unknown;
        currencySku: string;
        type: string;
    };
};

export type PurchaseResponseEvent = {
    type: GameEvents.PURCHASE_RESPONSE;
    payload: {
        payload: unknown;
        result: boolean;
        code?: number;
        reason?: string;
    };
};

export type GameEventTypes = keyof typeof GameEvents;

export type IGameEventAction = {
    type: GameEventTypes;
    payload?: any;
};

export class GameObservable {
    fn = null;

    functions = [];

    // Being called by game
    next(event) {
        if (this.fn) {
            this.fn(event);
        }
    }

    xSubscribe(fn) {
        this.fn = fn;
    }

    xUnsubscribe() {
        this.fn = null;
    }

    // Reserved - used by game
    subscribe(f) {
        this.functions.push(f);
    }

    dispatch(action: IGameEventAction, postMessageToIframePassed?) {
        this.functions.forEach((f) => f(action));
        const postMessageToIframe = postMessageToIframePassed || (window as any)?.postmessagetoiframe; // in window id for middleware, find gameObservable?.dispatch({ type: GameEvents.GAME_RESUME });
        if (postMessageToIframe) {
            postMessageToIframe({
                type: IframeMessageTypes.UPDATE_LOCAL_STORAGE,
                payload: {
                    ...JSON.parse(JSON.stringify(window.localStorage)),
                    passedCookiesData: `${document.cookie}`,
                },
            });
            postMessageToIframe({
                type: IframeMessageTypes.AUTH_API_EVENT,
                payload: {
                    ...action,
                },
            });
        }
    }
}
