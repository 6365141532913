import React from 'react';

import styles from './ButtonAvatarMore.css';
import { I18nText } from '../../atoms/i18nText/i18nText';

type ButtonAvatarMoreProps = {
  onClick: () => void;
};
export const ButtonAvatarMore = React.memo(({ onClick }: ButtonAvatarMoreProps) => (
  <div role="presentation" className={styles.container} onClick={onClick}>
    <I18nText as="div" keyName={'MORE'} className={styles.label}/>
  </div>
));
