export type UserSubscription = {
    userId: number;
    app: {
        id: number;
        name: string;
    };
    planId: string;
    platform: {
        id: number;
        name: string;
    };
    platformId: number;
    renewalCount: number;
    subscriptionId: string;
    startDate: string;
    endDate: string;
    isAutoRenewable: boolean;
};

export type ExUserSubscription = {
    appId: number;
    application: {
        appStoreId: string;
        id: number;
        name: 'arkadium.com';
        title: null;
        type: null;
    };
};

type Currency = {
    currency?: string | null;
    setupFee?: number | null;
    unitAmount?: number | null;
};
export type SubscriptionPlan = {
    code: string;
    name: string;
    description: string;
    intervalUnit: string;
    intervalLength: number;
    currencies: Currency[];
};

export enum SubscriptionSource {
    PREROLL = 'Game_preroll_screen',
    ADBLOCKER = 'Ad_block_screen',
    PROFILE = 'Ark_Advantage_profile_tab',
    HEADER = 'Header_Subscription_button',
    SIDEBAR_TOP = 'Sidebar_Subscription_Button',
    IN_GAME = 'In_game_messaging',
    HOUSE_AD = 'House_ad',
    AVATAR_PANEL = 'Avatar_panel',
    EXTERNAL = 'External',
    MOBILE_MENU = 'Mobile_menu',
    EMAIL_REDEMPTION = 'Email_redemption',
    HOME_CAROUSEL = 'Home_carousel',
    EARLY_ACCESS = 'Early_Access',
    SHOP_AA_TAB = 'Shop_Arkadium_Advantage_tab',
    SHOP_AA_BANNER = 'Shop_Arkadium_Advantage_Banner',
}

export type SubscriptionSourceType = SubscriptionSource | '';

export enum PlatformType {
    RECURLY = 1,
    GOOGLE = 2,
    APPLE = 3,
    INTERNAL = 4,
}

export enum SubscriptionStatus {
    NONE = 'None',
    ACTIVE = 'Active',
    EXPIRED = 'Expired',
}

export enum SubscriptionAutoRenew {
    NONE = 'No active subscription',
    AUTORENEW = 'Active',
    CANCELLED = 'Cancelled',
}

export enum SubscriptionLifeCycle {
    NEW = 'new',
    RENEW = 'renew',
}
