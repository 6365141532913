import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .matches(/^[0-9a-zA-Z]+$/, 'Your screen name should contain only Latin characters and numbers')
    .min(3, 'Your screen name needs to contain at least 3 characters.')
    .max(29, 'Your screen name needs to be under 30 characters.')
});
