import { BaseApiService } from './BaseApiService';
import { LocalStorageService } from './LocalStorage';
import { HttpUtils } from '../../utils/HttpUtils';
import { globalErrorHandler } from '../../utils/LogUtils';
import { MiscUtils } from '../../utils/MiscUtils';
import { StringUtils } from '../../utils/StringUtils';
import { environment } from '../config/environment';
import { LS_COOKIE_CONSTS } from '../models/Enums';

export const RECENTLY_PLAYED_QUEUE_LENGTH = 7;

interface RecentlyPlayedServiceInterface {
  recentlyPlayedFetch(userId: string): Promise<RecentlyPlayedModel[]>;
  recentlyPlayedSave(recentlyPlayed: RecentlyPlayedModel[], getToken: () => Promise<string>);
  recentlyPlayedAddLocal(slug: string): RecentlyPlayedModel[];
  recentlyPlayedSyncLocalWithDB(getToken: () => Promise<string>): void;
}

export type RecentlyPlayedModel = {
  slug: string;
  timestamp: number;
};

class RecentlyPlayedService extends BaseApiService implements RecentlyPlayedServiceInterface {
  private saveRecentlyPlayedGameToLocalStorage(slug: string): RecentlyPlayedModel[] {
    let recentlyPlayed: RecentlyPlayedModel[] = [];
    const newItem: RecentlyPlayedModel = {
      slug,
      timestamp: MiscUtils.getNowTimestampUTC()
    };
    const recentlyPlayedJson = LocalStorageService.getItem(LS_COOKIE_CONSTS.RECENTLY_PLAYED);

    if (recentlyPlayedJson) {
      try {
        recentlyPlayed = JSON.parse(recentlyPlayedJson) as RecentlyPlayedModel[];
      } catch (e) {
        globalErrorHandler({
          error: e,
          filename: 'RecentlyPlayedService.ts',
          info: 'saveRecentlyPlayedGameToLocalStorage()'
        });
      }

      const existingItem = recentlyPlayed.find((rp) => StringUtils.equalIgnoreCase(rp.slug, slug));

      if (!existingItem) {
        recentlyPlayed.push(newItem);
      } else {
        existingItem.timestamp = MiscUtils.getNowTimestampUTC();
      }
    } else {
      recentlyPlayed.push(newItem);
    }

    LocalStorageService.setItem(LS_COOKIE_CONSTS.RECENTLY_PLAYED, JSON.stringify(recentlyPlayed));
    return recentlyPlayed;
  }

  private fetchRecentlyPlayedGamesFromLocalStorage(): RecentlyPlayedModel[] {
    const recentlyPlayedJson = LocalStorageService.getItem(LS_COOKIE_CONSTS.RECENTLY_PLAYED);
    let recentlyPlayed: RecentlyPlayedModel[] = [];

    if (recentlyPlayedJson) {
      try {
        recentlyPlayed = JSON.parse(recentlyPlayedJson) as RecentlyPlayedModel[];
      } catch (e) {
        globalErrorHandler({
          error: e,
          filename: 'RecentlyPlayedService.ts',
          info: 'fetchRecentlyPlayedGamesFromLocalStorage()'
        });
      }
    }

    return recentlyPlayed;
  }

  private clearRecentlyPlayedGamesFromLocalStorage(): void {
    LocalStorageService.removeItem(LS_COOKIE_CONSTS.RECENTLY_PLAYED);
  }

  private async saveRecentlyPlayedGameToDB(recentlyPlayed: RecentlyPlayedModel[], apiToken: string) {
    // save data to azure table storage
    try {
      const url = `${environment.USER_API_BASE_URL}/recently-played`;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${apiToken}`
        },
        body: JSON.stringify(recentlyPlayed) // body data type must match "Content-Type" header
      };

      await HttpUtils.fetch(url, options, false);
    } catch (error) {
      globalErrorHandler({ error, filename: 'RecentlyPlayedService.ts', info: 'saveRecentlyPlayedGameToDB()' });
      this.trackError(
        error,
        'An error occurred while try to save recently played games to server. See app insight logs for details.'
      );
      throw error;
    }
  }

  private async fetchRecentlyPlayedGamefromDB(userId: string): Promise<RecentlyPlayedModel[]> {
    const takeCount = 7;
    let recentlyPlayedFromDB: RecentlyPlayedModel[] = [];

    try {
      const url = `${environment.USER_API_BASE_URL}/recently-played/${userId}?take=${takeCount}`;
      const options = {
        method: 'GET'
      };

      recentlyPlayedFromDB = (await HttpUtils.fetch(url, options)) as RecentlyPlayedModel[];
    } catch (error) {
      this.trackError(
        error,
        'An error occurred while try to fetch recently played games from server. See app insight logs for details.'
      );
      throw error;
    }

    return recentlyPlayedFromDB;
  }

  public recentlyPlayedAddLocal(slug: string): RecentlyPlayedModel[] {
    // save data to local storage
    return this.saveRecentlyPlayedGameToLocalStorage(slug);
  }

  public async recentlyPlayedSyncLocalWithDB(getToken: () => Promise<string>) {
    // fetch data from local storage
    const recentlyPlayed = this.fetchRecentlyPlayedGamesFromLocalStorage();

    if (recentlyPlayed && recentlyPlayed.length > 0) {
      this.recentlyPlayedSave(recentlyPlayed, getToken);
    }
  }

  public async recentlyPlayedFetch(userId: string): Promise<RecentlyPlayedModel[]> {
    return this.fetchRecentlyPlayedGamefromDB(userId);
  }

  public async recentlyPlayedSave(recentlyPlayed: RecentlyPlayedModel[], getToken: () => Promise<string>) {
    const apiToken = await getToken();

    await this.saveRecentlyPlayedGameToDB(recentlyPlayed, apiToken);
    this.clearRecentlyPlayedGamesFromLocalStorage();
  }
}

export default new RecentlyPlayedService();
