import { createAction, createReducer } from 'redux-act';

import { PageTypes } from '../../constants/Pages';
import { CategoryPageSEO, GeneralPageSEO } from '../../models/PagesData';

export const setPageSeo = createAction<Array<GeneralPageSEO | CategoryPageSEO>>('set page seo');
export const pageSeoReducer = createReducer<Array<GeneralPageSEO | CategoryPageSEO>>({}, []);
pageSeoReducer.on(setPageSeo, (_state, payload) => payload);

export const setPageType = createAction<string>('set page type');
export const pageTypeReducer = createReducer<string>({}, PageTypes.Home);
pageTypeReducer.on(setPageType, (_state, payload: string) => payload);

export const setShouldTrackPageview = createAction<boolean>('should track pageview');
export const shouldTrackPageviewReducer = createReducer<boolean>({}, null);
shouldTrackPageviewReducer.on(setShouldTrackPageview, (_state, payload = false) => payload);
