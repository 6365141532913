import React from 'react';

import classNames from 'classnames';

import { I18nText } from '../../../atoms/i18nText/i18nText';
import styles from './SupportCard.css';

export enum SupportCardMode {
    ROW = 'row',
    DEFAULT = 'default',
}

type SupportCardProps = {
    caption: string;
    image: string;
    mode?: SupportCardMode;
    description?: string;
    className?: string;
    onClick: () => void;
    dataElementDescription?: string;
};

export const SupportCard = React.memo((props: SupportCardProps) => {
    const { caption, image, mode, description, className, onClick, dataElementDescription } = props;
    const isRowMode = mode === SupportCardMode.ROW;
    return (
        <button
            className={classNames(styles.container, { [styles.rowModeContainer]: isRowMode }, className)}
            onClick={onClick}
            data-element-description={dataElementDescription}
        >
            <div className={classNames(styles.content, isRowMode ? styles.rowModeContent : '')}>
                <img
                    className={classNames(styles.image, isRowMode ? styles.rowModeImage : '')}
                    src={image}
                    alt={caption}
                />
                {description && <I18nText as="p" keyName={description} className={styles.description} />}

                <I18nText
                    as="p"
                    keyName={caption}
                    className={classNames(styles.caption, isRowMode ? styles.rowModeCaption : '')}
                />
            </div>
        </button>
    );
});
