import { createAction, createReducer } from 'redux-act';

export type preLoadDataState = {
    adFree?: boolean;
    subscription?: boolean;
};

export const setPreLoadData = createAction<preLoadDataState>('set data from cookies');
export const preLoadDataReducer = createReducer<preLoadDataState>(
    {},
    {
        adFree: false,
        subscription: false,
    }
);
preLoadDataReducer.on(setPreLoadData, (_state, payload) => payload);
