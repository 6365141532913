export const TOP_CATEGORIES = [
    { name: { en: 'Free Games', fr: 'Jeux gratuits' }, link: { en: '/free-online-games/', fr: '/jeux-gratuits/' } },
    {
        name: { en: 'Free Solitaire', fr: 'Solitaire gratuit' },
        link: { en: '/free-online-games/solitaire/', fr: '/jeux-gratuits/solitaire/' },
    },
    { name: { en: 'Crossword Puzzles', fr: 'Mots croisés' }, link: { en: '/free-online-games/crosswords/', fr: '' } },
    { name: { en: 'Sudoku', fr: 'Sudoku' }, link: { en: '/free-online-games/sudoku/', fr: '/jeux-gratuits/sudoku/' } },
    { name: { en: 'Casino Games', fr: 'Jeux de casino' }, link: { en: '/free-online-games/casino/', fr: '' } },
];
