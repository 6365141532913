import { createAction, createReducer } from 'redux-act';

import { ABTestsSettingsConfig } from '../../models/ABTests';

export const setABTestsData = createAction<ABTestsSettingsConfig>('set ABTestsData');
export const abTestsReducer = createReducer<ABTestsSettingsConfig>({}, null);
abTestsReducer.on(setABTestsData, (_state, payload) => payload);

export const setABTestsValue = createAction<string>('setABTestsValue');
export const abTestsValueReducer = createReducer<string>({}, '');
abTestsValueReducer.on(setABTestsValue, (_state, payload) => payload);
