import { IGame } from '../../models/Game/Game';
import { RegistrationSource } from '../../models/User/RegistrationSource';
import { GemsAnalyticsRegistrationLocations } from '../../store/ducks/leanplum/lpAnalytics';
import { ProfileAnalyticsAi } from './AI/ProfileAnalyticsAi';
import { AnalyticsEventsPair, makeEventsPair } from './AnalyticsEventBuilder';
import { ProfileAnalyticsGa } from './GA/ProfileAnalyticsGa';

export class ProfileAnalytics {
    aiAnalytics = new ProfileAnalyticsAi();
    gaAnalytics = new ProfileAnalyticsGa();

    async loginImpression(category: string, game: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.loginImpression(category, game));
    }

    async loginClick(
        category: string,
        game: IGame,
        loginRegisterSource?: RegistrationSource
    ): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.loginClick(category, game, null, loginRegisterSource));
    }

    async loginSuccess(
        category: string,
        game: any,
        loginLocation: GemsAnalyticsRegistrationLocations,
        loginRegisterSource?: RegistrationSource
    ): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            null,
            this.aiAnalytics.loginSuccess(category, game, loginLocation, loginRegisterSource)
        );
    }

    async emailOptIn(): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.emailOptIn());
    }

    async registration(
        category: string,
        game: any,
        source: RegistrationSource,
        avatarType: string,
        avatarBackground: string,
        registrationLocation: GemsAnalyticsRegistrationLocations,
        xsollaId: string = ''
    ): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.registration(xsollaId),
            this.aiAnalytics.registration(
                category,
                game,
                source,
                avatarType,
                avatarBackground,
                registrationLocation,
                xsollaId
            )
        );
    }

    async registrationEmailConfirm(
        category: string,
        game: any,
        source: RegistrationSource,
        avatarType: string,
        avatarBackground: string,
        registrationLocation: GemsAnalyticsRegistrationLocations
    ): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            null,
            this.aiAnalytics.registrationEmailConfirm(
                category,
                game,
                source,
                avatarType,
                avatarBackground,
                registrationLocation
            )
        );
    }

    async publicProfileName(source: RegistrationSource): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.publicProfileName(source));
    }

    async profileEmail(source: RegistrationSource): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.profileEmail(source));
    }

    async profileAvatar(avatarType: string, source: RegistrationSource): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.profileAvatar(avatarType, source));
    }

    async profileAvatarCustomize(
        avatarBackground: string,
        avatarFrame: string,
        source: RegistrationSource
    ): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            null,
            this.aiAnalytics.profileAvatarCustomize(avatarBackground, avatarFrame, source)
        );
    }

    async onboardingPersonal(source: RegistrationSource): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.onboardingPersonal(source));
    }

    async onboardingEmail(source: RegistrationSource): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.onboardingEmail(source));
    }

    async resendConfirmation(): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.resendConfirmation());
    }

    async resetPassword(type: string): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.resetPassword(type));
    }

    async socialEmailRequest(): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.socialEmailRequest());
    }

    async registrationMoreAvatars(avatarType: string, source: RegistrationSource): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.registrationMoreAvatars(avatarType, source));
    }

    async registrationAvatarSkip(avatarType: string, source: RegistrationSource): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.registrationAvatarSkip(avatarType, source));
    }

    async createAccountButton(): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(this.gaAnalytics.createAccountClick(), this.aiAnalytics.createAccountButton());
    }

    async createAccountSuccess(): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(this.gaAnalytics.createAccountSuccess(), this.aiAnalytics.createAccountSuccess());
    }
}
