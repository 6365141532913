export type AvatarDataType = {
    filename: string;
    analyticsKey: string;
    premium: boolean;
    description: string;
};

export const AvatarImagesDefault: AvatarDataType[] = [
    {
        filename: 'avatar_14.png',
        analyticsKey: '14',
        premium: false,
        description: 'Middle-aged man with salt-and-pepper goatee.',
    },
    {
        filename: 'avatar_24.png',
        analyticsKey: '24',
        premium: false,
        description: 'Woman with greying hair in a bob cut.',
    },
    {
        filename: 'avatar_43.png',
        analyticsKey: '43',
        premium: false,
        description: 'Older woman with short grey hair and glasses.',
    },
    {
        filename: 'avatar_4.png',
        analyticsKey: '4',
        premium: false,
        description: 'Smiling young man with copper hair and beard.',
    },
    {
        filename: 'avatar_38.png',
        analyticsKey: '38',
        premium: false,
        description: 'Woman with fluffy brown hair in bob cut.',
    },
    {
        filename: 'avatar_32.png',
        analyticsKey: '32',
        premium: false,
        description: 'Similing woman with greying hair pulled into ponytail.',
    },
    {
        filename: 'avatar_19.png',
        analyticsKey: '19',
        premium: false,
        description: 'Man with black parted hair smiling.',
    },
    {
        filename: 'avatar_31.png',
        analyticsKey: '31',
        premium: false,
        description: 'Grey haired main with thin glasses and moustache.',
    },
    {
        filename: 'avatar_12.png',
        analyticsKey: '12',
        premium: false,
        description: 'Spectacled man with buzz cut and beard.',
    },
    {
        filename: 'avatar_34.png',
        analyticsKey: '34',
        premium: false,
        description: 'Woman with who\'s shoulder-length hair has blonde highlights.',
    },
    {
        filename: 'avatar_16.png',
        analyticsKey: '16',
        premium: false,
        description: 'Older man with short curly hair and a white goatee.',
    },
    {
        filename: 'avatar_3.png',
        analyticsKey: '3',
        premium: false,
        description: 'Smiling woman with long blonde hair and hoop earings.',
    },
    {
        filename: 'avatar_41.png',
        analyticsKey: '41',
        premium: false,
        description: 'Woman with short black hair and cat-eye makeup.',
    },
    {
        filename: 'avatar_11.png',
        analyticsKey: '11',
        premium: false,
        description: 'Young smiling woman with black hair pulled back.',
    },
    {
        filename: 'avatar_40.png',
        analyticsKey: '40',
        premium: false,
        description: 'Smiling person with a shaved head.',
    },
    {
        filename: 'avatar_37.png',
        analyticsKey: '37',
        premium: false,
        description: 'Older man who looks a little surprised.',
    },
    {
        filename: 'avatar_39.png',
        analyticsKey: '39',
        premium: false,
        description: 'Smiling woman with wavy short hair and earrings.',
    },
    {
        filename: 'avatar_23.png',
        analyticsKey: '23',
        premium: false,
        description: 'Smiling older man with swept back grey hair.',
    },
    {
        filename: 'avatar_33.png',
        analyticsKey: '33',
        premium: false,
        description: 'Smiling man with moustache and soul patch.',
    },
    {
        filename: 'avatar_5.png',
        analyticsKey: '5',
        premium: false,
        description: 'Older womman with short grey hair with bangs.',
    },
    {
        filename: 'avatar_9.png',
        analyticsKey: '9',
        premium: false,
        description: 'Smiling young woman with curly brown hair.',
    },
    {
        filename: 'avatar_2.png',
        analyticsKey: '2',
        premium: false,
        description: 'Young man with five o\'clock shadow.',
    },
    {
        filename: 'avatar_28.png',
        analyticsKey: '28',
        premium: false,
        description: 'Similing woman with hoop earlings and long hair.',
    },
    {
        filename: 'avatar_26.png',
        analyticsKey: '26',
        premium: false,
        description: 'Middle-aged woman with black shoulder length hair.',
    },
    {
        filename: 'avatar_6.png',
        analyticsKey: '6',
        premium: false,
        description: 'Middle-aged bald man with stubble.',
    },
    {
        filename: 'avatar_35.png',
        analyticsKey: '35',
        premium: false,
        description: 'Middle-aged man with a black comb over haircut.',
    },
    {
        filename: 'avatar_22.png',
        analyticsKey: '22',
        premium: false,
        description: 'Middle-aged woman with parted bangs.',
    },
    {
        filename: 'avatar_20.png',
        analyticsKey: '20',
        premium: false,
        description: 'Smiling woman with parted short hair.',
    },
    {
        filename: 'avatar_30.png',
        analyticsKey: '30',
        premium: false,
        description: 'Older woman with glasses and a tight bun.',
    },
    {
        filename: 'avatar_7.png',
        analyticsKey: '7',
        premium: false,
        description: 'Older person with messy grey hair.',
    },
    {
        filename: 'avatar_13.png',
        analyticsKey: '13',
        premium: false,
        description: 'Middle-aged woman with curly highlighted hair.',
    },
    {
        filename: 'avatar_1.png',
        analyticsKey: '1',
        premium: false,
        description: 'Woman with wavy red hair.',
    },
    {
        filename: 'avatar_17.png',
        analyticsKey: '17',
        premium: false,
        description: 'Smiling man with thick eyebrows.',
    },
    {
        filename: 'avatar_10.png',
        analyticsKey: '10',
        premium: false,
        description: 'Young man with dark beard and thin moustache.',
    },
    {
        filename: 'avatar_8.png',
        analyticsKey: '8',
        premium: false,
        description: 'Older man with a grey comb over.',
    },
    {
        filename: 'avatar_15.png',
        analyticsKey: '15',
        premium: false,
        description: 'Older woman with short curly hair.',
    },
    {
        filename: 'avatar_21.png',
        analyticsKey: '21',
        premium: false,
        description: 'Middle-aged man with glasses and collared shirt.',
    },
    {
        filename: 'avatar_36.png',
        analyticsKey: '36',
        premium: false,
        description: 'Middle-aged woman with large curly hair.',
    },
    {
        filename: 'avatar_18.png',
        analyticsKey: '18',
        premium: false,
        description: 'Smiling young woman with long black hair.',
    },
    {
        filename: 'avatar_27.png',
        analyticsKey: '27',
        premium: false,
        description: 'Bald man with medium length graying beard.',
    },
    {
        filename: 'avatar_29.png',
        analyticsKey: '29',
        premium: false,
        description: 'Man with prominent moustache.',
    },
    {
        filename: 'avatar_25.png',
        analyticsKey: '25',
        premium: false,
        description: 'Smiling man with well manicured hair and short beard.',
    },
    {
        filename: 'avatar_42.png',
        analyticsKey: '42',
        premium: false,
        description: 'Woman wearing suit with salt and pepper buzz cut.',
    },
    {
        filename: 'avatar_44.png',
        analyticsKey: '44',
        premium: false,
        description: 'Woman with 80\'s style haircut.',
    },
    {
        filename: 'avatar_45.png',
        analyticsKey: '45',
        premium: false,
        description: 'Man with receding hairline and closely shaved beard.',
    },
    {
        filename: 'avatar_46.png',
        analyticsKey: '46',
        premium: false,
        description: 'Woman with curly grey hair and black glasses.',
    },
    {
        filename: 'avatar_47.png',
        analyticsKey: '47',
        premium: false,
        description: 'Middle-aged man with messy short hair.',
    },
    {
        filename: 'avatar_48.png',
        analyticsKey: '48',
        premium: false,
        description: 'Aging woman with short hair and pearl earrings.',
    },
    {
        filename: 'avatar_49.png',
        analyticsKey: '49',
        premium: false,
        description: 'Middle-aged man with receding hairline.',
    },
    {
        filename: 'avatar_50.png',
        analyticsKey: '50',
        premium: false,
        description: 'Older man with short white hair and glasses.',
    },
    {
        filename: 'avatar_51.png',
        analyticsKey: '51',
        premium: false,
        description: 'Middle-aged man with short hair and full cheeks.',
    },
];
export const AvatarImagesPremium = [
    // {
    //     filename: 'avatar_halloween_premium_1.png',
    //     analyticsKey: 'halloween_premium_1',
    //     premium: true,
    //     description: '',
    // },
    {
        filename: 'avatar_premium_1.png',
        analyticsKey: 'premium_1',
        premium: true,
        description: 'Young man short dark hair and expressive eyes.',
    },
    {
        filename: 'avatar_premium_2.png',
        analyticsKey: 'premium_2',
        premium: true,
        description: 'Young man with chestnut hair and a short beard.',
    },
    {
        filename: 'avatar_premium_3.png',
        analyticsKey: 'premium_3',
        premium: true,
        description: 'Woman with flowing blonde hair.',
    },
    {
        filename: 'avatar_premium_4.png',
        analyticsKey: 'premium_4',
        premium: true,
        description: 'Woman with red hair and matching lipstick.',
    },
    {
        filename: 'avatar_premium_5.png',
        analyticsKey: 'premium_5',
        premium: true,
        description: 'Young woman with wavy highlighted hair.',
    },
    {
        filename: 'avatar_premium_6.png',
        analyticsKey: 'premium_6',
        premium: true,
        description: 'Smiling woman with long blonde hair.',
    },
    {
        filename: 'avatar_premium_7.png',
        analyticsKey: 'premium_7',
        premium: true,
        description: 'Young woman with black parted hair.',
    },
    {
        filename: 'avatar_premium_8.png',
        analyticsKey: 'premium_8',
        premium: true,
        description: 'Young person with short dark hair and a blue baseball cap.',
    },
    {
        filename: 'avatar_premium_9.png',
        analyticsKey: 'premium_9',
        premium: true,
        description: 'Bearded smiling man with a blue hat.',
    },
    {
        filename: 'avatar_premium_10.png',
        analyticsKey: 'premium_10',
        premium: true,
        description: 'Young man with well manicured medium blonde hair.',
    },
    {
        filename: 'avatar_premium_11.png',
        analyticsKey: 'premium_11',
        premium: true,
        description: 'Young woman with long dark hair and a surprised expression.',
    },
    {
        filename: 'avatar_premium_12.png',
        analyticsKey: 'premium_12',
        premium: true,
        description: 'Man with a square jaw, manicured facial hair, and a red hat.',
    },
    {
        filename: 'avatar_premium_13.png',
        analyticsKey: 'premium_13',
        premium: true,
        description: 'Woman with long dark hair and hoop earrings who is looking over her shoulder.',
    },
    {
        filename: 'avatar_premium_14.png',
        analyticsKey: 'premium_14',
        premium: true,
        description: 'Man with a thick beard and a baseball cap.',
    },
    {
        filename: 'avatar_premium_15.png',
        analyticsKey: 'premium_15',
        premium: true,
        description: 'Laughing woman with long dark hair.',
    },
    {
        filename: 'avatar_premium_16.png',
        analyticsKey: 'premium_16',
        premium: true,
        description: 'Young woman with long black hair who appears to be speaking.',
    },
    {
        filename: 'avatar_premium_17.png',
        analyticsKey: 'premium_17',
        premium: true,
        description: 'Man with hair pulled back into bun.',
    },
    {
        filename: 'avatar_premium_18.png',
        analyticsKey: 'premium_18',
        premium: true,
        description: 'Young man gazing into the distance with  short black hair.',
    },
    {
        filename: 'avatar_premium_19.png',
        analyticsKey: 'premium_19',
        premium: true,
        description: 'Smiling bearded man with dark gelled hair.',
    },
    {
        filename: 'avatar_premium_20.png',
        analyticsKey: 'premium_20',
        premium: true,
        description: 'Man with dark brown hair that is styled with a spike in the front.',
    },
    {
        filename: 'avatar_premium_21.png',
        analyticsKey: 'premium_21',
        premium: true,
        description: 'Young man with a square jaw and parted hair.',
    },
    {
        filename: 'avatar_premium_22.png',
        analyticsKey: 'premium_22',
        premium: true,
        description: 'Smiling young man with tightly curled hair.',
    },
    {
        filename: 'avatar_premium_23.png',
        analyticsKey: 'premium_23',
        premium: true,
        description: 'Woman with medium brown hair swept over her shoulder.',
    },
    {
        filename: 'avatar_premium_24.png',
        analyticsKey: 'premium_24',
        premium: true,
        description: 'Young woman with hair in a long ponytail.',
    },
    {
        filename: 'avatar_premium_25.png',
        analyticsKey: 'premium_25',
        premium: true,
        description: 'Young person with short black hair.',
    },
    {
        filename: 'avatar_premium_26.png',
        analyticsKey: 'premium_26',
        premium: true,
        description: 'Smiling woman with curly afro.',
    },
    {
        filename: 'avatar_premium_27.png',
        analyticsKey: 'premium_27',
        premium: true,
        description: 'Man with a five o\'clock shadow and a white baseball cap.',
    },
    {
        filename: 'avatar_premium_28.png',
        analyticsKey: 'premium_28',
        premium: true,
        description: 'Smiling man with hair dark hair combed to one side.',
    },
    {
        filename: 'avatar_premium_29.png',
        analyticsKey: 'premium_29',
        premium: true,
        description: 'Unicorn with a rainbow mane.',
    },
    {
        filename: 'avatar_premium_30.png',
        analyticsKey: 'premium_30',
        premium: true,
        description: 'Happy golden retriever.',
    },
    {
        filename: 'avatar_premium_31.png',
        analyticsKey: 'premium_31',
        premium: true,
        description: 'Cute tabby cat.',
    },
];
export const SPECIAL_AVATARS = {
    'naturalgasoline@msn.com': {
        image: 'avatar_kenny.png',
        backgroundColor: '#5e9efe',
        description: 'Arkadium co-founder Kenny.',
    },
    'jessica@arkadium.com': { 
        image: 'avatar_jess.png',
        backgroundColor: '#e9a2d7',  
        description: 'Arkadium co-founder Jess.',
    },
};

export const AvatarImagesData = [...AvatarImagesDefault, ...AvatarImagesPremium];

export const DEFAULT_AVATAR = {
    filename: 'avatar_blank.png',
    analyticsKey: '0',
    premium: false,
    description: 'A default avatar that is a silhouette.',
};

export const DEFAULT_AVATAR_BACKGROUND = `rgba(0,66,187)`;

export const getAvatarAnalyticsKey = (avatarFilename) => {
    if (avatarFilename) {
        if (avatarFilename === DEFAULT_AVATAR.filename) {
            return DEFAULT_AVATAR.analyticsKey;
        }
        return AvatarImagesData.find((item) => item.filename === avatarFilename)?.analyticsKey;
    }
    return '';
};

export const getAvatarDescription = (avatarFilename) => {
    if (avatarFilename) {
        if (avatarFilename === DEFAULT_AVATAR.filename) {
            return DEFAULT_AVATAR.description;
        }
        return AvatarImagesData.find((item) => item.filename === avatarFilename)?.description;
    }
    return '';
};

export const getAvatarIndex = (avatarFilename) => {
    if (avatarFilename) {
        if (avatarFilename === DEFAULT_AVATAR.filename) {
            return 0;
        }
        return AvatarImagesData.findIndex((item) => item.filename === avatarFilename);
    }
    return 0;
};
