import React from 'react';

type iconProps = {
    className?: string;
    size?: number;
    onMouseDown?: () => void;
    onMouseUp?: () => void;
    onClick?: () => void;
};

export const CloseIcon = React.memo(({ className, size = 34, onMouseDown, onMouseUp, onClick }: iconProps) => {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 34 34"
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onClick={onClick}
        >
            <g fill="none" fillRule="evenodd">
                <path d="M0 0H34V34H0z" />
                <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="bevel" strokeWidth="2">
                    <path d="M0 0L16 16" transform="translate(8 8)" />
                    <path d="M0 0L16 16" transform="translate(8 8) matrix(-1 0 0 1 16 0)" />
                </g>
            </g>
        </svg>
    );
});
