import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';

import styles from './LanguageSwitcher.css';
import { FlagEn } from '../../atoms/Icons/Styleguide/Flags/FlagEnNew';
import { FlagFr } from '../../atoms/Icons/Styleguide/Flags/FlagFrNew';
import { PageTypes } from '../../constants/Pages';
import { GameService } from '../../services/GameService';
import { PageService } from '../../services/PageService';
import { ROUTES, UrlService } from "../../services/UrlService";
import { SrOnly } from '../SrOnly/SrOnly';

const DEFAULT_LANG = 'en';
const ADDITIONAL_LANG = 'fr';
const switcherItems = {
  en: {
    label: DEFAULT_LANG,
    icon: <FlagEn/>
  },
  fr: {
    label: ADDITIONAL_LANG,
    icon: <FlagFr/>
  }
};
const BLOCKED_LANG_SWITCH_PAGES = [PageTypes.Dynamic, PageTypes.BlogArchive, PageTypes.BlogPost, ROUTES.Subscription];

export const LanguageSwitcher = () => {
  const currentLang = useSelector(({ currentLang }) => currentLang);
  const gameArkadiumSlug = useSelector(({ gameArkadiumSlug }) => gameArkadiumSlug);
  const games = useSelector(({ games }) => games);
  const pages = useSelector(({ pages }) => pages);
  const categoryPageName = useSelector(({ categoryPageName }) => categoryPageName);
  const pageType = useSelector(({ pageType }) => pageType);
  const [currentItem, setCurrentItem] = useState<{ label: string, icon: JSX.Element; }>(null);
  const [isFrSupported, setIsFrSupported] = useState<boolean>(true);

  useEffect(() => {
    const keyValue = currentLang == ADDITIONAL_LANG ? ADDITIONAL_LANG : DEFAULT_LANG;

    setCurrentItem(switcherItems[keyValue]);

    if (pageType == PageTypes.Game && gameArkadiumSlug !== null) {
      const game = GameService.findGameByArkadiumSlug(GameService.gameModelToGame(games), gameArkadiumSlug);
      const gameFRLangSupported = GameService.isLanguageSupportedByGame(game, ADDITIONAL_LANG);

      setIsFrSupported(gameFRLangSupported);
    }

    if (BLOCKED_LANG_SWITCH_PAGES.find((page) => pageType == page)) {
      setIsFrSupported(false);
    }
  }, []);

  /**
   * Get new URL after switch on/off and set URL
   *
   * @param {string} toggledLang
   */
  const switchLanguage = (toggledLang: string) => {
    let url: string;

    url = UrlService.createURL(toggledLang === ADDITIONAL_LANG ? `/${ADDITIONAL_LANG}/` : '/', true);
    const langPrefix = toggledLang === DEFAULT_LANG ? `` : `/${toggledLang}`;

    if ((pageType === PageTypes.Category || pageType === PageTypes.AllCategories) && categoryPageName) {
      const currentCategory = PageService.getPageSEOByPageName(pages, categoryPageName);
      const slug = currentCategory.slug[toggledLang];
      let path: string;
      const pathToCategory = UrlService.getPageLocalizedRoute(toggledLang, pageType);

      if (pageType === PageTypes.Category && categoryPageName !== 'All') {
        path = `${langPrefix}/${pathToCategory}/${slug}/`;
      } else {
        path = `${langPrefix}/${pathToCategory}/`;
      }

      url = UrlService.createURL(path, true);
    }

    if (pageType === PageTypes.Game) {
      const path = `${langPrefix}/games/${gameArkadiumSlug}/`;

      url = UrlService.createURL(path, true);
    }

    if (pageType === PageTypes.Profile) {
      const path = `${langPrefix}/profile/`;

      url = UrlService.createURL(path, true);
    }

    if (pageType === PageTypes.PrivacyPolicy) {
      const path = `${langPrefix}/privacy-policy/`;

      url = UrlService.createURL(path, true);
    }

    if (pageType === PageTypes.AboutUs) {
      const pageSeo = PageService.getPageSEOByPageName(pages, 'About Us');
      const path = `${langPrefix}/${pageSeo.slug[toggledLang]}/`;

      url = UrlService.createURL(path, true);
    }

    if (pageType === PageTypes.SiteMap) {
      const path = `${langPrefix}/site-map/`;

      url = UrlService.createURL(path, true);
    }

    if (pageType === PageTypes.GiftCard) {
      const path = `${langPrefix}/gift-card/`;

      url = UrlService.createURL(path, true);
    }

    if (pageType === PageTypes.GameSubmissionDisclaimer) {
      const path = `${langPrefix}/game-submission-disclaimers/`;

      url = UrlService.createURL(path, true);
    }

    window.location.href = url;
  };
  /**
   * Main handler for switch language (if supported)
   *
   */
  const handleToggleLang = () => {
    if (isFrSupported) {
      const toggledLang = currentLang == ADDITIONAL_LANG ? DEFAULT_LANG : ADDITIONAL_LANG;

      setCurrentItem(switcherItems[toggledLang]);
      //Timeout for animation
      setTimeout(() => {
        switchLanguage(toggledLang);
      }, 200);
    }
  };

  return (
    <>
      <SrOnly/>
      <div
        role="button"
        tabIndex={0}
        className={classNames(
          styles.container,
          currentItem?.label == ADDITIONAL_LANG && styles.switched,
          !isFrSupported && styles.disabled
        )}
        onClick={() => {
          handleToggleLang();
        }}
        onKeyDown={(event) => {
          const { key } = event;

          if (key === ' ' || key === 'Spacebar') {
            event.preventDefault();
            handleToggleLang();
          }
        }}
      >
        <div className={styles.langLabel} lang={currentLang}>
          {currentItem?.label}
        </div>
        <div className={styles.flagCircle}>{currentItem?.icon}</div>
      </div>
    </>
  );
};