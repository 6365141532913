import React, { useState } from 'react';

import classNames from 'classnames';

import styles from './InlineDropdown.css';
import { Button } from '../Buttons/Base/Base';
import { i18nTags, I18nText } from '../i18nText/i18nText';
import { MinusIcon } from '../Icons/Styleguide/MinusIcon';
import { PlusIcon } from '../Icons/Styleguide/PlusIcon';

type ListItemType = {
    title: string;
    flag?: string;
    alt?: string;
};

type InlineDropdownProps = {
    caption: string;
    items: ListItemType[];
    twoColumns?: boolean;
    dropdownContainerClassName?: string;
    iconClassName?: string;
    buttonClassName?: string;
    captionClassName?: string;
    itemCaptionClassName?: string;
    listItemClassName?: string;
    dropdownOpenedClassName?: string;
    i18n?: boolean;
    asHTMLTag?: i18nTags;
    onItemClick?: (item: any, ind?: any) => any;
    hasAttentionLink: boolean;
};

export const InlineDropdown = React.memo(
    ({
        caption,
        items,
        twoColumns,
        dropdownContainerClassName,
        iconClassName,
        buttonClassName,
        captionClassName,
        listItemClassName,
        dropdownOpenedClassName,
        i18n,
        asHTMLTag,
        onItemClick,
        hasAttentionLink,
    }: InlineDropdownProps) => {
        const [isOpened, setIsOpened] = useState(false);

        return (
            <div className={classNames(styles.dropdown, dropdownContainerClassName)}>
                <button
                    className={classNames(styles.dropdownCaption, buttonClassName)}
                    onClick={() => setIsOpened(!isOpened)}
                >
                    <span className={classNames(styles.dropdownCaptionText, captionClassName)}>
                        {!i18n ? caption : <I18nText keyName={caption} />}
                    </span>
                    {isOpened ? (
                        <MinusIcon key="minus" className={classNames(styles.dropdownIcon, iconClassName)} />
                    ) : (
                        <PlusIcon key="plus" className={classNames(styles.dropdownIcon, iconClassName)} />
                    )}
                </button>
                <div
                    className={classNames(styles.dropdownList, styles[dropdownOpenedClassName], {
                        [styles.dropdownOpened]: isOpened,
                        [styles.twoColumns]: twoColumns,
                    })}
                >
                    <ul>
                        {items.map((item, index) => (
                            <li key={item.title}>
                                <Button
                                    className={classNames(listItemClassName, { [styles.attentionLink]: index === 0 && hasAttentionLink })}
                                    onClick={() => {
                                        if (onItemClick) {
                                            onItemClick(item, index);
                                        }
                                    }}
                                >
                                    {!i18n ? item.title : <I18nText keyName={item.title} as={asHTMLTag} />}
                                    {item.flag && item.flag}
                                </Button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    }
);
