import React from 'react';

import classNames from 'classnames';

import styles from './Leaderboard.css';

const iconSettings = {
    nav: {
        svgSize: { height: 20, width: 20 },
        circleSize: { cx: 10, cy: 10, r: 10 },
    },
    'leaderboard-row': {
        svgSize: { height: 14, width: 14 },
        circleSize: { cx: 7, cy: 7, r: 7 },
    },
};
type TPlace = 'nav' | 'leaderboard-row';
type IconProps = {
    place: TPlace;
};

export const LeaderboardNotificationIcon = (props: IconProps) => {
    return (
        <svg
            {...iconSettings[props.place].svgSize}
            className={classNames([styles['notification-icon'], styles[props.place]])}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle {...iconSettings[props.place].circleSize} cx="5" cy="5" r="4.5" fill="#DC2B55" stroke="#F9F9F9" />
        </svg>
    );
};
