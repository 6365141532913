import { NavigationMenuItem } from '../../constants/Menu';
import { IGame } from '../../models/Game/Game';
import { GeneralAnalyticsAi } from './AI/GeneralAnalyticsAi';
import { AnalyticsEventsPair, makeEventsPair, MobileBookmarkPwaData } from './AnalyticsEventBuilder';
import { GeneralAnalyticsGa } from './GA/GeneralAnalyticsGa';
import { WebVitals } from '../../models/WebVitals';

export class GeneralAnalytics {
    gaAnalytics = new GeneralAnalyticsGa();

    aiAnalytics = new GeneralAnalyticsAi();

    async arenaPromoBox(menuItems: NavigationMenuItem[], page: string): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, null);
    }

    async promoboxSlotClick(
        cardSize: string,
        location: string,
        game: IGame,
        position: number
    ): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(this.gaAnalytics.promoBoxSlotClick(), this.aiAnalytics.promoboxSlotClick(cardSize, location, game, position));
    }

    async promoBoxSlot(game?: IGame, position?: number): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.promoBoxSlot(game, position));
    }

    async bookmarkImpression(data: { category?: string; game?: IGame }): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.bookmarkImpression(data.game));
    }

    async bookmarkClick(data: { category?: string; game?: IGame }): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.bookmarkClick(data.game));
    }

    async breadcrumbClick(game?: IGame): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.breadcrumbClick(game));
    }

    async socialShareClick(data: { social: string; category?: string; game?: IGame }): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, null);
    }

    async topNavClick(category: string, index: number): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(this.gaAnalytics.categoryClick(category), this.aiAnalytics.topNavClick(category, index));
    }

    async displayAd(
        refresh: boolean,
        isViewable: boolean,
        game: IGame,
        size: number[],
        displayAdLocation: string
    ): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.displayAd(refresh, isViewable, game, size),
            this.aiAnalytics.displayAd(refresh, game, size, displayAdLocation)
        );
    }



    async desktopBookmarkClick(data: { category?: string; game?: IGame }): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.desktopBookmarkClick(data.game));
    }

    async desktopBookmarkImpression(data: { category?: string; game?: IGame }): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.desktopBookmarkImpression(data.game));
    }

    async desktopBookmarkVisit(data: { category?: string; game?: IGame }): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.desktopBookmarkVisit(data.game));
    }

    async desktopBookmarkPWAInstall(data: { category?: string; game?: IGame }): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.desktopBookmarkPWAInstall(data.game));
    }

    async desktopBookmarkPWACancel(data: { category?: string; game?: IGame }): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.desktopBookmarkPWACancel(data.game));
    }

    async desktopBookmarkPWAClick(data: { category?: string; game?: IGame }): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.desktopBookmarkPWAClick(data.game));
    }

    async desktopBookmarkPWAImpression(data: { category?: string; game?: IGame }): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.desktopBookmarkPWAImpression(data.game));
    }

    async mobileBookmarkPWAClick(data: MobileBookmarkPwaData): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.mobileBookmarkPWAClick(data));
    }

    async mobileBookmarkPWAImpression(data: MobileBookmarkPwaData): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.mobileBookmarkPWAImpression(data));
    }

    async mobileBookmarkPWAVisit(data: MobileBookmarkPwaData): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.mobileBookmarkPWAVisit(data));
    }

    async desktopBookmarkPWAVisit(data: {
        category?: string;
        game?: IGame;
        home?: boolean;
    }): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.desktopBookmarkPWAVisit(data.game));
    }

    async desktopBookmarkDrag(data: { category?: string; game?: IGame }): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.desktopBookmarkDrag(data.game));
    }

    async desktopInstallAdd(data: { category?: string; game?: IGame; home?: boolean }): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, null);
    }

    async androidBookmarkImpression(data: {
        category?: string;
        game?: IGame;
        home?: boolean;
    }): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, null);
    }

    async androidBookmarkVisit(data: {
        category?: string;
        game?: IGame;
        home?: boolean;
    }): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.androidBookmarkVisits(data.game));
    }

    async androidInstallAdd(data: { category?: string; game?: IGame; home?: boolean }): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.androidInstallAdd(data.game));
    }

    async IOSBookmarkVisit(data: { category?: string; game?: IGame; home?: boolean }): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.IOSBookmarkVisit(data.game));
    }

    async pushNotifications(data: {
        action: string;
        label: string;
        nonInteraction: boolean;
        category: string;
        game: IGame;
    }): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            null,
            this.aiAnalytics.pushNotifications(data.action, data.label, data.nonInteraction, data.category, data.game)
        );
    }

    async footerImpression(): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.footerImpression());
    }

    async footerNavClick(category: string, index: number): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.footerNavClick(category, index));
    }

    async searchBoxImpression(searchType: string): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.searchBoxImpression(searchType));
    }

    async searchBoxQuery(searchType: string, query: string): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(this.gaAnalytics.searchQuery(), this.aiAnalytics.searchBoxQuery(searchType, query));
    }

    async searchBoxClick(searchType: string, slugOrCategory: string): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(this.gaAnalytics.searchGameClick(), this.aiAnalytics.searchBoxClick(searchType, slugOrCategory));
    }

    async topNavAdvantage(): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(this.gaAnalytics.topNavAdvantage(), this.aiAnalytics.topNavAdvantage());
    }

    async homepageCarousel(slideLabel: string): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.homepageCarousel(slideLabel));
    }

    async carouselButton(slot: string, buttonLabel: string): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(this.gaAnalytics.carouselButtonClick(), this.aiAnalytics.carouselButton(slot, buttonLabel));
    }

    async webVitals(metrics: WebVitals): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.webVitals(metrics));
    }
}
