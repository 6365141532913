import { SubscriptionSourceType } from '../../../models/Subscription/SubscriptionData';
import { AnalyticsEvent, AnalyticsEventAction, AnalyticsEventBuilder } from '../AnalyticsEventBuilder';

export class GiftCardAnalyticsGaGa {
    private newEventBuilder(): AnalyticsEventBuilder {
        return new AnalyticsEventBuilder();
    }

    gifterStart(location: SubscriptionSourceType): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('gifterStart');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel(location);
        event.setGaNonInteraction(false);
        return event.build();
    }

    giftRecipientName(location: SubscriptionSourceType): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('giftRecipientName');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel(location);
        event.setGaNonInteraction(false);
        return event.build();
    }

    giftRecipientEmail(location: SubscriptionSourceType): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('giftRecipientEmail');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel(location);
        event.setGaNonInteraction(false);
        return event.build();
    }

    giftConfirmEmail(location: SubscriptionSourceType): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('giftConfirmEmail');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel(location);
        event.setGaNonInteraction(false);
        return event.build();
    }

    giftDeliveryDate(location: SubscriptionSourceType): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('giftDeliveryDate');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel(location);
        event.setGaNonInteraction(false);
        return event.build();
    }

    giftCustomMessage(location: SubscriptionSourceType): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('giftCustomMessage');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel(location);
        event.setGaNonInteraction(false);
        return event.build();
    }

    giftSenderName(location: SubscriptionSourceType): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('giftSenderName');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel(location);
        event.setGaNonInteraction(false);
        return event.build();
    }

    giftSenderEmail(location: SubscriptionSourceType): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('giftSenderEmail');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel(location);
        event.setGaNonInteraction(false);
        return event.build();
    }

    gifterNextStep(location: SubscriptionSourceType): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('gifterNextStep');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel(location);
        event.setGaNonInteraction(false);
        return event.build();
    }

    gifterSendAgain(location: SubscriptionSourceType): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('gifterSendAgain');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel(location);
        event.setGaNonInteraction(false);
        return event.build();
    }

    gifterFormViewable(location: SubscriptionSourceType): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('gifterFormViewable');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel(location);
        event.setGaNonInteraction(true);
        return event.build();
    }
}
