export default {
    'uBlocker - #1 Adblock Tool for Chrome': {
        id: 'ppdonaappkjkbgbncmmjencphdclioab',
        file: 'adblock-jquery-ui.custom.css',
    },
    'AdBlock — best ad blocker': {
        id: 'gighmmpiobklfepjocnamgkkbiglidom',
        file: 'adblock-uiscripts-adblock-wizard.css',
    },
    'Adblock - No More Ads': {
        id: 'mblbcmmhijbfhblohmfjopjjlagmkgem',
        file: 'img/icon24.png',
    },
    'Ads Killer': {
        id: 'jjckigopagkhaikodedjnmbccfpnmiea',
        file: 'block.html',
    },
    SpotiAds: {
        id: 'mghhlojofjipigjobacbjdngmjafdeim',
        file: 'injected/ads_removal.js',
    },
    'Purple Ads Blocker': {
        id: 'lkgcfobnmghhbhgekffaadadhmeoindg',
        file: 'PurpleFlowString.js',
    },
    'Adswerve - dataLayer Inspector+': {
        id: 'kmcbdogdandhihllalknlcjfpdjcleom',
        file: 'images/doubleclick-logo.png',
    },
    'Site blocker': {
        id: 'offfjidagceabmodhpcngpemnnlojnhn',
        file: 'blocked-user.png',
    },
    'Popup Blocker (strict)': {
        id: 'aefkmifgmaafnojlojpnekbpbmjiiogg',
        file: 'data/ui/ui.css',
    },
    'Easy Ad Blocker': {
        id: 'naffoicfphgmlgikpcmghdooejkboifd',
        file: 'skin/background.png',
    },
    'Video Ad Blocker Plus for YouTube™': {
        id: 'hegneaniplmfjcmohoclabblbahcbjoe',
        file: 'img/Icon_128.png',
    },
    'AdBlock Stream': {
        id: 'npknjhekabepaannekikfhjbjgjhlbpf',
        file: '128.png',
    },
    'Ad n Popup-Blocker': {
        id: 'eofhamhgcjpjmipbpegbjeododphaoep',
        file: '_locales/de/messages.json',
    },
    'Trend Micro Check - Browser Security': {
        id: 'imhhfjfjfhjjjgaedcanngoffjmcblgi',
        file: '_locales/de/adblock.json',
    },
    'Web Ad Blocker': {
        id: 'fifcailncnlobddlehplcimgnehnldio',
        file: 'tutorial/cash.min.js',
    },
    'Stopza Adblocker': {
        id: 'kdmoafnhillldhoddnccefecelkofphm',
        file: 'icons/icon-128.png',
    },
    'AdBlocker by Trustnav': {
        id: 'dgbldpiollgaehnlegmfhioconikkjjh',
        file: 'views/notifications/search_extension_uninstalled/index.html',
    },
    'Popup Blocker Pro': {
        id: 'kiodaajmphnkcajieajajinghpejdjai',
        file: 'assets/images/icon128.png',
    },
    'Advertising Terminator': {
        id: 'fpdnjdlbdmifoocedhkighhlbchbiikl',
        file: 'block.html',
    },
    'Adaware Ad Block': {
        id: 'cmllgdnjnkbapbchnebiedipojhmnjej',
        file: 'img/bg_wave.png',
    },
    'Slope unblocked': {
        id: 'jnhijdocabbfbhdjkdckkkfheobmckgf',
        file: 'images/128.png',
    },
    'Block Site': {
        id: 'lebiggkccaodkkmjeimmbogdedcpnmfb',
        file: 'data/blocked/index.html',
    },
    'Vex 4 Unblocked Game': {
        id: 'flogffpkikminpapehgocpbckfckmohi',
        file: 'app-icon.png',
    },
    'Pop up blocker for Chrome™ - Poper Blocker': {
        id: 'bkkbcggnhapdmkeljlodobbkopceiche',
        file: 'message.html',
    },
    'Ghostery – Privacy Ad Blocker': {
        id: 'mlomiejdfkolichcflejclcbmpeaniij',
        file: 'app/templates/trackers-preview.html',
    },
    'Fair AdBlocker': {
        id: 'lgblnfidahcdcjddiepkckcfdhpknnjh',
        file: 'views/web_accessible/block-element/view.html',
    },
    'AdBlocker Ultimate': {
        id: 'ohahllgiabjaoigichmmfljhkcfikeof',
        file: 'lib/content-script/assistant/css/font-awesome.min.css',
    },
    'Total Adblock - Ad Blocker': {
        id: 'gekdekpbfehejjiecgonmgmepbdnaggp',
        file: 'assets/img/_brand/icon/120px.png',
    },
};
