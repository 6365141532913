import React from 'react';

import { iconProps } from '../../../../models/Icon/IconPropsModel';

export const CollectionsIcon = React.memo(({ className }: iconProps) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            aria-label="collections-icon"
        >
            <path
                d="M3 11V7C3 5.89543 3.89543 5 5 5H19C20.1046 5 21 5.89543 21 7V11M3 11V18.5C3 18.7761 3.22386 19 3.5 19H20.5C20.7761 19 21 18.7761 21 18.5V11M3 11H10M10 11V10C10 9.44772 10.4477 9 11 9H13C13.5523 9 14 9.44772 14 10V11M10 11V12C10 12.5523 10.4477 13 11 13H13C13.5523 13 14 12.5523 14 12V11M14 11H21"
                stroke="#231F20"
                strokeWidth="2"
            />
        </svg>
    );
});
