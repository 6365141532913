import React from 'react';
import ContentLoader from 'react-content-loader';

const LazyImageLoader = (props) => (
    <ContentLoader
        speed={2}
        width={600}
        height={600}
        viewBox="0 0 600 600"
        backgroundColor="#f2f2f2"
        foregroundColor="#f2f2f2"
        {...props}
    >
        <rect x="0" y="-1" rx="3" ry="3" width="601" height="631" />
    </ContentLoader>
);

export default LazyImageLoader;
