import React from 'react';

import classNames from 'classnames';

import styles from './AppLoader.css';

type AppLoaderProps = {
    text?: string;
    className?;
    fullPage?: boolean;
    inWrapper?: boolean;
};

export const AppLoader = React.memo(({ text, className, fullPage, inWrapper }: AppLoaderProps) => {
    return (
        <Container
            className={classNames(className, {
                [styles.fullPageLoader]: fullPage,
                [styles.inWrapper]: inWrapper,
            })}
        >
            <Svg>
                <Circle />
            </Svg>

            {text && <Text>{text}</Text>}
        </Container>
    );
});
AppLoader.displayName = 'AppLoader';

const Container = (props: any) => <div className={classNames(styles.container, props.className)} {...props} />;
const Svg = (props: any) => (
    <svg
        className={styles.spinner}
        role="img"
        aria-label="loader-img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 66 66"
        {...props}
    />
);
const Circle = (props: any) => (
    <circle className={styles.path} stroke="#08B238" fill="none" cx="33" cy="33" r="30" {...props} />
);
const Text = (props: any) => <div className={styles.text} {...props} />;
