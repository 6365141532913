import * as yup from 'yup';

import { TranslationService } from '../../../../../services/TranslationService';

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required(TranslationService.translateIt('REGISTER_AN_EMAIL_ADDRESS_IS_REQUIRED'))
    .email(TranslationService.translateIt('REGISTER_PLEASE_ENTER_A_VALID_EMAIL_ADDRESS'))
});

export const emailValidation = yup.string().required().email();
