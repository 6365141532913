import React from 'react';

import { iconProps } from '../../models/Icon/IconPropsModel';

export const WhatsAppIcon = ({ className }: iconProps) => {
    return (
        <svg
            className={className}
            width="21"
            height="22"
            viewBox="0 0 21 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.8125 3.59375C15.8906 1.625 13.2656 0.5 10.4531 0.5C4.73438 0.5 0.046875 5.1875 0.046875 10.9062C0.046875 12.7812 0.5625 14.5625 1.45312 16.1094L0 21.5L5.48438 20.0938C7.03125 20.8906 8.71875 21.3594 10.4531 21.3594C16.2188 21.3594 21 16.6719 21 10.9531C21 8.14062 19.7812 5.5625 17.8125 3.59375ZM10.4531 19.5781C8.90625 19.5781 7.40625 19.1562 6.04688 18.3594L5.76562 18.1719L2.48438 19.0625L3.375 15.875L3.14062 15.5469C2.29688 14.1406 1.82812 12.5469 1.82812 10.9062C1.82812 6.17188 5.71875 2.28125 10.5 2.28125C12.7969 2.28125 14.9531 3.17188 16.5938 4.8125C18.2344 6.45312 19.2188 8.60938 19.2188 10.9531C19.2188 15.6875 15.2344 19.5781 10.4531 19.5781ZM15.2344 13.1094C14.9531 12.9688 13.6875 12.3594 13.4531 12.2656C13.2188 12.1719 13.0312 12.125 12.8438 12.4062C12.7031 12.6406 12.1875 13.25 12.0469 13.4375C11.8594 13.5781 11.7188 13.625 11.4844 13.4844C9.9375 12.7344 8.95312 12.125 7.92188 10.3906C7.64062 9.92188 8.20312 9.96875 8.67188 8.98438C8.76562 8.79688 8.71875 8.65625 8.67188 8.51562C8.625 8.375 8.0625 7.10938 7.875 6.59375C7.64062 6.07812 7.45312 6.125 7.26562 6.125C7.125 6.125 6.9375 6.125 6.79688 6.125C6.60938 6.125 6.32812 6.17188 6.09375 6.45312C5.85938 6.73438 5.20312 7.34375 5.20312 8.60938C5.20312 9.92188 6.09375 11.1406 6.23438 11.3281C6.375 11.4688 8.0625 14.0938 10.6875 15.2188C12.3281 15.9688 12.9844 16.0156 13.8281 15.875C14.2969 15.8281 15.3281 15.2656 15.5625 14.6562C15.7969 14.0469 15.7969 13.5312 15.7031 13.4375C15.6562 13.2969 15.4688 13.25 15.2344 13.1094Z"
                fill="#F9F9F9"
            />
        </svg>
    );
};
