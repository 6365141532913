import extensions from './extensions';
import { devConsole } from '../../../utils/DevConsole';
import { adBlockDetector } from '../AdBlockerService';

export type AdBlocker = null | {
    name: string;
    detected: boolean;
    id: string;
};

export const checkAdblockersListChromium = async (isGoodConnection): Promise<string[]> => {
    const extensionsCheckedCommon: any[] = Object.keys(extensions).map(
        async (key) =>
            await fetch(`chrome-extension://${extensions[key].id}/${extensions[key].file}`)
                .then(() => ({ name: key, detected: true, id: extensions[key].id }))
                .catch(() => ({ name: key, detected: false, id: extensions[key].id }))
    );
    const extensionsChecked: AdBlocker[] = await Promise.all(extensionsCheckedCommon).then((values) => values);
    // isGoodConnection condition not to break UX of players with bad connection or slow PC
    // ToDo: Research how to keep checkProtectedExtensions() working with slow PC and using less requests
    const extensionsCheckedProtected: AdBlocker[] = isGoodConnection ? [] /*await checkProtectedExtensions()*/ : [];
    setTimeout(() => {
        devConsole('You have these adblockers installed: ', adBlockDetector);
    }, 1); // to avoid tons of fetch errors in console => need to be called as early as possible
    const extensionsCheckedAll = extensionsChecked.concat(extensionsCheckedProtected);
    const detectedAdblockers: string[] = extensionsCheckedAll
        .filter((extension) => extension.detected)
        .map((extension) => extension.name);
    return detectedAdblockers.length ? detectedAdblockers : null;
};
