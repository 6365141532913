import React from 'react';

import { MiscUtils } from '../../../utils/MiscUtils';
import { ArkCssBreakpoints } from '../../constants/ArkCssBreakpoints';

export enum MobileQueryOrientation {
    PORTRAIT = 'portrait',
    LANDSCAPE = 'landscape',
}

type ResponsiveProps = {
    minWidth?: number;
    maxWidth?: number;
    minHeight?: number;
    maxHeight?: number;
    orientation?: MobileQueryOrientation;
};

const { isServer, debounce } = MiscUtils;

export class Responsive extends React.PureComponent<ResponsiveProps> {
    checkMediaQuery(): boolean {
        if (isServer) {
            if (this.props.minWidth && this.props.minWidth >= ArkCssBreakpoints.ARK_SMALL_DESKTOP) {
                return true;
            }
            return false;
        }

        const query = this.getQuery();
        return Boolean(window.matchMedia(query).matches);
    }

    isNullOrUndefined(value: any) {
        return typeof value === 'undefined' || value === null;
    }

    getQuery(): string {
        const { minWidth, maxWidth, minHeight, maxHeight, orientation } = this.props;

        const minWidthQuery = !this.isNullOrUndefined(minWidth) ? `(min-width: ${minWidth}px)` : ``;

        const maxWidthQuery = !this.isNullOrUndefined(maxWidth) ? `(max-width: ${maxWidth}px)` : ``;

        const minHeightQuery = !this.isNullOrUndefined(minHeight) ? `(min-height: ${minHeight}px)` : ``;

        const maxHeightQuery = !this.isNullOrUndefined(maxHeight) ? `(max-height: ${maxHeight}px)` : ``;

        const orientationQuery = !this.isNullOrUndefined(orientation) ? `(orientation: ${orientation})` : ``;

        let result = ``;

        if (minWidthQuery) {
            result = minWidthQuery;
        }

        if (maxWidthQuery) {
            if (result) {
                result = `${result} and ${maxWidthQuery}`;
            } else {
                result = maxWidthQuery;
            }
        }

        if (minHeightQuery) {
            if (result) {
                result = `${result} and ${minHeightQuery}`;
            } else {
                result = minHeightQuery;
            }
        }

        if (maxHeightQuery) {
            if (result) {
                result = `${result} and ${maxHeightQuery}`;
            } else {
                result = maxHeightQuery;
            }
        }

        if (orientationQuery) {
            if (result) {
                result = `${result} and ${orientationQuery}`;
            } else {
                result = orientationQuery;
            }
        }

        return result;
    }

    rerenderComponent = () => {
        this.forceUpdate();
    };

    debouncedResizeHandler = debounce(this.rerenderComponent, 25);

    componentDidMount() {
        window.addEventListener('resize', this.debouncedResizeHandler);
        this.rerenderComponent();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.debouncedResizeHandler);
    }

    render() {
        const chk = this.checkMediaQuery();

        if (chk) {
            return this.props.children;
        }

        return <></>;
    }
}

export const DesktopQuery = (props: any) => {
    return <Responsive {...props} minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP} />;
};

export const MobileQuery = (props: any) => {
    return <Responsive {...props} maxWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP_BELOW} />;
};
