import React, { useContext, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styles from './CompleteSocialProfile.css';
import { defaultValues, validationSchema } from './validationSchemas/CompleteSocialProfileValidationSchema';
import { Avatar, AvatarTypes } from '../../../../atoms/Avatar/Avatar';
import { Checkbox } from '../../../../atoms/Checkbox/Checkbox';
import { Input } from '../../../../atoms/HookFormInput/Input';
import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { CheckboxTypes } from '../../../../atoms/Icons/Styleguide/CheckboxIcon';
import { getAvatarAnalyticsKey } from '../../../../constants/AvatarImagesData';
import { SignInUpComponents } from '../../../../constants/SignInUpComponents';
import { TextWithLink } from '../../../../FigmaStyleguide/TextWithLink/TextWithLink';
import { AuthorizationProvider } from '../../../../models/User/AuthorizationResponse';
import { RegistrationSource } from '../../../../models/User/RegistrationSource';
import {
  PASSWORD_VALIDATION_INSTRUCTIONS_IDS,
  ValidationHints
} from '../../../../molecules/ValidationHints/ValidationHints';
import { Analytics } from '../../../../services/Analytics/Analytics';
import { LEANPLUM_EVENTS, LeanplumAnalytics } from '../../../../services/Analytics/LeanplumAnalytics';
import { AuthDataContext } from '../../../../services/AuthDataReact';
import { GameService } from '../../../../services/GameService';
import { TranslationService } from '../../../../services/TranslationService';
import UserService from '../../../../services/UserService';
import { setSignInUpState, setSnackbarData } from '../../../../store/ducks/layout';
import { SocialButtons } from '../../../SocialButtons/SocialButtons';

export const CompleteSocialProfile = React.memo(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [checkBox, setCheckbox] = useState<boolean>(true);
  const categoryPageName = useSelector(({ categoryPageName }) => categoryPageName);
  const gameArkadiumSlug = useSelector(({ gameArkadiumSlug }) => gameArkadiumSlug);
  const games = useSelector(({ games }) => games);
  const user = useSelector(({ user }) => user);
  const socialRegistrationLocation = useSelector(({ socialRegistrationLocation }) => socialRegistrationLocation);
  const authDataContext = useContext(AuthDataContext);
  const game = gameArkadiumSlug
    ? GameService.findGameByArkadiumSlug(GameService.gameModelToGame(games), gameArkadiumSlug)
    : '';
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues
  });
  const values = watch();
  const { email, password } = values;

  useEffect(() => {
    setValue('email', authDataContext.value.email);
  }, []);

  const onSubmit = () => {
    const { avatar, avatarBackground } = user;
    const avatarAnalyticsKey = getAvatarAnalyticsKey(avatar);

    void Analytics.trackEvent(
      Analytics.profile.registration(
        categoryPageName,
        game,
        RegistrationSource.EMAIL,
        avatarAnalyticsKey,
        avatarBackground,
        socialRegistrationLocation
      )
    );
    LeanplumAnalytics.trackEvent(LEANPLUM_EVENTS.REGISTRATION);
    // To have LP userAttribute {registered: true} set here we don't have email confirmed,
    // final Eagle user id, and LP session started with this id to set it in LP to correct user
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.tabContent}>
      <div className={styles.header}>
        <I18nText keyName="COMPLETE_PROFILE"/>
      </div>
      <Avatar image={authDataContext.value.avatar} avatarList size={AvatarTypes.MEDIUM_LIST_ITEM} noFrame/>
      <div className={styles.subHeader}>
        Welcome back, <b>{authDataContext.value.name}</b> <br/>
        <b>{authDataContext.value.email}</b> <br/>
        <I18nText keyName="COMPLETE_SOCIAL_PROFILE"/>
      </div>
      <div className={styles.inputWrapper}>
        <Input
          className={styles.input}
          isValid={!errors.email}
          ariaInvalid={errors.email ? 'true' : 'false'}
          register={register}
          name="email"
          type="text"
          placeholder={t('SIGN_UP.ENTER_EMAIL')}
          value={email}
          showError={errors?.email}
          errorMessage={errors?.email?.message ?? ''}
          disabled
        />
      </div>
      <div className={styles.inputWrapper}>
        <Input
          required
          className={styles.input}
          isValid={!errors.password}
          ariaInvalid={errors.password ? 'true' : 'false'}
          register={register}
          name="password"
          type="password"
          placeholder={t('REGISTER_CREATE_YOUR_PASSWORD')}
          value={password}
          ariaDescribedBy={PASSWORD_VALIDATION_INSTRUCTIONS_IDS}
        />
        <ValidationHints password={password}/>
      </div>
      <Checkbox
        className={styles.checkboxContainer}
        iconClassName={styles.checkbox}
        labelClassName={styles.checkboxLabel}
        onClick={() => {
          setCheckbox(!checkBox);

          if (!checkBox) {
            void Analytics.trackEvent(Analytics.profile.emailOptIn());
          }
        }}
        state={checkBox ? CheckboxTypes.ACTIVE : CheckboxTypes.INACTIVE}
        caption={TranslationService.translateIt('REGISTER_EMAIL_ME')}
        dataElementDescription="radio-button"
        btnType="button"
      />
      <SocialButtons
        provider={AuthorizationProvider[authDataContext.value.auth_provider_id[0]]}
        email={email}
        className={styles.socialButtons}
        game={game}
        category={categoryPageName}
        checkBox={checkBox}
        errorMessageSocial={(res) => {
          dispatch(
            setSnackbarData({
              isOpened: true,
              message: UserService.errorCodeToText(res),
              type: 'error',
              parentNode: 'rightSideMenu'
            })
          );
        }}
        password={password}
      />
      <TextWithLink
        textKeyName={`Not ${authDataContext.value.email}? `}
        linkKeyName="Start over"
        onClick={() => dispatch(setSignInUpState(SignInUpComponents.SIGN_IN))}
      />
    </form>
  );
});
