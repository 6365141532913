import React from 'react';

import { iconProps } from '../../models/Icon/IconPropsModel';

export const DiscountIcon = React.memo((props: iconProps) => {
    return (
        <svg
            className={props.className}
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.8993 4.5C12.9353 4.5 10.2494 6.24551 9.04566 8.95397L3.71233 20.954C2.85045 22.8932 2.85045 25.1068 3.71233 27.046L9.04566 39.046C10.2494 41.7545 12.9353 43.5 15.8993 43.5H38C42.1421 43.5 45.5 40.1421 45.5 36V12C45.5 7.85786 42.1421 4.5 38 4.5H15.8993ZM11.7871 10.1724C12.5094 8.5473 14.1209 7.5 15.8993 7.5H38C40.4853 7.5 42.5 9.51472 42.5 12V36C42.5 38.4853 40.4853 40.5 38 40.5H15.8993C14.1209 40.5 12.5094 39.4527 11.7871 37.8276L6.45377 25.8276C5.93664 24.6641 5.93664 23.3359 6.45377 22.1724L11.7871 10.1724ZM31.2992 16.9561L28.7011 15.4561L19.7011 31.0445L22.2992 32.5445L31.2992 16.9561ZM20.0098 21.0027C20.0108 22.1087 19.1149 23.0054 18.0088 23.0054C16.9027 23.0054 16.0053 22.1087 16.0043 21.0027C16.0033 19.8966 16.8992 18.9999 18.0053 18.9999C19.1114 18.9999 20.0088 19.8966 20.0098 21.0027ZM18.0115 26.0053C20.7744 26.0053 23.0121 23.7655 23.0097 21.0027C23.0073 18.2398 20.7655 16 18.0026 16C15.2397 16 13.002 18.2398 13.0044 21.0027C13.0068 23.7655 15.2486 26.0053 18.0115 26.0053ZM33.0088 29.0054C34.1149 29.0054 35.0108 28.1087 35.0098 27.0027C35.0088 25.8966 34.1114 24.9999 33.0053 24.9999C31.8992 24.9999 31.0033 25.8966 31.0043 27.0027C31.0053 28.1087 31.9027 29.0054 33.0088 29.0054ZM38.0097 27.0027C38.0121 29.7655 35.7744 32.0053 33.0115 32.0053C30.2486 32.0053 28.0068 29.7655 28.0044 27.0027C28.002 24.2398 30.2397 22 33.0026 22C35.7655 22 38.0073 24.2398 38.0097 27.0027Z"
                fill="#F9F9F9"
            />
        </svg>
    );
});
