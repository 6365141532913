import React from 'react';

import { iconProps } from '../../../models/Icon/IconPropsModel';

export const SearchIcon = React.memo((props: iconProps) => {
    return (
        <svg
            role="img"
            aria-label="search-icon"
            className={props.className}
            width="35"
            height="34"
            viewBox="0 0 35 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M31.2558 28.7442L26 23.5308C28.0401 20.9871 29.0281 17.7583 28.7608 14.5085C28.4935 11.2587 26.9912 8.23476 24.5628 6.05857C22.1344 3.88237 18.9645 2.71929 15.705 2.80849C12.4454 2.89768 9.34384 4.23237 7.0381 6.5381C4.73237 8.84384 3.39768 11.9454 3.30849 15.205C3.21929 18.4645 4.38237 21.6344 6.55857 24.0628C8.73476 26.4912 11.7587 27.9935 15.0085 28.2608C18.2583 28.5281 21.4871 27.5401 24.0308 25.5L29.2442 30.7133C29.3759 30.8461 29.5325 30.9515 29.7052 31.0234C29.8778 31.0953 30.063 31.1324 30.25 31.1324C30.437 31.1324 30.6222 31.0953 30.7948 31.0234C30.9674 30.9515 31.1241 30.8461 31.2558 30.7133C31.5112 30.4492 31.6539 30.0961 31.6539 29.7287C31.6539 29.3613 31.5112 29.0083 31.2558 28.7442ZM16.0833 25.5C14.122 25.5 12.2047 24.9184 10.5739 23.8287C8.94313 22.7391 7.67208 21.1903 6.92151 19.3783C6.17095 17.5662 5.97456 15.5723 6.3572 13.6487C6.73984 11.725 7.68431 9.95805 9.07118 8.57118C10.458 7.18431 12.225 6.23984 14.1487 5.8572C16.0723 5.47456 18.0662 5.67095 19.8783 6.42151C21.6903 7.17208 23.2391 8.44313 24.3287 10.0739C25.4184 11.7047 26 13.622 26 15.5833C26 18.2134 24.9552 20.7357 23.0955 22.5955C21.2357 24.4552 18.7134 25.5 16.0833 25.5Z"
                fill="#231F20"
            />
        </svg>
    );
});
