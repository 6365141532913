import React, { useState } from 'react';

import classNames from 'classnames';
import { FieldError } from 'react-hook-form';

import styles from './Input.css';
import { InputEyeClose } from '../../FigmaStyleguide/Icons/InputEyeClose';
import { InputEyeOpen } from '../../FigmaStyleguide/Icons/InputEyeOpen';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { NoMobileZoomOn } from '../ZoomControls/NoMobileZoomOn';

export enum INPUT_TYPE {
  TEXT = 'text',
  PASSWORD = 'password',
  SEARCH = 'search',
  EMAIL = 'email',
  BUTTON = 'button',
  FILE = 'file',
}

type InputProps = {
  value: string;
  register: any; // need to check ts for react-hook-form
  name: string;
  ariaInvalid: string;
  ariaDescribedBy?: string;
  type: string;
  className?: string;
  placeholder: string;
  isValid: boolean;
  showError?: FieldError;
  errorMessage?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: () => void;
  minLength?: number;
  maxLength?: number;
  dataRecurly?: string;
  disabled?: boolean;
  required?: boolean;
};

export const Input = ({
  value,
  register,
  name,
  ariaInvalid,
  ariaDescribedBy,
  type,
  className,
  placeholder,
  isValid,
  onFocus,
  onBlur,
  onChange,
  minLength,
  maxLength,
  dataRecurly,
  showError,
  errorMessage,
  disabled = false,
  required
}: InputProps) => {
  const [typeInternal, setTypeInternal] = useState(type || INPUT_TYPE.TEXT);
  const toggleType = () => {
    setTypeInternal(typeInternal === INPUT_TYPE.PASSWORD ? INPUT_TYPE.TEXT : INPUT_TYPE.PASSWORD);
  };

  return (
    <div className={styles.wrapper}>
      <NoMobileZoomOn />
      <input
        aria-describedby={ariaDescribedBy ?? `${name}Error`}
        className={classNames(
          styles.input,
          disabled && styles.disabled,
          isValid ? value?.length > 0 && styles.successful : styles.error,
          className
        )}
        aria-invalid={ariaInvalid}
        type={typeInternal}
        id={name}
        placeholder={placeholder}
        onFocus={onFocus}
        minLength={minLength}
        maxLength={maxLength}
        data-recurly={dataRecurly}
        required={required ? 'required' : ''}
        {...register(name, {
          value: value,
          onBlur: onBlur,
          onChange: onChange
        })}
        disabled={disabled}
      />
      {type === INPUT_TYPE.PASSWORD && (
        <button onClick={toggleType} className={styles.handlerIcon} type="button">
          {typeInternal === INPUT_TYPE.PASSWORD ? (
            <InputEyeClose className={styles.eyeIconClose} />
          ) : (
            <InputEyeOpen className={styles.eyeIconOpen} />
          )}
        </button>
      )}
      {showError && (
        <ErrorMessage
          id={`${name}Error`}
          className={styles.errorMessage}
          message={errorMessage}
        />
      )}
    </div>
  );
};
