import React from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { Button } from "../../atoms/Buttons/Base/Base";
import { GameCardTypes } from "../GameCard/GameCard";
import styles from "../GameCard/GameCard.css";
import { GameCardCobrandedTypes } from "../GameCardCobranded/GameCardCobranded";

interface GameCardErrorButtonProps {
  gameCardType: GameCardTypes|GameCardCobrandedTypes;
  dataElementDescription: string;
}

const GameCardErrorButton = ({ gameCardType, dataElementDescription }: GameCardErrorButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      onDragStart={() => false}
      draggable={false}
      className={classNames(styles.gameCard, styles[gameCardType])}
      data-element-description={dataElementDescription}
      noPadding
    >
      <div>{t('GAME_CARD_ERROR')}</div>
    </Button>
  );
};

export default GameCardErrorButton;