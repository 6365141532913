import { UserModel } from './UserModel';

export type AuthorizationResponse = {
    access_token: string;
    refresh_token: string;
    token_type: string;
    expires_in: number;
    user: UserModel;
};

export enum AuthorizationProvider {
    FACEBOOK = 1,
    GOOGLE = 2,
    APPLE = 3,
}
