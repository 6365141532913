import { createAction, createReducer } from 'redux-act';

export type LeanplumCarouselSlide = {
    //deprecated background image
    backgroundImage?: string;
    desktopBackgroundImage: string;
    tabletBackgroundImage?: string;
    mobileBackgroundImage?: string;
    buttonLink: string;
    buttonText: string;
    title: string;
    subtitle: string;
    mobileTitle?: string;
    mobileSubtitle?: string;
    mobileContent?: boolean;
};

export const homepageCarouselInitialData: LeanplumCarouselSlide[] = [
    {
        title: 'Welcome to Arkadium',
        subtitle: 'Your destination for the best online games',
        buttonText: 'Play now',
        buttonLink: '',
        desktopBackgroundImage:
            'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-index/_arena-shared-content_/ark-carousel/Prod/placeholder/placeholder-pc.webp',
        tabletBackgroundImage:
            'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-index/_arena-shared-content_/ark-carousel/Prod/placeholder/placeholder-tab.webp',
        mobileBackgroundImage:
            'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-index/_arena-shared-content_/ark-carousel/Prod/placeholder/placeholder-mob.webp',

    },
];

export const leanplumCarouselData = createReducer<LeanplumCarouselSlide[]>({}, homepageCarouselInitialData);
export const setLeanplumCarouselData = createAction<LeanplumCarouselSlide[]>('set carousel data');
leanplumCarouselData.on(setLeanplumCarouselData, (_state, payload) => payload);
