import React, { FC, memo } from 'react';

import { Helmet } from 'react-helmet-async';
import { UrlService } from '../../services/UrlService';
import IPageMeta from "../../types/pageMeta";

interface IStructuredDataWebPage {
    pageMeta: IPageMeta;
}

export const StructuredDataWebPage: FC<IStructuredDataWebPage> = memo((props) => {
    const { name, url, description, datePublished, dateModified, image, imageWidth, imageHeight } = props.pageMeta;

    const structuredData = {
        '@context': 'https://schema.org/',
        '@type': 'WebPage',
        '@id': `${url}#webpage`,
        url: UrlService.createURL(UrlService.removeCountryCodeFromUrlBeginning(url), undefined, false, true),
        inLanguage: 'en',
        name: `${name}`,
        description: `${description}`,
        datePublished: `${datePublished}`,
        dateModified: `${dateModified}`,
        about: { '@id': `https://www.arkadium.com/#organization` },
        isPartOf: { '@id': 'https://www.arkadium.com/#website' },
        primaryImageOfPage: {
            '@type': 'ImageObject',
            '@id': `${url}#primaryimage`,
            url: `${image}`,
            width: `${imageWidth}`,
            height: `${imageHeight}`,
        },
    };

    return (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
        </Helmet>
    );
});
