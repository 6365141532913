import { createAction, createReducer } from 'redux-act';

import { Page } from '../../../models/Strapi';

export const setStrapiPages = createAction<Array<Page>>('set strapi pages');
export const strapiPagesReducer = createReducer<Array<Page>>({}, []);
strapiPagesReducer.on(setStrapiPages, (_state, payload) => payload);

export const setStrapiPageSlugs = createAction<Array<string>>('set strapi page slugs');
export const strapiPageSlugsReducer = createReducer<Array<string>>({}, []);
strapiPageSlugsReducer.on(setStrapiPageSlugs, (_state, payload) => payload);


export const setStrapiComingSoonPages = createAction<Array<Page>>('set strapi coming soon pages');
export const strapiComingSoonPagesReducer = createReducer<Array<Page>>({}, []);
strapiComingSoonPagesReducer.on(setStrapiComingSoonPages, (_state, payload) => payload);