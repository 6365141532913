import { CookieService } from './CookieService';
import { GameService } from './GameService';
import { UrlService } from './UrlService';
import { StringUtils } from '../../utils/StringUtils';
import { additionalNonEnSupportedLocales, defaultNonEnSupportedLocales } from "../constants/Locales";
import { NavigationMenuItem } from '../constants/Menu';
import { PageName, PageTypes } from '../constants/Pages';
import { LS_COOKIE_CONSTS } from '../models/Enums';
import { IGame } from '../models/Game/Game';
import { CategoryPageSEO, CoBrandedPageSEO, GeneralPageSEO, PagesData } from '../models/PagesData';

enum PageNames {
  ALL = 'All',
  ALL_CATEGORIES = 'AllCategories',
  HOME = 'Home',
  NEW = 'New',
  BEST = 'Best',
}

export type SidebarCategories = {
  order: number;
  name: string;
  sidebarCaption: string;
  pageName: PageName;
};

export class PageService {
  public static getPageNameBySlug(
    pages: (GeneralPageSEO | CategoryPageSEO | CoBrandedPageSEO)[],
    language: string,
    slug: string,
    pageType: string,
    searchOnlyInCategory = false
  ): PageName {
    // since now, we have 2 pages with similar slug ('All' games category and 'All Categories' category)
    // we should avoid search by slug in duplicate values
    if (pageType === PageTypes.AllCategories) {
      return pages.find((ps) => StringUtils.equalIgnoreCase(ps.pageName, PageTypes.AllCategories)).pageName;
    }

    // remove 'All categories' page from menu items
    pages = pages.filter((ps) => !StringUtils.equalIgnoreCase(ps.pageName, PageTypes.AllCategories));

    slug = slug || ``;

    const found = pages.find((pageSEO) => {
      const equals = StringUtils.equalIgnoreCase(slug, pageSEO.slug[language]);

      if (searchOnlyInCategory) {
        return (pageSEO as CategoryPageSEO).category && equals;
      }

      return equals;
    });

    return found ? (found.pageName as PageName) : null;
  }

  public static getPageSEOByPageName(
    pages: (GeneralPageSEO | CategoryPageSEO | CoBrandedPageSEO)[],
    name: PageName
  ): GeneralPageSEO | CategoryPageSEO | CoBrandedPageSEO {
    return pages.find((pageSEO) => StringUtils.equalIgnoreCase(pageSEO.pageName, name));
  }

  public static getPageSEOByName(
    pages: (GeneralPageSEO | CategoryPageSEO | CoBrandedPageSEO)[],
    name: string,
    lang: string
  ): GeneralPageSEO | CategoryPageSEO | CoBrandedPageSEO {
    return pages.find((pageSEO) => StringUtils.equalIgnoreCase(pageSEO.name[lang], name));
  }

  public static getTopLevelMenuItems(
    pages: (GeneralPageSEO | CategoryPageSEO | CoBrandedPageSEO)[],
    language: string,
    games: IGame[]
  ): NavigationMenuItem[] {
    const result: NavigationMenuItem[] = [];

    pages.forEach((page) => {
      let item: NavigationMenuItem = null;

      if (StringUtils.equalIgnoreCase(page.pageName, PageNames.ALL_CATEGORIES)) {
        return;
      }

      if (StringUtils.equalIgnoreCase(page.pageName, PageNames.ALL)) {
        item = {
          order: page.order,
          key: `/${UrlService.getPageLocalizedRoute(language, PageTypes.Category)}/`,
          caption: page.navigationLabel[language], // Actual value - 'All Games'
          sidebarCaption: (page as CategoryPageSEO).sidebarLabel[language],
          slug: page.slug[language],
          url: UrlService.createURL(`/${UrlService.getPageLocalizedRoute(language, PageTypes.Category)}/`),
          pageName: page.pageName
        };
      } else if (StringUtils.equalIgnoreCase(page.pageName, PageNames.HOME)) {
        item = {
          order: page.order,
          key: `/`,
          caption: page.navigationLabel[language],
          slug: page.slug[language],
          url: UrlService.createURL('/'),
          pageName: page.pageName
        };
      } else {
        const categoryGames = games.filter((game) =>
          game.categories.find((gameCategory) =>
            StringUtils.equalIgnoreCase(gameCategory, page.name[language])
          )
        );

        if (
          (page as CategoryPageSEO).category &&
          GameService.filterGamesByLang(categoryGames, language).length > 0 &&
          page.name[language] // if category has filled name
        ) {
          item = {
            order: page.order,
            key: `/${UrlService.getPageLocalizedRoute(language, PageTypes.Category)}/${
              page.slug[language]
            }/`,
            caption: page.navigationLabel[language],
            sidebarCaption: (page as CategoryPageSEO).sidebarLabel[language],
            slug: page.slug[language],
            url: UrlService.createURL(
              `/${UrlService.getPageLocalizedRoute(language, PageTypes.Category)}/${page.slug[language]}/`
            ),
            pageName: page.pageName
          };
        }
      }

      if (item) {
        result.push(item);
      }
    });

    return result.sort((p1, p2) => p1.order - p2.order);
  }

  public static getSidebarLevelMenuItems(
    pages: (GeneralPageSEO | CategoryPageSEO | CoBrandedPageSEO)[],
    language: string,
    games: IGame[]
  ): SidebarCategories[] {
    const result = [];

    pages.forEach((page) => {
      let item = null;

      if (StringUtils.equalIgnoreCase(page.pageName, PageNames.HOME)) {
        return;
      }

      if (StringUtils.equalIgnoreCase(page.pageName, PageNames.ALL_CATEGORIES)) {
        return;
      }

      if (StringUtils.equalIgnoreCase(page.pageName, PageNames.ALL)) {
        item = {
          order: page.order,
          name: page.name[language],
          sidebarCaption: (page as CategoryPageSEO).sidebarLabel[language],
          pageName: page.pageName
        };
      } else if ((page as CategoryPageSEO).category) {
        const categoryGames = games.filter((game) =>
          game.categories.find((gameCategory) =>
            StringUtils.equalIgnoreCase(gameCategory, page.name[language])
          )
        );

        if (
          (page as CategoryPageSEO).category &&
          GameService.filterGamesByLang(categoryGames, language).length > 0 &&
          page.name[language] // if category has filled name
        ) {
          item = {
            order: page.order,
            name: page.name[language],
            sidebarCaption: (page as CategoryPageSEO).sidebarLabel[language],
            pageName: page.pageName
          };
        }
      }

      if (item) {
        result.push(item);
      }
    });

    return result.sort((p1, p2) => p1.order - p2.order);
  }

  public static getNonEnLocales = (
      category: GeneralPageSEO | CategoryPageSEO | CoBrandedPageSEO,
      hasAdditionalNonEnLocales?: boolean
  ): string[] =>
      Object.keys(category.name).filter((locale) =>
          hasAdditionalNonEnLocales
              ? additionalNonEnSupportedLocales
                  .concat(defaultNonEnSupportedLocales)
                  .find((language) => StringUtils.equalIgnoreCase(language, locale))
              : defaultNonEnSupportedLocales.find((language) => StringUtils.equalIgnoreCase(language, locale))
      );

  public static getPageSlugByAffiliateCookie(pages) {
    return PagesData.getOnlyCoBrandedPages(pages).find(
      (page) => page.cookieValue === CookieService.getArkCookie(LS_COOKIE_CONSTS.ARK_AFFILIATE)
    )?.slug['en'];
  }
}
