import { batch } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { globalErrorHandler } from '../../../utils/LogUtils';
import { skipPrerollItem } from '../../constants/GemsConstants';
import { AppInsightService } from '../../services/AppInsight';
import GemsService from '../../services/GemsService';
import {
  setIsGemsAmountLoading,
  setGemsAmount,
  setPrerollSkipPrice,
  setIsPrerollSkipPriceLoading
} from '../ducks/gems/gems';
import { AppState } from '../index';

export class GemsEffects {
  static UpdateGemsAmount() {
    return async (dispatch: ThunkDispatch<AppState, void, Action<any>>) => {
      dispatch(setIsGemsAmountLoading(true));

      try {
        const gemsAmount = await GemsService.getUserGemsAmount();

        batch(() => {
          dispatch(setGemsAmount(gemsAmount));
          dispatch(setIsGemsAmountLoading(false));
        });
      } catch (err) {
        globalErrorHandler({ error: err, filename: 'gems.effects.ts', info: 'UpdateGemsAmount()' });
        AppInsightService.trackAppError(err, { data: 'getUserGemsAmount()' });
        batch(() => {
          dispatch(setGemsAmount(0));
          dispatch(setIsGemsAmountLoading(false));
        });
      }
    };
  }

  static UpdatePriceSkipPreroll() {
    return async (dispatch: ThunkDispatch<AppState, void, Action<any>>) => {
      dispatch(setIsPrerollSkipPriceLoading(true));

      try {
        const prerollSkipPrice = await GemsService.getPurchasableItemsBySku(skipPrerollItem.sku);

        batch(() => {
          // TODO: usage of 0 index can lead to errors. Need to consider some usage of sku.
          // @ts-ignore-next-line
          dispatch(setPrerollSkipPrice(prerollSkipPrice?.internalPrices[0].amount));
          dispatch(setIsPrerollSkipPriceLoading(false));
        });
      } catch (err) {
        globalErrorHandler({ error: err, filename: 'gems.effects.ts', info: 'UpdatePriceSkipPreroll()' });
        AppInsightService.trackAppError(err, { data: 'UpdatePriceSkipPreroll()' });
        batch(() => {
          dispatch(setPrerollSkipPrice(0)); // todo ask Tristan about it
          dispatch(setIsPrerollSkipPriceLoading(false));
        });
      }
    };
  }


}
