import React, { FocusEventHandler, KeyboardEventHandler, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CloseIcon } from '../Icons/Styleguide/CloseIcon';
import { SearchIcon } from '../Icons/Styleguide/SearchIcon';
import { WarningIcon } from '../Icons/Styleguide/WarningIcon';
import styles from './TextField.css';

export enum TextFieldTypes {
    SIMPLE_INPUT = 'simple',
    SEARCH_INPUT = 'search',
}

type TextFieldProps = {
    inputType: TextFieldTypes;
    inputFieldId?: string;
    placeholder?: string;
    value?: string;
    onChange?: (value: string) => void;
    type?: string;
    onFocus?: () => void;
    className?: string;
    isValid?: boolean;
    errorText?: string;
    onBlur?: FocusEventHandler;
    onBlurEnabled?: boolean;
    onKeyDown?: KeyboardEventHandler;
    dataElementDescription?: string;
    focusOnLoad?: boolean;
    ariaLabel?: string;
    maxLength?: number;
    autocompleteAttrValue?: string;
    required?: boolean;
    engageFocus?: boolean;
    onClick?: (e) => void;
};

export const TextField = React.memo((props: TextFieldProps) => {
    const isValid = !('isValid' in props) || ('isValid' in props && props.isValid);
    const ERROR_ELEMENT_ID = `${props.inputFieldId}-error-message`;

    const [inFocus, setInFocus] = useState(false);
    const ref = useRef<HTMLInputElement | null>(null);
    useEffect(() => {
        if (props.engageFocus || props.inputType === TextFieldTypes.SEARCH_INPUT) {
            ref.current?.focus();
        }
    }, [props.inputType, props.engageFocus]);

    useEffect(() => {
        if (props.focusOnLoad) {
            const observer = new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting) {
                    ref.current?.focus();
                }
            });
            if (ref.current) {
                observer.observe(ref.current);
            }

            return () => {
                observer.disconnect();
            };
        }
    }, [ref, ref.current, props.focusOnLoad]);

    const mobileNavmenuUxRedesignActiveButton = useSelector((state) => state.mobileNavigationUxRedesignedActiveButton);
    const redesignSearchPlaceholder = useTranslation()?.[0]?.('SEARCH_GAME_PLACEHOLDER');
    const placeholder =
        props.inputType === TextFieldTypes.SEARCH_INPUT && Boolean(mobileNavmenuUxRedesignActiveButton)
            ? (redesignSearchPlaceholder as unknown as string) || props.placeholder
            : props.placeholder;

    return (
        <div
            className={classNames(styles.inputContainer, props.className, {
                [styles.invalid]: !isValid,
                [styles.inFocus]: inFocus,
                [styles.searchField]: props.inputType === TextFieldTypes.SEARCH_INPUT,
            })}
        >
            <div className={styles.inputWithIcon}>
                <input
                    id={props.inputFieldId}
                    autoComplete={props.autocompleteAttrValue}
                    required={props.required}
                    ref={ref}
                    onChange={(e) => {
                        props.onChange?.(e.target.value);
                    }}
                    onFocus={() => {
                        props.onFocus?.();
                        setInFocus(true);
                    }}
                    onBlur={(e) => {
                        setInFocus(false);
                        if (props.onBlurEnabled) {
                            props.onBlur?.(e);
                        }
                    }}
                    onKeyDown={(e) => {
                        props.onKeyDown?.(e);
                    }}
                    onClick={(e) => props.onClick && props.onClick(e)}
                    placeholder={placeholder}
                    value={props.value}
                    type={props.type || 'text'}
                    className={classNames(styles.input, {
                        [styles.search]: props.inputType === TextFieldTypes.SEARCH_INPUT,
                    })}
                    aria-labelledby={props.dataElementDescription}
                    data-element-description={props.dataElementDescription}
                    aria-label={props.ariaLabel}
                    maxLength={props.maxLength || 50}
                    aria-describedby={ERROR_ELEMENT_ID || undefined}
                    aria-invalid={props.inputType === TextFieldTypes.SIMPLE_INPUT && !props.isValid}
                />
                {props.inputType === TextFieldTypes.SEARCH_INPUT && (
                    <div className={styles.iconContainer}>
                        <SearchIcon />
                    </div>
                )}

                {(props.value || '').length >= 3 && (
                    <button
                        className={styles.clearButton}
                        onClick={() => {
                            props.onChange?.('');
                        }}
                        aria-label="clear input field"
                        tabIndex={0}
                    >
                        <CloseIcon />
                    </button>
                )}

                {!isValid && <WarningIcon className={styles.warningIcon} />}
            </div>

            <span
                id={props.dataElementDescription}
                className={classNames(styles.inputLabel, {
                    [styles.visibleLabel]: inFocus || Boolean(props.value) || !isValid || props.required,
                })}
            >
                {`${placeholder}${props.required ? ' *' : ''}`}
            </span>

            {!isValid && (
                <p className={styles.errorText} id={props.inputFieldId && ERROR_ELEMENT_ID}>
                    {props.errorText}
                </p>
            )}
        </div>
    );
});
