import { environment } from '../../../config/environment';
import { IGame } from '../../../models/Game/Game';
import { Analytics } from '../Analytics';
import { AnalyticsEvent, AnalyticsEventAction, AnalyticsEventBuilder } from '../AnalyticsEventBuilder';

export class GemsAnalyticsGa {
    private newEventBuilder(): AnalyticsEventBuilder {
        return new AnalyticsEventBuilder();
    }

    gemsPurchaseSuccess(internalPrice: any, purchasableItemId: string, gameId?: string): AnalyticsEvent {
        const event = this.newEventBuilder();

        event.setNameGA('virtualItemPurchaseSuccess');
        event.setEventGaCategory('virtualItemPurchaseSuccess');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel(purchasableItemId);

        if (typeof internalPrice === 'number' || internalPrice) {
            event.setCustom('event_value', internalPrice);
        }

        event.setGaNonInteraction(false);
        return event.build();
    }

    videoAdSkipClick(game: IGame): AnalyticsEvent {
        const event = this.newEventBuilder();

        event.setNameGA('Video_Ad_Skip_Click');
        event.setCustom('event_category', game.slug);
        event.setCustom('event_label', Analytics.generateLabelForGA4(environment.ARENA_DOMAIN, `/games/${game.slug}`));
        event.setGaNonInteraction(false);
        return event.build();
    }
}
