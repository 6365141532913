// TODO: use IGame.pushNotificationEnabled property instead in the future
export const pushSupportedPages = [
    'home',
    'popular',
    'new',
    'card',
    'strategy',
    'word',
    'puzzles',
    'crosswords',
    'solitaire',
    'mahjong',
    'addicting',
    'math',
    'sudoku',
    'board-games',
    'bridge',
    'bridge-classic',
    'mahjongg-dimensions',
    'word-wipe',
    'mahjongg-dark-dimensions',
    'mahjongg-candy',
    'pyramid-solitaire',
    'mahjongg-dimensions-new',
    'classic-solitaire',
    'holiday-mahjong-dimensions',
    'free-online-backgammon',
    'crescent-solitaire',
    'mahjongg-dimensions-candy',
    'free-online-hearts-card-game',
    'spades-free-online-card-game',
    'tripeaks-solitaire-free',
    'mahjongg-toy-chest',
    'canfield-solitaire',
    'trizzle',
    'masque-chess-multiplayer',
    'mahjongg-alchemy',
    'klondike-solitaire',
    'daily-crossword',
    'masque-bingo-multiplayer',
    'outspell',
    'free-freecell-solitaire',
    'spider-solitaire',
    'free-addiction-solitaire',
    'mahjongg-solitaire',
    '5-roll',
    'spellbound',
    'monkey-gems',
    'match-merge',
    'sudoku',
    'scramble-words-game',
    'sparks',
    'tumble-tiles',
    'the-daily-sudoku',
    'pegland',
    'ice-cream-blast',
    'online-checkers-game',
    'flip-out',
    'eggz-blast',
    'forty-thieves-solitaire',
    'free-online-jigsaw-puzzle',
    'the-daily-diagonal-sudoku',
    'treasure-hunt',
    'the-daily-jigsaw',
    'golf-solitaire',
    'bubble-dragons',
    'free-8-ball-pool',
    'score',
    'kritter-krawler',
    'slingshock',
    'blackjack',
    'word-search',
    'stunt-armadillos',
    'picture-pieces',
    '2048-game',
    'spliterature',
    'wander-words',
    'keno',
    'minesweeper',
    'slots-hollywood-dreams',
    'true',
    'math-doku',
    'daily-crossword-mini',
    'landing-party',
    'alus-revenge-2',
    'daily-crossword-pennydell',
    'daily-sudoku-pennydell',
    'sunday-crossword-pennydell',
    'daily-word-search-pennydell',
    'sheffer-crossword-kingsfeatures',
    'joseph-crossword-kingsfeatures',
    'premier-crossword-kingsfeatures',
    'sweet-shuffle',
    'daily-word-search',
    'ten-x-ten',
    'daily-crossword-commuter',
    '9-ball-pool',
    'free-mahjong-game',
    'bubble-dragons-saga',
    'jigsaw-blast',
    'car-rush-racing-game',
    'block-champ',
    'tap-zap-boom',
    'chess-classic',
    'knife-hit',
    'drop-dunks',
    'switch-colors',
    'neon-blitz',
];
