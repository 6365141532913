import { reduxStore } from '../store';
import { setABTestsValue } from '../store/ducks/abtests';

const { store } = reduxStore;

class AbTestService {
    getVariationFromStore() {
        return store.getState().abTestsValue;
    }
    setVariationToStore(value) {
        if (value) {
            store.dispatch(setABTestsValue(value));
        }
    }
}

export default new AbTestService();
