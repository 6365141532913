export const AvatarPickerDefaultImagesData = [
    {
        filename: 'avatar_blank.png',
        analyticsKey: '0',
        premium: false,
    },
    {
        filename: 'avatar_9.png',
        analyticsKey: '9',
        premium: false,
    },
    {
        filename: 'avatar_14.png',
        analyticsKey: '14',
        premium: false,
    },
    {
        filename: 'avatar_22.png',
        analyticsKey: '22',
        premium: false,
    },
    {
        filename: 'avatar_19.png',
        analyticsKey: '19',
        premium: false,
    },
];
