import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import UserService from '../../services/UserService';
import { AdvantageShopTab } from '../AdvantageShopTab/AdvantageShopTab';
import { SelectSubscription } from '../HeaderSideMenu/SubscriptionTab/SelectSubscription/SelectSubscription';

export const AdvantageTabPanel = () => {
    const user = useSelector((state) => state.user);
    const [isSubscriber, setIsSubscriber] = useState(UserService.isUserSubscriber());
    useEffect(() => {
        setIsSubscriber(UserService.isUserSubscriber());
    }, [user]);
    return <>{isSubscriber ? <AdvantageShopTab /> : <SelectSubscription />}</>;
};
