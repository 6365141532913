import React, { ReactNode } from 'react';

import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { PageTypes } from '../../constants/Pages';
import styles from './Link.css';

type LinkProps = {
    children: ReactNode;
    pageType: PageTypes;
    to?: string;
    target?: string;
    className?: string;
    rel?: string;
    role?: React.AriaRole;
    draggable?: boolean;
    tabIndex?: number;
    suppressHydrationWarning?: boolean;
    onClick?: (...args: unknown[]) => void;
    onMouseDown?: (...args: unknown[]) => void;
    onTouchStart?: (...args: unknown[]) => void;
    onDragStart?: (...args: unknown[]) => void;
    onKeyDown?: (...args: unknown[]) => void;
    itemProp?: string;
    ariaLabel?: string;
    // the title is added so that only links in the header don't jump
    title?: string;
    isSSRLink?: boolean;
};
const WrapperLink = ({
    to,
    className,
    target,
    rel,
    tabIndex,
    role,
    children,
    pageType,
    onClick,
    title,
    itemProp,
    onDragStart,
    onKeyDown,
    draggable,
    suppressHydrationWarning,
    isSSRLink = false,
    ariaLabel,
}: LinkProps) => {
    return pageType !== PageTypes.Game && !isSSRLink ? (
        <Link
            role={role}
            onClick={onClick}
            className={classNames(styles.baseStyle, className)}
            data-name="22"
            to={to}
            title={title}
            itemProp={itemProp}
            target={target}
            rel={rel}
            tabIndex={tabIndex}
            onDragStart={onDragStart}
            onKeyDown={onKeyDown}
            draggable={draggable}
            suppressHydrationWarning={suppressHydrationWarning}
            aria-label={ariaLabel}
        >
            {children}
        </Link>
    ) : (
        <a
            href={to}
            rel={rel}
            title={title}
            itemProp={itemProp}
            className={classNames(styles.baseStyle, className)}
            target={target}
            tabIndex={tabIndex}
            data-name="22"
            role={role}
            onClick={onClick}
            onDragStart={onDragStart}
            onKeyDown={onKeyDown}
            draggable={draggable}
            suppressHydrationWarning={suppressHydrationWarning}
            aria-label={ariaLabel}
        >
            {children}
        </a>
    );
};

export const NavLink = connect((state) => ({
    pageType: state.pageType,
}))(WrapperLink);
