import React, { FC, memo } from 'react';

import { Helmet } from 'react-helmet-async';

import { UrlService } from '../../services/UrlService';

interface IStructuredDataOrganization {
    id: string;
}

export const StructuredDataOrganization: FC<IStructuredDataOrganization> = memo(({ id }) => {
    const logo = UrlService.toCDNUrl('/images/arkadium_logo_medium_500.png');

    const structuredData = {
        '@context': 'https://schema.org/',
        '@type': 'Organization',
        '@id': id,
        logo: {
            '@type': 'ImageObject',
            '@id': 'https://www.arkadium.com/#logo',
            url: logo,
            width: 500,
            height: 173,
            caption: 'Arkadium',
        },
        name: 'Arkadium',
        url: 'https://www.arkadium.com/',
        sameAs: [
            'https://www.facebook.com/arkadium',
            'https://www.instagram.com/arkadiumgames/',
            'https://www.linkedin.com/company/arkadium',
            'https://twitter.com/Arkadium',
        ],
        contactPoint: {
            '@type': 'contactPoint',
            telephone: '+1-212-337-3701',
            contactType: 'sales',
            email: 'sales@arkadium.com',
            contactOption: 'TollFree',
            areaServed: '',
            availableLanguage: null,
        },
        address: {
            '@type': 'PostalAddress',
            addressCountry: 'US',
            addressLocality: 'New York',
            addressRegion: 'NY',
            postalCode: '10010',
            streetAddress: '40 West 25th Street, 4th Fl',
        },
        image: { '@id': 'https://www.arkadium.com/#logo' },
    };

    return (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
        </Helmet>
    );
});
