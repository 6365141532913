import React from 'react';

import { Helmet } from 'react-helmet-async';

import { MobileQuery } from '../Layout/Responsive';

export const NoMobileZoomOn = () => (
    <MobileQuery>
        <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
        </Helmet>
        <div></div>
    </MobileQuery>
);
