import React, { useContext, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { I18nText } from '../../../../../atoms/i18nText/i18nText';
import { Button } from '../../../../../FigmaStyleguide/Button/Button';
import CaptchaIframe from '../../../../../molecules/Captcha/CaptchaIframe';
import useCaptcha, {
  RECAPTCHA_ACTIONS,
  RECAPTCHA_MODES
} from '../../../../../molecules/Captcha/hooks/useCaptcha';
import { Analytics } from '../../../../../services/Analytics/Analytics';
import { AuthDataContext } from '../../../../../services/AuthDataReact';
import UserService from '../../../../../services/UserService';
import { setSnackbarData } from '../../../../../store/ducks/layout';
import { setShowRecaptcha } from '../../../../../store/ducks/recaptcha';
import styles from '../../RightPanelCommonStyles/RightPanelCommonStyles.css';
import { RightPanelWrapper } from '../../RightPanelWrapper/RightPanelWrapper';
import { StillNeedHelp } from '../../StillNeedHelp/StillNeedHelp';

const INITIAL_RESEND_TIMER = 10; // 10 seconds

export const ThankYou = React.memo(() => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [messageKey, setMessageKey] = useState<string>('REGISTER_IF_YOU_DO_NOT_RECEIVE_EMAIL');
  const authDataContext = useContext(AuthDataContext);
  const { email } = authDataContext.value;
  const intervalRef = useRef<NodeJS.Timeout>(); // Add a ref to store the interval id
  const [timeLeft, setTimeLeft] = useState<number>(INITIAL_RESEND_TIMER);
  const {
    iframeRef,
    iframeURL,
    setIsIframeCaptchaLoaded,
    getCaptchaToken,
    clearCaptchaData,
    showChallengeRecaptcha,
    captchaToken
  } = useCaptcha();

  useEffect(() => {
    if (captchaToken) {
      void fetchRequestResetPassword();
    }
  }, [captchaToken]);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTimeLeft((t) => t - 1);
    }, 1000);

    if (timeLeft <= 0) {
      clearInterval(intervalRef.current);
    }

    return () => clearInterval(intervalRef.current);
  }, [timeLeft]);

  const fetchRequestResetPassword = async () => {
    try {
      await UserService.requestResetPassword({
        email,
        resetPasswordConfirmationFormUrl: new URL(UserService.generateUrl()),
        registrationPlaceUrl: new URL(UserService.generateUrl()),
        captchaToken,
        captchaMode: showChallengeRecaptcha ? RECAPTCHA_MODES.CHALLENGE : undefined
      });
      handleRequestResetPasswordSuccess();
    } catch (err) {
      handleRequestResetPasswordError(err);
    } finally {
      setLoading(false);
    }
  };
  const handleRequestResetPasswordSuccess = () => {
    clearCaptchaData();
    setTimeLeft(INITIAL_RESEND_TIMER);
    setMessageKey('REGISTER_THE_EMAIL_HAS_BEEN_SENT_AGAIN');
    void Analytics.trackEvent(Analytics.profile.resetPassword('start'));
  };
  const handleRequestResetPasswordError = (err: number) => {
    setTimeLeft(INITIAL_RESEND_TIMER);
    dispatch(setShowRecaptcha(err === 1023));
    dispatch(
      setSnackbarData({
        isOpened: true,
        message: UserService.errorCodeToText(err),
        type: 'error',
        parentNode: 'rightSideMenu'
      })
    );
  };
  const handleResend = () => {
    if (email) {
      setLoading(true);
      getCaptchaToken(RECAPTCHA_ACTIONS.PASSWORD_RESET);
    }
  };
  const handleOk = () => {
    UserService.closePanel();
  };

  return (
    <RightPanelWrapper headerLabelKey="REGISTER_THANK_YOU">
      {!showChallengeRecaptcha && (
        <>
          <div className={styles.subHeader}>
            <I18nText keyName={'REGISTER_WE_SENT_AN_EMAIL_TO'} />{' '}
            <span className={styles.subHeaderEmail}>{email}</span>{' '}
            <I18nText keyName={'REGISTER_WITH_INSTRUCTIONS'} />
          </div>
          <div className={styles.receiveText}>
            <I18nText keyName={messageKey} />
          </div>
          <div className={classNames(styles.buttonsRow, styles.resendBtnsRow)}>
            <Button outlined loading={loading} onClick={handleResend} disabled={Boolean(timeLeft)}>
              {timeLeft ? String(timeLeft) : <I18nText keyName={'REGISTER_RESEND'} />}
            </Button>
            <Button onClick={handleOk}>
              <I18nText keyName={'REGISTER_OK'} />
            </Button>
          </div>
          <StillNeedHelp />
        </>
      )}
      <CaptchaIframe iframeRef={iframeRef} iframeURL={iframeURL} setIsIframeCaptchaLoaded={setIsIframeCaptchaLoaded} />
    </RightPanelWrapper>
  );
});
ThankYou.displayName = 'ThankYou';
