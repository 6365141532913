import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useVisibilityAPI } from '../../services/hooks/useVisibilityAPI';
import { favoritesService } from './FavoritesService';

export const FavoritesHandlerFuncComponent = function () {
    // Favorites list update
    const user = useSelector((state) => state.user);
    useEffect(() => {
        favoritesService.updateFavorites();
    }, [user]);
    useVisibilityAPI({ onVisible: this.updateFavorites }); // if changed in another tab
    return <></>;
}.bind(favoritesService);
