import React, { useEffect, useState } from 'react';

import classnames from 'classnames';


import { AddGamePwaTutorial } from './AddGamePwaTutorial';
import { ButtonsBlock } from './ButtonsBlock';
import { CategoriesBody } from './CategoriesBody';
import { CategoriesTitle } from './CategoriesTitle';
import { IconBack } from './IconBack';
import { SearchField } from './SearchInput';
import styles from './styles.css';
import { ArkadiumFullLogo } from '../../../atoms/Icons/Styleguide/ArkadiumLogo/ArkadiumLogo';
import { IGame } from '../../../models/Game/Game';
import { PwaPlatformsMobile } from '../../../services/Analytics/AnalyticsEventBuilder';
import { PWAService } from '../../../services/PWAService';
import { MobileNavButtonsNames } from '../MobileNavigationUxRedesigned.types';

export type SharedProps = {
  activeButtonName?: MobileNavButtonsNames;
  isItGamePage?: boolean;
  isHomePage?: boolean;
  games?: IGame[];
  style?: React.CSSProperties;
};

export const MenuBody = (props: SharedProps) => {
  const { activeButtonName } = props;
  const style = props.style || {};
  const getSafariVhAddressBarHeightStyleFix = () => ({
    paddingTop: `calc(${(window.outerHeight - window.innerHeight)}px - 4rem)`
  });
  const [safariVhAddressBarHeightStyleFix, setSafariVhAddressBarHeightStyleFix] = useState<any>({});
  const handleSafariVhAddressBarHeightStyleFix = () => {
    PWAService.getDeviceOs() === PwaPlatformsMobile.IOS &&
    window?.outerHeight !== window?.innerHeight &&
    setSafariVhAddressBarHeightStyleFix(getSafariVhAddressBarHeightStyleFix());
  };
  const isAndroid = PWAService.getDeviceOs() === PwaPlatformsMobile.ANDROID;

  useEffect(() => {
    handleSafariVhAddressBarHeightStyleFix();
    window.addEventListener('resize', handleSafariVhAddressBarHeightStyleFix);
    return window.removeEventListener('resize', handleSafariVhAddressBarHeightStyleFix);
  }, []);
  return (
    <div className={classnames(styles.Root, {
      [styles.isAndroid]: isAndroid
    })} style={{ ...style, ...safariVhAddressBarHeightStyleFix }}>
      {activeButtonName === MobileNavButtonsNames.MENU && (
        <>
          <div className={classnames(styles.Row, styles.__RowLogo)}>
            <IconBack />
            <ArkadiumFullLogo className={styles.ArkLogo} />
          </div>
          <div className={classnames(styles.Row, styles.__RowSearch)}>
            <SearchField />
          </div>
          <div className={classnames(styles.Row, styles.__RowButtons)}>
            <ButtonsBlock {...props} />
          </div>
          <div className={classnames(styles.Row, styles.__RowCategoriesTitle)}>
            <CategoriesTitle />
          </div>
          <div className={classnames(styles.Row, styles.__RowCategories)}>
            <CategoriesBody games={props.games} />
          </div>
        </>
      )}
      {/* @ts-ignore */}
      {activeButtonName === MobileNavButtonsNames.ADD_GAME && <AddGamePwaTutorial {...props} />}
    </div>
  );
};
