import { AnalyticsEvent, AnalyticsEventAction, AnalyticsEventBuilder } from '../AnalyticsEventBuilder';

export interface MainModuleCD {
    moduleScreenId: string;
    ctaText: string;
}

export interface ColumnModuleCD extends MainModuleCD {
    columnIndex: number;
    shortTitle: string;
}

export class PageBuilderAdvantageAnalyticsAi {
    async MainModule(CD: MainModuleCD): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        const moduleID = CD.moduleScreenId || 'MainModule';
        event.setName(moduleID);
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        Object.keys(CD).forEach((cd) => {
            event.setCustom(cd, CD[cd]);
        });
        return event.build();
    }
    async ColumnsModule(CD: ColumnModuleCD, isImpression?: boolean): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('ColumnsModule');
        if (isImpression) {
            event.setEventAction(AnalyticsEventAction.IMPRESSION);
        } else {
            event.setEventAction(AnalyticsEventAction.CLICK);
        }
        event.setNonInteraction(false);
        Object.keys(CD).forEach((cd) => {
            event.setCustom(cd, CD[cd]);
        });
        return event.build();
    }

    async comingSoonCTAClick(name: string): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();

        event.setName(name);
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        return event.build();
    }

    private async newEventBuilder(): Promise<AnalyticsEventBuilder> {
        return await new AnalyticsEventBuilder().baseEvent();
    }
}
