import React, { useState } from 'react';

import { useDispatch } from 'react-redux';

import { TextField, TextFieldTypes } from '../../../atoms/TextField/TextField';
import { Analytics } from '../../../services/Analytics/Analytics';
import { TranslationService } from '../../../services/TranslationService';
import { setMobileNavigationUxRedesignedActiveButton } from '../MobileNavigationUxRedesign.ducks';
import { MobileNavButtonsNames } from '../MobileNavigationUxRedesigned.types';

export const SearchField = (props: { onFocus?: () => void }) => {
    const dispatch = useDispatch();
    const [t, setT] = useState('');

    return (
        <TextField
            value={t}
            onChange={(v) => setT(v)}
            inputType={TextFieldTypes.SEARCH_INPUT}
            placeholder={TranslationService.translateIt('SEARCH_GAME_PLACEHOLDER')}
            focusOnLoad={false}
            onClick={(e) => {
                e.preventDefault(); // prevent own focus for caller-field
                Analytics.trackEvent(Analytics.mobileNavMenuUxRedesigned.bottomNavMenu('Search'));
                dispatch(setMobileNavigationUxRedesignedActiveButton(MobileNavButtonsNames.SEARCH));
            }}
        />
    );
};
