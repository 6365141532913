import React from 'react';

type TabRouterProps = {
  activeState: string;
  componentsMap: Map<string, React.ElementType>;
  key?: string;
  keepAlive?: string;
  [key: string]: unknown;
};

export const TabRouter = React.memo(({ activeState, componentsMap, keepAlive, ...rest }: TabRouterProps) => {
  const Component = componentsMap.get(activeState);
  const KeepAliveComponent = keepAlive ? componentsMap.get(keepAlive) : null;

  return (
    <>
      {KeepAliveComponent && <KeepAliveComponent {...rest} keepAliveStatus={keepAlive === activeState}/>}
      {keepAlive !== activeState && Component && <Component {...rest} activeState={activeState}/>}
    </>
  );
});
TabRouter.displayName = 'TabRouter';
