import { ArenaEnvironmentType } from '@arkadium/modules';
import Cookies from 'js-cookie';

import { EnvironmentName } from '../client/constants/Environment';
import { pushSupportedPages } from '../client/constants/PushSupportedPages';
import { pagesToRemoveGoogleVignette } from '../client/constants/PagesToRemoveGoogleVignette';
import { WIDTH_QUERY } from '../client/models/Enums';
import { RegistrationSource } from '../client/models/User/RegistrationSource';


enum AuthType {
  EmailPassword = 'EmailPassword',
  Facebook = 'Facebook',
  Google = 'Google',
}

type Options = {
  retries?: number;
  interval?: number;
  exponentialBackoff?: boolean;
};

export type SdkInfo = {
  GameKey: string;
  GameVersion: string;
  GameRelease: string;
  NestVersion: string;
  SdkVersion: string;
};

export class MiscUtils {
  public static get isServer(): boolean {
    return typeof window === 'undefined';
  }

  public static matchWidth(query: WIDTH_QUERY, value: string, ssrResult = false): boolean {
    if (MiscUtils.isServer) {
      return ssrResult;
    }

    const queryString = `(${query}: ${value}px)`;

    return window.matchMedia(queryString).matches;
  }

  public static clone(obj: {}): any {
    return JSON.parse(JSON.stringify(obj));
  }

  public static isNumber(x: any): x is number {
    return typeof x === 'number';
  }

  public static isString(x: any): x is string {
    return typeof x === 'string';
  }

  public static isBoolean(x: unknown): x is boolean {
    return typeof x === 'boolean';
  }

  public static random(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  public static isAdBlockEnabled(): boolean {
    return (window as any).__ark_ads__ === undefined || typeof (window as any).__ark_ads__.AdVideo !== 'function';
  }

  public static pushNotificationsValidator(urlKey: string) {
    return !!pushSupportedPages.find(function (item) {
      return item === urlKey;
    });
  }

  public static getNowTimestampUTC(): number {
    return new Date().getTime();
  }

  public static fixMissingLang(lang: string): string {
    if (lang === 'es' || lang === 'de') {
      return 'en';
    }

    return lang;
  }

  public static debounce(wrappedFunction: () => any, delayInMilliSeconds: number): () => any {
    let debounceTimerId: number;

    return (...args) => {
      clearTimeout(debounceTimerId);
      debounceTimerId = window.setTimeout(() => {
        wrappedFunction.apply(this, args);
      }, delayInMilliSeconds);
    };
  }

  public static numberWithCommas(num: string | number): string {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  public static loadableRetry<R>(
    fn: () => Promise<R>,
    { retries = 3, interval = 500, exponentialBackoff = true }: Options = {}
  ) {
    return new Promise<R>((resolve, reject) => {
      fn()
        .then(resolve)
        .catch((error) => {
          setTimeout(() => {
            if (retries === 0) {
              reject(error);
              return;
            }

            // Passing on "reject" is the important part
            MiscUtils.loadableRetry(fn, {
              retries: retries - 1,
              interval: exponentialBackoff ? interval * 2 : interval
            }).then(resolve, reject);
          }, interval);
        });
    });
  }

  public static getAuthSource(authProvider: string): RegistrationSource {
    let source = RegistrationSource.EMAIL; // default

    if (authProvider) {
      authProvider === AuthType.Facebook && (source = RegistrationSource.FACEBOOK);
      authProvider === AuthType.Google && (source = RegistrationSource.GOOGLE);
    }

    return source;
  }

  public static async initArenaApi(): Promise<SdkInfo> {
    if (MiscUtils.isServer) {
      return {} as SdkInfo;
    }

    const arenaApi = (window as any).__ArenaApi__;

    if (arenaApi) {
      const sdkApi = await arenaApi.getInstance(
        ['production', 'canary', 'staging', 'games-staging'].includes(BUILD_ENV) ? 'PROD' : 'DEV'
      );

      return sdkApi.analytics.getEnvVersion();
    }

    return {} as SdkInfo;
  }

  public static async getSdKInfo(): Promise<SdkInfo> {
    return MiscUtils.initArenaApi().then((info) => {
      return info;
    });
  }

  public static adaptEnvNameToMatchArenaEnvs(env: EnvironmentName): ArenaEnvironmentType {
    switch (env) {
      case EnvironmentName.STAGING:
      case EnvironmentName.GAMESSTAGING:
      case EnvironmentName.CANARY:
      case EnvironmentName.PRODUCTION:
        return 'prod';
      default:
        return 'dev';
    }
  }

  public static isPagesToRemoveGoogleVignette(pathname, origin) {
    const normalizedPathname = pathname.toLowerCase();
    const normalizedOrigin = origin.toLowerCase();

    return pagesToRemoveGoogleVignette.some(substring =>
        normalizedPathname.includes(substring) || normalizedOrigin.includes(substring)
    );
  }
}
