import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';

import styles from './CategoryCard.css';
import { getCategoryFileURL } from './CategoryCardSettings';
import { StringUtils } from '../../../../utils/StringUtils';
import { NavLink } from '../../../atoms/Link/Link';
import { ImageTypes, LazyImage } from '../../../atoms/ProgressiveImage/LazyImage';
import { PageName, PageTypes } from '../../../constants/Pages';
import { UrlService } from '../../../services/UrlService';

type CategoryCardProps = {
    caption: string;
    cardType: PageName;
    currentLang: string;
    categorySlug: string;
    onClick?: (e: any) => void;
};

export const CategoryCard = React.memo((props: CategoryCardProps) => {
    const href = StringUtils.equalIgnoreCase(props.cardType, 'All')
        ? UrlService.createURL(`/${UrlService.getPageLocalizedRoute(props.currentLang, PageTypes.Category)}/`)
        : UrlService.createURL(
              `/${UrlService.getPageLocalizedRoute(props.currentLang, PageTypes.Category)}/${props.categorySlug}/`
          );
    const [userPressedItem, setUserPressedItem] = useState(false);
    const categoryPromoData = useSelector((state) => state.categoryPromoData);
    const onMouseUp = () => setUserPressedItem(false);

    useEffect(() => {
        window.document.addEventListener('mouseup', onMouseUp);
        window.document.addEventListener('touchend', onMouseUp);
        window.document.addEventListener('touchcancel', onMouseUp);

        return () => {
            window.document.removeEventListener('mouseup', onMouseUp);
            window.document.removeEventListener('touchend', onMouseUp);
            window.document.removeEventListener('touchcancel', onMouseUp);
        };
    });

    let cardStyle;

    if (
        StringUtils.equalIgnoreCase(props.cardType, 'Hidden Object') ||
        StringUtils.equalIgnoreCase(props.cardType, `Stan's Puzzles`) ||
        StringUtils.equalIgnoreCase(props.cardType, `Game Shows`) ||
        StringUtils.equalIgnoreCase(props.cardType, `Daily Games`) ||
        StringUtils.equalIgnoreCase(props.cardType, `Multiplayer Games`) ||
        StringUtils.equalIgnoreCase(props.cardType, `Car Games`) ||
        StringUtils.equalIgnoreCase(props.cardType, `Browser Games`) ||
        StringUtils.equalIgnoreCase(props.cardType, `Flash Games`) ||
        StringUtils.equalIgnoreCase(props.cardType, `Trivia Games`) ||
        StringUtils.equalIgnoreCase(props.cardType, `Pool Games`) ||
        StringUtils.equalIgnoreCase(props.cardType, `Block Games`) ||
        StringUtils.equalIgnoreCase(props.cardType, `3D Games`) ||
        StringUtils.equalIgnoreCase(props.cardType, `Bubble Games`)
    ) {
        cardStyle = styles[props.categorySlug];
    } else {
        cardStyle = styles[props.cardType.toLowerCase()];
    }

    return (
        <NavLink
            className={classNames(styles.categoryCard, cardStyle, {
                [styles.pressedState]: userPressedItem,
            })}
            to={href}
            onMouseDown={() => setUserPressedItem(true)}
            onTouchStart={() => {
                setUserPressedItem(true);
            }}
            onClick={props.onClick}
            data-element-description="category-card"
        >
            <div className={styles.cardContainer}>
                <div className={styles.categoryCardBack}>
                    <LazyImage
                        imageType={ImageTypes.SQUARE}
                        img={
                            categoryPromoData?.categoryName === props.cardType
                                ? categoryPromoData?.categoryListImage?.url
                                : getCategoryFileURL(props.cardType.replace(/'|\s+/g, ''), 'svg')
                        }
                        className={styles.categoryImage}
                        alt={props.caption}
                        aria-hidden="true"
                    />
                </div>
                <div className={styles.categoryCardCaption}>{props.caption}</div>
            </div>
        </NavLink>
    );
});
