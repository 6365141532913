import { SubscriptionSourceType } from '../../../models/Subscription/SubscriptionData';
import { AnalyticsEvent, AnalyticsEventAction, AnalyticsEventBuilder } from '../AnalyticsEventBuilder';

export class GiftCardAnalyticsAi {
    private async newEventBuilder(): Promise<AnalyticsEventBuilder> {
        return await new AnalyticsEventBuilder().baseEvent();
    }

    async gifterStart(location: SubscriptionSourceType): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('gifterStart');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setCustom('subscriptionLocation', location);
        event.setNonInteraction(false);
        return event.build();
    }

    async giftRecipientName(location: SubscriptionSourceType): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('giftRecipientName');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setCustom('subscriptionLocation', location);
        event.setNonInteraction(false);
        return event.build();
    }

    async giftRecipientEmail(location: SubscriptionSourceType): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('giftRecipientEmail');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setCustom('subscriptionLocation', location);
        event.setNonInteraction(false);
        return event.build();
    }

    async giftConfirmEmail(location: SubscriptionSourceType): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('giftConfirmEmail');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setCustom('subscriptionLocation', location);
        event.setNonInteraction(false);
        return event.build();
    }

    async giftDeliveryDate(location: SubscriptionSourceType): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('giftDeliveryDate');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setCustom('subscriptionLocation', location);
        event.setNonInteraction(false);
        return event.build();
    }

    async giftCustomMessage(location: SubscriptionSourceType): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('giftCustomMessage');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setCustom('subscriptionLocation', location);
        event.setNonInteraction(false);
        return event.build();
    }

    async giftSenderName(location: SubscriptionSourceType): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('giftSenderName');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setCustom('subscriptionLocation', location);
        event.setNonInteraction(false);
        return event.build();
    }

    async giftSenderEmail(location: SubscriptionSourceType): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('giftSenderEmail');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setCustom('subscriptionLocation', location);
        event.setNonInteraction(false);
        return event.build();
    }

    async gifterNextStep(location: SubscriptionSourceType): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('gifterNextStep');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setCustom('subscriptionLocation', location);
        event.setNonInteraction(false);
        return event.build();
    }

    async gifterSendAgain(location: SubscriptionSourceType): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('gifterSendAgain');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setCustom('subscriptionLocation', location);
        event.setNonInteraction(false);
        return event.build();
    }

    async gifterFormViewable(location: SubscriptionSourceType): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('gifterFormViewable');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setCustom('subscriptionLocation', location);
        event.setNonInteraction(true);
        return event.build();
    }
}
