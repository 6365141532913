import React from 'react';

import { TFunction, WithTranslation, withTranslation } from 'react-i18next';
import { Action, Dispatch } from 'redux';

import styles from './CategorySearchTab.css';
import { DeviceUtils } from '../../../../utils/DeviceUtils';
import { IGame } from '../../../models/Game/Game';
import { CategoryPageSEO, CoBrandedPageSEO, GeneralPageSEO } from '../../../models/PagesData';
import { CategoryCard } from '../../../molecules/Cards/CategoryCard/CategoryCard';
import { Analytics } from '../../../services/Analytics/Analytics';
import { PageService } from '../../../services/PageService';
import { setSideMenuOpened, setSideMenuRequest } from '../../../store/ducks/layout';
import { SearchResultList } from '../SearchResultList/SearchResultList';

type CategorySearchTabProps =
  {
    games: IGame[];
    pages: (GeneralPageSEO | CategoryPageSEO | CoBrandedPageSEO)[];
    currentLang: string;
    currentRequest: string;
    extendMediaQuery: boolean;
    searchValue: string;
    dispatch?: Dispatch<Action>;
  }
  & WithTranslation;

export const CategorySearchTab = withTranslation()(
  React.memo((props: CategorySearchTabProps) => {
    const { games, pages, currentLang, currentRequest, extendMediaQuery, searchValue = '', dispatch, t } = props;
    const CATEGORIES_LIST = PageService.getSidebarLevelMenuItems(pages, currentLang, games).map((p) => p.name);
    const searchValueRef = DeviceUtils.isStyleguideMobileMode() ? currentRequest : searchValue;
    const cats = filterCategories(CATEGORIES_LIST, searchValueRef);
    const hasSearchResult = searchValueRef.length > 0 && cats.length > 0;
    const renderItem = renderItemFn.bind(null, t, games, pages, currentLang);

    function renderItemFn(
      t: TFunction,
      games: IGame[],
      pages: (GeneralPageSEO | CategoryPageSEO | CoBrandedPageSEO)[],
      currentLang: string,
      data: string,
      index: number
    ) {
      const category = PageService.getPageSEOByName(pages, data, currentLang);

      return (
        <CategoryCard
          key={index}
          cardType={category.pageName}
          categorySlug={category.slug[currentLang]}
          caption={category.name[currentLang]}
          currentLang={currentLang}
          onClick={() => {
            void Analytics.trackEvent(Analytics.general.searchBoxClick('category', category.name[currentLang]));
            dispatch(setSideMenuOpened(false));
            dispatch(setSideMenuRequest(''));
          }}
        />
      );
    }

    return (
      <>
        {hasSearchResult && (
          <>
            <SearchResultList
              heading="CATEGORIES_TAB_DID_FIND"
              items={cats}
              renderListItem={renderItem}
              className={styles.hasSearchResultHeading}
              extendMediaQuery={extendMediaQuery}
            />
          </>
        )}
      </>
    );
  })
);

function filterCategories(categories: string[], text: string): string[] {
  return categories.filter((it) => {
    return it.toLowerCase().includes(text.toLowerCase());
  });
}
