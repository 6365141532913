import React from 'react';

import { iconProps } from '../../../models/Icon/IconPropsModel';

export const WarningIcon = React.memo((props: iconProps) => {
    return (
        <svg
            role="img"
            aria-label="warning-icon"
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 34 34"
        >
            <g fill="none" fillRule="evenodd">
                <path d="M0 0H34V34H0z" />
                <path
                    fill="#DC1E34"
                    d="M17 25.32c1.52 0 2.927-.38 4.22-1.14 1.253-.72 2.24-1.707 2.96-2.96.76-1.293 1.14-2.7 1.14-4.22 0-1.52-.38-2.927-1.14-4.22-.72-1.253-1.707-2.24-2.96-2.96-1.293-.76-2.7-1.14-4.22-1.14-1.507 0-2.907.38-4.2 1.14-1.253.733-2.247 1.727-2.98 2.98-.76 1.293-1.14 2.693-1.14 4.2 0 1.507.38 2.907 1.14 4.2.72 1.253 1.707 2.247 2.96 2.98 1.293.76 2.7 1.14 4.22 1.14z"
                />
                <path
                    fill="#FFF"
                    d="M17.82 17.82L16.18 17.82 16.18 12.82 17.82 12.82zM17.82 21.18L16.18 21.18 16.18 19.5 17.82 19.5z"
                />
            </g>
        </svg>
    );
});
