import { createAction, createReducer } from 'redux-act';

type StrapiAsset = {
    url: string;
    // a lot of other fields that are not used
};

export type CategoryPromoType = {
    categoryName: string;
    promoButtonTextRegistered: string;
    promoButtonTextUnRegistered: string;
    promoPopupButtonText: string;
    promoPopupText: string;
    promoPopupTitle: string;
    promoText: string;
    promoTextClicked: string;
    promoTitle: string;
    createdAt: string;
    id: number;
    locale: string;
    publishedAt: string;
    updatedAt: string;
    pdf: StrapiAsset;
    topCategoryImage: StrapiAsset;
    promoLeftImage: StrapiAsset;
    promoBGImage: StrapiAsset;
    promoBGImageMobile: StrapiAsset;
    promoPopupBG: StrapiAsset;
    whiteThemeOn: boolean;
    categorySlug: string;
    categoryListImage: StrapiAsset;
};

// keys for this object are hardcoded as per business requirements
export type StrapiPromoType = {
    contentSpotlight: {
        contentSpotlight1: StrapiContentItem;
        contentSpotlight2: StrapiContentItem;
        contentSpotlight3: StrapiContentItem;
        contentSpotlight4: StrapiContentItem;
    };
    contentRail: {
        contentRail1: StrapiContentItem;
        contentRail2: StrapiContentItem;
        contentRail3: StrapiContentItem;
        contentRail4: StrapiContentItem;
    };
    contentCollection: {
        contentCollection1: StrapiContentItem;
        contentCollection2: StrapiContentItem;
        contentCollection3: StrapiContentItem;
    };
};

type ImageFormat = {
    ext: string;
    url: string;
    hash: string;
    mime: string;
    name: string;
    path: null | string;
    size: number;
    width: number;
    height: number;
};

// keeping all three types in case they change
type StrapiContentItem = {
    title: string;
    promoBoxEvent: string;
    sectionTitle: string;
    text: string;
    locale: string;
    image: {
        id: number;
        name: string;
        alternativeText: null | string;
        caption: null | string;
        width: number;
        height: number;
        formats: {
            small: ImageFormat;
            thumbnail: ImageFormat;
        };
        hash: string;
        ext: string;
        mime: string;
        size: number;
        url: string;
        previewUrl: null | string;
        provider: string;
        provider_metadata: null | any;
        folderPath: string;
        createdAt: string;
        updatedAt: string;
    };
    button: {
        id: number;
        url: string;
        label: string;
    };
};

export const setCategoryPromoData = createAction<CategoryPromoType>('set category promo data');
export const categoryPromoDataReducer = createReducer<CategoryPromoType>({}, null);
categoryPromoDataReducer.on(setCategoryPromoData, (_state, payload) => payload);

type categoryPromoStateProps = {
    isCollectBtnDisabled: boolean;
};
const initialData = {
    isCollectBtnDisabled: false,
};
export const setCategoryPromoState = createAction<categoryPromoStateProps>('set category promo state change');
export const categoryPromoStateReducer = createReducer<categoryPromoStateProps>({}, initialData);
categoryPromoStateReducer.on(setCategoryPromoState, (_state, payload) => payload);

export const setStrapiPromoData = createAction<StrapiPromoType>('set strapi promo data');
export const strapiPromoDataReducer = createReducer<StrapiPromoType>({}, null);
strapiPromoDataReducer.on(setStrapiPromoData, (_state, payload) => payload);
