import React from 'react';

import classNames from 'classnames';

import styles from './Block.css';

export type BlockProps = {
    as?: string;
    elevated?: boolean;
    children?: any;
    padding?: string;
    margin?: string;
    className?: string;
};

export const Block = React.memo(({ as, elevated, margin, className, padding, ...props }: BlockProps) => {
    const Component: any = as || 'div';

    return (
        <Component
            className={classNames(
                styles.base,
                {
                    [styles.elevated]: !!elevated,
                },
                className
            )}
            style={{
                margin: margin || undefined,
                padding: padding || undefined,
            }}
            {...props}
        />
    );
});

Block.displayName = 'Block';
