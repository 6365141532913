import React from 'react';

import { iconProps } from '../../models/Icon/IconPropsModel';

export const SubscriptionTabIcon = React.memo((props: iconProps) => {
    return (
        <svg
            role="img"
            aria-label="advantage-icon"
            className={props.className}
            width="35"
            height="34"
            viewBox="0 0 35 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.3349 5H28.6782C31.0902 5 32.6492 6.94285 32.1603 9.33962L29.4179 23.6604C28.9289 26.057 26.5771 28 24.1651 28H5.82184C3.4097 28 1.85081 26.057 2.33968 23.6604L5.08214 9.33962C5.57101 6.94285 7.92273 5 10.3349 5Z"
                stroke="#231F20"
                strokeWidth="2.5"
            />
            <path
                d="M16.3372 19.233C16.2679 19.1956 16.1967 19.1654 16.1243 19.1421C15.8239 19.0251 15.5069 18.9529 15.1885 18.9299C15.172 18.9288 15.1554 18.9279 15.1388 18.9274L14.9947 18.9227C14.9735 18.9221 14.9524 18.9219 14.9313 18.9223C14.5238 18.9299 14.1182 18.9793 13.7207 19.0696C13.674 19.0802 13.6285 19.0933 13.5844 19.1087L13.5174 19.1278C13.2088 19.2091 12.8954 19.2702 12.5792 19.3108L12.3366 19.3375L12.2665 19.3417C12.0239 19.3457 11.7733 19.2989 11.4959 19.1768C10.6983 18.7923 10.3922 18.1091 10.5339 17.4017C10.6327 16.9084 10.9482 16.4207 11.3517 16.0911C11.352 16.0908 11.3523 16.0906 11.3527 16.0903L17.8258 10.8316L17.8258 10.8316L17.8359 10.8233C18.6501 10.1475 18.8181 10.0424 19.1943 9.89096C19.4288 9.79655 19.6566 9.75061 19.8862 9.75001L19.9062 9.75018C20.2033 9.75595 20.4857 9.83103 20.7002 9.94179C20.7085 9.94603 20.7167 9.95018 20.725 9.95424C21.3024 10.2362 21.5715 10.5313 21.7284 10.8477C21.9044 11.2024 22.0082 11.7229 21.9995 12.5716L21.9994 12.5716V12.5845V21.176C21.9994 21.7394 21.8154 22.2829 21.5086 22.6612C21.2271 23.0084 20.8247 23.246 20.2375 23.2499C19.9656 23.2412 19.7067 23.1793 19.5065 23.0907C19.4982 23.087 19.4897 23.0834 19.4812 23.0798C19.0221 22.8886 18.7543 22.6026 18.4147 21.8544C18.3954 21.8118 18.3477 21.7013 18.2939 21.5765C18.2688 21.5184 18.2436 21.4597 18.2231 21.4126L18.1944 21.3472C18.1897 21.3365 18.1791 21.3126 18.1675 21.288L18.0405 21.0156C18.0364 21.0068 18.0321 20.9979 18.0278 20.9891C17.6591 20.2448 17.0704 19.6292 16.3372 19.233Z"
                stroke="#231F20"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
});
