import React from 'react';

import { iconProps } from '../../../models/Icon/IconPropsModel';

export const PlusIcon = React.memo((props: iconProps) => {
    return (
        <svg
            role="img"
            aria-label="plus-icon"
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 34 34"
        >
            <g fill="none" fillRule="evenodd">
                <path d="M0 0H34V34H0z" />
                <path
                    fill="currentColor"
                    d="M17 7c.828 0 1.5.672 1.5 1.5v7h7c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5h-7v7c0 .828-.672 1.5-1.5 1.5s-1.5-.672-1.5-1.5v-7h-7c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5h7v-7c0-.828.672-1.5 1.5-1.5z"
                />
            </g>
        </svg>
    );
});
