import { createAction, createReducer } from 'redux-act';

import { BillingInfoValues } from '../../../models/Subscription/PaymentForm';

export const billingInfoInitValues = {
    address1: '',
    city: '',
    country: '',
    postal_code: '',
    creditBrand: '',
    creditLastFour: '',
    state: '',
};

export const setBillingInfoValues = createAction<BillingInfoValues>('set user billing info');
export const billingInfoValuesReducer = createReducer<BillingInfoValues>({}, billingInfoInitValues);
billingInfoValuesReducer.on(setBillingInfoValues, (_state, payload) => payload);
