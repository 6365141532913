import React from 'react';

import { iconProps } from '../../../../models/Icon/IconPropsModel';

export const FlagFr = React.memo((props: iconProps) => {
    return (
        <svg
            role="img"
            aria-label="French"
            className={props.className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_503_9209)">
                <path
                    d="M12 24.375C18.0751 24.375 23 18.8345 23 12C23 5.16548 18.0751 -0.375 12 -0.375C5.92487 -0.375 1 5.16548 1 12C1 18.8345 5.92487 24.375 12 24.375Z"
                    fill="#F0F0F0"
                />
                <path
                    d="M23 12C23 6.95668 20.1393 2.6573 16.125 1V23C20.1393 21.3427 23 17.0433 23 12Z"
                    fill="#D80027"
                />
                <path d="M1 12C1 17.0433 3.86078 21.3427 7.875 23V1C3.86078 2.65731 1 6.9567 1 12Z" fill="#0052B4" />
            </g>
            <path
                d="M12 22C6.47715 22 2 17.5228 2 12H0C0 18.6274 5.37258 24 12 24V22ZM22 12C22 17.5228 17.5228 22 12 22V24C18.6274 24 24 18.6274 24 12H22ZM12 2C17.5228 2 22 6.47715 22 12H24C24 5.37258 18.6274 0 12 0V2ZM12 0C5.37258 0 0 5.37258 0 12H2C2 6.47715 6.47715 2 12 2V0Z"
                fill="#F9F9F9"
            />
            <defs>
                <clipPath id="clip0_503_9209">
                    <path
                        d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
});
