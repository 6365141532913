import React from 'react';

import { iconProps } from '../../../models/Icon/IconPropsModel';

export const ArrowSmallIcon = React.memo((props: iconProps) => {
    return (
        <svg
            className={props.className}
            role="img"
            aria-label="arrow-small-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 34 34"
        >
            <g fill="none" fillRule="evenodd">
                <path d="M0 0H34V34H0z" />
                <rect
                    width="3"
                    height="26"
                    x="15.5"
                    y="4.002"
                    fill="currentColor"
                    rx="1.5"
                    transform="rotate(90 17 17.002)"
                />
                <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    d="M22.491 24L29.5 16.927 22.491 10"
                />
            </g>
        </svg>
    );
});
