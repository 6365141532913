import React, { MouseEventHandler } from 'react';

import classNames from 'classnames';

import styles from './BorderedButton.css';
import { AppLoader } from '../../AppLoader/AppLoader';
import { I18nText } from '../../i18nText/i18nText';

type BorderedButtonProps = {
  caption: string;
  disabled?: boolean;
  submit?: boolean;
  filled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  dataElementDescription?: string;
  isLoading?: boolean;
};

export const BorderedButton = React.memo(
  ({
    caption,
    submit,
    filled,
    disabled,
    onClick,
    className,
    dataElementDescription,
    isLoading
  }: BorderedButtonProps) => {
    return (
      <button
        type={submit ? 'submit' : 'button'}
        className={classNames(
          styles.borderedButton,
          {
            [styles.filled]: filled
          },
          className
        )}
        disabled={disabled}
        onClick={onClick}
        data-element-description={dataElementDescription}
      >
        {isLoading ?
          <AppLoader className={styles.btnLoader}/> :
          <I18nText keyName={caption} className={styles.caption}/>}
      </button>
    );
  }
);
