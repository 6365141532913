import React, { FC } from 'react';

import classNames from 'classnames';

import { NavLink } from '../../../../../atoms/Link/Link';
import { HeaderSideMenuTabs } from '../../../../../constants/HeaderSideMenuTabs';
import { reduxStore } from '../../../../../store';
import { setSideMenuActivePage, setSideMenuOpened } from '../../../../../store/ducks/layout';
import styles from './AlreadySubscribedBlock.css';

interface AlreadySubscribedBlockProps {
    type: string;
}

const { store } = reduxStore;

export const AlreadySubscribedBlock: FC<AlreadySubscribedBlockProps> = React.memo(
    ({ type }: AlreadySubscribedBlockProps) => {
        const types = type === 'subscribed1' ? 'oneSubscription' : 'twoSubscriptions';

        return (
            <div className={classNames(styles.container, styles[types])}>
                {types === 'oneSubscription' && (
                    <p>
                        Your subscription was purchased through one of the app stores. Please visit the{' '}
                        <strong>App Store</strong> or <strong>Google Play</strong> to manage or cancel your subscription
                    </p>
                )}
                {types === 'twoSubscriptions' && (
                    <p>
                        Our records indicate that you purchased a subscription on the Arkadium website and through an
                        Arkadium game app. Please visit the <strong>App Store</strong> or <strong>Google Play</strong>{' '}
                        to cancel.
                    </p>
                )}
                <p className={styles.help}>
                    Need Help?{' '}
                    <NavLink
                        className={styles.link}
                        onClick={() => {
                            store.dispatch(setSideMenuActivePage(HeaderSideMenuTabs.HELP_TAB));
                            store.dispatch(setSideMenuOpened(true));
                        }}
                    >
                        Contact support
                    </NavLink>
                </p>
            </div>
        );
    }
);
