import React from 'react';

import { iconProps } from '../../../models/Icon/IconPropsModel';

export const ArrowDownIcon = React.memo(({ className }: iconProps) => {
    return (
        <svg
            className={className}
            role="img"
            aria-label="arrow-icon"
            width="14"
            height="12"
            viewBox="0 0 14 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill="currentColor"
                d="M12.71 5.29C12.617 5.19627 12.5064 5.12188 12.3846 5.07111C12.2627 5.02034 12.132 4.9942 12 4.9942C11.868 4.9942 11.7373 5.02034 11.6154 5.07111C11.4936 5.12188 11.383 5.19627 11.29 5.29L8 8.59V1C8 0.734784 7.89465 0.48043 7.70711 0.292893C7.51957 0.105357 7.26522 0 7 0C6.73479 0 6.48043 0.105357 6.2929 0.292893C6.10536 0.48043 6 0.734784 6 1V8.59L2.71 5.29C2.5217 5.1017 2.2663 4.99591 2 4.99591C1.7337 4.99591 1.47831 5.1017 1.29 5.29C1.1017 5.4783 0.995911 5.7337 0.995911 6C0.995911 6.2663 1.1017 6.5217 1.29 6.71L6.29 11.71C6.38511 11.801 6.49725 11.8724 6.62 11.92C6.7397 11.9729 6.86913 12.0002 7 12.0002C7.13087 12.0002 7.2603 11.9729 7.38 11.92C7.50275 11.8724 7.6149 11.801 7.71 11.71L12.71 6.71C12.8037 6.61704 12.8781 6.50644 12.9289 6.38458C12.9797 6.26272 13.0058 6.13201 13.0058 6C13.0058 5.86799 12.9797 5.73728 12.9289 5.61542C12.8781 5.49356 12.8037 5.38296 12.71 5.29Z"
            />
        </svg>
    );
});
