import React from 'react';

export enum CheckboxTypes {
    INACTIVE = 'inactive',
    ACTIVE = 'active',
    WARNING = 'warning',
}

type iconProps = {
    className?: string;
    iconType: CheckboxTypes;
};

export const CheckboxIcon = React.memo((props: iconProps) => {
    switch (props.iconType) {
        case CheckboxTypes.INACTIVE:
        case CheckboxTypes.WARNING:
            return <CheckboxEmptyIcon {...props} />;
        case CheckboxTypes.ACTIVE:
            return <CheckboxActiveIcon {...props} />;
        default:
            return null;
    }
});

const CheckboxEmptyIcon = React.memo((props: iconProps) => {
    const strokeColor = props.iconType === CheckboxTypes.WARNING ? '#DC1E34' : '#31323D';
    return (
        <svg
            role="img"
            aria-label="checkbox-empty-icon"
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 34 34"
        >
            <g fill="none" fillRule="evenodd">
                <path d="M0 0H34V34H0z" />
                <rect width="17" height="17" x="8.5" y="8.5" stroke={strokeColor} strokeWidth="3" rx="2" />
            </g>
        </svg>
    );
});

const CheckboxActiveIcon = React.memo((props: iconProps) => {
    return (
        <svg
            className={props.className}
            role="img"
            aria-label="checkbox-active-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 34 34"
        >
            <g fill="none" fillRule="evenodd">
                <path d="M0 0H34V34H0z" />
                <rect width="20" height="20" x="7" y="7" fill="currentColor" rx="2" />
                <path
                    fill="#FFF"
                    fillRule="nonzero"
                    d="M14.816 22.334L9.434 16.9 10.968 15.366 14.816 19.24 23.032 10.998 24.566 12.584z"
                />
            </g>
        </svg>
    );
});
