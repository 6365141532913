import React from 'react';

import { iconProps } from '../../../../models/Icon/IconPropsModel';

export const ArkadiumIcon = React.memo((props: iconProps) => {
    return (
        <svg
            aria-label="arkadium-logo"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M13.6324 4.48645L13.6325 4.48649L13.6405 4.47997C14.6916 3.63193 14.9699 3.44739 15.5498 3.22046C15.9199 3.07562 16.2933 3.0005 16.6771 3L16.7095 3.00027C17.1858 3.00872 17.6455 3.12444 18.0104 3.30756L18.03 3.31713C18.8705 3.71622 19.3241 4.16841 19.5926 4.69469C19.876 5.25007 20.0109 5.9994 19.9993 7.09499L19.9993 7.09499V7.10556V17.845C19.9993 19.5012 18.9472 20.994 17.1187 21C16.6869 20.988 16.2691 20.8938 15.9318 20.7486L15.9118 20.7403C15.1196 20.4195 14.6907 19.9117 14.2258 18.9157C14.1981 18.8563 14.1339 18.7116 14.0662 18.5588L14.0658 18.558C14.0336 18.4853 14.0016 18.4132 13.9758 18.3554C13.9571 18.3136 13.9309 18.255 13.9117 18.2154L13.7472 17.8725L13.7366 17.851C13.3137 17.021 12.6419 16.341 11.8132 15.9057C11.7534 15.8743 11.6918 15.8495 11.6293 15.8311C11.627 15.8302 11.6246 15.8293 11.6223 15.8284C11.2892 15.7008 10.9388 15.6228 10.5897 15.5983C10.5768 15.5974 10.5639 15.5968 10.551 15.5964L10.3657 15.5906C10.3492 15.5901 10.3328 15.59 10.3164 15.5903C9.83592 15.599 9.35751 15.6556 8.88837 15.7592C8.84656 15.7685 8.80594 15.7802 8.76662 15.7942L8.6541 15.8253C8.22708 15.935 7.79327 16.0173 7.35542 16.0717L7.02228 16.1074L6.90355 16.1143C6.48765 16.123 6.06761 16.044 5.62375 15.8525C4.35162 15.2606 3.82048 14.1572 4.05428 13.0221C4.20959 12.268 4.69475 11.5482 5.30808 11.0613C5.30833 11.0611 5.30858 11.0609 5.30883 11.0607L13.6324 4.48645Z"
                stroke="#231F20"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
});
