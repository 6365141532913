import React from 'react';

import classNames from 'classnames';

import { Button } from '../Buttons/Base/Base';
import { CheckboxIcon, CheckboxTypes } from '../Icons/Styleguide/CheckboxIcon';
import { WarningIcon } from '../Icons/Styleguide/WarningIcon';
import styles from './Checkbox.css';

type CheckboxProps = {
    className?: string;
    iconClassName?: string;
    labelClassName?: string;
    caption: string;
    state: CheckboxTypes;
    onClick: () => void;
    errorText?: string;
    dataElementDescription?: string;
    btnType?: 'submit' | 'reset' | 'button';
    required?: boolean;
};

export const Checkbox = React.memo(
    ({
        className,
        onClick,
        dataElementDescription,
        state,
        iconClassName,
        labelClassName,
        caption,
        errorText,
        btnType,
        required,
    }: CheckboxProps) => {
        const ERROR_ELEMENT_ID = 'checkbox-error-message';
        return (
            <div className={classNames(styles.container, className)}>
                <Button
                    className={styles.checkbox}
                    onClick={onClick}
                    data-element-description={dataElementDescription}
                    noPadding
                    role="checkbox"
                    ariaChecked={state === CheckboxTypes.ACTIVE}
                    type={btnType}
                    ariaDescribedBy={ERROR_ELEMENT_ID}
                    required={required}
                >
                    <CheckboxIcon iconType={state} className={iconClassName} />
                    <p className={classNames(styles.label, labelClassName)}>{caption}</p>
                </Button>

                {state === CheckboxTypes.WARNING && (
                    <p id={ERROR_ELEMENT_ID} className={styles.errorText}>
                        {errorText}
                    </p>
                )}

                {state === CheckboxTypes.WARNING && <WarningIcon className={styles.warningIcon} />}
            </div>
        );
    }
);
