import {
    AnalyticsEvent,
    AnalyticsEventAction,
    AnalyticsEventBuilder,
} from '../../services/Analytics/AnalyticsEventBuilder';
import { MobileNavButtonsNames } from './MobileNavigationUxRedesigned.types';

export class MobileNavMenuUxRedesignedAnalyticsAi {
    async bottomNavSlot(topnavClick: MobileNavButtonsNames): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('bottomNavSlot');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        await event.setCustom('topnavClick', topnavClick);
        return event.build();
    }

    async bottomNavMenu(topnavClick: string): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('bottomNavMenu');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        await event.setCustom('topnavClick', topnavClick);
        return event.build();
    }

    private async newEventBuilder(): Promise<AnalyticsEventBuilder> {
        return await new AnalyticsEventBuilder().baseEvent();
    }
}
