import { createAction, createReducer } from 'redux-act';

import { PublicUserModel, UserModel } from '../../models/User/UserModel';

export const setUser = createAction<UserModel | null>('set logged in user');
export const userReducer = createReducer<UserModel | null>({}, null);
userReducer.on(setUser, (_state, payload: UserModel | null) => payload);

export const setUserProcessed = createAction<boolean>('set user processed');
export const userProcessedReducer = createReducer<boolean>({}, false);
userProcessedReducer.on(setUserProcessed, (_state, payload: boolean) => payload);

export const setUserRegistrationCheckbox = createAction<boolean>('set user registration checkbox');
export const userRegistrationCheckboxReducer = createReducer<boolean>({}, false);
userRegistrationCheckboxReducer.on(setUserRegistrationCheckbox, (_state, payload: boolean) => payload);

export const setHostedLoginToken = createAction<string>('set hosted login token');
export const hostedLoginTokenReducer = createReducer<string | null>({}, null);
hostedLoginTokenReducer.on(setHostedLoginToken, (_state, payload: string) => payload);

type PublicProfileData = {
    publicUser: PublicUserModel | null;
    isMyProfile: boolean;
};

export const setPublicUser = createAction<PublicUserModel>('set public user profile');
export const setIsMyProfile = createAction<boolean>('set is my profile flag');
export const publicProfileReducer = createReducer<PublicProfileData>({}, { publicUser: null, isMyProfile: true });
publicProfileReducer.on(setPublicUser, (_state, payload: PublicUserModel) => ({
    ..._state,
    publicUser: payload,
}));
publicProfileReducer.on(setIsMyProfile, (_state, payload: boolean) => ({
    ..._state,
    isMyProfile: payload,
}));
