import React, { FC, useEffect, useState } from 'react';

import classNames from 'classnames';

import styles from './AnnualCard.css';
import { Button } from '../../FigmaStyleguide/Button/Button';
import { SubscriptionStar } from '../../FigmaStyleguide/SubscriptionStar/SubscriptionStar';
import { SubscriptionPlan } from '../../models/Subscription/SubscriptionData';
import { ManageType } from '../../organisms/HeaderSideMenu/SubscriptionTab/ManagePlan/ManagePlan';

type AnnualCardProps = {
  btnLabel: string;
  managePlanStyles?: boolean;
  manageType?: ManageType;
  plan: SubscriptionPlan;
  handlerBtn: () => void;
  monthlyPlan?: SubscriptionPlan;
};
export const AnnualCard: FC<AnnualCardProps> = React.memo(
  ({ btnLabel, managePlanStyles, manageType, plan, handlerBtn, monthlyPlan }: AnnualCardProps) => {
    AnnualCard.displayName = 'AnnualCard';

    const [planPriceLabel, setPlanPriceLabel] = useState<number>(null);
    const [planPriceFull, setPlanPriceFull] = useState<number>(null);
    const [currencySign, setCurrencySign] = useState<string>('');
    const [percentsSaved, setPercentsSaved] = useState<number | null>(null);

    useEffect(() => {
      const planFullPrice = plan?.currencies[0]?.unitAmount;
      const planPriceValue = planFullPrice / plan?.intervalLength;
      const planLabel = Math.floor(planPriceValue * 100) / 100;
      const planCurency = plan?.currencies[0]?.currency === 'USD' ? '$' : ''; // create a helper for getting currencies signs
      const monthlySubscrPayment = monthlyPlan ? monthlyPlan?.currencies[0]?.unitAmount : null;
      const savedSum =
        monthlySubscrPayment && monthlySubscrPayment > planPriceValue
          ? Math.floor(((monthlySubscrPayment - planPriceValue) / monthlySubscrPayment) * 100)
          : null;

      setPercentsSaved(savedSum);
      setPlanPriceFull(planFullPrice);
      setPlanPriceLabel(planLabel);
      setCurrencySign(planCurency);
    }, []);

    return (
      <div
        className={classNames(
          styles.container,
          managePlanStyles && styles.managePlanStyles,
          manageType === ManageType.UPDATE && styles.managePlanUpdate
        )}
      >
        <div className={styles.info}>
          <div>
            <div className={styles.heading}>Annual</div>
            <div>
              <span className={styles.price}>
                {currencySign}
                {planPriceLabel}
              </span>&nbsp;
              <span className={styles.monthLabel}>/month</span>
            </div>
          </div>
        </div>
        {manageType !== ManageType.UPDATE && (
          <div className={styles.btnRow}>
            <Button className={styles.btnSubscribe} onClick={handlerBtn}>
              {btnLabel}
            </Button>
          </div>
        )}

        <div className={styles.savingsInfo}>
          <div>
            Billed at{' '}
            <strong>
              {currencySign}
              {planPriceFull}
            </strong>
            /year – {!managePlanStyles && <br/>}that's a&nbsp;
            <strong>{percentsSaved ? `${percentsSaved}%` : '37%'}</strong> savings
          </div>
        </div>
        <div className={styles.saveStar}>
          <SubscriptionStar percentsSaved={percentsSaved}/>
        </div>
      </div>
    );
  }
);
